import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
// import { format } from 'date-fns';
import { get, toUpper } from 'lodash';
import { useIntl } from 'react-intl';
import { useAuth } from '@octopy/react-auth';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Hidden,
  IconButton,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { Menu, ExitToApp } from '@material-ui/icons';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useDrawer } from 'components/Drawer';
import { useSideMenu } from 'components/SideMenu';
import { Popover } from 'components/Popover';
import { Theme } from '../Theme';
import { useStyles } from './HeaderStyles';
import { Notifications } from './Notifications';
import { messages } from './HeaderMessages';
import { useApi } from 'hooks';

const Header = () => {
  const intl = useIntl();
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const { setVisible, mini } = useSideMenu();
  const { actions: authActions, auth } = useAuth();
  const { handleOpenDrawer } = useDrawer();
  const [anchorEl, setAnchorEl] = useState(null);
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));
  const storage = JSON.parse(localStorage.getItem('session'));
  const dataUser = get(storage, 'user', {});

  const [logoutService] = useApi({
    endpoint: 'auth/logout/',
    method: 'post'
  });

  const user = {
    name: get(dataUser, 'name', 'Default User')
  };

  const firstLetter = toUpper(user.name[0]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    const tokenRefresh = get(auth, 'refreshToken', '');

    const { headerResponse = {} } = await logoutService({
      body: { refresh_token: tokenRefresh }
    });

    if (headerResponse.code === 200) {
      authActions.logout();
      history.push('/login');
    }
  };

  const toolbarStyle = {
    [theme.direction === 'ltr' ? 'marginLeft' : 'marginRight']: mdUp
      ? theme.mixins.getSideMenu(mini).width
      : 0,
    transition: theme.transitions.create('margin', {
      duration: theme.transitions.duration.standard
    })
  };

  const handleOpenNotificationsModal = () => {
    handleOpenDrawer({
      closeButton: true,
      title: (
        <Box m={2}>
          <Typography className={classes.drawerTitle}>
            {intl.formatMessage(messages.alertTitle)}
          </Typography>
        </Box>
      ),
      body: <Notifications />,
      configProps: {
        anchor: 'right',
        transitionDuration: 1000
      }
    });
  };

  return (
    <div className={classes.root}>
      <AppBar
        color="default"
        position="fixed"
        className={classes.headerBackground}
      >
        <Toolbar style={toolbarStyle}>
          <Hidden mdUp implementation="css">
            <Tooltip title={intl.formatMessage(messages.openSideMenu)}>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="switch-sidemenu"
                onClick={() => setVisible((visible) => !visible)}
              >
                <Menu />
              </IconButton>
            </Tooltip>
          </Hidden>

          <Typography variant="h6" className={classes.title}>
            <Link to="/" className={classes.link}>
              <span
                role="img"
                aria-label="rocket"
                className={classes.titleContainer}
              >
                <span className={classes.titleDecoration}>|</span>
                <span className={classes.textTitleContainer}>
                  <span>{intl.formatMessage(messages.title)}</span>
                  <br />
                  <span className={classes.subtitle}>
                    {intl.formatMessage(messages.subtitle)}
                  </span>
                </span>
              </span>
            </Link>
          </Typography>
          <IconButton onClick={() => handleOpenNotificationsModal()}>
            {/* <Badge className={classes.badge}> */}
            <NotificationsIcon className={classes.notificationIcon} />
            {/* </Badge> */}
          </IconButton>
          <Box mr={2} ml="auto">
            <Button
              variant="text"
              aria-controls="locale-menu"
              aria-haspopup="true"
              onClick={handleClick}
              className={classes.label}
            >
              <Avatar>{firstLetter}</Avatar>
            </Button>

            <Popover
              id="locale-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <Box py={1}>
                <MenuItem onClick={handleClose}>
                  <Theme />
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <Box mx={1} onClick={handleLogout}>
                    <>
                      <IconButton edge="start" aria-label="change-theme">
                        <ExitToApp />
                      </IconButton>
                      {intl.formatMessage(messages.exitApp)}
                    </>
                  </Box>
                </MenuItem>
              </Box>
            </Popover>
          </Box>
        </Toolbar>
      </AppBar>
    </div>
  );
};

export { Header };
