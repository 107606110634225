import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { messages as formMessages } from '@octopy/react-form';
import { messages } from './BranchesMessages';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { useLoader } from '@octopy/react-loader';
import { get } from 'lodash';

export const useBranches = (branchOptions, editRobot) => {
  const intl = useIntl();
  const { modalSuccess } = useModalConfirmation();
  const { handleShowLoader } = useLoader();
  const history = useHistory();

  //table structure
  const tableHead = [
    { key: 'branchoffice_id', label: 'ID' },
    { key: 'name', label: 'NOMBRE' },
    { key: '', label: '' }
  ];

  const filters = [
    { key: 'name', value: 'NOMBRE' },
    { key: '', value: '' }
  ];

  const BranchListMapper = {
    branchoffice_id: 'id',
    name: 'name',
    audio_text: 'audio_text'
  };

  //Formik structure
  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );

  const initialValues = {
    mainBranch: ''
  };

  const getValidationRules = () =>
    Yup.object().shape({
      mainBranch: Yup.string().required(requiredFieldError)
    });

  const inputProps = {
    fullWidth: true
  };

  //Init Formik
  const formik = useFormik({
    initialValues,
    validationSchema: getValidationRules(),
    onSubmit: (values) => handleSubmit(values)
  });

  const formikfields = [
    Object.assign({
      type: 'select',
      name: 'mainBranch',
      label: intl.formatMessage(messages.mainBranchText),
      // placeholder: intl.formatMessage(messages.placeholderSpanishSpecialty),
      breakpoints: { xs: 9 },
      options: branchOptions,
      getOptionLabel: (option) => `${option.name || ''}`,
      getOptionValue: (option) => `${option.branchoffice_id || ''}`,
      getOptionSelected: (option, value) => option === value,
      inputProps: inputProps
    })
  ];

  const handleSubmit = async (values) => {
    handleShowLoader(true);

    const response = await editRobot({
      body: {
        main_branch_office: values.mainBranch
      }
    });
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code === 200) {
      modalSuccess(
        intl.formatMessage(messages.titleSuccessModalBranch),
        intl.formatMessage(messages.textMainBranchInfoSucces),
        () => {
          history.push('/robots');
        }
      );
    } else {
      handleShowLoader(false);
    }
  };

  return {
    tableHead,
    filters,
    BranchListMapper,
    formik,
    formikfields
  };
};
