import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Typography,
  Card,
  IconButton,
  CardContent,
  Grid,
  Button
} from '@material-ui/core';
import {
  renderTextField,
  renderAutocomplete,
  renderDatePicker
} from '@octopy/react-form';
import { useGraphicSurvey } from './useGraphicSurvey';
import { messages } from './GraphicSurveyMessages';
import { useStyles } from './GraphicSurveyStyles';
import { Images } from 'assets';
import { ChartSurvey } from './ChartSurvey';
import { HeaderTitle } from 'components/HeaderTitle';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { orderArray } from './utils';

const GraphicSurvey = () => {
  const classes = useStyles();
  const [showTable, setshowTable] = useState(false);
  const { formatMessage: f } = useIntl();
  const { excelIcon, zipIcon } = Images;
  const {
    formik,
    fieldsForm,
    totalAnswers,
    chartsData,
    donwload,
    mapSurveys,
    exportZip
  } = useGraphicSurvey();

  const fieldsMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'text' && renderTextField({ index, formik, field })}
      {field.type === 'select' && renderAutocomplete({ index, formik, field })}
      {field.type === 'datePicker' &&
        renderDatePicker({
          index,
          formik,
          field
        })}
    </Grid>
  );

  const chartsMapper = (chart, index) => (
    <ChartSurvey
      key={index}
      title={chart.question}
      subtitle={`${chart.totalResponses} ${f(messages.chartSubtitle)}`}
      data={chart.data}
      type={chart.type}
      id={index}
    />
  );

  const exportExcel = () => {
    setTimeout(() => {
      setshowTable(false);
    }, 1000);
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={2} direction="row">
        <HeaderTitle firstTitle={f(messages.title)} />
        <Grid>
          <Typography className={classes.subtitle} display="block">
            {f(messages.subtitle)}
          </Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        {fieldsForm.map(fieldsMapper)}
        <Grid container item xs={12} direction="row-reverse">
          <Button
            className={classes.button}
            onClick={() => formik.submitForm()}
          >
            <Typography>{f(messages.buttonAccept)}</Typography>
          </Button>
        </Grid>
      </Grid>

      <Card className={classes.titleCard}>
        <CardContent>
          <Grid container spacing={2} direction="row">
            <Grid item xs={6}>
              <Typography className={classes.title} display="block">
                {totalAnswers}
                {f(messages.mainCardTitle)}
              </Typography>
              <Typography className={classes.subtitle} display="block">
                {f(messages.mainCardSubtitle)}
              </Typography>
            </Grid>
            <Grid container item xs={6} direction="row-reverse">
              <label htmlFor="raised-button-file">
                <IconButton
                  disabled={!donwload}
                  component="span"
                  onClick={() => {
                    setshowTable(true);
                    exportExcel();
                  }}
                >
                  <img
                    src={excelIcon}
                    alt={'pdf-export-icon'}
                    className={classes.icon}
                  />
                </IconButton>
              </label>

              <IconButton
                disabled={!donwload}
                onClick={() => {
                  exportZip();
                }}
              >
                <img
                  src={zipIcon}
                  alt={'pdf-export-icon'}
                  className={classes.icon}
                />
              </IconButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      {chartsData.map(chartsMapper)}
      {showTable && (
        <table id="Survey-table">
          {mapSurveys(chartsData).map((item) => {
            const options = orderArray(Object.keys(item));
            const headers = options.map((question) => <th>{question}</th>);
            const body = options.map((question) => <td>{item[question]}</td>);

            return (
              <tbody>
                <tr>{headers} </tr>
                <tr>{body}</tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            );
          })}
        </table>
      )}

      <ReactHTMLTableToExcel
        className={classes.export}
        table={'Survey-table'}
        filename={'listado_de_encuestas'}
        sheet="tablexls"
        buttonText="Exportar CSV"
        id="raised-button-file"
      />
    </div>
  );
};

GraphicSurvey.propTypes = {};

export { GraphicSurvey };
