import { makeStyles } from '@material-ui/core/styles';

import robotImage from 'assets/images/metrics/background.png';
import { hexToRgba } from 'providers/theme/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(-4)
  },
  paralaxEffect: {
    backgroundImage: `url(${robotImage})`,
    height: theme.typography.pxToRem(300),

    backgroundAttachment: 'fixed',
    backgroundPosition: 'top',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'auto 50%'
  },
  paralaxTextCotainer: {
    position: 'relative',
    paddingLeft: theme.spacing(6),
    top: '40%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
      alignItems: 'center'
    }
  },
  welcomeHeader: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(45),
    fontWeight: 'bold'
  },
  username: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '6px'
  },
  welcomeRole: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'normal',
    marginTop: theme.spacing(1)
  },
  paperContainer: {
    backgroundColor: hexToRgba(theme.palette.text.secondary, 0.07),
    borderRadius: 0,
    padding: theme.spacing(6),
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(5)
    }
  },
  pageTitle: {
    fontSize: theme.typography.pxToRem(45),
    fontWeight: 'bold'
  },
  metricsContainer: {
    marginTop: theme.spacing(3)
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
    height: theme.typography.pxToRem(550),
    width: '100%'
  },
  cardTitle: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  title: {
    fontSize: '15px',
    letterSpacing: '1.5px',
    fontWeight: 800
  },
  cardTop: {
    padding: '20px'
  }
}));

export { useStyles };
