import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Form, messages as formMessages } from '@octopy/react-form';
import { Button, Typography } from '@material-ui/core';
import { useDrawer } from 'components/Drawer';
import * as Yup from 'yup';
import { inputProps } from '../utils';
import { messages } from './FormResolutionsMessages';
import { useStyles } from './FormResolutionsStyles';

const FormResolutions = ({ dataEdit = null }) => {
  const intl = useIntl();
  const classes = useStyles();
  const formikRef = useRef();
  const { handleCloseDrawer } = useDrawer();

  const getInitialValues = (initialValues) => ({
    name: dataEdit ? dataEdit.name : '',
    wide: dataEdit ? dataEdit.wide : '',
    height: dataEdit ? dataEdit.height : '',
    activate: dataEdit ? dataEdit.status : false,
    ...initialValues
  });

  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );

  const getValidationRules = () =>
    Yup.object({
      name: Yup.string().required(requiredFieldError),
      wide: Yup.string().required(requiredFieldError),
      height: Yup.string().required(requiredFieldError)
    });

  const getFields = () => [
    {
      section: 'input',
      type: 'text',
      name: 'name',
      label: intl.formatMessage(messages.nameLabel),
      placeholder: intl.formatMessage(messages.namePlaceholder),
      breakpoints: { xs: 12, lg: 10 }
    },
    {
      section: 'input',
      type: 'text',
      name: 'wide',
      label: intl.formatMessage(messages.wideLabel),
      placeholder: intl.formatMessage(messages.wideLabel),
      breakpoints: { xs: 12, lg: 10 }
    },
    {
      section: 'input',
      type: 'text',
      name: 'height',
      label: intl.formatMessage(messages.heightLabel),
      placeholder: intl.formatMessage(messages.heightLabel),
      breakpoints: { xs: 12, lg: 10 }
    },
    {
      section: 'switch',
      type: 'switch',
      name: 'activate',
      label: intl.formatMessage(messages.activate),
      breakpoints: { xs: 12 }
    }
  ];

  const sections = () => [
    {
      name: 'input',
      description: {
        text: (
          <Typography className={classes.subtitle}>
            {intl.formatMessage(messages.subtitle)}
          </Typography>
        )
      },
      editable: true
    },
    {
      name: 'switch',
      description: {
        text: (
          <Typography className={classes.switchText}>
            {intl.formatMessage(messages.switchText)}
          </Typography>
        )
      },
      editable: true
    }
  ];

  const handleSubmit = (formData) => {
    handleCloseDrawer();
  };

  return (
    <div className={classes.container}>
      <Form
        formikRef={formikRef}
        initialValues={getInitialValues()}
        validationRules={getValidationRules()}
        fields={getFields()}
        handleSubmit={handleSubmit}
        showSubmitButton={false}
        inputProps={inputProps}
        withInputsBorder
        sections={sections()}
      />

      <Button
        className={classes.buttonSave}
        onClick={() => formikRef.current.submitForm()}
      >
        {intl.formatMessage(messages.save)}
      </Button>
    </div>
  );
};

FormResolutions.propTypes = {};

export { FormResolutions };
