import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0),
      marginTop: theme.spacing(3),
      margin: `0 ${theme.spacing(1)}px`
    }
  },
  contentTitles: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  productsTitle: {
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800
  },
  itemsTitle: {
    fontSize: '20px',
    letterSpacing: '6px',
    color: theme.palette.primary.main,
    fontWeight: 800
  },
  contentButons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      marginTop: '15px'
    }
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  contentTable: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(7)
    },
    '& td:nth-child(3)': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis !important',
      maxWidth: '100px'
    }
  }
}));

export { useStyles };
