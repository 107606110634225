import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleDesignScene: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.titleDesignScene',
    defaultMessage: 'Diseñar escena: '
  },
  textResolution: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.textResolution',
    defaultMessage: 'Resolución: '
  },
  textOrientationHorizontal: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.textOrientationHorizontal',
    defaultMessage: 'Ver horizontal'
  },
  buttonSave: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.buttonSave',
    defaultMessage: 'GUARDAR'
  },
  titleAddMedia: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.titleAddMedia',
    defaultMessage: 'Agregar medios'
  },
  buttonImage: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.buttonImage',
    defaultMessage: 'Imagen'
  },
  buttonVideo: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.buttonVideo',
    defaultMessage: 'Video'
  }
});
