import { useState, useEffect } from 'react';
import { useApi } from 'hooks';
import { responseMapper } from 'utils/responseMapper';
import { get } from 'lodash';

const templateInformation = {
  id: 'information_item_id',
  name: 'title',
  description: 'description'
};

export const useApiInformation = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = get(storage, 'user.client.client_id', {});
  const [informationList, setInformationList] = useState([]);

  const [getInformationByClientId] = useApi({
    endpoint: `/information/information/information_items_by_client/${clientId}/`,
    method: 'get'
  });
  const [deleteInformation] = useApi({
    endpoint: 'information/information',
    method: 'delete'
  });

  const handleGetInformation = async () => {
    const { headerResponse, payload } = await getInformationByClientId();

    if (headerResponse.code === 200) {
      const mapperInformation = responseMapper({
        template: templateInformation,
        data: payload
      });

      setInformationList(mapperInformation);
    }
  };
  const handleDeleteInformation = async (idInformation) => {
    const { headerResponse } = await deleteInformation({
      urlParams: idInformation
    });

    if (headerResponse.code === 200) {
      handleGetInformation();
    }
  };

  useEffect(() => {
    handleGetInformation();
  }, []);

  return {
    informationList,
    handleDeleteInformation
  };
};
