const tableHead = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: 'NOMBRE' },
  { key: 'description', label: 'DESCRIPCIÓN' },
  { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false }
];

const filters = [
  { key: 'name', value: 'NOMBRE' },
  { key: 'description', value: 'DESCRIPCIÓN' }
];

export { tableHead, filters };
