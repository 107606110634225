import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTextBotSelect } from './useTextBotSelect';
import { Grid, TextField, Button } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { messages } from './TextBotSelectMessages';
import { useStyles } from './TextBotSelectStyles';
import { useIntl } from 'react-intl';
import { SoundPlayer } from 'components/SoundPlayer';

const TextBotSelect = ({
  textBot,
  textBotMessage,
  idBot,
  idBotMessage,
  onHandleChangeText,
  onHandleChangeBot,
  isEnglish,
  isQuimico
}) => {
  const { modalReportProblem } = useModalConfirmation();
  const intl = useIntl();
  const classes = useStyles();
  const {
    disabledButton,
    robots,
    inputValue,
    setInputValue,
    value,
    setValue,
    preview,
    setPreview,
    audioUrl,
    handlePreviewSpeech
  } = useTextBotSelect(idBot, isEnglish, isQuimico);

  useEffect(() => {
    if (disabledButton) {
      modalReportProblem(
        intl.formatMessage(messages.errorRobot),
        intl.formatMessage(messages.infoText),
        intl.formatMessage(messages.suggestionText)
      );
      setPreview(false);
    }
  }, [disabledButton]);

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Grid container>
          <TextField
            error={textBotMessage}
            helperText={<span style={{ color: 'red' }}>{textBotMessage}</span>}
            fullWidth
            value={textBot}
            onChange={(event) => {
              onHandleChangeText(event.target.value);
            }}
            label={
              isEnglish
                ? intl.formatMessage(messages.TextLabelEnglish)
                : intl.formatMessage(messages.TextLabelSpanish)
            }
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
            onHandleChangeBot(newValue);
          }}
          inputValue={inputValue}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          options={robots}
          getOptionLabel={(option) => option.label}
          renderInput={(params) => (
            <TextField
              {...params}
              error={textBotMessage}
              helperText={<span style={{ color: 'red' }}>{idBotMessage}</span>}
              label={
                isEnglish
                  ? intl.formatMessage(messages.BotLabelEnglish)
                  : intl.formatMessage(messages.BotLabelSpanish)
              }
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid
        container
        spacing={3}
        justify="flex-end"
        alignItems="center"
        style={{ marginTop: 5 }}
      >
        <Grid item>{preview && <SoundPlayer src={audioUrl} />}</Grid>
        <Grid item>
          <Button
            disabled={!textBot || !idBot || disabledButton}
            className={classes.button}
            onClick={() => {
              handlePreviewSpeech(textBot);
            }}
          >
            {isEnglish
              ? intl.formatMessage(messages.previewEnglish)
              : intl.formatMessage(messages.previewSpanish)}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

TextBotSelect.defaultProps = {
  isQuimico: false
};

TextBotSelect.propTypes = {
  isQuimico: PropTypes.bool
};

export { TextBotSelect };
