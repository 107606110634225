import React, { useState, useEffect } from 'react';
import { Table } from '@octopy/react-table';
import { useTable } from './useTableTransferList';
import { Grid, Typography } from '@material-ui/core';
import { messages } from './TableTransferListMessages';
import { useStyles } from './TableTransferListStyles';
import { useIntl } from 'react-intl';
import xorBy from 'lodash/xorBy';
import cloneDeep from 'lodash/cloneDeep';

const not = (a, b) => a.filter((value) => b.indexOf(value) === -1);

const intersection = (a, b) => a.filter((value) => b.indexOf(value) !== -1);

const TableTransferList = ({
  tableHead,
  filters,
  data,
  labelTotal,
  changeData,
  leftAux,
  id,
  onChange
}) => {
  const { formatMessage: f } = useIntl();
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [handle, setHandle] = useState(false);

  useEffect(() => {
    if (leftAux) {
      let newRight = xorBy(data, leftAux, id);

      setRight(newRight);
    } else {
      setRight(data);
    }
  }, [data, leftAux]);

  useEffect(() => {
    if (leftAux && !handle) {
      let scenceLeftApi = cloneDeep(leftAux);
      let auxRight = cloneDeep(right);

      setLeft(scenceLeftApi);

      let newRight = xorBy(auxRight, scenceLeftApi, `${id}`);

      setRight(newRight);
    }
  }, [leftAux]);

  const handleToggleAdd = (value) => {
    setHandle(true);
    const currentIndex = checked.indexOf(value);
    const newChecked = checked.slice();

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    //se ejecuta automatico para pasar los valores a la lista de la izquierda
    let aux = intersection(newChecked, right);

    setLeft(left.concat(aux));
    changeData(left.concat(aux));
    setRight(not(right, aux));
    setChecked(not(newChecked, aux));
  };

  const handleToggleDelete = (value) => {
    setHandle(true);

    const currentIndex = checked.indexOf(value);
    const newChecked = checked.slice();

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    //se ejecuta automatico para pasar los valores a la lista de la izquierda
    let aux = intersection(newChecked, left);

    setRight(right.concat(aux));
    setLeft(not(left, aux));
    changeData(not(left, aux));
    setChecked(not(newChecked, aux));
  };

  const { configPropsLeft, configPropsRight } = useTable(
    handleToggleAdd,
    handleToggleDelete,
    filters
  );

  const customList = (items, isLeft) => {
    if (isLeft) {
      return (
        <Table columns={tableHead} data={items} configProps={configPropsLeft} />
      );
    } else {
      return (
        <Table
          columns={tableHead}
          data={items}
          configProps={configPropsRight}
        />
      );
    }
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="flex-start"
      xs={12}
    >
      <Grid item xs={12}>
        <Typography className={classes.containerText}>
          <b>{labelTotal}</b>
          &nbsp;
          {left.length}
          &nbsp;{f(messages.unionOf)}&nbsp;
          {data.length}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.tableTransferList}>
        {customList(left, true)}
      </Grid>
      <Grid item xs={12} sm={12} md={6} className={classes.tableTransferList}>
        {customList(right, false)}
      </Grid>
    </Grid>
  );
};

export { TableTransferList };
