const inputProps = {
  variant: 'outlined',
  fullWidth: true,
  size: 'medium'
};

const tableHead = [
  { key: 'subcategory_id', label: 'ID' },
  { key: 'name', label: 'NOMBRE' },
  { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false }
];

export { inputProps, tableHead };
