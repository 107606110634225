import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5),
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(8)
    }
  },
  textSection: {
    fontSize: theme.typography.pxToRem(16),
    letterSpacing: 0,
    fontWeight: 800,
    marginBottom: theme.typography.pxToRem(20),
    alignItems: 'center'
  },
  textLabel: {
    fontSize: theme.typography.pxToRem(16),
    marginBottom: theme.typography.pxToRem(10),
    alignItems: 'center'
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: `${theme.typography.pxToRem(9)} ${theme.typography.pxToRem(15)}`,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  textButton: {
    fontSize: theme.typography.pxToRem(16)
  },
  gridHeader: {
    marginBottom: theme.spacing(2)
  }
}));

export { useStyles };
