export const mappedCategoriesSpanish = (categories) => {
  const categoriesSpanish = categories.map((item) => ({
    category_id: item.category_id,
    name: item.name[0].text
  }));

  return categoriesSpanish;
};

export const mappedCategoriesEnglish = (categories) => {
  const categoriesEnglish = categories.map((item) => ({
    category_id: item.category_id,
    name: item.name[1].text
  }));

  return categoriesEnglish;
};

export const mappedSubcategoriesSpanish = (subCategories) => {
  const subcategoriesSpanish = subCategories.map((item) => ({
    subcategory_id: item.subcategory_id,
    name: item.name[0].text
  }));

  return subcategoriesSpanish;
};

export const mappedSubcategoriesEnglish = (subCategories) => {
  const subcategoriesEnglish = subCategories.map((item) => ({
    subcategory_id: item.subcategory_id,
    name: item.name[1].text
  }));

  return subcategoriesEnglish;
};
