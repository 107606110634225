import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Container, Grid, Button } from '@material-ui/core';
import { messages } from './DirectoryFormMessages';
import { useStyles } from './DirectoryFormStyles';
import { useHelper } from './helper';
import {
  renderTextField,
  renderAutocomplete,
  renderCheckbox
} from '@octopy/react-form';
import { TextBotSelect } from 'components/TextBotSelect';
import { TimePicker } from 'components/TimePicker';
import { get } from 'lodash';
import { HeaderTitle } from 'components/HeaderTitle';

const DirectoryForm = () => {
  const { formatMessage: f } = useIntl();
  const classes = useStyles();
  const storage = JSON.parse(localStorage.getItem('session'));
  const nameClient = get(storage, 'user.client.name', '');

  const {
    idMedical,
    formik,
    fieldsMainFormik,
    fieldAddEnglishFormik,
    fieldsEnglishFormik,
    handleChangeText,
    handleChangeRobot,
    handleChangeTextEnglish,
    handleChangeRobotEnglish,
    weekState,
    handleChangeStateDay,
    handleChangeTime
  } = useHelper();

  const fieldsMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'title' && field.text}
      {field.type === 'text' && renderTextField({ index, formik, field })}
      {field.type === 'select' && renderAutocomplete({ index, formik, field })}
    </Grid>
  );

  const fieldCheckboxMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'checkbox' && renderCheckbox({ index, formik, field })}
    </Grid>
  );

  const daysMapper = (day, index) => (
    <TimePicker
      item={day}
      onChangeTime={handleChangeTime}
      onChangeCheckBox={handleChangeStateDay}
    />
  );

  return (
    <div className={classes.container}>
      <Container maxWidth="xl">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <HeaderTitle
              firstTitle={
                idMedical === 0
                  ? f(messages.titleAddMedical)
                  : f(messages.titleEditMedical)
              }
            />
          </Grid>
          {fieldsMainFormik.map(fieldsMapper)}
          <Grid container spacing={2} item xs={12}>
            {weekState.map(daysMapper)}
          </Grid>
          <TextBotSelect
            textBot={formik.values.text}
            textBotMessage={formik.touched.text && formik.errors.text}
            idBot={formik.values.robot}
            idBotMessage={formik.touched.robot && formik.errors.robot}
            onHandleChangeText={handleChangeText}
            onHandleChangeBot={handleChangeRobot}
            isEnglish={false}
          />
          {fieldAddEnglishFormik.map(fieldCheckboxMapper)}
          {formik.values.addEnglish ? (
            <>
              <Grid item xs={12}>
                <Typography className={classes.title}>
                  {f(messages.englishTitle)}
                </Typography>
              </Grid>
              <TextBotSelect
                textBot={formik.values.textEnglish}
                textBotMessage={
                  formik.touched.textEnglish && formik.errors.textEnglish
                }
                idBot={formik.values.robotEnglish}
                idBotMessage={
                  formik.touched.robotEnglish && formik.errors.robotEnglish
                }
                onHandleChangeText={handleChangeTextEnglish}
                onHandleChangeBot={handleChangeRobotEnglish}
                isEnglish={true}
              />
              <Grid item xs={12} style={{ marginTop: 20 }}>
                {fieldsEnglishFormik.map(fieldsMapper)}
              </Grid>
            </>
          ) : null}
          <Grid item xs={12}>
            <Typography className={classes.textPrivacy}>
              {f(messages.privacy)} &nbsp;
              <span className={classes.nameClient}>{nameClient}</span> &nbsp;
              <span className={classes.textPrivacy}>
                {f(messages.privacyComplete)}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.contentButtons}>
            <Button
              className={classes.saveButton}
              onClick={() => formik.submitForm()}
            >
              {f(messages.saveButton)}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

DirectoryForm.propTypes = {};

export { DirectoryForm };
