import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  requiredError: {
    id: 'components.EventSearch.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  title: {
    id: 'routes.Setup.title',
    defaultMessage: 'Encuesta'
  },
  helperText: {
    id: 'routes.Setup.helperText',
    defaultMessage:
      'Completa los campos para la creación del formulario de encuesta'
  },
  helperTextEdit: {
    id: 'routes.Setup.helperTextEdit',
    defaultMessage:
      'Completa los campos para la edición del formulario de encuesta'
  },
  previewSurvey: {
    id: 'routes.Setup.previewSurvey',
    defaultMessage: 'Previsualizar encuesta'
  },
  enableSurvey: {
    id: 'routes.Setup.enableSurvey',
    defaultMessage: 'activar encuesta'
  },
  surveyTitle: {
    id: 'routes.Setup.surveyTitle',
    defaultMessage: 'Título de la encuesta'
  },
  max100Characters: {
    id: 'routes.Setup.max100Characters',
    defaultMessage: 'Máximo 100 caracteres'
  },
  surveyDescription: {
    id: 'routes.Setup.surveyDescription',
    defaultMessage: 'Descripción'
  },
  max500Characters: {
    id: 'routes.Setup.max500Characters',
    defaultMessage: 'Máximo 500 caracteres'
  },
  surveyFarewell: {
    id: 'routes.Setup.surveyFarewell',
    defaultMessage: 'Mensaje de despedida'
  },
  questionSectionTitle: {
    id: 'routes.Setup.questionSectionTitle',
    defaultMessage: 'Agrega las preguntas que se visualizarán en la encuesta.'
  },
  /**English */
  titleEnglish: {
    id: 'routes.Setup.titleEnglish',
    defaultMessage: 'Survey title'
  },
  max100CharactersEnglish: {
    id: 'routes.Setup.max100CharactersEnglish',
    defaultMessage: 'Maximum 100 characters'
  },
  surveyDescriptionEnglish: {
    id: 'routes.Setup.surveyDescriptionEnglish',
    defaultMessage: 'Description'
  },
  surveyFarewellEnglish: {
    id: 'routes.Setup.surveyFarewellEnglish',
    defaultMessage: 'Goodbye message'
  },
  max500CharactersEnglish: {
    id: 'routes.Setup.max500CharactersEnglish',
    defaultMessage: 'Maximum 500 characters'
  },
  englishSurveys: {
    id: 'routes.Setup.english',
    defaultMessage: 'Agregar información en inglés'
  },
  englishInfo: {
    id: 'components.ProductForm.englishInfo',
    defaultMessage: 'INFORMACIÓN QUE SE MOSTRARÁ EN INGLÉS'
  }
});
