const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `0 ${theme.spacing(5)}px`,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      margin: `0 ${theme.spacing(1.5)}px`,
      marginTop: theme.spacing(4)
    }
  },
  title: {
    fontSize: '20px',
    color: theme.palette.common.black,
    fontWeight: 800,
    letterSpacing: '6px',
    marginBottom: theme.spacing(2)
  },
  pageTitle: {
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bold',
    letterSpacing: theme.typography.pxToRem(8)
  },
  inputTitle: {
    color: theme.palette.info.dark,
    fontSize: '18px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '15px'
    }
  },
  formButtonSubmit: {
    color: theme.palette.common.white,
    textTransform: 'uppercase',
    marginTop: theme.spacing(2),
    fontSize: theme.typography.pxToRem(15),
    padding: `${theme.spacing(1)} ${theme.spacing(2)}px`,
    background: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: `${theme.palette.info.dark}!important`
    }
  },
  button: {
    textTransform: 'uppercase',
    color: theme.palette.common.white,
    marginTop: theme.spacing(2),
    padding: `${theme.spacing(1)} ${theme.spacing(2)}px`,
    background: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: `${theme.palette.info.dark}!important`
    }
  },
  checkBoxLabel: {
    fontSize: '13px'
  },
  uploadInfo: {
    marginLeft: theme.spacing(2)
  },
  optionLabel: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14)
  },
  infoInputText: {
    fontSize: '12px',
    color: theme.palette.grey[500],
    marginTop: theme.spacing(1),
    opacity: 1
  },
  contentTable: {
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(7)
    }
  }
}));

export { useStyles };
