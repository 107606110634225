const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  input: {
    '& .MuiFilledInput-input': {
      padding: theme.spacing(1)
    },
    '& .MuiFilledInput-underline:before': {
      border: 'transparent'
    },
    '& .MuiFilledInput-root': {
      borderRadius: theme.typography.pxToRem(5)
    }
  }
}));

export { useStyles };
