import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  boxCreate: {
    id: 'components.AddTakeTurnDrawer.boxCreate',
    defaultMessage: 'Crear una caja'
  },
  boxDelete: {
    id: 'components.AddTakeTurnDrawer.boxDelete',
    defaultMessage: 'Eliminar una caja'
  },
  selectBranch: {
    id: 'components.AddTakeTurnDrawer.selectBranch',
    defaultMessage: 'Seleccionar sucursal'
  },
  boxNumber: {
    id: 'components.AddTakeTurnDrawer.boxNumber',
    defaultMessage: 'Número de caja'
  },
  boxCreateS: {
    id: 'components.AddTakeTurnDrawer.boxCreateS',
    defaultMessage: 'Crear caja'
  },
  boxSelect: {
    id: 'components.AddTakeTurnDrawer.BoxSelect',
    defaultMessage: 'Seleccionar una caja'
  },
  boxDeleteS: {
    id: 'components.AddTakeTurnDrawer.boxDeleteS',
    defaultMessage: 'Eliminar caja'
  }
});
