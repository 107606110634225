import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  nameLabel: {
    id: 'components.SubcategoriesForm.nameLabel',
    defaultMessage: 'Nombre'
  },
  namePlaceholder: {
    id: 'components.SubcategoriesForm.namePlaceholder',
    defaultMessage: 'Nombre'
  },
  nameEnglishLabel: {
    id: 'components.SubcategoriesForm.nameEnglishLabel',
    defaultMessage: 'Name'
  },
  nameEnglishPlaceholder: {
    id: 'components.SubcategoriesForm.nameEnglishPlaceholder',
    defaultMessage: 'Name'
  },
  descriptionAddSubcategory: {
    id: 'components.SubcategoriesForm.descriptionAddSubcategory',
    defaultMessage: 'Agrega un nombre a la nueva subcategoría'
  },
  titleEnglish: {
    id: 'components.SubcategoriesForm.titleEnglish',
    defaultMessage: 'EN INGLES'
  },
  saveButton: {
    id: 'components.SubcategoriesForm.saveButton',
    defaultMessage: 'GUARDAR'
  }
});
