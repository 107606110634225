import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleCreate: {
    id: 'routes.CreateBranch.title',
    defaultMessage: 'Agregar Sucursal'
  },
  titleEdit: {
    id: 'routes.CreateBranch.titleEdit',
    defaultMessage: 'Editar Sucursal'
  },
  englishTitle: {
    id: 'routes.CreateBranch.englishTitle',
    defaultMessage: 'Información que se mostrará en inglés'
  },
  create: {
    id: 'routes.CreateBranch.create',
    defaultMessage: 'CREAR'
  },
  save: {
    id: 'routes.CreateBranch.save',
    defaultMessage: 'GUARDAR'
  },
  textAddContact: {
    id: 'routes.CreateBranch.textAddContact',
    defaultMessage: 'Agregar información de contacto'
  },
  textRobot: {
    id: 'routes.CreateBranch.textRobot',
    defaultMessage: 'Agrega un texto para el bot'
  },
  textRobotInfo: {
    id: 'routes.CreateBranch.textRobotInfo',
    defaultMessage:
      'Este texto se reproducirá cuando el usuario vea la información.'
  },
  nameLabel: {
    id: 'routes.CreateBranch.nameLabel',
    defaultMessage: 'Nombre de la sucursal'
  },
  phoneLabel: {
    id: 'routes.CreateBranch.phoneLabel',
    defaultMessage: 'Teléfono'
  },
  fromLabel: {
    id: 'routes.CreateBranch.fromLabel',
    defaultMessage: 'Desde'
  },
  toLabel: {
    id: 'routes.CreateBranch.toLabel',
    defaultMessage: 'Hasta'
  },
  addressLabel: {
    id: 'routes.CreateBranch.addressLabel',
    defaultMessage: 'Dirección'
  },
  googleMapsLabel: {
    id: 'routes.CreateBranch.googleMapsLabel',
    defaultMessage: 'URL Google Maps'
  },
  latitudeLabel: {
    id: 'routes.CreateBranch.latitudeLabel',
    defaultMessage: 'Latitud'
  },
  latitudePlaceholder: {
    id: 'routes.CreateBranch.latitudePlaceholder',
    defaultMessage: 'Ingrese latitud'
  },
  lengthLabel: {
    id: 'routes.CreateBranch.lengthLabel',
    defaultMessage: 'Longitud'
  },
  lengthPlaceholder: {
    id: 'routes.CreateBranch.lengthPlaceholder',
    defaultMessage: 'ingrese longitud'
  },
  spanishBotTextLabel: {
    id: 'routes.CreateBranch.spanishBotTextLabel',
    defaultMessage: 'Texto para bot'
  },
  englishBotTextLabel: {
    id: 'routes.CreateBranch.englishBotTextLabel',
    defaultMessage: 'Text for the robot'
  },
  descriptionLabel: {
    id: 'routes.CreateBranch.descriptionLabel',
    defaultMessage: 'Descripción'
  },
  englishDescriptionLabel: {
    id: 'routes.CreateBranch.englishDescriptionLabel',
    defaultMessage: 'Description'
  },
  spanishBotOptionsLabel: {
    id: 'routes.CreateBranch.spanishBotOptionsLabel',
    defaultMessage: 'Selecciona robot'
  },
  englishBotOptionsLabel: {
    id: 'routes.CreateBranch.englishBotOptionsLabel',
    defaultMessage: 'Select robot'
  },
  required: {
    id: 'routes.CreateBranch.required',
    defaultMessage: 'Este campo es obligatorio'
  },
  positiveNumber: {
    id: 'routes.CreateBranch.positiveNumber',
    defaultMessage: 'Solo se aceptan números positivos'
  },
  integerNumbers: {
    id: 'routes.CreateBranch.integerNumbers',
    defaultMessage: 'Solo se aceptan números enteros'
  },
  error: {
    id: 'routes.CreateBranch.error',
    defaultMessage: 'Solo se aceptan números'
  },
  labelCheckBox: {
    id: 'routes.CreateBranch.labelCheckBox',
    defaultMessage: 'Agregar información en inglés'
  },
  onlyNumbers: {
    id: 'routes.CreateBranch.onlyNumbers',
    defaultMessage: 'Escriba unicamente números'
  },
  decimalNumbers: {
    id: 'routes.CreateBranch.decimalNumbers',
    defaultMessage: 'Número no válido'
  },
  minNumber: {
    id: 'routes.CreateBranch.maxNumber',
    defaultMessage: 'Teléfono mínimo de 10 digitos'
  },
  englishPart: {
    id: 'components.CreateBranch.englishPart',
    defaultMessage: 'Agregar información en inglés'
  },
  preview: {
    id: 'components.CreateBranch.preview',
    defaultMessage: 'Previsualizar'
  },
  uploadImageLabel: {
    id: 'components.CreateBranch.uploadImageLabel',
    defaultMessage: 'Subir imágen'
  },
  addImage: {
    id: 'components.CreateBranch.addImage',
    defaultMessage: 'Agrega una imágen para la sucursal'
  },
  uploadFileError: {
    id: 'views.CreateBranch.uploadFileError',
    defaultMessage:
      'Ha ocurrido un error al cargar el archivo. Intentalo de nuevo más tarde.'
  },
  errorModalTitle: {
    id: 'views.CreateBranch.errorModalTitle',
    defaultMessage: 'ERROR AL SOLICITAR PETICIÓN.'
  },
  errorModalDescription: {
    id: 'views.CreateBranch.errorModalDescription',
    defaultMessage:
      'Para continuar es necesario guardar la información de la sucursal en ingles.'
  },
  attentionSchedule: {
    id: 'views.CreateBranch.attentionSchedule',
    defaultMessage: 'Horario de atención'
  }
});
