import { useIntl } from 'react-intl';

import * as yup from 'yup';

import { messages } from '../SetupHeaderMessages';

export const useValidationSchema = () => {
  const intl = useIntl();

  const requiredErrorMessage = intl.formatMessage(messages.requiredError);

  const validationSchema = yup.object({
    surveyTitle: yup.string().max(100).required(requiredErrorMessage),
    surveyDescription: yup.string().max(500).required(requiredErrorMessage),
    surveyFarewell: yup.string().max(500).required(requiredErrorMessage),
    surveyTitleEnglish: yup
      .string()
      .max(100)
      .when('isEnglishSurveys', {
        is: true,
        then: yup.string().max(100).required(requiredErrorMessage)
      }),
    surveyDescriptionEnglish: yup
      .string()
      .max(500)
      .when('isEnglishSurveys', {
        is: true,
        then: yup.string().max(500).required(requiredErrorMessage)
      }),
    surveyFarewellEnglish: yup
      .string()
      .max(500)
      .when('isEnglishSurveys', {
        is: true,
        then: yup.string().max(500).required(requiredErrorMessage)
      })
  });

  return validationSchema;
};
