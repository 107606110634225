import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import { messages as formMessages } from '@octopy/react-form';
import { useLoader } from '@octopy/react-loader';
import { Typography, Grid, Button } from '@material-ui/core';
import { TextInput } from 'components/MUI';
import { useModalConfirmation } from 'components/ModalConfirmation';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useApi } from 'hooks';
import { responseMapper } from 'utils/responseMapper';
import { messages } from './TimeMessages';
import { useStyles } from './TimeStyles';
import { HeaderTitle } from 'components/HeaderTitle';

const timeMapper = {
  max_wait_time: 'max_wait_time',
  downtime_allowed: 'downtime_allowed',
  mic_listening_time: 'mic_listening_time',
  time_to_remind_client: 'time_to_remind_client'
};

const Time = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { modalSuccess } = useModalConfirmation();
  const { handleShowLoader } = useLoader();
  const history = useHistory();
  const location = useLocation();
  const idRobot = location.state.robot_id;

  const [getRobotId] = useApi({
    endpoint: `/robots/robots/${idRobot}`,
    method: 'get'
  });

  const handleDataRobot = async () => {
    try {
      const responseRobotId = await getRobotId();

      if (responseRobotId.headerResponse.code === 200) {
        const dataResponseRobotId = get(responseRobotId, 'payload', []);
        const mappedTimes = responseMapper({
          template: timeMapper,
          data: dataResponseRobotId
        });

        handleShowLoader(false);

        formik.setValues({
          max_wait_time: mappedTimes.max_wait_time
            ? `${mappedTimes.max_wait_time}`
            : '',
          downtime_allowed: mappedTimes.downtime_allowed
            ? `${mappedTimes.downtime_allowed}`
            : '',
          mic_listening_time: mappedTimes.mic_listening_time
            ? `${mappedTimes.mic_listening_time}`
            : '',
          time_to_remind_client: mappedTimes.time_to_remind_client
            ? `${mappedTimes.time_to_remind_client}`
            : ''
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleShowLoader(true);
    handleDataRobot();
  }, []);

  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );
  const validationSchema = Yup.object({
    max_wait_time: Yup.number()
      .min(20, intl.formatMessage(messages.minTime))
      .max(60, intl.formatMessage(messages.maxTime))
      .required(requiredFieldError),
    downtime_allowed: Yup.number()
      .min(20, intl.formatMessage(messages.minTime))
      .max(60, intl.formatMessage(messages.maxTime))
      .required(requiredFieldError),
    mic_listening_time: Yup.number()
      .min(6, intl.formatMessage(messages.minTimeSix))
      .max(10, intl.formatMessage(messages.maxTimeTen))
      .required(requiredFieldError),
    time_to_remind_client: Yup.number()
      .min(10, intl.formatMessage(messages.minTimeTen))
      .max(60, intl.formatMessage(messages.maxTime))
      .required(requiredFieldError)
  });

  const formik = useFormik({
    initialValues: {
      max_wait_time: '',
      downtime_allowed: '',
      mic_listening_time: '',
      time_to_remind_client: ''
    },
    validationSchema,
    onSubmit: (values) => {
      let body = {
        max_wait_time: parseInt(values.max_wait_time),
        downtime_allowed: parseInt(values.downtime_allowed),
        mic_listening_time: parseInt(values.mic_listening_time),
        time_to_remind_client: parseInt(values.time_to_remind_client)
      };

      handleEditTime(body);
    }
  });

  const [robotUpdate] = useApi({
    endpoint: `/robots/robots/${idRobot}/`,
    method: 'put'
  });

  const handleEditTime = async (times) => {
    try {
      const responseRobotUpdate = await robotUpdate({
        body: times
      });
      const headerResponse = get(responseRobotUpdate, 'headerResponse', {});

      if (headerResponse.code === 200) {
        modalSuccess(
          intl.formatMessage(messages.titleSuccessModal),
          intl.formatMessage(messages.textInfoSucces),
          () => {
            history.push('/robots');
          }
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classes.container}>
      <HeaderTitle
        firstTitle={history.location.state.name}
        secondTitle={intl.formatMessage(messages.timeTitle)}
        redirection="robots"
      />
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2} style={{ marginTop: 30 }}>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography className={classes.titleTimes}>
              {intl.formatMessage(messages.actionTitle)}
            </Typography>
            <TextInput
              name="max_wait_time"
              formik={formik}
              placeholder="00"
              label={intl.formatMessage(messages.actionDescription)}
            />
            <Typography className={classes.timeMin}>
              {intl.formatMessage(messages.minTime)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography className={classes.titleTimes}>
              {intl.formatMessage(messages.inactivityTitle)}
            </Typography>
            <TextInput
              name="downtime_allowed"
              formik={formik}
              placeholder="00"
              label={intl.formatMessage(messages.inactivityDescription)}
            />
            <Typography className={classes.timeMin}>
              {intl.formatMessage(messages.minTime)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography className={classes.titleTimes}>
              {intl.formatMessage(messages.microTitle)}
            </Typography>
            <TextInput
              name="mic_listening_time"
              formik={formik}
              placeholder="00"
              label={intl.formatMessage(messages.microDescription)}
            />
            <Typography className={classes.timeMin}>
              {intl.formatMessage(messages.minTimeSix)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4}>
            <Typography className={classes.titleTimes}>
              {intl.formatMessage(messages.reminderTitle)}
            </Typography>
            <TextInput
              name="time_to_remind_client"
              formik={formik}
              placeholder="00"
              label={intl.formatMessage(messages.reminderDescription)}
            />
            <Typography className={classes.timeMin}>
              {intl.formatMessage(messages.minTimeTen)}
            </Typography>
          </Grid>
          <Grid
            container
            direction="column"
            alignItems="flex-end"
            justify="flex-end"
            xs={12}
            sm={12}
            md={8}
            lg={8}
          >
            <Button
              className={classes.button}
              type="submit"
              onClick={() => formik.submitForm()}
            >
              {intl.formatMessage(messages.buttonSave)}
            </Button>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

Time.propTypes = {};

export { Time };
