import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'routes.Permissions.title',
    defaultMessage: 'Perfiles y permisos'
  },
  addProfile: {
    id: 'routes.Permissions.addBranch',
    defaultMessage: 'Crear perfil'
  },
  newProfile: {
    id: 'routes.Permissions.newProfile',
    defaultMessage: 'NUEVO PERFIL'
  }
});
