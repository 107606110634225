import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { messages as formMessages } from '@octopy/react-form';
import { messages } from './GraphicSurveyMessages';
import { get } from 'lodash';
import { useApi } from 'hooks';
import { useFormik } from 'formik';
import { useLoader } from '@octopy/react-loader';
import { useHistory } from 'react-router-dom';
import { TodayRounded } from '@material-ui/icons';
import { format } from 'date-fns';
import { jsPDF } from 'jspdf';
import ApexCharts from 'apexcharts';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

const randomBlue = () => {
  const g = Math.floor(Math.random() * (130 - 60));
  const b = Math.floor(Math.random() * (255 - 120));

  return `rgb(0, ${g}, ${b})`;
};

export const useGraphicSurvey = () => {
  const { handleShowLoader } = useLoader();
  const { formatMessage: f } = useIntl();
  const history = useHistory();

  const storage = JSON.parse(localStorage.getItem('session'));
  const idClient = storage.user.client.client_id;
  const idSurvey = history.location.state ? history.location.state.idSurvey : 0;

  // Get Robots
  const [robots, setRobots] = useState([]);

  const [getRobotsList] = useApi({
    endpoint: `robots/robots/robots_by_client/${idClient}`,
    method: 'get'
  });

  const getRobots = async () => {
    const response = await getRobotsList();

    const robotsResponse = get(response, 'payload', []);

    setRobots(robotsResponse);
  };

  useEffect(() => {
    getRobots();
  }, []);

  //initial Formik Structure
  const requiredFieldError = f(formMessages.requiredFieldError);

  const getValidationRules = () =>
    Yup.object({
      client: Yup.string().required(requiredFieldError),
      robot: Yup.string().required(requiredFieldError),
      poll: Yup.string().required(requiredFieldError),
      start_date: Yup.string().nullable().required(requiredFieldError),
      end_date: Yup.string().nullable().required(requiredFieldError)
    });

  const initialValues = {
    client: idClient,
    robot: '',
    poll: idSurvey,
    complete: '',
    language_id: 1,
    start_date: null,
    end_date: null
  };

  const inputProps = {
    fullWidth: true
  };

  const fieldsForm = [
    Object.assign({
      type: 'select',
      name: 'robot',
      label: f(messages.selectBot),
      placeholder: f(messages.selectBot),
      breakpoints: { xs: 12, md: 3 },
      options: robots,
      getOptionLabel: (option) => `${option.name || ''}`,
      getOptionValue: (option) => `${option.robot_id || ''}`,
      getOptionSelected: (option, value) => option === value,
      inputProps: inputProps
    }),
    Object.assign(
      {
        type: 'datePicker',
        name: 'start_date',
        label: f(messages.startDate),
        placeholder: f(messages.startDate),
        variant: 'inline',
        format: 'dd/MM/yyyy',
        disableToolbar: true,
        inputVariant: 'outlined',
        breakpoints: { xs: 12, md: 3 },
        InputProps: {
          endAdornment: <TodayRounded />
        }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'datePicker',
        name: 'end_date',
        label: f(messages.endDate),
        placeholder: f(messages.endDate),
        variant: 'inline',
        format: 'dd/MM/yyyy',
        disableToolbar: true,
        inputVariant: 'outlined',
        breakpoints: { xs: 12, md: 3 },
        InputProps: {
          endAdornment: <TodayRounded />
        }
      },
      inputProps
    ),
    Object.assign({
      type: 'select',
      name: 'complete',
      label: f(messages.status),
      placeholder: f(messages.status),
      breakpoints: { xs: 12, md: 3 },
      options: [
        { label: f(messages.optionComplete), value: true },
        { label: f(messages.optionIncomplete), value: false }
      ],
      getOptionLabel: (option) => `${option.label || ''}`,
      getOptionValue: (option) => `${option.value || null}`,
      getOptionSelected: (option, value) => option === value,
      inputProps: inputProps
    }),
    Object.assign({
      type: 'select',
      name: 'language_id',
      label: f(messages.language),
      placeholder: f(messages.language),
      breakpoints: { xs: 12, md: 3 },
      options: [
        { label: f(messages.español), value: 1 },
        { label: f(messages.ingles), value: 2 }
      ],
      getOptionLabel: (option) => `${option.label || ''}`,
      getOptionValue: (option) => `${option.value || null}`,
      getOptionSelected: (option, value) => option === value,
      inputProps: inputProps
    })
  ];

  //Formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationRules(),
    onSubmit: (values) =>
      handleSubmit({ ...values, language_id: parseInt(values.language_id) })
  });

  const handleSubmit = async (values) => {
    handleShowLoader(true);
    let body = {
      ...values,
      robot: parseInt(values.robot),
      start_date:
        values.start_date !== null
          ? format(values.start_date, 'yyyy-MM-dd')
          : null,
      end_date:
        values.end_date !== null ? format(values.end_date, 'yyyy-MM-dd') : null,
      complete: values.complete == 'true' ? true : false
    };

    await handleAnswerAnalytics(body);
  };

  //states
  const [totalAnswers, setTotalAnswers] = useState('--');
  const [chartsData, setChartsData] = useState([]);
  const [donwload, setDonwload] = useState(false);

  //get answers survey
  const [getAnswerAnalytics] = useApi({
    endpoint: 'analytics/analytics/count_answers/',
    method: 'post'
  });

  const handleAnswerAnalytics = async (data) => {
    const response = await getAnswerAnalytics({
      body: data
    });

    const headerResponse = get(response, 'headerResponse', {});
    const dataResponse = get(response, 'payload', {});

    if (headerResponse.code == 200) {
      let chartGraphics = [];

      dataResponse.charts.map((chart) => {
        let element = chart;

        if (element) {
          let jsonData = element.answers;
          var keys = Object.keys(jsonData);
          let arrayAnswers = []; //serie
          let arrayCategories = []; //categorie

          keys.map((item) => {
            arrayAnswers.push({
              label: item,
              value: jsonData[item]
            });

            arrayCategories.push(item);
          });

          let data = {
            series: arrayAnswers,
            categories: arrayCategories
          };

          let item = {
            question: element.question[0],
            totalResponses: element.total,
            data: data,
            type: element.chart_type[0]
          };

          chartGraphics.push(item);
        }
      });

      setTotalAnswers(dataResponse['total answers']);
      setChartsData(chartGraphics);
      handleShowLoader(false);
    } else {
      handleShowLoader(false);
      setTotalAnswers('--');
      setChartsData([]);
    }
  };

  const mapSurveys = (values, type = 'chart') => {
    const mappedSurveys = values.map((question, index) => {
      let item = {
        Pregunta:
          type === ' chart'
            ? question.question
            : question.question.replace(/[\/\\?:\*\[\]]/g, '_')
      };

      let data = question.data.series;

      data.map((key, index) => {
        item[`${key.label}`] = `${key.value}`;
      });

      item['Total'] = question.totalResponses;

      return item;
    });

    return mappedSurveys;
  };

  const exportPDF = async () => {
    const pdf = new jsPDF();
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfDOCHeight = pdf.internal.pageSize.getHeight();
    let x = 20;
    let y = 10;

    for (let i = 0; i < chartsData.length; i++) {
      let imageWithURI = await ApexCharts.exec(`chart-${i}`, 'dataURI');
      const imgProps = pdf.getImageProperties(imageWithURI.imgURI);
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      let aux = y + 20 + pdfHeight;

      if (aux >= pdfDOCHeight) {
        y = 20;
        pdf.addPage();
      }

      pdf.addImage(imageWithURI.imgURI, 'PNG', x, y, pdfWidth - 40, pdfHeight);
      y = y + 20 + pdfHeight;
    }

    const date = new Date().toLocaleDateString();
    const time = new Date().toLocaleTimeString();

    pdf.save(`Resultados_${date}_${time}.pdf`);
  };

  const exportZip = async () => {
    let zip = new JSZip();

    for (let i = 0; i < chartsData.length; i++) {
      let imageWithURI = await ApexCharts.exec(`chart-${i}`, 'dataURI');

      zip.file(`chart-${i + 1}.jpeg`, dataURItoBlob(imageWithURI.imgURI), {
        binary: true
      });
    }

    const date = new Date().toLocaleDateString();
    const time = new Date().toLocaleTimeString();

    zip.generateAsync({ type: 'blob' }).then(function callback(blob) {
      saveAs(blob, `Resultados_${date}_${time}.zip`);
    });
  };

  function dataURItoBlob(dataURI) {
    // Convert Base64 to raw binary data held in a string.

    var byteString = atob(dataURI.split(',')[1]);

    // Separate the MIME component.
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // Write the bytes of the string to an ArrayBuffer.
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    // Write the ArrayBuffer to a BLOB and you're done.
    var bb = new Blob([ab]);

    return bb;
  }

  useEffect(() => {
    if (chartsData.length > 0) {
      setDonwload(true);
    } else {
      setDonwload(false);
    }
  }, [chartsData]);

  return {
    formik,
    fieldsForm,
    totalAnswers,
    chartsData,
    donwload,
    exportPDF,
    mapSurveys,
    exportZip
  };
};

export const pieChart = [
  {
    label: 'Respuesta 1',
    value: 50
  },
  {
    label: 'Respuesta 2',
    value: 250
  },
  {
    label: 'TRespuesta 3',
    value: 20
  },
  {
    label: 'Respuesta 4',
    value: 80
  }
];

export const pieColors = (number) => {
  let colors = [];

  for (let i = 0; i < number; i++) {
    colors.push(randomBlue());
  }

  return colors;
};
