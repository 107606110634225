import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    [theme.breakpoints.down('xs')]: {
      margin: `0 ${theme.spacing(1)}px`,
      marginTop: theme.spacing(3),
      padding: theme.spacing(0)
    }
  },
  containerActions: {
    display: 'flex',
    alignItems: 'center'
  },
  contentTitles: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  catalogTitle: {
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800
  },
  titleDrawer: {
    fontSize: '20px',
    letterSpacing: '6px',
    color: theme.palette.common.black,
    fontWeight: 800,
    paddingRight: theme.spacing(10),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(0)
    }
  },
  categoriesTitle: {
    fontSize: '20px',
    letterSpacing: '6px',
    color: theme.palette.primary.main,
    fontWeight: 800
  },
  contentButons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      marginTop: '15px'
    }
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  }
}));

export { useStyles };
