import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  advertising: {
    id: 'components.Resolutions.advertising',
    defaultMessage: 'PUBLICIDAD'
  },
  resolutions: {
    id: 'components.Resolutions.resolutions',
    defaultMessage: 'RESOLUCIONES'
  },
  add: {
    id: 'components.Resolutions.add',
    defaultMessage: 'AGREGAR'
  },
  addResolution: {
    id: 'components.Resolutions.addResolution',
    defaultMessage: 'AGREGAR RESOLUCION'
  },
  subtitle: {
    id: 'components.Resolutions.subtitle',
    defaultMessage: 'Agrega un nombre y dimensiones de la nueva resolución.'
  }
});
