import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  users: {
    id: 'components.Users.users',
    defaultMessage: 'USUARIOS'
  },
  interfaceAccess: {
    id: 'components.Users.interfaceAccess',
    defaultMessage: 'ACCESO A INTERFAZ'
  },
  createUser: {
    id: 'components.Users.createUser',
    defaultMessage: 'CREAR USUARIO'
  },
  titleModal: {
    id: 'components.Users.titleModal',
    defaultMessage: 'ELIMINAR USUARIO'
  },
  infoTextModal: {
    id: 'components.Users.infoTextModal',
    defaultMessage: '¿Seguro que quiere eliminar este usuario'
  },
  cancel: {
    id: 'components.Users.cancel',
    defaultMessage: 'CANCELAR'
  },
  delete: {
    id: 'components.Users.delete',
    defaultMessage: 'Eliminar'
  },
  titleDrawer: {
    id: 'components.Users.titleDrawer',
    defaultMessage: 'ACCESO PARA INTERFACES'
  },
  pinLabel: {
    id: 'components.Users.pin',
    defaultMessage: 'Pin'
  },
  save: {
    id: 'components.Users.save',
    defaultMessage: 'GUARDAR'
  },
  onlyNumbers: {
    id: 'routes.Users.onlyNumbers',
    defaultMessage: 'Escriba unicamente números'
  },
  positiveNumbers: {
    id: 'routes.Users.positiveNumbers',
    defaultMessage: 'Escriba unicamente números positivos'
  },
  decimalNumbers: {
    id: 'routes.Users.decimalNumbers',
    defaultMessage: 'Número no válido'
  }
});
