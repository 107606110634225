import { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useIntl } from 'react-intl';
import { useApi } from 'hooks';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Alert, useModal } from '@octopy/react-modal';
import { useLoader } from '@octopy/react-loader';
import { useHistory } from 'react-router-dom';
import { sources } from 'providers/AWS';
import { responseMapper } from 'utils/responseMapper';
import { getFileFromUrl } from 'utils/fileAudioUrl';
import { uploadFilePromise } from 'utils/aws';
import { intentionsMapped, agentMapped } from '../utils';
import { messages } from '../CreateAudioFlowMessages';

export const useCreateAudioFlow = ({
  setEnableSelectAudio,
  clientBucketName
}) => {
  const history = useHistory();

  const bucketName = clientBucketName;

  const intl = useIntl();
  const { handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();
  const { audios } = sources(bucketName);
  const [valueInputName, setvalueInputName] = useState('');
  const [dataAudios, setDataAudios] = useState([]);
  const [agentData, setAgentData] = useState(null);
  const [showSoundPlayer, setShowSoundPlayer] = useState(false);
  const [previewSpeech, setPreviewSpeech] = useState(null);

  const idIntention = history.location.state
    ? history.location.state.idIntention
    : history.push('/robots');

  const [getIntentionById] = useApi({
    endpoint: `/intents/${idIntention}/`,
    method: 'get'
  });

  const [getAgentById] = useApi({
    endpoint: '/agents',
    method: 'get'
  });

  const [createSpeech] = useApi({
    endpoint: '/speech/',
    method: 'post'
  });

  useEffect(() => {
    getIntentions();
  }, []);

  const validationSchema = Yup.object({
    text: Yup.string().when('uploadAudio', {
      is: false,
      then: Yup.string()
        .min(5, intl.formatMessage(messages.minCharacters))
        .max(300, intl.formatMessage(messages.maxCharacters))
        .required(intl.formatMessage(messages.requiredField))
    })
  });

  const formik = useFormik({
    initialValues: {
      text: '',
      uploadAudio: false,
      source: null
    },
    validationSchema,
    onSubmit: async (values) => {
      if (values.uploadAudio === true) {
        if (values.source && values.source.file) {
          handleShowLoader(true);
          const fileSource = values.source.file;

          let urlS3 = await uploadFilePromise(
            fileSource,
            audios,
            errorUploadFile
          );

          let newBody = {
            text: fileSource.name,
            s3_url: urlS3
          };

          let aux = dataAudios.slice();

          aux.push({ position: dataAudios.length + 1, ...newBody });

          setDataAudios(aux);
          formik.resetForm();
          setEnableSelectAudio(false);
          handleShowLoader(false);
        }
      } else {
        handleAddAudio(values);
      }
    }
  });

  const getIntentions = async () => {
    try {
      const response = await getIntentionById();
      const headerResponse = get(response, 'headerResponse', '');
      const dataResponse = get(response, 'payload', {});

      if (headerResponse.code === 200) {
        const intentionsMapper = responseMapper({
          template: intentionsMapped,
          data: dataResponse
        });

        if (intentionsMapper.audios !== null) {
          const audiosFormat = intentionsMapper.audios.map((item, index) => {
            const position = index + 1;

            return {
              ...item,
              position: position
            };
          });

          setDataAudios(audiosFormat);
        }

        getAgentId(intentionsMapper.agent_id);
        setvalueInputName(intentionsMapper.name);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getAgentId = async (idAgent) => {
    try {
      const response = await getAgentById({
        urlParams: `${idAgent}/`
      });

      const headerResponse = get(response, 'headerResponse', '');
      const dataResponse = get(response, 'payload', {});

      if (headerResponse.code === 200) {
        const agentMapper = responseMapper({
          template: agentMapped,
          data: dataResponse
        });

        setAgentData(agentMapper);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddAudio = (values) => {
    try {
      handleShowLoader(true);
      setTimeout(async () => {
        let body = {
          key: agentData.agent_key,
          language: agentData.language,
          tone: agentData.tone,
          speed: agentData.speed,
          pitch: agentData.pitch,
          text: formik.values.text
        };

        const response = await createSpeech({
          body: body
        });
        const headerResponse = get(response, 'headerResponse', '');
        const dataResponse = get(response, 'payload', '');

        if (headerResponse.code === 200) {
          const fileUrl = await getFileFromUrl(dataResponse, 'audio');
          const urlS3 = await uploadFilePromise(
            fileUrl,
            audios,
            errorUploadFile
          );

          let newBody = {
            text: body.text,
            s3_url: urlS3
          };

          let aux = dataAudios.slice();

          aux.push({ position: dataAudios.length + 1, ...newBody });

          setDataAudios(aux);
          formik.resetForm();
          setShowSoundPlayer(false);
          handleShowLoader(false);
        }
      }, 1500);
    } catch (error) {
      handleShowLoader(false);
    }
  };

  const handlePreviewSpeech = async () => {
    try {
      handleShowLoader(true);
      let body = {
        key: agentData.agent_key,
        language: agentData.language,
        tone: agentData.tone,
        speed: agentData.speed,
        pitch: agentData.pitch,
        text: formik.values.text
      };

      const response = await createSpeech({
        body: body
      });
      const headerResponse = get(response, 'headerResponse', '');
      const dataResponse = get(response, 'payload', '');

      if (headerResponse.code === 200) {
        setPreviewSpeech(dataResponse);
        setShowSoundPlayer(true);
        handleShowLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const errorUploadFile = () => {
    handleOpenModal({
      configProps: {
        maxWidth: 'sm'
      },
      body: <Alert message={intl.formatMessage(messages.uploadFileError)} />
    });
    handleShowLoader(false);
  };

  return {
    formik,
    valueInputName,
    dataAudios,
    showSoundPlayer,
    previewSpeech,
    setDataAudios,
    handlePreviewSpeech
  };
};
