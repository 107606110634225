import React from 'react';
import { useIntl } from 'react-intl';
import { Table } from '@octopy/react-table';
import { Typography, Grid, Button } from '@material-ui/core';
import { AddCircle, Delete } from '@material-ui/icons';
import { useDrawer } from 'components/Drawer';
import { messages } from './ScenesMessages';
import { useStyles } from './ScenesStyles';
import { FormScenes } from './FormScenes';
import { useScenes } from './useScenes';

const Scenes = () => {
  const { formatMessage: f } = useIntl();
  const classes = useStyles();
  const { handleOpenDrawer } = useDrawer();
  const {
    scenes,
    handleDeleteScene,
    handleSuccessForm,
    tableHead,
    filters
  } = useScenes();

  const handleDrawer = (item) => {
    handleOpenDrawer({
      closeButton: true,
      title: (
        <Typography className={classes.titleDrawer}>
          {item == 0 ? f(messages.addEscenes) : f(messages.editEscenes)}
        </Typography>
      ),
      body: <FormScenes idScene={item} callback={handleSuccessForm} />,
      configProps: {
        anchor: 'right'
      }
    });
  };

  const configProps = {
    filters,
    selectedOptions: {
      checkboxHidden: true
    },
    actions: {
      edit: {
        onClick: (item) => handleDrawer(item.id),
        disabled: false,
        hidden: false
      },
      deleteAction: {
        text: 'Eliminar',
        icon: <Delete />,
        onClick: (item) => handleDeleteScene(item.id)
      }
    }
  };

  return (
    <div className={classes.container}>
      <Grid container className={classes.contentActions}>
        <Grid item xs={12} lg={6} className={classes.contentTitle}>
          <Typography className={classes.title}>
            {f(messages.advertising)} /&nbsp;
          </Typography>
          <Typography className={classes.interactions}>
            {f(messages.escenes)}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.contentButton}>
          <Button
            className={classes.button}
            endIcon={<AddCircle className={classes.addIcon} />}
            onClick={() => handleDrawer(0)}
          >
            {f(messages.add)}
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.contentTable}>
        <Table columns={tableHead} data={scenes} configProps={configProps} />
      </Grid>
    </div>
  );
};

Scenes.propTypes = {};

export { Scenes };
