import { makeStyles } from '@material-ui/core/styles';

import robotImage from 'assets/images/metrics/background.png';

const useStyles = makeStyles((theme) => ({
  container: {
    // padding: theme.spacing(2)
    marginTop: theme.spacing(-4)
  },
  paralaxEffect: {
    backgroundImage: `url(${robotImage})`,
    height: theme.typography.pxToRem(300),
    width: '100%',
    minHeight: '90vh',

    // backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  paralaxTextCotainer: {
    position: 'relative',
    paddingLeft: theme.spacing(6),
    top: '40%',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      paddingLeft: theme.spacing(0),
      alignItems: 'center'
    }
  },
  welcomeHeader: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(45),
    fontWeight: 'bold'
  },
  username: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '6px'
  },
  welcomeRole: {
    color: theme.palette.common.black,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'normal',
    marginTop: theme.spacing(1)
  }
}));

export { useStyles };
