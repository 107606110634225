import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  changePassword: {
    id: 'components.ChangePassword.changePassword',
    defaultMessage: 'CAMBIAR CONTRASEÑA'
  },
  currentPassword: {
    id: 'components.ChangePassword.currentPassword',
    defaultMessage: 'Contraseña actual'
  },
  newPassword: {
    id: 'components.ChangePassword.newPassword',
    defaultMessage: 'Nueva contraseña'
  },
  confirmPassword: {
    id: 'components.ChangePassword.confirmPassword',
    defaultMessage: 'Confirmar Contraseña'
  },
  change: {
    id: 'components.ChangePassword.change',
    defaultMessage: 'CAMBIAR'
  },
  passwordRegexError: {
    id: 'components.ChangePassword.regexError',
    defaultMessage:
      'La contraseña no cumple con la política de seguridad (Min. 8 caracteres, 1 mayúscula, 1 número y 1 símbolo).'
  },
  changeSuccessTitle: {
    id: 'components.ChangePassword.changeSuccessTitle',
    defaultMessage: 'Cambiar contraseña'
  },
  changeSuccessText: {
    id: 'components.ChangePassword.changeSuccessText',
    defaultMessage: 'La contraseña ha sido cambiada exitosamente.'
  }
});
