import { get } from 'lodash';

const { useApi } = require('hooks');

const useApiCalls = () => {
  const [getClients] = useApi({
    endpoint: `/management/clients/`,
    method: 'get'
  });

  const getClientsList = async () => {
    const response = await getClients();
    const dataResponse = get(response, 'payload', []);

    return dataResponse;
  };

  const [getAllModules] = useApi({
    endpoint: `/management/modules/`,
    method: 'get'
  });

  const getAllModulesList = async () => {
    const response = await getAllModules();

    return response.payload;
  };

  const [getModules] = useApi({
    endpoint: `/management/modules/modules_by_client`,
    method: 'get'
  });

  const getModulesList = async (idClient) => {
    const response = await getModules({ urlParams: idClient });

    return response.payload;
  };

  const [postProfiles] = useApi({
    endpoint: '/management/profiles/',
    method: 'post'
  });

  const createProfile = async (body) => {
    const response = await postProfiles({ body });

    return response.payload;
  };

  const [putProfiles] = useApi({
    endpoint: '/management/profiles',
    method: 'put'
  });

  const updateProfile = async (itemId, body) => {
    const response = await putProfiles({ urlParams: `${itemId}/`, body });

    return response.payload;
  };

  return {
    getAllModulesList,
    getModulesList,
    createProfile,
    updateProfile,
    getClientsList
  };
};

export default useApiCalls;
