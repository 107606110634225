import { useEffect, useState } from 'react';
import { useLoader } from '@octopy/react-loader';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { useApi } from 'hooks';

export const useClientsList = () => {
  const history = useHistory();
  const { handleShowLoader } = useLoader();
  const [clients, setClients] = useState([]);

  const [getCLientsList] = useApi({
    endpoint: `management/clients`,
    method: 'get'
  });

  const getClientsData = async () => {
    const response = await getCLientsList();

    const clientsResponse = get(response, 'payload', []);

    setClients(clientsResponse);
    handleShowLoader(false);
  };

  useEffect(() => {
    handleShowLoader(true);
    getClientsData();
  }, []);

  const [deleteClient] = useApi({
    endpoint: `management/clients`,
    method: 'delete'
  });

  const handleDeleteClient = async (item) => {
    handleShowLoader(true);
    const response = await deleteClient({ urlParams: item });

    getClientsData();
  };

  const handleEditClient = async (item) => {
    history.push(`/client/form/${item}`);
  };

  return {
    clients,
    handleEditClient,
    handleDeleteClient
  };
};
