import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  welcomeHeader: {
    id: 'routes.Metrics.welcomeHeader',
    defaultMessage: 'Bienvenido'
  },
  welcomeRole: {
    id: 'routes.Metrics.welcomeRole',
    defaultMessage: 'Has iniciado sesión como:'
  },
  pageTitle: {
    id: 'routes.Metrics.pageTitle',
    defaultMessage: 'Estadísticas'
  },
  peopleServed: {
    id: 'routes.Metrics.peopleServed',
    defaultMessage: 'Conteo de personas que han cruzado'
  },
  totalInteractions: {
    id: 'routes.Metrics.totalInteractions',
    defaultMessage: 'Interacciones totales'
  },
  answeredSurveys: {
    id: 'routes.Metrics.answeredSurveys',
    defaultMessage: 'Encuestas respondidas'
  },
  genderInteractions: {
    id: 'routes.Metrics.genderInteractions',
    defaultMessage: 'Interacciones por género'
  },
  interactionTime: {
    id: 'routes.Metrics.interactionTime',
    defaultMessage: 'Tiempos de interacción'
  },
  conductedSurveys: {
    id: 'routes.Metrics.conductedSurveys',
    defaultMessage: 'Encuestas realizadas'
  },
  totalInteractionsLegend: {
    id: 'routes.Metrics.totalInteractionsLegend',
    defaultMessage: 'Total de interacciones:'
  },
  titleGraph0: {
    id: 'routes.Metrics.titleGraph0',
    defaultMessage: 'Conteo de personas que han cruzando'
  },
  titleGraph1: {
    id: 'routes.Metrics.titleGraph1',
    defaultMessage: 'Personas'
  },
  titleGraph2: {
    id: 'routes.Metrics.titleGraph2',
    defaultMessage: 'Encuestas'
  },
  filter: {
    id: 'routes.Metrics.filter',
    defaultMessage: 'FILTRAR'
  },
  export: {
    id: 'routes.Metrics.export',
    defaultMessage: 'EXPORTAR'
  },
  topProduct: {
    id: 'routes.Metrics.topProduct',
    defaultMessage: 'TOP DE PRODUCTOS MÁS VISTOS'
  },
  topCategory: {
    id: 'routes.Metrics.topCategory',
    defaultMessage: 'TOP DE CATEGORÍAS'
  },
  topIntent: {
    id: 'routes.Metrics.topIntent',
    defaultMessage: 'TOP DE INTENCIONES'
  }
});
