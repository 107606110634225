import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useLoader } from '@octopy/react-loader';
import { Typography, Grid, Button } from '@material-ui/core';
import {
  GetApp,
  Refresh,
  Check,
  CloudDownload,
  Clear
} from '@material-ui/icons';
import { Table } from '@octopy/react-table';
import { screensData } from './utils';
import { messages } from './ScreensMessages';
import { useStyles } from './ScreensStyles';

const Screens = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleShowLoader } = useLoader();

  const fetchData = async () => {
    // call api

    // assign data to state
    await setTimeout(() => {
      handleShowLoader(false);
    }, 1000);
  };

  useEffect(() => {
    handleShowLoader(true);
    fetchData();
  }, []);

  const data = screensData.map((item) => {
    const stateData =
      item.state === 'cargando' ? (
        <Check className={classes.checkColor} />
      ) : (
        <CloudDownload className={classes.cloudColor} />
      );
    const authorizedData =
      item.authorized === 'Autorizado' ? (
        <Check className={classes.checkColor} />
      ) : (
        <Clear className={classes.clearColor} />
      );
    const connectedData =
      item.connected === 'Activa' ? (
        <Check className={classes.checkColor} />
      ) : (
        <Clear className={classes.clearColor} />
      );

    return {
      ...item,
      state: stateData,
      authorized: authorizedData,
      connected: connectedData
    };
  });

  const tableHead = [
    { key: 'id', label: 'ID' },
    { key: 'screen', label: 'PANTALLA' },
    { key: 'state', label: 'ESTADO' },
    { key: 'authorized', label: 'AUTORIZADO' },
    { key: 'connected', label: 'CONECTADO' },
    { key: 'lastAcces', label: 'ÚLTIMO ACCESO' },
    { key: 'ip', label: 'DIRECCIÓN IP' },
    { key: 'mac', label: 'DIRECCIÓN MAC' }
  ];

  const filters = [
    { key: 'screen', value: 'PANTALLA' },
    { key: 'state', value: 'ESTADO' },
    { key: 'authorized', value: 'AUTORIZADO' },
    { key: 'connected', value: 'CONECTADO' },
    { key: 'lastAcces', value: 'ÚLTIMO ACCESO' },
    { key: 'ip', value: 'DIRECCIÓN IP' },
    { key: 'mac', value: 'DIRECCIÓN MAC' }
  ];

  const onSelectItems = (items) => {
    console.log('items: ', items);
  };

  const configProps = {
    filters,
    actions: {
      edit: {
        onClick: (item) => alert(item),
        disabled: false,
        hidden: false
      }
    }
  };

  const updatePage = () => {
    alert('Actualizando');
  };

  const downloadFile = () => {
    alert('Descargando');
  };

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} lg={6} className={classes.contentTitles}>
          <Typography className={classes.advertisingTitle}>
            {intl.formatMessage(messages.advertisingTitle)}
            &nbsp;-&nbsp;
          </Typography>
          <Typography className={classes.screensTitle}>
            {intl.formatMessage(messages.screensTitle)}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} className={classes.contentButons}>
            <Grid item>
              <Button
                className={classes.downloadButton}
                endIcon={<GetApp />}
                onClick={() => downloadFile()}
              >
                <Typography className={classes.textButon}>
                  {intl.formatMessage(messages.downloadControler)}
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.updateButton}
                endIcon={<Refresh />}
                onClick={() => updatePage()}
              >
                <Typography className={classes.textButon}>
                  {intl.formatMessage(messages.Update)}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Table
        columns={tableHead}
        data={data}
        configProps={configProps}
        onSelectItems={onSelectItems}
      ></Table>
    </div>
  );
};

Screens.propTypes = {};

export { Screens };
