/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { messages as formMessages } from '@octopy/react-form';
import { messages } from './DirectoryFormMessages';
import { Typography } from '@material-ui/core';
import { useStyles } from './DirectoryFormStyles';
import { responseMapper } from 'utils/responseMapper';
import {
  specialtysMapperSpanish,
  specialtysMapperEnglish,
  formikMapper
} from './schemesMapper';
import { get } from 'lodash';
import { useApi } from 'hooks';
import { useFormik } from 'formik';
import { useLoader } from '@octopy/react-loader';
import { useHistory } from 'react-router-dom';
import { useModalConfirmation } from 'components/ModalConfirmation';
// import { data } from 'views/Branches/BranchesDummy';

export const useHelper = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleShowLoader } = useLoader();
  const { modalReportProblem } = useModalConfirmation();
  const history = useHistory();
  const idMedical = history.location.state
    ? history.location.state.idMedical
    : 0;
  const storage = JSON.parse(localStorage.getItem('session'));
  const idClient = storage.user.client.client_id;

  //Get Specialtys
  const [specialtysSpanish, setSpecialtysSpanish] = useState([]);
  const [specialtysEnglish, setSpecialtysEnglish] = useState([]);
  const [weekState, setWeekState] = useState([]);

  const [getSpecialtysList] = useApi({
    endpoint: `directories/specialty/`,
    method: 'get'
  });

  const getSpecialtys = async () => {
    const response = await getSpecialtysList();

    const specialtysResponse = get(response, 'payload', []);
    const mappedSpecialtysSpanish = responseMapper({
      template: specialtysMapperSpanish,
      data: specialtysResponse
    });

    const mappedSpecialtysEnglish = responseMapper({
      template: specialtysMapperEnglish,
      data: specialtysResponse
    });

    setSpecialtysSpanish(mappedSpecialtysSpanish);
    setSpecialtysEnglish(mappedSpecialtysEnglish);
  };

  //initial Formik Structure
  const weekData = [
    {
      id: 1,
      isActive: false,
      day: [
        { title: 'Lunes', language_id: 1 },
        { title: 'Monday', language_id: 2 }
      ],
      from: null,
      to: null
    },
    {
      id: 2,
      isActive: false,
      day: [
        { title: 'Martes', language_id: 1 },
        { title: 'Tuesday', language_id: 2 }
      ],
      from: null,
      to: null
    },
    {
      id: 3,
      isActive: false,
      day: [
        { title: 'Miércoles', language_id: 1 },
        { title: 'Wednesday', language_id: 2 }
      ],
      from: null,
      to: null
    },
    {
      id: 4,
      isActive: false,
      day: [
        { title: 'Jueves', language_id: 1 },
        { title: 'Thursday', language_id: 2 }
      ],
      from: null,
      to: null
    },
    {
      id: 5,
      isActive: false,
      day: [
        { title: 'Viernes', language_id: 1 },
        { title: 'Friday', language_id: 2 }
      ],
      from: null,
      to: null
    },
    {
      id: 6,
      isActive: false,
      day: [
        { title: 'Sabado', language_id: 1 },
        { title: 'Saturday', language_id: 2 }
      ],
      from: null,
      to: null
    },
    {
      id: 7,
      isActive: false,
      day: [
        { title: 'Domingo', language_id: 1 },
        { title: 'Sunday', language_id: 2 }
      ],
      from: null,
      to: null
    }
  ];

  const initialValues = {
    nameDoctor: '',
    phone: '',
    specialty: '',
    address: '',
    description: '',
    text: '',
    robot: '',
    addEnglish: false,
    specialtyEnglish: '',
    textEnglish: '',
    robotEnglish: ''
  };

  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );

  const inputProps = {
    variant: 'outlined',
    fullWidth: true,
    size: 'medium'
  };

  const getValidationRules = () =>
    Yup.object({
      nameDoctor: Yup.string().required(requiredFieldError),
      phone: Yup.string().required(requiredFieldError),
      specialty: Yup.string().required(requiredFieldError),
      address: Yup.string().required(requiredFieldError),
      description: Yup.string().required(requiredFieldError),
      text: Yup.string().required(requiredFieldError),
      robot: Yup.string().required(requiredFieldError),
      specialtyEnglish: Yup.string().when('addEnglish', {
        is: true,
        then: Yup.string().required(requiredFieldError)
      }),
      textEnglish: Yup.string().when('addEnglish', {
        is: true,
        then: Yup.string().required(requiredFieldError)
      }),
      robotEnglish: Yup.string().when('addEnglish', {
        is: true,
        then: Yup.string().required(requiredFieldError)
      })
    });

  //Formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationRules(),
    onSubmit: (values) => {
      if (formik.values.addEnglish === false) {
        modalReportProblem(
          intl.formatMessage(messages.errorModalTitle),
          intl.formatMessage(messages.errorModalDescription),
          () => {}
        );
      } else handleSubmit(values);
    }
  });

  const handleSubmit = (values) => {
    let data = {
      name: values.nameDoctor,
      phone: values.phone,
      direction: values.address,
      specialty: values.specialty,
      client: idClient,
      enabled_days: getEnableDays(),
      description: [
        {
          language_id: 1,
          text: values.description
        }
      ],
      audio_text: [
        {
          language_id: 1,
          text: values.text
        },
        {
          language_id: 2,
          text: values.textEnglish
        }
      ],
      audio_url: [
        {
          language_id: 1,
          robot_id: values.robot
        },
        {
          language_id: 2,
          robot_id: values.robotEnglish
        }
      ]
    };

    if (idMedical == 0) {
      handleCreate(data);
    } else {
      handleEdit(data);
    }
  };

  //FIELDS FORMIK
  const fieldsMainFormik = [
    Object.assign({
      type: 'title',
      breakpoints: { xs: 12 },
      text: (
        <Typography className={classes.description}>
          {intl.formatMessage(messages.descriptionContact)}
        </Typography>
      )
    }),
    Object.assign(
      {
        type: 'text',
        name: 'nameDoctor',
        label: intl.formatMessage(messages.labelSpanishNameDoctor),
        placeholder: intl.formatMessage(messages.placeholderSpanishNameDoctor),
        breakpoints: { xs: 12, md: 4, lg: 3 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'phone',
        label: intl.formatMessage(messages.labelSpanishPhone),
        placeholder: intl.formatMessage(messages.placeholderSpanishPhone),
        breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 }
      },
      inputProps
    ),
    Object.assign({
      type: 'select',
      name: 'specialty',
      label: intl.formatMessage(messages.labelSpanishSpecialty),
      placeholder: intl.formatMessage(messages.placeholderSpanishSpecialty),
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      options: specialtysSpanish,
      getOptionLabel: (option) => `${option.label || ''}`,
      getOptionValue: (option) => `${option.name || ''}`,
      getOptionSelected: (option, value) => option === value,
      inputProps: inputProps
    }),
    Object.assign(
      {
        type: 'text',
        name: 'address',
        label: intl.formatMessage(messages.labelSpanishAddress),
        placeholder: intl.formatMessage(messages.placeholderSpanishAddress),
        breakpoints: { xs: 12, lg: 3 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'description',
        label: intl.formatMessage(messages.labelSpanishDescription),
        placeholder: intl.formatMessage(messages.placeholderSpanishDescription),
        breakpoints: { xs: 12, lg: 3 }
      },
      inputProps
    )
  ];

  const fieldAddEnglishFormik = [
    Object.assign(
      {
        type: 'checkbox',
        name: 'addEnglish',
        label: intl.formatMessage(messages.englishPart),
        breakpoints: { xs: 12 },
        color: 'primary'
      },
      inputProps
    )
  ];

  const fieldsEnglishFormik = [
    Object.assign({
      type: 'select',
      name: 'specialtyEnglish',
      label: intl.formatMessage(messages.labelEnglishSpecialty),
      placeholder: intl.formatMessage(messages.placeholderEnglishSpecialty),
      breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
      options: specialtysEnglish,
      getOptionLabel: (option) => `${option.label || ''}`,
      getOptionValue: (option) => `${option.name || ''}`,
      getOptionSelected: (option, value) => option === value,
      inputProps: inputProps
    })
  ];

  //Assign values Component TextBotSelect
  const handleChangeText = (value) => {
    formik.setFieldValue('text', value);
  };

  const handleChangeRobot = (value) => {
    formik.setFieldValue('robot', value.name);
  };

  const handleChangeTextEnglish = (value) => {
    formik.setFieldValue('textEnglish', value);
  };

  const handleChangeRobotEnglish = (value) => {
    formik.setFieldValue('robotEnglish', value.name);
  };

  //Component Timepicker handles
  const handleChangeStateDay = (id, value) => {
    let aux = [];

    weekState.map((day) => {
      let dayData = day;

      if (dayData.id == id) {
        dayData.isActive = value;
      }

      aux.push(dayData);
    });

    setWeekState(aux);
  };

  const handleChangeTime = (id, name, value) => {
    let aux = [];

    weekState.map((day) => {
      let dayData = day;

      if (dayData.id == id) {
        dayData[name] = value;
      }

      aux.push(dayData);
    });

    setWeekState(aux);
  };

  //Get Correct Data weekState
  const getEnableDays = () => {
    let enableDays = [];

    weekState.map((day) => {
      if (day.isActive) {
        enableDays.push({
          day: day.day,
          from: day.from ? new Date(day.from).toLocaleTimeString() : '',
          to: day.to ? new Date(day.to).toLocaleTimeString() : ''
        });
      }
    });

    return enableDays;
  };

  //Create Medical
  const [createMedical] = useApi({
    endpoint: 'directories/directory/',
    method: 'post'
  });

  const handleCreate = async (data) => {
    const response = await createMedical({
      body: data
    });
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code == 200) {
      history.push('/directory');
    } else {
      handleShowLoader(false);
    }
  };
  //Edit Medical
  const [editMedical] = useApi({
    endpoint: 'directories/directory',
    method: 'put'
  });

  const handleEdit = async (data) => {
    const response = await editMedical({
      urlParams: `${idMedical}/`,
      body: data
    });
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code == 200) {
      history.push('/directory');
    } else {
      handleShowLoader(false);
    }
  };
  //Get Medical
  const [getMedical] = useApi({
    endpoint: 'directories/directory',
    method: 'get'
  });

  const getMedicalData = async () => {
    const response = await getMedical({
      urlParams: idMedical
    });

    const dataResponse = get(response, 'payload', {});
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code === 404) {
      history.push('/directory');
    } else {
      const mappedFormikValues = responseMapper({
        template: formikMapper,
        data: dataResponse
      });

      formik.setValues({
        ...initialValues,
        ...mappedFormikValues,
        specialty: mappedFormikValues.specialty
          ? `${mappedFormikValues.specialty}`
          : '',
        specialtyEnglish: mappedFormikValues.specialtyEnglish
          ? `${mappedFormikValues.specialtyEnglish}`
          : '',
        addEnglish: true
      });

      let auxWeek = weekData.slice();

      dataResponse.enabled_days.map((day) => {
        let index = auxWeek.findIndex(
          (x) => x.day[0].title == day.day[0].title
        );

        if (index >= 0) {
          auxWeek[index].isActive = true;
          auxWeek[index].from = new Date(
            null,
            null,
            null,
            day.from.split(':')[0],
            day.from.split(':')[1]
          );
          auxWeek[index].to = new Date(
            null,
            null,
            null,
            day.to.split(':')[0],
            day.to.split(':')[1]
          );
        }
      });

      setWeekState(auxWeek);
    }
  };

  useEffect(() => {
    getSpecialtys();
    setWeekState(weekData);
    if (idMedical != 0) {
      getMedicalData();
    }
  }, []);

  return {
    idMedical,
    formik,
    fieldsMainFormik,
    fieldAddEnglishFormik,
    fieldsEnglishFormik,
    handleChangeText,
    handleChangeRobot,
    handleChangeTextEnglish,
    handleChangeRobotEnglish,
    weekState,
    handleChangeStateDay,
    handleChangeTime
  };
};
