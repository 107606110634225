import React from 'react';
import { TextField } from '@material-ui/core';
import { useStyles } from './FieldStyles';

const Field = (props) => {
  const classes = useStyles();

  return <TextField {...props} className={classes.fields} size="large" />;
};

export { Field };
