import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import {
  AddCircle as AddCircleIcon,
  Delete as DeleteIcon,
  Edit as EditIcon
} from '@material-ui/icons';
import { useLoader } from '@octopy/react-loader';
import { Table } from '@octopy/react-table';
import { useDrawer } from 'components/Drawer';
import { useModal } from '@octopy/react-modal';
import { useStyles } from './PermissionsStyles';
import { messages } from './PermissionsMessages';
import { tableHead } from './PermissionsTableConfig';
import useApiCalls from './useApiCall';
import PermissionsCreateUpate from './PermissionsCreateUpate/PermissionsCreateUpate';
import { get } from 'lodash';

const Permissions = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const isSuperAdmin = get(storage, 'user.is_staff', {});

  const { handleShowLoader } = useLoader();
  const classes = useStyles();
  const intl = useIntl();
  const [data, setData] = useState([]);
  const { handleOpenDrawer } = useDrawer();
  const {
    getAllPermissionsList,
    getPermissionsList,
    deletePermissionById
  } = useApiCalls();
  const { handleCloseModal } = useModal();

  const fetchData = async () => {
    // call api
    let permissionsList;

    if (isSuperAdmin) {
      permissionsList = await getAllPermissionsList();
    } else {
      permissionsList = await getPermissionsList();
    }

    // assign data to state
    await setData(permissionsList);
    handleShowLoader(false);
  };

  useEffect(() => {
    handleShowLoader(true);
    fetchData();
  }, []);

  const actionFunctions = {
    edit: {
      text: 'Editar',
      icon: <EditIcon />,
      onClick: async (item) => {
        handleDrawer(item);
      }
    },
    deleteAction: {
      text: 'Eliminar',
      icon: <DeleteIcon />,
      onClick: async (item) => {
        await deletePermissionById(item.profile_id);
        handleCloseModal();
        fetchData();
      }
    }
  };

  const configProps = {
    actions: actionFunctions,
    selectedOptions: {
      checkboxHidden: true
    }
  };

  const handleDrawer = (item) => {
    handleOpenDrawer({
      closeButton: true,
      configProps: {
        anchor: 'right'
      },
      title: (
        <Typography className={classes.titleDrawer}>
          {intl.formatMessage(messages.newProfile)}
        </Typography>
      ),
      body: <PermissionsCreateUpate item={item} onCreate={fetchData} />
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className={classes.pageTitle}>
            {intl.formatMessage(messages.title)}
          </div>
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          direction="column"
          alignItems="flex-end"
        >
          <Button
            className={classes.addPofileButton}
            onClick={() => handleDrawer()}
            endIcon={<AddCircleIcon />}
          >
            {intl.formatMessage(messages.addProfile)}
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.contentTable}>
        <Table data={data} columns={tableHead} configProps={configProps} />
      </Grid>
    </div>
  );
};

Permissions.propTypes = {};

export default Permissions;
