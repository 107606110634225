import { uploadFile } from '@octopy/react-aws-utils';

export const uploadFilePromise = (file, path, callbackError) =>
  new Promise((resolve, reject) => {
    uploadFile({
      file,
      path,
      onSuccess: (url) => resolve(url),
      onError: (error) => {
        reject(error);
        callbackError();
      }
    });
  });
