import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'routes.Campaigns.title',
    defaultMessage: 'Publicidad /'
  },
  subtitle: {
    id: 'routes.Campaigns.subtitle',
    defaultMessage: 'Campañas'
  },
  add: {
    id: 'routes.Campaigns.add',
    defaultMessage: 'AGREGAR'
  },
  edit: {
    id: 'routes.Campaign.edit',
    defaultMessage: 'Editar'
  },
  delete: {
    id: 'routes.Campaign.delete',
    defaultMessage: 'Eliminar'
  },
  preview: {
    id: 'routes.Campaign.preview',
    defaultMessage: 'Previsualizar'
  },
  requiredError: {
    id: 'components.EventSearch.requiredError',
    defaultMessage: 'Este campo es requerido'
  }
});
