import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    [theme.breakpoints.down('xs')]: {
      margin: `0 ${theme.spacing(1.5)}px`,
      paddingTop: theme.spacing(3),
      padding: theme.spacing(0)
    }
  },
  contentActions: {
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  contentTitle: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  title: {
    fontSize: '20px',
    fontWeight: 800,
    letterSpacing: '6px',
    textTransform: 'uppercase',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
      letterSpacing: '1.6px'
    }
  },
  iconBack: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  events: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    fontWeight: 800,
    letterSpacing: '6px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px'
    }
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1.5)
    }
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  deleteButton: {
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.common.black}CE`,
      color: theme.palette.common.white
    }
  },
  addIcon: {
    marginBottom: theme.spacing(0.5)
  },
  containerSearch: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  rootCard: {
    widt: '100%',
    borderRadius: '16px',
    padding: theme.spacing(4),
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  },
  calendar: {
    width: '100%',
    height: 800
  },
  titleModal: {
    fontSize: '20px',
    fontWeight: 800,
    letterSpacing: '6px',
    textTransform: 'uppercase'
  },
  contentModal: {
    width: '450px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  contentIconClose: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  iconClose: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5)
    }
  },
  infoTextModal: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2.5)
  },
  contentButtonsModal: {
    marginTop: 25,
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '100%',
    marginBottom: 20
  },
  infoModalTitle: {
    fontSize: '15px',
    fontWeight: 800
  },
  infoModal: {
    fontSize: '15px'
  }
}));

export { useStyles };
