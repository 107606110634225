import React from 'react';
import { Button, Typography, Grid } from '@material-ui/core';
import {
  renderTextField,
  renderAutocomplete,
  renderSwitch
} from '@octopy/react-form';
import { useHelper } from './helpers';
import { useIntl } from 'react-intl';
import { useStyles } from './PermissionsCreateUpateStyles';
import { messages } from './PermissionsCreateUpateMessages';

const PermissionsCreateUpate = ({ item = {}, onCreate }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { formik, mainFieldsForm, modulesFieldsForm } = useHelper(
    item,
    onCreate
  );

  const fieldsMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'text' && renderTextField({ index, formik, field })}
      {field.type === 'select' && renderAutocomplete({ index, formik, field })}
    </Grid>
  );

  const fieldsMapperSwitch = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'switch' && renderSwitch({ index, formik, field })}
    </Grid>
  );

  return (
    <div className={classes.container}>
      <Grid container spacing={2} direction="row">
        <Grid item container xs={12} spacing={2}>
          {mainFieldsForm.map(fieldsMapper)}
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.subtitle}>
              {intl.formatMessage(messages.assignedPermissions)}
            </Typography>
            <Typography className={classes.textInfo}>
              {intl.formatMessage(messages.textInfo)}
            </Typography>
          </Grid>
          {modulesFieldsForm.map(fieldsMapperSwitch)}
        </Grid>
      </Grid>

      <Button
        disabled={modulesFieldsForm.length === 0}
        className={classes.button}
        type="submit"
        onClick={() => {
          formik.submitForm();
        }}
      >
        {intl.formatMessage(messages.save)}
      </Button>
    </div>
  );
};

PermissionsCreateUpate.propTypes = {};

export default PermissionsCreateUpate;
