const tableHead = [
  { key: 'id', label: 'ID', align: 'left' },
  { key: 'name', label: 'NOMBRE', align: 'center' },
  { key: 'scenes', label: 'ESCENAS', align: 'center' },
  { key: 'duration', label: 'DURACIÓN', align: 'center' },
  { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false }
];

const filters = [{ key: 'name', value: 'NOMBRE' }];

const mediaDummy = [
  {
    id: 1,
    position: 1,
    name: 'San Francisco – Oakland Bay Bridge, United States',
    url:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
    type: 'image',
    time: 1
  },
  {
    id: 5,
    position: 3,
    name: 'Bird2',
    url:
      'https://hemofilia-files.s3.us-west-2.amazonaws.com/dd2b772f-4f6b-4e8b-9493-d58447492eb6-Video _¿Que es la salud__.mp4',
    type: 'video',
    time: 10
  }
];

export { filters, tableHead, mediaDummy };
