const tableHead = [
  { key: 'position', label: 'ORDEN' },
  { key: 'name', label: 'NOMBRE' },
  { key: 'duration', label: 'TIEMPO', align: 'center' },
  { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false },
  { key: '', value: '' }
];

const CampaingsListMapper = {
  name: 'name',
  campaing_id: 'campaign_id',
  duration: 'duration'
};

export { tableHead, CampaingsListMapper };
