const tableHead = [
  { key: 'id', label: 'ID' },
  { key: 'name', label: 'NOMBRE INTENCIÓN' },
  { key: 'audios', label: 'AUDIOS', align: 'center' },
  { key: 'agent', label: 'AGENTE', align: 'center' },
  { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false }
];

const filters = [{ key: 'name', value: 'NOMBRE' }];

export { filters, tableHead };
