import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { messages as formMessages } from '@octopy/react-form';
import { Typography } from '@material-ui/core';
import { messages } from './RelateSurveyFormMessages';
import { useStyles } from './RelateSurveyFormStyles';
import { useHelper as useSurvey } from '../../Surveys/useHelper';

export const useHelper = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { surveys } = useSurvey();

  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );

  const getValidationRules = () =>
    Yup.object({
      polls: Yup.string().required(requiredFieldError)
    });

  const getFields = () => [
    {
      section: 'info',
      type: 'select',
      name: 'polls',
      label: intl.formatMessage(messages.labelSurvey),
      placeholder: intl.formatMessage(messages.placeholderSurvey),
      breakpoints: { xs: 12, lg: 10 },
      options: surveys
        .filter((item) => item.status === 'Activo')
        .map((item) => ({
          label: item.title,
          value: item.id
        })),
      getOptionLabel: (option) => `${option.label || ''}`,
      getOptionValue: (option) => `${option.value || ''}`,
      getOptionSelected: (option, value) => option === value
    },
    {
      section: 'info',
      type: 'checkbox',
      name: 'applyToAllRobots',
      breakpoints: { xs: 12 },
      color: 'primary',
      label: intl.formatMessage(messages.applyToAllRobots)
    }
  ];
  const getSections = () => [
    {
      name: 'info',
      title: {
        text: (
          <Typography className={classes.textSelectSurvey}>
            {intl.formatMessage(messages.textSelectSurvey)}
          </Typography>
        )
      }
    }
  ];

  return {
    getValidationRules,
    getFields,
    getSections
  };
};
