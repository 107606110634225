import {
  CallSplit,
  LinearScale as LinearScaleIcon,
  RadioButtonChecked,
  Delete
} from '@material-ui/icons';

import { QuestionTypes } from './QuestionTypes';

export const mapperOptionsQuestions = (data) =>
  data.map((item) => {
    let newOptions = {
      id: item.id,
      label: item.name
    };

    const newQuestionsTypes = {
      1: {
        value: QuestionTypes.squares,
        icon: Delete
      },
      2: {
        value: QuestionTypes.yesNo,
        icon: CallSplit
      },
      3: {
        value: QuestionTypes.multipleChoice,
        icon: Delete
      },
      4: {
        value: QuestionTypes.linearScale,
        icon: LinearScaleIcon
      },
      5: {
        value: QuestionTypes.uniqueOption,
        icon: RadioButtonChecked
      },
      6: {
        value: QuestionTypes.section,
        icon: Delete
      }
    };

    return { ...newOptions, ...newQuestionsTypes[item.id] };
  }, []);
