import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800,
    marginBottom: '20px'
  },
  helperText: {
    fontSize: theme.typography.pxToRem(14)
  },
  fieldsItems: {
    marginBottom: theme.spacing(3)
  },
  button: {
    textTransform: 'uppercase',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  icon: {
    marginBottom: theme.spacing(0.2)
  }
}));

export { useStyles };
