import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  branches: {
    id: 'components.Branches.branches',
    defaultMessage: 'SUCURSALES'
  },
  infoText: {
    id: 'components.Branches.infoText',
    defaultMessage:
      'Selecciona las sucursales que deseas que muestre este robot'
  },
  checkBoxText: {
    id: 'components.Branches.checkBoxText',
    defaultMessage: 'Aplicar a todos los robots'
  },
  save: {
    id: 'components.Branches.save',
    defaultMessage: 'GUARDAR'
  },
  cancel: {
    id: 'components.Branches.cancel',
    defaultMessage: 'CANCELAR'
  },
  branchesTotal: {
    id: 'components.Branches.branchesTotal',
    defaultMessage: 'Sucursales agregadas:'
  },
  uploadFileError: {
    id: 'views.Branches.uploadFileError',
    defaultMessage:
      'Ha ocurrido un error al cargar el archivo. Intentalo de nuevo más tarde.'
  },
  titleSuccessModalBranch: {
    id: 'routes.Branches.titleSuccessModalBranch',
    defaultMessage: 'Dato guardado con éxito'
  },
  titleSuccessModal: {
    id: 'routes.Branches.titleSuccessModal',
    defaultMessage: 'Datos guardados con éxito'
  },
  acept: {
    id: 'routes.Branches.acept',
    defaultMessage: 'ACEPTAR'
  },
  textInfoSucces: {
    id: 'routes.Branches.textInfoSucces',
    defaultMessage: 'Las sucursales se han agregado exitosamente.'
  },
  textMainBranchInfoSucces: {
    id: 'routes.Branches.textMainBranchInfoSucces',
    defaultMessage: 'La sucursal ha sido asignada exitosamente.'
  },
  emptyAgents: {
    id: 'routes.Branches.emptyAgents',
    defaultMessage: 'NO EXISTEN AGENTES PARA ESTE ROBOT'
  },
  infoReportProblem: {
    id: 'routes.Branches.infoReportProblem',
    defaultMessage: 'Consulte a soporte técnico.'
  },
  infoReportProblemIsStaff: {
    id: 'routes.Branches.infoReportProblemIsStaff',
    defaultMessage:
      'Genere agentes para este robot en: "Relacionar Plataformas", posteriormente, vuelva a intentarlo.'
  },
  mainBranchText: {
    id: 'routes.Branches.mainBranchText',
    defaultMessage: 'Sucursal del robot'
  },
  mainBranchSubtitle: {
    id: 'routes.Branches.mainBranchSubtitle',
    defaultMessage: 'Seleccione una sucursal para asignarla al robot.'
  }
});
