export const mapTurns = (values) => {
  const mappedTurns = values.map((turn) => ({
    id: turn.id,
    Turno: turn.turn,
    'Fecha de la solicitud en curso': turn.created_at,
    'Fecha de asignación': turn.attention_date,
    'Tiempo de espera': turn.waiting_time,
    'Fecha de atención': turn.end_date,
    'Tiempo de atención': turn.service_duration,
    'Atendido por': turn.attended_by
  }));

  return mappedTurns;
};
