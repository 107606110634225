import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
      marginBottom: theme.spacing(5)
    }
  },
  export: {
    display: 'none'
  },
  contentActions: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  title: {
    fontSize: '20px',
    letterSpacing: '6px',
    color: theme.palette.common.black,
    fontWeight: 800
  },
  subtitle: {
    fontSize: '15px',
    color: theme.palette.common.black,
    marginBottom: theme.spacing(2)
  },
  titleCard: {
    marginTop: theme.spacing(2),
    backgroundColor: '#E5E5E5',
    borderBottomLeftRadius: '0px',
    borderBottomRightRadius: '0px'
  },
  icon: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    color: theme.palette.common.black
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  }
}));

export { useStyles };
