import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleEdit: {
    id: 'routes.CreateAudioFlow.titleEdit',
    defaultMessage: 'EDITAR AUDIO'
  },
  pageTitle: {
    id: 'routes.CreateAudioFlow.pageTitle',
    defaultMessage: 'Agregar audio'
  },
  submitButton: {
    id: 'routes.CreateAudioFlow.submitButton',
    defaultMessage: 'Guardar'
  },
  intentionName: {
    id: 'routes.CreateAudioFlow.intentionName',
    defaultMessage: 'Nombre de la intención'
  },
  intentionNamePlaceholder: {
    id: 'routes.CreateAudioFlow.intentionNamePlaceholder',
    defaultMessage: 'Nombre'
  },
  optionsText: {
    id: 'routes.CreateAudioFlow.optionsText',
    defaultMessage:
      'Agrega un texto para el bot o selecciona un archivo de audio'
  },
  optionsTextPlaceholder: {
    id: 'routes.CreateAudioFlow.optionsTextPlaceholder',
    defaultMessage: 'Texto para bot'
  },
  audioSelection: {
    id: 'routes.CreateAudioFlow.audioSelection',
    defaultMessage: 'Selecciona un archivo de audio'
  },
  name: {
    id: 'routes.CreateAudioFlow.name',
    defaultMessage: 'Nombre'
  },
  infoInputText: {
    id: 'routes.CreateAudioFlow.infoInputText',
    defaultMessage: '*Nombre heredado de google. No editable.'
  },
  minCharacters: {
    id: 'routes.CreateAudioFlow.minCharacters',
    defaultMessage: 'Este campo debe contener mínimo 5 caracteres'
  },
  maxCharacters: {
    id: 'routes.CreateAudioFlow.maxCharacters',
    defaultMessage: 'Este campo debe contener máximo 300 caracteres'
  },
  requiredField: {
    id: 'routes.CreateAudioFlow.requiredField',
    defaultMessage: 'Este campo es obligatorio.'
  },
  buttonUpload: {
    id: 'routes.CreateAudioFlow.buttonUpload',
    defaultMessage: 'Seleccionar archivo de audio'
  },
  uploadInfo: {
    id: 'routes.CreateAudioFlow.uploadInfo',
    defaultMessage: 'No se ha seleccionado un archivo de audio'
  },
  uploadFileError: {
    id: 'views.CreateAudioFlow.uploadFileError',
    defaultMessage:
      'Ha ocurrido un error al cargar el archivo. Intentalo de nuevo más tarde.'
  },
  preview: {
    id: 'routes.CreateAudioFlow.preview',
    defaultMessage: 'PREVISUALIZAR'
  },
  add: {
    id: 'routes.CreateAudioFlow.add',
    defaultMessage: 'AGREGAR'
  },
  addedAudio: {
    id: 'routes.CreateAudioFlow.addedAudio',
    defaultMessage: 'AUDIOS AGREGADOS'
  },
  audioSequence: {
    id: 'routes.CreateAudioFlow.audioSequence',
    defaultMessage: 'Secuencia de audios agregados actualmente'
  },
  delete: {
    id: 'routes.CreateAudioFlow.delete',
    defaultMessage: 'Eliminar'
  },
  titleModal: {
    id: 'routes.CreateAudioFlow.titleModal',
    defaultMessage: 'DATOS GUARDADOS CON ÉXITO'
  },
  textInfoSucces: {
    id: 'routes.CreateAudioFlow.textInfoSucces',
    defaultMessage: 'Los audios se han agregado exitosamente.'
  }
});
