import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  hello: {
    id: 'components.PasswordRecovery.hello',
    defaultMessage: 'Hello {name}!!'
  },
  recoveryPassword: {
    id: 'components.PasswordRecovery.recoveryPassword',
    defaultMessage: 'RECUPERAR CONTRASEÑA'
  },
  infoText: {
    id: 'components.PasswordRecovery.infoText',
    defaultMessage:
      'Escribe una nueva contraseña para poder acceder a la plataforma.'
  },
  passwordCharacters: {
    id: 'components.PasswordRecovery.passwordCharacters',
    defaultMessage: 'Tu contraseña debe de tener 8 caracteres'
  },
  recoveryText: {
    id: 'components.PasswordRecovery.recoveryText',
    defaultMessage: 'RESTABLECER'
  },
  passwordText: {
    id: 'components.PasswordRecovery.passwordText',
    defaultMessage: 'CONTRASEÑA'
  },
  infoTextModal: {
    id: 'components.PasswordRecovery.infoTextModal',
    defaultMessage:
      'Ya puedes iniciar sesión para acceder a la plataforma. Por favor, revisa tu correo electrónico.'
  },
  acept: {
    id: 'components.PasswordRecovery.acept',
    defaultMessage: 'ACEPTAR'
  },
  title: {
    id: 'components.PasswordRecovery.title',
    defaultMessage: 'Robotics'
  },
  subTitle: {
    id: 'components.PasswordRecovery.subTitle',
    defaultMessage: 'Plataforma de gestión'
  },
  expiredTimeMessage: {
    id: 'components.PasswordRecovery.expiredTimeMessage',
    defaultMessage:
      'Su tiempo para restablecer la contraseña ha expirado, vuelva a intentarlo.'
  },
  successRecoveryMessage: {
    id: 'components.PasswordRecovery.successRecoveryMessage',
    defaultMessage:
      'Se ha restablecido la contraseña exitosamente, ya puedes iniciar sesión.'
  }
});
