export const countdownInterval = (timeMinutesToExpire = 10, callback) => {
  let now = new Date();

  now.setMinutes(now.getMinutes() + timeMinutesToExpire);
  now = new Date(now);

  const intervalId = setInterval(function () {
    const date = new Date().getTime();
    const distance = now - date;

    if (distance < 0) {
      clearInterval(intervalId);

      if (callback) callback();
    }
  }, 500);

  return intervalId;
};
