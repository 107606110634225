import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Button } from '@material-ui/core';
import { Table } from '@octopy/react-table';
import { useStyles } from './HistoryTurnStyles';
import { messages } from './HistoryTurnMessages';
import { useHistoryTurn } from './useHistoryTurn';
import { ExitToApp } from '@material-ui/icons';
import { useExport } from 'hooks/useExport';
import { mapTurns } from './helpers';

const HistoryTurn = () => {
  const { formatMessage: f } = useIntl();
  const classes = useStyles();
  const { turns, tableHead, configProps } = useHistoryTurn();
  const { exportToExcel } = useExport();

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} lg={6} className={classes.contentTitles}>
          <Typography className={classes.tittleView}>
            {f(messages.titleView)}
          </Typography>
        </Grid>
        <Grid item container direction="row-reverse" xs={12} lg={6}>
          <Button
            className={classes.button}
            endIcon={<ExitToApp />}
            onClick={() => {
              exportToExcel(mapTurns(turns), f(messages.titleView));
            }}
          >
            {f(messages.buttonExport)}
          </Button>
        </Grid>
      </Grid>

      <Grid className={classes.contentTable}>
        <div className={`tab-0`}>
          <Table columns={tableHead} data={turns} configProps={configProps} />
        </div>
      </Grid>
    </div>
  );
};

export { HistoryTurn };
