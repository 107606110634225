import React, { useRef } from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { Form, messages as formMessages } from '@octopy/react-form';
import { useModal } from '@octopy/react-modal';
import { useAuth } from '@octopy/react-auth';
import { Typography, Button, Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useApi } from 'hooks';
import * as Yup from 'yup';
import { Images } from 'assets';
import { setItem } from 'utils/persistentStorage';
import * as formConfig from '../../../utils/form-config';
import { EmailForm } from '../PasswordRecovery';
import { messages } from './LoginMessages';
import { useStyles } from './LoginStyles';

const Login = () => {
  const intl = useIntl();
  const classes = useStyles();
  const formikRef = useRef();
  const history = useHistory();
  const { handleOpenModal } = useModal();
  const { actions: authActions } = useAuth();

  const [loginService] = useApi({
    endpoint: 'auth/login/',
    method: 'post'
  });

  const handleSubmit = async (data) => {
    try {
      const authResponse = await loginService({
        body: data
      });

      const token = get(authResponse, ['payload', 'access_token'], null);
      const refreshToken = get(
        authResponse,
        ['payload', 'refresh_token'],
        null
      );
      const user = get(authResponse, ['payload', 'user'], null);

      if (token) {
        const sessionData = {
          token,
          user,
          status: true
        };

        await authActions.login(sessionData);

        setItem('session', {
          refreshToken,
          token,
          user,
          status: true
        });

        history.push('/');
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const getInitialValues = () => ({
    email: '',
    password: ''
  });

  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );

  const invalidEmailError = intl.formatMessage(formMessages.invalidEmailError);

  const getValidationRules = () =>
    Yup.object({
      email: Yup.string().email(invalidEmailError).required(requiredFieldError),
      password: Yup.string().required(requiredFieldError)
    });

  const getFields = () => [
    {
      type: 'text',
      name: 'email',
      label: intl.formatMessage(formMessages.emailLabel),
      placeholder: intl.formatMessage(formMessages.emailPlaceholder)
    },
    {
      type: 'password',
      name: 'password',
      label: intl.formatMessage(formMessages.passwordLabel),
      placeholder: intl.formatMessage(formMessages.passwordLabel)
    }
  ];

  const handlePasswordRecoveryClick = () => {
    handleOpenModal({
      body: <EmailForm />,
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  return (
    <Box className={classes.container}>
      <Box className={classes.formContainer}>
        <Box className={classes.contentTitles}>
          <img
            alt="Octopy"
            src={Images.octopy}
            className={classes.octopyLogo}
          />
        </Box>
        <Form
          formikRef={formikRef}
          initialValues={getInitialValues()}
          validationRules={getValidationRules()}
          fields={getFields()}
          handleSubmit={handleSubmit}
          showSubmitButton={false}
          inputProps={formConfig.inputProps}
        />

        <Button
          className={classes.submitButton}
          size="large"
          variant="contained"
          onClick={() => formikRef.current.submitForm()}
        >
          {intl.formatMessage(messages.buttonSubmit)}
        </Button>

        <Typography
          onClick={handlePasswordRecoveryClick}
          className={classes.passwordRecoveryLink}
        >
          {intl.formatMessage(messages.passwordRecovery)}
        </Typography>
      </Box>
    </Box>
  );
};

Login.propTypes = {};

export { Login };
