export const tableHead = [
  { key: 'user_id', label: 'ID' },
  { key: 'name', label: 'NOMBRE' },
  { key: 'email', label: 'EMAIL' },
  { key: 'last_login', label: 'ÚLTIMO ACCESO' },
  { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false }
];

export const filters = [
  { key: 'name', value: 'NOMBRE' },
  { key: 'email', value: 'EMAIL' },
  { key: '', value: '' }
];

export const getInitialValues = () => ({
  name: ''
});
