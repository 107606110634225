import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Button, Grid } from '@material-ui/core';
import { messages } from './RelateSurveyFormMessages';
import { Form } from '@octopy/react-form';
import { useStyles } from './RelateSurveyFormStyles';
import { useHelper } from './helpers';

const defaultInitialValues = {
  polls: '',
  applyToAllRobots: false
};

const RelateSurveyForm = ({
  initialValues = defaultInitialValues,
  handleSubmit
}) => {
  const intl = useIntl();
  const classes = useStyles();

  const formikRef = useRef();

  const helper = useHelper();

  return (
    <div>
      <Form
        initialValues={initialValues}
        validationRules={helper.getValidationRules()}
        fields={helper.getFields()}
        showSubmitButton={false}
        handleSubmit={handleSubmit}
        formikRef={formikRef}
        withInputsBorder
        inputProps={{
          variant: 'outlined',
          fullWidth: true
        }}
        sections={helper.getSections()}
      />
      <Grid className={classes.contentButton}>
        <Button
          onClick={() => formikRef.current.submitForm()}
          className={classes.saveButton}
        >
          {intl.formatMessage(messages.saveButton)}
        </Button>
      </Grid>
    </div>
  );
};

RelateSurveyForm.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { RelateSurveyForm };
