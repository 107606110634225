/* eslint-disable no-fallthrough */
import React, { useEffect, useState } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Divider,
  Grid,
  IconButton,
  Typography,
  TextField
} from '@material-ui/core';
import { Delete, ExpandMore, MoreHoriz } from '@material-ui/icons';

import Autocomplete from 'components/MUI/Autocomplete/Autocomplete';
import { useStyles } from './QuestionStyles';
import UniqueOption from './UniqueOption/UniqueOption';
import YesNo from './YesNo/YesNo';
import LinearScale from './LinearScale/LinearScale';
import Square from './Square/Square';
import { QuestionTypes } from './QuestionTypes';
import { mapperOptionsQuestions } from './helper';

import { useApi } from 'hooks';
import { useIntl } from 'react-intl';

const Question = (props) => {
  const {
    questionObject,
    title,
    onDelete,
    startDrag,
    endDrag,
    onChangeData,
    setQuestions,
    setType,
    isEnglish,
    messages
  } = props;
  const classes = useStyles();
  const [questionType, setQuestionType] = useState([]);
  const { formatMessage } = useIntl();

  const [getPollsTypeList] = useApi({
    endpoint: '/polls/type/',
    method: 'get'
  });

  useEffect(async () => {
    const { headerResponse, payload } = await getPollsTypeList();

    if (headerResponse.code === 200) {
      setQuestionType(mapperOptionsQuestions(payload));
    }
  }, []);

  const initialQuestion = questionType.find(
    (element) => element.value === questionObject.type
  );

  const renderQuestion = (type) => {
    switch (type) {
      case QuestionTypes.uniqueOption:
        return (
          <UniqueOption
            onChangeData={onChangeData}
            data={questionObject}
            setQuestions={setQuestions}
            isEnglish={isEnglish}
            messages={messages}
          />
        );
      case QuestionTypes.multipleChoice:
        return (
          <UniqueOption
            onChangeData={onChangeData}
            data={questionObject}
            setQuestions={setQuestions}
            isEnglish={isEnglish}
            messages={messages}
          />
        );
      case QuestionTypes.linearScale:
        return (
          <LinearScale
            onChangeData={onChangeData}
            data={questionObject}
            isEnglish={isEnglish}
            messages={messages}
          />
        );
      case QuestionTypes.yesNo:
        return (
          <YesNo
            onChangeData={onChangeData}
            data={questionObject}
            isEnglish={isEnglish}
            messages={messages}
          />
        );
      case QuestionTypes.squares:
        return (
          <Square
            onChangeData={onChangeData}
            data={questionObject}
            setQuestions={setQuestions}
            isEnglish={isEnglish}
            messages={messages}
          />
        );
      default:
        return (
          <UniqueOption
            onChangeData={onChangeData}
            data={questionObject}
            isEnglish={isEnglish}
            messages={messages}
          />
        );
    }
  };

  const validacionMensajeRequired = () => {
    let messageRequired = '';

    if (isEnglish) {
      if (questionObject.dataEnglish?.required) {
        messageRequired = formatMessage(messages.requiredErrorEnglish);
      }
    } else {
      if (questionObject.data?.required) {
        messageRequired = formatMessage(messages.requiredError);
      }
    }

    return messageRequired;
  };

  return (
    <Card className={classes.container}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid
            item
            xs={12}
            sm={12}
            md={10}
            lg={10}
            className={classes.textFieldStylesQuestion}
          >
            <Typography>{title}</Typography>{' '}
            <Typography color="error">{validacionMensajeRequired()}</Typography>
          </Grid>
          <Grid container justify="flex-end" xs={12} sm={12} md={2} lg={2}>
            <IconButton className={classes.icon} onClick={onDelete}>
              <Delete />
            </IconButton>
            <IconButton
              className={classes.icon}
              onMouseOver={startDrag}
              onMouseLeave={endDrag}
            >
              <MoreHoriz />
            </IconButton>
          </Grid>
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <Grid container spacing={2} className={classes.questionZone}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              {renderQuestion(initialQuestion?.value)}
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}></Grid>
            <Grid item xs={12} sm={12} md={4} lg={4}>
              {isEnglish ? (
                <TextField
                  className={classes.textFieldStylesQuestion}
                  disabled
                  id="filled-disabled"
                  label={
                    isEnglish
                      ? formatMessage(messages.addOptionEnglish)
                      : formatMessage(messages.typeOfQuestion)
                  }
                  value={initialQuestion?.label ? initialQuestion?.label : ''}
                  variant="outlined"
                />
              ) : (
                <Autocomplete
                  value={initialQuestion}
                  name="questionType"
                  onChange={(event, newValue) => {
                    setType(newValue.value, questionObject.id);
                  }}
                  options={questionType}
                  optionLabel="label"
                  hasSeparateLabel={false}
                  label={
                    isEnglish
                      ? formatMessage(messages.addOptionEnglish)
                      : formatMessage(messages.typeOfQuestion)
                  }
                  renderOption={(option) => (
                    <div className={classes.options}>
                      <option.icon /> <span>{option['label']}</span>
                    </div>
                  )}
                  disabled={true}
                />
              )}
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

Question.propTypes = {};

export default Question;
