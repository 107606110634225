/* eslint-disable array-callback-return */
import React from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { useApi } from 'hooks';
import { Delete } from '@material-ui/icons';
import { SoundPlayer } from 'components/SoundPlayer';
import { useLoader } from '@octopy/react-loader';
import { Form } from '@octopy/react-form';
import { useStyles } from './UpdateGestureStyles';
import { messages } from './UpdateGestureMessages';
import { useUpdateGesture } from './hooks/useUpdateGesture';
import { Table } from '@octopy/react-table';
import { tableHead, tableHeadGesture } from '../UpdateGesture/utils';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { HeaderTitle } from 'components/HeaderTitle';

const UpdateGesture = (props) => {
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { handleShowLoader } = useLoader();
  const { modalSuccess } = useModalConfirmation();
  const {
    formik,
    formikRef,
    showSoundPlayer,
    prevewSpeech,
    dataAudios,
    tabGesture,
    selectedMediaId,
    inputName,
    setDataAudios,
    handleCreateSpeech,
    setShowSoundPlayer,
    setTabGesture,
    getValidationRules,
    getInitialValues,
    getFields,
    handleSubmit
  } = useUpdateGesture();

  const idIntents = history.location.state
    ? history.location.state.idIntents
    : history.push('/robots');

  const [updateIntents] = useApi({
    endpoint: `/intents/${idIntents}/`,
    method: 'put'
  });

  const actionFunctions = {
    selectedOptions: {
      checkboxHidden: true
    },
    actions: {
      customs: {
        text: 'Eliminar',
        icon: <Delete />,
        onClick: (item) => handleDeleteAudio(item)
      }
    }
  };

  const actionFunctionsGesture = {
    selectedOptions: {
      checkboxHidden: true
    },
    actions: {
      customs: {
        text: 'Eliminar',
        icon: <Delete />,
        onClick: (item) => handleDeleteGesture(item)
      }
    }
  };

  const handleDeleteAudio = (value) => {
    handleShowLoader(true);

    let newCheckedArray = [];

    dataAudios.map((item) => {
      if (item !== value) {
        newCheckedArray.push({
          ...item
        });
      }
    });

    setTimeout(() => {
      setDataAudios(newCheckedArray);
      handleShowLoader(false);
    }, 1000);
  };

  const handleDeleteGesture = (value) => {
    handleShowLoader(true);

    let newCheckedArray = [];

    tabGesture.map((item) => {
      if (item !== value) {
        newCheckedArray.push({
          ...item
        });
      }
    });

    setTimeout(() => {
      setTabGesture(newCheckedArray);
      handleShowLoader(false);
    }, 1000);
  };

  const handleUpdateIntents = async (data) => {
    try {
      let audiosArray = [];
      let gestureArray = [];
      let newBody = {
        publicity: selectedMediaId,
        gestures: gestureArray,
        audios: audiosArray
      };

      dataAudios.map((itemAudio) => {
        audiosArray.push({ text: itemAudio.text, s3_url: itemAudio.s3_url });
      });

      tabGesture.map((itemGesture) => {
        gestureArray.push({
          gesture_id: itemGesture.id_gesture,
          duration: parseInt(itemGesture.duration)
        });
      });

      const responseUpdate = await updateIntents({
        body: newBody
      });

      // console.log('UPDATE', responseUpdate);

      if (responseUpdate.headerResponse.code === 200) {
        modalSuccess(
          formatMessage(messages.titleModal),
          formatMessage(messages.textInfoSucces),
          () => {
            window.history.back();
          }
        );
      }
    } catch (error) {}
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HeaderTitle
            firstTitle={`${inputName} / ${formatMessage(messages.subtitle)}`}
          />
        </Grid>
        <Grid container spacing={2} style={{ marginTop: 20 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              disabled
              fullWidth
              variant="outlined"
              label={formatMessage(messages.name)}
              value={inputName}
            />
            <Typography className={classes.infoInputText}>
              {formatMessage(messages.nameGoogle)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              variant="outlined"
              type="text"
              label={formatMessage(messages.textRobot)}
              id="text"
              value={formik.values.text}
              onChange={formik.handleChange}
              error={formik.touched.text && Boolean(formik.errors.text)}
              helperText={formik.touched.text && formik.errors.text}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justify="flex-end"
          spacing={2}
          style={{ marginTop: '20px' }}
        >
          <Grid item>
            {showSoundPlayer && <SoundPlayer src={prevewSpeech} />}
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              disabled={formik.values.text === ''}
              onClick={() => {
                setShowSoundPlayer(true);
                handleCreateSpeech();
              }}
            >
              {formatMessage(messages.preview)}
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.button}
              onClick={() => formik.submitForm()}
            >
              {formatMessage(messages.addButton)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ marginTop: '20px' }}>
        <Typography className={classes.pageTitle}>
          {formatMessage(messages.audioSubtitle)}
        </Typography>
        <Typography className={classes.subttitleAudio}>
          {formatMessage(messages.addedAudioText)}
        </Typography>
        <Grid>
          <Table
            columns={tableHead}
            configProps={actionFunctions}
            data={dataAudios}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: '20px' }}>
        <Grid item xs={12} style={{ marginTop: '30px' }}>
          <Typography className={classes.subttitleAudio}>
            {formatMessage(messages.associateGesture)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ marginTop: '20px' }}>
          <Grid container className={classes.formGesture} spacing={2}>
            <Grid item xs={12}>
              <Form
                formikRef={formikRef}
                initialValues={getInitialValues()}
                validationRules={getValidationRules()}
                fields={getFields()}
                handleSubmit={handleSubmit}
                showSubmitButton={false}
                inputProps={{ variant: 'outlined', fullWidth: true }}
                withInputsBorder
              />
            </Grid>
            <Grid item xs={12} className={classes.buttonAddGesture}>
              <Button
                className={classes.button}
                onClick={() => formikRef.current.submitForm()}
              >
                {formatMessage(messages.buttonAdd)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} style={{ marginTop: '-10px' }}>
          <Table
            columns={tableHeadGesture}
            configProps={actionFunctionsGesture}
            data={tabGesture}
          />
        </Grid>

        {/* <PublicityForm /> */}
        {/* agregar video */}

        {/* <Grid item xs={12} style={{ marginTop: '30px' }}>
          <Typography className={classes.subttitleAudio}>
            {formatMessage(messages.associateVideo)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} style={{ marginTop: '20px' }}>
          <Grid container className={classes.formGesture} spacing={2}>
            <Grid item xs={12}>
              <Form
                formikRef={formikRef}
                initialValues={getInitialValues()}
                validationRules={getValidationRules()}
                fields={getFields()}
                handleSubmit={handleSubmit}
                showSubmitButton={false}
                inputProps={{ variant: 'outlined', fullWidth: true }}
                withInputsBorder
              />
            </Grid>
            <Grid item xs={12} className={classes.buttonAddGesture}>
              <Button
                className={classes.button}
                onClick={() => formikRef.current.submitForm()}
              >
                {formatMessage(messages.buttonAdd)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} style={{ marginTop: '-10px' }}>
          <Table
            columns={tableHeadGesture}
            configProps={actionFunctionsGesture}
            data={tabGesture}
          />
            </Grid> */}

        {/* ------------ */}

        <Grid container xs={12} className={classes.saveButton}>
          <Button
            className={classes.button}
            onClick={() => handleUpdateIntents()}
          >
            {formatMessage(messages.saveButton)}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

UpdateGesture.propTypes = {};

export default UpdateGesture;
