import { useState } from 'react';
import { Delete, Edit } from '@material-ui/icons';
import { TYPE_IMAGEN } from 'utils/TypeSource';
import { TYPE_VIEW_IMAGE, TYPE_VIEW_VIDEO } from 'utils/TypeViewScene';

export const useSceneDesign = (
  media,
  handleChangeMedia,
  handleChangeView,
  handleSetMediaSelected
) => {
  //States
  const [play, setPlay] = useState(false);
  const [isHorizontal, setIsHorizontal] = useState(false);

  //Function table media
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);

    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = ({ destination, source }, media, setMedia) => {
    if (!destination) return;

    const newItems = reorder(media, source.index, destination.index);

    setMedia(setPositionMedia(newItems));
  };

  const setPositionMedia = (items) => {
    items.map((item, index) => {
      item.position = index + 1; // eslint-disable-line no-param-reassign
    });

    return items;
  };

  //Options Item Media
  const options = [
    {
      text: 'Editar',
      onClick: (item) => {
        redirectToEdit(item);
      },
      icon: <Edit />
    },
    {
      text: 'Eliminar',
      onClick: (item) => {
        handleDeleteItem(item);
      },
      icon: <Delete />
    }
  ];

  const redirectToEdit = (item) => {
    console.log(item, '.....');
    handleSetMediaSelected(item);
    if (item.type == TYPE_IMAGEN) {
      handleChangeView(TYPE_VIEW_IMAGE);
    } else {
      handleChangeView(TYPE_VIEW_VIDEO);
    }
  };

  const handleDeleteItem = (idItem) => {
    var newItems = media.filter((item) => item.position !== idItem.position);

    handleChangeMedia(setPositionMedia(newItems));
  };

  return {
    onDragEnd,
    options,
    play,
    setPlay,
    isHorizontal,
    setIsHorizontal
  };
};
