import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Grid } from '@material-ui/core';
import Question from 'components/Question/Question';
import SurveySection from 'components/SurveySection/SurveySection';
import { messages } from './DraggableListMessages';

const DraggableListItem = ({
  item,
  index,
  isDragDisabled,
  startDrag,
  endDrag,
  onDelete,
  onChangeData,
  setQuestions,
  setType,
  isEnglishSurveys
}) => (
  <Draggable
    isDragDisabled={isDragDisabled}
    draggableId={item.id}
    index={index}
  >
    {(provided) => (
      <Grid
        item
        xs={12}
        sm={12}
        md={11}
        lg={11}
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {item.type === 'section' ? (
          <SurveySection
            onChangeData={onChangeData}
            data={item}
            startDrag={startDrag}
            endDrag={endDrag}
            onDelete={onDelete}
            isEnglish={isEnglishSurveys}
            messages={messages}
          />
        ) : (
          <Question
            onChangeData={onChangeData}
            setQuestions={setQuestions}
            questionObject={item}
            title={`${index + 1}.-`}
            startDrag={startDrag}
            endDrag={endDrag}
            onDelete={onDelete}
            setType={setType}
            isEnglish={isEnglishSurveys}
            messages={messages}
          />
        )}
      </Grid>
    )}
  </Draggable>
);

DraggableListItem.propTypes = {};

export default DraggableListItem;
