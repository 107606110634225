import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `0 ${theme.spacing(5)}px`,
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: `0 ${theme.spacing(1)}px`,
      padding: theme.spacing(1),
      marginTop: theme.spacing(3)
    }
  },
  pageTitle: {
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bold',
    letterSpacing: theme.typography.pxToRem(8)
  },
  addPofileButton: {
    textTransform: 'uppercase',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  },
  titleDrawer: {
    fontSize: '20px',
    fontWeight: 800,
    letterSpacing: '6px',
    marginBottom: theme.spacing(4)
  },
  contentTable: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(7)
    }
  }
}));

export { useStyles };
