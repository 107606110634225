import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  subtitle: {
    id: 'routes.RelatePlatforms.subtitle',
    defaultMessage: 'RELACIONAR PLATAFORMAS'
  },
  projectKey: {
    id: 'routes.RelatePlatforms.projectKey',
    defaultMessage: 'Llave de proyecto'
  },
  buttonFile: {
    id: 'routes.RelatePlatforms.buttonFile',
    defaultMessage: 'Seleccionar archivo JSON'
  },
  buttonSave: {
    id: 'routes.RelatePlatforms.buttonSave',
    defaultMessage: 'Guardar'
  },
  sectionTitle: {
    id: 'routes.RelatePlatforms.sectionTitle',
    defaultMessage: 'DialogFlow'
  },
  agentOneSpanish: {
    id: 'routes.RelatePlatforms.agentOneSpanish',
    defaultMessage: 'Agente 1: Español '
  },
  agentTwoEnglish: {
    id: 'routes.RelatePlatforms.agentTwoEnglish',
    defaultMessage: 'Agente 2: Ingles '
  },
  agentThreeSuport: {
    id: 'routes.RelatePlatforms.agentThreeSuport',
    defaultMessage: 'Agente 3: Soporte(español) '
  }
});
