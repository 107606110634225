import { Delete, AddCircleOutline } from '@material-ui/icons';
import ImageIcon from '@material-ui/icons/AccountCircleRounded';
import { useIntl } from 'react-intl';
import { messages } from './TableSelectMessages';

export const useTable = (handleClickAdd, handleClickDelete, isMediaEmpty) => {
  const { formatMessage: f } = useIntl();

  const configPropsRight = {
    selectedOptions: {
      checkboxHidden: true
    },
    actions: {
      customs: {
        text: 'Agregar',
        disabled: !isMediaEmpty,
        onClick: (item) => {
          handleClickAdd(item);
        },
        icon: <AddCircleOutline />
      }
    }
  };

  const configPropsLeft = {
    selectedOptions: {
      checkboxHidden: true
    },
    actions: {
      customs: {
        text: 'Eliminar',
        onClick: (item) => {
          handleClickDelete(item);
        },
        icon: <Delete />
      }
    }
  };

  const fieldImageForm = [
    Object.assign({
      type: 'image',
      name: 'source',
      breakpoints: { xs: 12 },
      icon: ImageIcon,
      label: f(messages.imageLabel),
      multiple: false,
      color: 'primary.main'
    })
  ];

  return {
    configPropsRight,
    configPropsLeft,
    fieldImageForm
  };
};
