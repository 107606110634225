import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '370px',
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    }
  },
  containerImage: {
    marginTop: '-4vh',
    marginBottom: '-4vh'
  },
  recoveryPasswordTexts: {
    textAlign: 'center',
    display: 'flex',
    fontSize: '26px !important',
    fontWeight: 800,
    letterSpacing: '6px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px'
    }
  },
  informationText: {
    display: 'flex',
    textAlign: 'center',
    fontSize: '16px',
    opacity: 1,
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      fontSize: '14px'
    }
  },
  contentForm: {
    width: '100%',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  contentButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(2)
  },
  buttonSend: {
    padding: theme.spacing(1.3),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: theme.palette.info.dark
    }
  },
  buttonCancel: {
    padding: theme.spacing(1.3),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    '&:hover': {
      backgroundColor: `${theme.palette.common.black}CE`,
      color: theme.palette.common.white
    }
  }
}));

export { useStyles };
