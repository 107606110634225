import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `0 ${theme.spacing(5)}px`,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(16),
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      margin: `0 ${theme.spacing(2)}px`,
      marginBottom: theme.spacing(16)
    }
  },
  surveyFormat: {
    fontWeight: 'bold',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  radioGroup: {
    '& .MuiFormControlLabel-root > .MuiTypography-body1': {
      fontSize: theme.typography.pxToRem(14)
    }
  },
  bold: {
    fontWeight: 'bold'
  },
  maxCharactersHelperText: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[600]
  },
  questionSectionTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold'
  },
  lastRow: {
    position: 'fixed',
    right: '30px',
    bottom: '30px',
    marginBottom: theme.spacing(4)
  },
  saveButton: {
    textTransform: 'uppercase',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  }
}));

export { useStyles };
