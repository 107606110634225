import React, { useState, useRef } from 'react';
import { useIntl } from 'react-intl';
import { Form } from '@octopy/react-form';
import { Typography, Button, Grid } from '@material-ui/core';
import { messages } from './FormScenesMessages';
import { useStyles } from './FormScenesStyles';
import { useFormScene } from './useFormScene';

const FormScenes = ({ idScene, callback }) => {
  const [isDesign, setIsDesign] = useState(false);

  const { formatMessage: f } = useIntl();
  const classes = useStyles();
  const formikRef = useRef();
  const {
    getInitialValues,
    getValidationRules,
    getFields,
    inputProps,
    handleSubmit
  } = useFormScene(idScene, isDesign, callback, formikRef);

  return (
    <div className={classes.container}>
      <Typography className={classes.subtitle}>
        {f(messages.subtitle)}
      </Typography>
      <Form
        formikRef={formikRef}
        initialValues={getInitialValues()}
        validationRules={getValidationRules()}
        fields={getFields()}
        handleSubmit={handleSubmit}
        showSubmitButton={false}
        inputProps={inputProps}
        withInputsBorder
      />
      <Grid container spacing={2} className={classes.contentButtons}>
        <Grid item>
          <Button
            className={classes.button}
            onClick={() => {
              setIsDesign(true);
              formikRef.current.submitForm();
            }}
          >
            {f(messages.saveAndDesign)}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

FormScenes.propTypes = {};

export { FormScenes };
