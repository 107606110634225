import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `0 ${theme.spacing(5)}px`,
    fontSize: theme.typography.pxToRem(14),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      margin: `0 ${theme.spacing(2)}px`
    }
  },
  pageTitle: {
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bold',
    letterSpacing: theme.typography.pxToRem(8)
  },
  MuiFormControlLabelRoot: {
    marginLeft: `-${theme.spacing(1)}px`,
    marginRight: 0
  },
  button: {
    textTransform: 'uppercase',
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  responsiveButton: {
    paddingBottom: theme.spacing(2)
  }
}));

export { useStyles };
