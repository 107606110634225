import { io } from 'socket.io-client';
import { config } from 'providers/config';
import { getItem } from 'utils/persistentStorage';

const token = getItem('token');
const sessionId = getItem('sessionId');

const socket = io(config.endpoints.socketBackendURL, {
  extraHeaders: {
    'x-api-key': token,
    'session-id': sessionId
  }
});

export const isSocketConnected = socket.connected;

export const emitSocket = (key, data) => {
  socket.emit(key, data);
};

export const onSocket = (key, callback) => {
  socket.on(key, (data) => {
    callback(data);
  });
};

export const connectError = (callback) => {
  socket.on('connect_error', () => {
    setTimeout(() => {
      socket.connect();
    }, 1000);
  });
};

export const disconnectionSocket = (callback) => {
  socket.on('disconnect', (reason) => {
    if (reason === 'io server disconnect') {
      // the disconnection was initiated by the server, you need to reconnect manually
      socket.connect();
    } else {
      callback(reason);
    }
  });
};

export const connectionSocket = (callback) => {
  socket.on('connect', (reason) => {
    callback(reason);
  });
};
