export const mapProducts = (values) => {
  const mappedProducts = values.map((item) => ({
    id: item.item_id,
    Nombre: item.name,
    Categoría: item.category,
    Subcategoría: item.sub_category
  }));

  return mappedProducts;
};
