import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  renderTextField,
  renderAutocomplete,
  renderImagePicker,
  renderCheckbox
} from '@octopy/react-form';
import { Typography, Grid, Button } from '@material-ui/core';
import { TextBotSelect } from 'components/TextBotSelect';
import { useHelper } from './helpers';
import { messages } from './ProductFormMessages';
import { useStyles } from './ProductFormStyles';
import { HeaderTitle } from 'components/HeaderTitle';

const ProductForm = () => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    idProduct,
    formik,
    fieldsMainFormik,
    fieldsImageFormik,
    fieldsDescriptionSpanishFormik,
    fieldAddEnglishFormik,
    fieldsMainEnglishFormik,
    fieldsDescriptionEnglishFormik,
    handleChangeText,
    handleChangeRobot,
    handleChangeTextEnglish,
    handleChangeRobotEnglish
  } = useHelper();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fieldsMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'title' && field.text}
      {field.type === 'text' && renderTextField({ index, formik, field })}
      {field.type === 'select' &&
        renderAutocomplete({
          index,
          formik,
          field,
          inputProps: { variant: 'outlined', fullWidth: true }
        })}
    </Grid>
  );

  const fieldsImageMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'title' && field.text}
      {field.type === 'subTitle' && field.text}
      {field.type === 'image' && renderImagePicker({ index, formik, field })}
    </Grid>
  );

  const fieldsDescriptionSpanishMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'text' && renderTextField({ index, formik, field })}
    </Grid>
  );

  const fieldCheckboxMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'checkbox' && renderCheckbox({ index, formik, field })}
    </Grid>
  );

  const fieldsEnglishMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'text' && renderTextField({ index, formik, field })}
      {field.type === 'select' &&
        renderAutocomplete({
          index,
          formik,
          field,
          inputProps: { variant: 'outlined', fullWidth: true }
        })}
    </Grid>
  );

  const fieldsDescriptionEnglishMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'text' && renderTextField({ index, formik, field })}
    </Grid>
  );

  return (
    <div className={classes.container}>
      <HeaderTitle
        firstTitle={
          idProduct === 0
            ? intl.formatMessage(messages.titleItem)
            : intl.formatMessage(messages.titleEditItem)
        }
      />
      <Grid container spacing={2}>
        {fieldsMainFormik.map(fieldsMapper)}
      </Grid>
      <Grid style={{ marginTop: '25px', marginBottom: '20px' }}>
        {fieldsImageFormik.map(fieldsImageMapper)}
      </Grid>
      <Grid container>
        <Grid item>
          <Typography className={classes.titleTextBot}>
            {intl.formatMessage(messages.titleTextBot)}
          </Typography>
          <Typography className={classes.descriptionTextBot}>
            {intl.formatMessage(messages.descriptionTextBot)}
          </Typography>
        </Grid>
        <Grid item container spacing={2}>
          {fieldsDescriptionSpanishFormik.map(fieldsDescriptionSpanishMapper)}
          <TextBotSelect
            textBot={formik.values.text}
            idBot={formik.values.robot}
            textBotMessage={formik.touched.text && formik.errors.text}
            idBotMessage={formik.touched.robot && formik.errors.robot}
            onHandleChangeText={handleChangeText}
            onHandleChangeBot={handleChangeRobot}
            isEnglish={false}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 30 }}>
        <Grid item>{fieldAddEnglishFormik.map(fieldCheckboxMapper)}</Grid>
      </Grid>
      <Grid container spacing={2}>
        {formik.values.addEnglish ? (
          <Grid item container style={{ marginTop: 20 }}>
            <Grid item xs={12}>
              <Typography className={classes.title}>
                {intl.formatMessage(messages.englishInfo)}
              </Typography>
            </Grid>
            <Grid container spacing={2}>
              {fieldsMainEnglishFormik.map(fieldsEnglishMapper)}
            </Grid>
            <Grid item style={{ marginTop: 20 }}>
              <Typography className={classes.titleTextBot}>
                {intl.formatMessage(messages.titleTextBot)}
              </Typography>
              <Typography className={classes.descriptionTextBot}>
                {intl.formatMessage(messages.descriptionTextBot)}
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              {fieldsDescriptionEnglishFormik.map(
                fieldsDescriptionEnglishMapper
              )}
              <TextBotSelect
                textBot={formik.values.textEnglish}
                idBot={formik.values.robotEnglish}
                textBotMessage={
                  formik.touched.textEnglish && formik.errors.textEnglish
                }
                idBotMessage={
                  formik.touched.robotEnglish && formik.errors.robotEnglish
                }
                onHandleChangeText={handleChangeTextEnglish}
                onHandleChangeBot={handleChangeRobotEnglish}
                isEnglish={true}
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      <Grid className={classes.contentButton}>
        <Button className={classes.button} onClick={() => formik.submitForm()}>
          {idProduct === 0
            ? intl.formatMessage(messages.add)
            : intl.formatMessage(messages.save)}
        </Button>
      </Grid>
    </div>
  );
};

ProductForm.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { ProductForm };
