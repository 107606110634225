import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    display: 'flex',
    textAlign: 'center',
    flexDirection: 'column',
    color: theme.palette.common.white,
    width: '100%',
    height: '100vh',
    backgroundColor: theme.palette.common.loaderBackground,
    opacity: '0.4 !important',
    zIndex: theme.zIndex.drawer + 2
  },
  loadingText: {
    fontSize: '60px',
    letterSpacing: '6px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '45px'
    }
  }
}));

export { useStyles };
