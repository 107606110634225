import { useEffect, useState } from 'react';
import { useApi } from 'hooks';
import { getFileFromUrl } from 'utils/fileAudioUrl';
import { useExport } from 'hooks/useExport';
import { get } from 'lodash';
import { getItem } from 'utils/persistentStorage';

const mapSurveys = (surveys) => {
  const mappedSurveys = surveys.map(
    (survey) => ({
      id: survey.poll_id,
      status: survey.status === true ? 'Activo' : 'Inactivo',
      title: get(survey, ['title', [0], 'text'], ''),
      creationDate: get(survey, 'created_date', ''),
      owner: `${survey.created_by.name} ${survey.created_by.last_name}`
    }),
    []
  );

  return mappedSurveys;
};

export const useHelper = () => {
  const storage = JSON.parse(localStorage.getItem('session'));

  const clientId = storage.user.client.client_id;

  const { exportToExcel } = useExport();

  const [surveys, setSurveys] = useState([]);

  const [deletePollspoll] = useApi({
    endpoint: '/polls/poll',
    method: 'delete'
  });
  const [getListPollpoll] = useApi({
    endpoint: `/polls/poll/polls_by_client_id/${clientId}`,
    method: 'get'
  });
  const [postAnswersExcel] = useApi({
    endpoint: '/polls/poll/answers_excel/',
    method: 'post'
  });

  const handleListPollPoll = async () => {
    try {
      const { headerResponse, payload } = await getListPollpoll();

      if (headerResponse.code === 200) {
        const mappedSurveys = mapSurveys(payload);

        setSurveys([...mappedSurveys]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAnswersExcel = async ({ poll, idClient }) => {
    try {
      const { headerResponse, payload } = await postAnswersExcel({
        body: {
          client: idClient,
          poll,
          start_date: '',
          end_date: ''
        }
      });

      if (headerResponse.code === 200) {
        const fileUrl = await getFileFromUrl(payload.url, 'Excel');

        const aElement = document.createElement('a');

        aElement.setAttribute(
          'download',
          `listado_de_encuestas_${fileUrl.lastModified}`
        );
        const href = URL.createObjectURL(fileUrl);

        aElement.href = href;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(href);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    handleListPollPoll();
  }, []);

  return { surveys, handleAnswersExcel };
};
