import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Button } from '@material-ui/core';
import { ExitToApp, AddCircle, Delete } from '@material-ui/icons';
import { Table } from '@octopy/react-table';
import { useHistory } from 'react-router-dom';
import { messages } from './DirectoryMessages';
import { useStyles } from './DirectoryStyles';
import { useDirectory } from './useDirectory';
import { useExport } from 'hooks/useExport';
import { mapDirectory } from './helpers';

const Directory = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { exportToExcel } = useExport();
  const { directory, handleEditItem, handleDeleteItem } = useDirectory();

  const tableHead = [
    { key: 'directory_id', label: 'ID' },
    { key: 'name', label: 'NOMBRE' },
    { key: 'specialty', label: 'ESPECIALIDAD' },
    { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false }
  ];

  const filters = [
    { key: 'name', value: 'NOMBRE' },
    { key: 'specialty', value: 'ESPECIALIDAD' }
  ];

  const configProps = {
    filters,
    selectedOptions: {
      checkboxHidden: true
    },
    actions: {
      edit: {
        onClick: (item) => handleEditItem(item.directory_id),
        disabled: false,
        hidden: false
      },
      deleteAction: {
        text: 'Eliminar',
        icon: <Delete />,
        onClick: (item) => handleDeleteItem(item.directory_id)
      }
    }
  };

  const addMedic = () => {
    history.push('/directory/form', {
      idMedical: 0
    });
  };

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} lg={6} className={classes.contentTitles}>
          <Typography className={classes.directoryTitle}>
            {intl.formatMessage(messages.directoryTitle)}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} className={classes.contentButons}>
            <Grid item>
              <Button
                onClick={() =>
                  exportToExcel(
                    mapDirectory(directory),
                    `${intl.formatMessage(messages.directoryTitle)}`
                  )
                }
                className={classes.exportButton}
                endIcon={<ExitToApp />}
              >
                <Typography className={classes.textButon}>
                  {intl.formatMessage(messages.exportButton)}
                </Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.addItemButton}
                endIcon={<AddCircle />}
                onClick={() => addMedic()}
              >
                <Typography className={classes.textButon}>
                  {intl.formatMessage(messages.addItems)}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.contentTable}>
        <Table columns={tableHead} data={directory} configProps={configProps} />
      </Grid>
    </div>
  );
};

Directory.propTypes = {};

export { Directory };
