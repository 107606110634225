/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Table } from '@octopy/react-table';
import { Grid } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { useApi } from 'hooks';
import { filters, tableHead } from './utils';
import { messages } from './AudioFlowMessages';
import { useStyles } from './AudioFlowStyles';
import { HeaderTitle } from 'components/HeaderTitle';

const AudioFlow = () => {
  const [data, setData] = useState([]);
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const idRobot = history.location.state.robot_id;
  const nameRobot = history.location.state.name;
  const clientBucketName = history.location.state.client.bucket_name;

  const [getAudioFlowByRobotId] = useApi({
    endpoint: `/intents/intents_screen_flow_by_robot/${idRobot}/`,
    method: 'get'
  });

  useEffect(() => {
    getAudioFlow();
  }, []);

  const getAudioFlow = async () => {
    try {
      const response = await getAudioFlowByRobotId();
      const dataResponse = get(response, 'payload', '');
      const headerResponse = get(response, 'headerResponse', '');

      if (headerResponse.code === 200) {
        const array = [];

        dataResponse.map((item) => {
          array.push({
            ...item,
            audios: item.audios ? item.audios.length : 0,
            agent: item.agent.session_type
              ? item.agent.session_type.session_name
              : ''
          });
        });

        setData(array);
      }
    } catch (error) {}
  };

  const actionFunctions = {
    edit: {
      text: intl.formatMessage(messages.edit),
      icon: EditIcon,
      onClick: async (item) => {
        history.push('/robot/audios-flow/update', {
          idIntention: item.id,
          clientBucketName
        });
      }
    }
  };

  const configProps = {
    filters,
    actions: actionFunctions,
    selectedOptions: {
      checkboxHidden: true
    }
  };

  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12}>
          <HeaderTitle
            firstTitle={nameRobot}
            secondTitle={intl.formatMessage(messages.title)}
            redirection="robots"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.contentTable}
        >
          <Table data={data} columns={tableHead} configProps={configProps} />
        </Grid>
      </Grid>
    </div>
  );
};

AudioFlow.propTypes = {};

export default AudioFlow;
