export const AUDIOFORMAT = [
  'audio/wav',
  'audio/aiff',
  'audio/au',
  'audio/flac',
  'audio/mpeg-4,',
  'audio/mpeg',
  'audio/shorten',
  'audio/tta',
  'audio/atrac',
  'audio/apple',
  'audio/lossless',
  'audio/mp3',
  'audio/vorbis',
  'audio/musepack',
  'audio/aac',
  'audio/wma',
  'audio/opus',
  'audio/ogg',
  'audio/dsd',
  'audio/mqa'
];

export const VIDEOFORMAT = [
  'video/mp4',
  'video/avi',
  'video/mkv',
  'video/flv',
  'video/mov',
  'video/wmv',
  'video/divx',
  'video/h.264',
  'video/xvid',
  'video/rm'
];

export const IMAGEFORMAT = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/bmp',
  'image/gif',
  'image/tiff',
  'image/heif',
  'image/raw',
  'image/psd'
];

export const SIZE100MB = 100000000;
export const SIZE30MB = 30000000;
