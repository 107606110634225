import { useApi } from 'hooks';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useLoader } from '@octopy/react-loader';
import { Alert, useModal } from '@octopy/react-modal';
import {
  extractAgentData,
  extractElementData,
  mergeAgentAndAudioText,
  createDataUpdate
} from 'utils/robot';
import { useScreenLoader } from 'components/ScreenLoader';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { sources } from 'providers/AWS/';
import { uploadFilePromise } from 'utils/aws';
import { getFileFromUrl } from 'utils/fileAudioUrl';
import { messages } from '../DirectoryMessages';
import { Button, Grid, Typography } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import { useStyles } from '../DirectoryStyles';

const mapperDirectoryList = (directoryList) => {
  const mapped = directoryList.map((directoryL) => ({
    id: directoryL?.directory_id,
    name: directoryL?.name,
    specialty: directoryL?.specialty ? directoryL.specialty.name[0].text : '',
    audio_text: directoryL.audio_text
  }));

  return mapped;
};

const mapperDirectoryListRobot = (directoryListRobot) => {
  const mapped = directoryListRobot.map((directoryLR) => ({
    id: directoryLR.directory_id,
    name: directoryLR.name,
    specialty: directoryLR.specialty ? directoryLR.specialty.name[0].text : '',
    audio_text: directoryLR.audio_text
  }));

  return mapped;
};

export const useHelper = (leftAux, setLeftAux, location) => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const bucketName = get(storage, 'user.client.bucket_name', {});
  const isStaff = get(storage, 'user.is_staff', '');

  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const { audios } = sources(bucketName);
  const { handleShowLoader } = useLoader();
  const { handleShowScreen } = useScreenLoader();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { modalSuccess } = useModalConfirmation();
  const [directory, setDirectory] = useState([]);

  const [getDirectoryList] = useApi({
    endpoint: '/directories/directory/',
    method: 'get'
  });
  const [getRobotId] = useApi({
    endpoint: '/robots/robots',
    method: 'get'
  });
  const [agentesByRobots] = useApi({
    endpoint: '/agents/agents_by_robot',
    method: 'get'
  });
  const [updateRobotsDirectory] = useApi({
    endpoint: '/robots/robots',
    method: 'put'
  });
  const [createSpeech] = useApi({
    endpoint: '/speech/',
    method: 'post'
  });
  const [getDirectoriesByRobot] = useApi({
    endpoint: '/directories/directory',
    method: 'get'
  });

  const handleDirectoryList = async () => {
    try {
      const { headerResponse, payload } = await getDirectoryList();

      if (headerResponse.code === 200) {
        const mappedDirectoryList = mapperDirectoryList(payload);

        setDirectory(mappedDirectoryList);
      }
    } catch (error) {}
  };

  const createDirectory = async (dataItems) => {
    let mappedDirectoryAux = [];

    for (const item of dataItems) {
      const response = await getDirectoriesByRobot({
        urlParams: item.directory_id
      });

      const dataResponse = get(response, 'payload', []);

      const mappedDirectoryListRobot = mapperDirectoryListRobot([dataResponse]);

      mappedDirectoryAux = [...mappedDirectoryAux, ...mappedDirectoryListRobot];
    }

    return mappedDirectoryAux;
  };

  const handleRobotId = async () => {
    try {
      const { headerResponse, payload } = await getRobotId({
        urlParams: location.state.robot_id
      });

      const dataAgents = get(payload, 'agents', []);
      const dataDirectories = get(payload, 'directories', []);

      if (headerResponse.code === 200) {
        if (dataAgents.length >= 1) {
          const result = await createDirectory(dataDirectories);

          setLeftAux(result ? result : null);
        } else {
          handleOpenModal({
            title: (
              <Grid className={classes.contentModal}>
                <ReportProblemOutlined className={classes.icon} />
                <Typography className={classes.titleModal}>
                  {intl.formatMessage(messages.emptyAgents)}
                </Typography>
              </Grid>
            ),
            body: (
              <Grid style={{ marginBottom: 20, textAlign: 'center' }}>
                <Typography className={classes.infoTextModal}>
                  {!isStaff
                    ? intl.formatMessage(messages.infoReportProblem)
                    : intl.formatMessage(messages.infoReportProblemIsStaff)}
                </Typography>
                <Button
                  className={classes.button}
                  onClick={() => {
                    history.push('/robots');
                    handleCloseModal();
                  }}
                >
                  {intl.formatMessage(messages.acept)}
                </Button>
              </Grid>
            )
          });
        }
      }
    } catch (error) {}
  };

  const handleCreateSpeech = async (data) => {
    try {
      handleShowLoader(false);

      const response = await createSpeech({
        body: data
      });

      const dataResponse = get(response, 'payload', '');
      const fileUrl = await getFileFromUrl(dataResponse, 'audio');
      let urlAudio = await uploadFilePromise(fileUrl, audios, errorUploadFile);

      return urlAudio;
    } catch (error) {
      handleShowScreen(false);
    }
  };

  const handleUpdate = async (s3) => {
    try {
      handleShowScreen(true);
      const robotId = location.state.robot_id;
      let body = { directories: s3 };

      const response = await updateRobotsDirectory({
        urlParams: `${robotId}/`,
        body
      });

      if (response.headerResponse.code === 200) {
        modalSuccess(
          intl.formatMessage(messages.titleSuccessModal),
          intl.formatMessage(messages.textInfoSucces),
          () => {
            history.push('/robots');
          }
        );
      }
    } catch (error) {
      handleShowScreen(false);
    } finally {
      handleShowScreen(false);
    }
  };

  const handleAddDirectoryRobot = async (leftAux) => {
    const robotId = location.state.robot_id;

    try {
      handleShowScreen(true);

      const responseItemsData = extractElementData(leftAux, 'id');
      const response = await agentesByRobots({
        urlParams: robotId
      });
      const dataResponse = get(response, 'payload', []);
      const responseAgentData = extractAgentData(dataResponse);
      const audioTextData = mergeAgentAndAudioText(
        responseItemsData,
        responseAgentData
      );

      createDataUpdate({
        items: audioTextData,
        idReference: 'directory_id',
        runFetches: handleCreateSpeech,
        callback: handleUpdate
      });
    } catch (error) {
      handleShowScreen(false);
    }
  };

  const errorUploadFile = () => {
    handleOpenModal({
      configProps: {
        maxWidth: 'sm'
      },
      body: <Alert message={intl.formatMessage(messages.uploadFileError)} />
    });
    handleShowLoader(false);
  };

  return {
    directory,
    handleDirectoryList,
    handleRobotId,
    handleAddDirectoryRobot
  };
};
