/* eslint-disable array-callback-return */
/* eslint-disable default-case */
import { FullScreenLayout } from 'layouts';
import { Error404 } from 'views/Error404';
import { Login, PasswordRecovery } from 'components/Auth';
import { get } from 'lodash';
import {
  Advertising,
  Branch,
  Catalog,
  Categories,
  Clients,
  Common,
  Directory,
  MetricsAnalyctics,
  Permission,
  Robots,
  RobotAdmin,
  Survey,
  Turns,
  Users,
  Information
} from './routesLists';
import {
  MODULE_ROBOT,
  MODULE_METRICS,
  MODULE_SURVEYS,
  MODULE_ADVERTISING,
  MODULE_CATEGORIES,
  MODULE_CATALOG,
  MODULE_BRANCHES,
  MODULE_DIRECTORY,
  MODULE_TURNS,
  MODULE_USERS,
  MODULE_PERMISSIONS,
  MODULE_CLIENTS,
  MODULE_INFORMATION
} from 'utils/modules';

export const getRouteList = (arrayModules) => {
  let routesList = [];

  arrayModules.map((item) => {
    switch (item.name) {
      case MODULE_ROBOT:
        routesList = routesList.concat(Robots);
        break;
      case MODULE_METRICS:
        routesList = routesList.concat(MetricsAnalyctics);
        break;
      case MODULE_SURVEYS:
        routesList = routesList.concat(Survey);
        break;
      case MODULE_ADVERTISING:
        routesList = routesList.concat(Advertising);
        break;
      case MODULE_CATEGORIES:
        routesList = routesList.concat(Categories);
        break;
      case MODULE_CATALOG:
        routesList = routesList.concat(Catalog);
        break;
      case MODULE_BRANCHES:
        routesList = routesList.concat(Branch);
        break;
      case MODULE_DIRECTORY:
        routesList = routesList.concat(Directory);
        break;
      case MODULE_TURNS:
        routesList = routesList.concat(Turns);
        break;
      case MODULE_USERS:
        routesList = routesList.concat(Users);
        break;
      case MODULE_PERMISSIONS:
        routesList = routesList.concat(Permission);
        break;
      case MODULE_CLIENTS:
        routesList = routesList.concat(Clients);
        break;
      case MODULE_INFORMATION:
        routesList = routesList.concat(Information);
        break;
    }
  });

  routesList = routesList.concat(Common);

  return routesList;
};

export const routes = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const userProfile = storage?.user ? storage.user?.profile : null;
  const modulesProfile = userProfile != null ? userProfile.modules : [];
  const isSuperAdmin = get(storage, 'user.is_staff', {});

  let routesList = [];

  if (isSuperAdmin) {
    routesList = routesList.concat(
      Robots,
      RobotAdmin,
      Users,
      Permission,
      Clients,
      Common
    );
  } else {
    routesList = getRouteList(modulesProfile);
  }

  return {
    admin: routesList,
    publicRoutes: [
      {
        path: '/login',
        layout: (props) => <FullScreenLayout withSplashScreen {...props} />,
        component: Login,
        exact: true,
        titleMessage: 'login',
        private: false
      },
      {
        path: '/password-recovery',
        layout: FullScreenLayout,
        component: PasswordRecovery,
        exact: true,
        titleMessage: 'passwordRecovery',
        private: false
      },
      {
        layout: FullScreenLayout,
        component: Error404,
        titleMessage: '404'
      }
    ]
  };
};
