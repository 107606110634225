import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `0 ${theme.spacing(5)}px`,
    padding: '20px',
    paddingBottom: theme.spacing(7),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      margin: `0 ${theme.spacing(1)}px`,
      padding: '10px',
      paddingBottom: theme.spacing(7)
    }
  },
  pageTitle: {
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bold',
    letterSpacing: theme.typography.pxToRem(6),
    marginBottom: '25px'
  },
  subttitleAudio: {
    fontSize: '14px',
    fontWeight: 'bold'
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  formGesture: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  buttonAddGesture: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  saveButton: {
    marginTop: '20px',
    // marginButton: '20px',
    justifyContent: 'flex-end',
    padding: '10px'
  },
  infoInputText: {
    fontSize: '12px',
    color: theme.palette.grey[500],
    marginTop: theme.spacing(1),
    opacity: 1
  }
}));

export { useStyles };
