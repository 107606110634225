import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  appName: {
    id: 'routes.Navigation.appName',
    defaultMessage: 'Octopy Architecture'
  },
  octopy: {
    id: 'routes.Navigation.octopy',
    defaultMessage: 'Octopy'
  },
  login: {
    id: 'routes.Navigation.login',
    defaultMessage: 'Iniciar sesión'
  },
  passwordRecovery: {
    id: 'routes.Navigation.passwordRecovery',
    defaultMessage: 'Recuperación de contraseña'
  },
  resolutions: {
    id: 'routes.Navigation.resolutions',
    defaultMessage: 'Resoluciones'
  },
  404: {
    id: 'routes.Navigation.404',
    defaultMessage: 'Pagina no encontrada'
  },
  metrics: {
    id: 'routes.Navigation.metrics',
    defaultMessage: 'Métricas'
  },
  welcome: {
    id: 'routes.Navigation.welcome',
    defaultMessage: 'Bienvenida'
  },
  myAccount: {
    id: 'routes.Navigation.profile',
    defaultMessage: 'Mi cuenta'
  },
  robot: {
    id: 'routes.Navigation.robot',
    defaultMessage: 'Robots'
  },
  robotAlerts: {
    id: 'routes.Navigation.robotAlerts',
    defaultMessage: 'Robots - Alertas'
  },
  robotDirectory: {
    id: 'routes.Navigation.robotDirectory',
    defaultMessage: 'Robots - Ditectory'
  },
  robotCatalog: {
    id: 'routes.Navigation.robotCatalog',
    defaultMessage: 'Robots - Catálogo'
  },
  robotBranches: {
    id: 'routes.Navigation.robotBranches',
    defaultMessage: 'Robots - Sucursales'
  },
  robotInteractions: {
    id: 'routes.Navigation.robot_interactions',
    defaultMessage: 'Robots - Interacciones'
  },
  robotGestures: {
    id: 'routes.Navigation.robotGestures',
    defaultMessage: 'Robots - Gestos'
  },
  robotGesturesEdit: {
    id: 'routes.Navigation.robotGesturesEdit',
    defaultMessage: 'Robots - Editar intención'
  },
  robotTimes: {
    id: 'routes.Navigation.robotTimes',
    defaultMessage: 'Robots - Tiempos de trayectoria'
  },
  robot_relate_platforms: {
    id: 'routes.Navigation.robot_relate_platforms',
    defaultMessage: 'Robots - Relacionar plataformas'
  },
  robot_audios: {
    id: 'routes.Navigation.robot_audios',
    defaultMessage: 'Robots - Audios para flujos de pantalla'
  },
  robot_audios_create: {
    id: 'routes.Navigation.robot_audios_create',
    defaultMessage: 'Robots - Audios para flujos de pantalla: Crear'
  },
  robotBranding: {
    id: 'routes.Navigation.robotBranding',
    defaultMessage: 'Robots - Branding'
  },
  personalInformation: {
    id: 'routes.Navigation.personalInformation',
    defaultMessage: 'Robots - Información Personal de Laboratorio'
  },
  surveys: {
    id: 'routes.Navigation.surveys',
    defaultMessage: 'Encuestas'
  },
  surveysCreate: {
    id: 'routes.Navigation.surveysCreate',
    defaultMessage: 'Encuestas - Nueva encuesta'
  },
  surveysEdit: {
    id: 'routes.Navigation.surveysEdit',
    defaultMessage: 'Encuestas - Editar encuesta'
  },
  surveysPreview: {
    id: 'routes.Navigation.surveysPreview',
    defaultMessage: 'Encuestas - Previsualizar encuesta'
  },
  advertising: {
    id: 'routes.Navigation.advertising',
    defaultMessage: 'Publicidad'
  },
  advertisingScenes: {
    id: 'routes.Navigation.advertisingScenes',
    defaultMessage: 'Publicidad - Escenas'
  },
  advertisingCampaigns: {
    id: 'routes.Navigation.advertisingCampaigns',
    defaultMessage: 'Publicidad - Campañas'
  },
  advertisingMediaLibrary: {
    id: 'routes.Navigation.advertisingMediaLibrary',
    defaultMessage: 'Publicidad - Biblioteca de medios'
  },
  advertisingResolutions: {
    id: 'routes.Navigation.advertisingResolutions',
    defaultMessage: 'Publicidad - Resoluciones'
  },

  advertisingEvents: {
    id: 'routes.Navigation.advertisingEvents',
    defaultMessage: 'Publicidad - Eventos'
  },
  advertisingScreens: {
    id: 'routes.Navigation.advertisingScreens',
    defaultMessage: 'Publicidad - Pantallas'
  },
  products: {
    id: 'routes.Navigation.products',
    defaultMessage: 'Productos'
  },
  productsAdd: {
    id: 'routes.Navigation.productsAdd',
    defaultMessage: 'Productos - Agregar'
  },
  categories: {
    id: 'routes.Navigation.categories',
    defaultMessage: 'Categorías'
  },
  subcategories: {
    id: 'routes.Navigation.subcategories',
    defaultMessage: 'Subcategorías'
  },
  branch: {
    id: 'routes.Navigation.branch',
    defaultMessage: 'Sucursales'
  },
  branchCreate: {
    id: 'routes.Navigation.branchCreate',
    defaultMessage: 'Sucursales - Nueva sucursal'
  },
  directory: {
    id: 'routes.Navigation.directory',
    defaultMessage: 'Directorio'
  },
  directoryForm: {
    id: 'routes.Navigation.directoryForm',
    defaultMessage: 'Directorio - Formulario'
  },
  turn: {
    id: 'routes.Navigation.turn',
    defaultMessage: 'Turnos'
  },
  users: {
    id: 'routes.Navigation.users',
    defaultMessage: 'Usuarios'
  },
  permissions: {
    id: 'routes.Navigation.permissions',
    defaultMessage: 'Permisos'
  },
  clients: {
    id: 'routes.Navigation.clients',
    defaultMessage: 'Clientes'
  },
  historyTurns: {
    id: 'routes.Navigation.historyTurns',
    defaultMessage: 'Historial de turnos'
  },
  takeTurns: {
    id: 'routes.Navigation.takeTurns',
    defaultMessage: 'Tomar turno'
  },
  information: {
    id: 'components.Navigation.information',
    defaultMessage: 'Información'
  },
  informationAdd: {
    id: 'components.Navigation.information',
    defaultMessage: 'Información'
  }
});
