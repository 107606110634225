/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { useModal, Alert } from '@octopy/react-modal';
import { useLoader } from '@octopy/react-loader';
import { Button, Grid, Typography } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import { useApi } from 'hooks';
import { get } from 'lodash';
import { responseMapper } from 'utils/responseMapper';
import { useScreenLoader } from 'components/ScreenLoader';
import { informationListMapper } from '../utils';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { messages } from '../PersonalInformationMessages';
import { useStyles } from '../PersonalInformationStyles';
import { getFileFromUrl } from 'utils/fileAudioUrl';
import { uploadFilePromise } from 'utils/aws';
import { sources } from 'providers/AWS';
import { useModalConfirmation } from 'components/ModalConfirmation';
import {
  createDataUpdate,
  extractAgentData,
  extractElementData,
  mergeAgentAndAudioText
} from 'utils/robot';
import { getItem } from 'utils/persistentStorage';

export const usePersonalInformation = ({ setLeftAux, leftAux }) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const storage = getItem('session');
  const bucketName = get(storage, 'user.client.bucket_name', {});
  const isStaff = get(storage, ['user', 'is_staff'], '');
  const clientId = get(storage, 'user.client.client_id', {});
  const { audios } = sources(bucketName);
  const { modalSuccess } = useModalConfirmation();
  const { handleShowLoader } = useLoader();
  const { handleShowScreen } = useScreenLoader();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { location } = history;
  const [dataTable, setDataTable] = useState([]);
  const idRobot = location.state.robot_id;

  const [getRobotId] = useApi({
    endpoint: '/robots/robots',
    method: 'get'
  });
  const [getInformationByClientId] = useApi({
    endpoint: `/information/information/information_items_by_client/${clientId}/`,
    method: 'get'
  });

  const [getAgents] = useApi({
    endpoint: 'agents/agents_by_robot',
    method: 'get'
  });
  const [createSpeech] = useApi({
    endpoint: '/speech/',
    method: 'post'
  });
  const [robotUpdate] = useApi({
    endpoint: `/robots/robots/${idRobot}/`,
    method: 'put'
  });
  const [getInformationId] = useApi({
    endpoint: 'information/information',
    method: 'get'
  });

  const createInformation = async (dataItems) => {
    let mappedInformationAux = [];
    let newArray = [];

    for (const item of dataItems) {
      const response = await getInformationId({
        urlParams: item.information_item_id
      });

      const dataResponse = get(response, 'payload', []);

      [dataResponse].map((itm) => {
        newArray.push({
          ...itm,
          audio_text: [
            {
              language: {
                language_id: 1,
                name: 'Español',
                currency: 'MXN'
              },
              text: itm.text
            }
          ]
        });
      });

      const mappedInformation = responseMapper({
        template: informationListMapper,
        data: newArray
      });

      mappedInformationAux = [...mappedInformation];
    }

    return mappedInformationAux;
  };

  const handleRobotId = async () => {
    try {
      const response = await getRobotId({
        urlParams: location.state.robot_id
      });
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', []);
      //const dataAgents = get(dataResponse, 'agents', []);
      const dataInformation = get(dataResponse, 'information_items', []);

      /* Acá tambien tiene un find
      let agentChemical = dataAgents.find(
        (item) => item.session_type.session_name === 'usuario_quimico'
      );*/

      if (headerResponse.code === 200) {
        let result = await createInformation(dataInformation);

        setLeftAux(result);
      } else {
        handleOpenModal({
          title: (
            <Grid className={classes.contentModal}>
              <ReportProblemOutlined className={classes.icon} />
              <Typography className={classes.titleModal}>
                {intl.formatMessage(messages.emptyAgentChemical)}
              </Typography>
            </Grid>
          ),
          body: (
            <Grid style={{ marginBottom: 20, textAlign: 'center' }}>
              <Typography className={classes.infoTextModal}>
                {!isStaff
                  ? intl.formatMessage(messages.infoReportProblem)
                  : intl.formatMessage(messages.infoReportProblemIsStaff)}
              </Typography>
              <Button
                className={classes.button}
                onClick={() => {
                  history.push('/robots');
                  handleCloseModal();
                }}
              >
                {intl.formatMessage(messages.acept)}
              </Button>
            </Grid>
          )
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInformation = async () => {
    try {
      const responseList = await getInformationByClientId();
      const dataResponse = get(responseList, 'payload', []);
      const headerResponse = get(responseList, 'headerResponse', {});
      let newArray = [];

      dataResponse.map((item) => {
        newArray.push({
          ...item,
          audio_text: [
            {
              language: {
                language_id: 1,
                name: 'Español',
                currency: 'MXN'
              },
              text: item.text
            }
          ]
        });
      });

      const mappedInformation = responseMapper({
        template: informationListMapper,
        data: newArray
      });

      if (headerResponse.code === 200) {
        setDataTable(mappedInformation);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleOnSubmit = async () => {
    try {
      handleShowScreen(true);

      const responseInformationData = extractElementData(
        leftAux,
        'information_item_id'
      );

      const response = await getAgents({
        urlParams: idRobot
      });
      const dataResponse = get(response, 'payload', []);
      const responseAgentData = extractAgentData(dataResponse);

      const audioTextData = mergeAgentAndAudioText(
        responseInformationData,
        responseAgentData
      );

      createDataUpdate({
        items: audioTextData,
        idReference: 'information_item_id',
        runFetches: handleCreateSpeech,
        callback: handleUpdate
      });
    } catch (error) {
      handleShowScreen(false);
    }
  };

  const handleCreateSpeech = async (data) => {
    try {
      handleShowLoader(false);

      if (data.key.project_id === 'ehrlich-quimico-tpba') {
        const response = await createSpeech({
          body: data
        });
        const dataResponse = get(response, 'payload', '');
        const fileUrl = await getFileFromUrl(dataResponse, 'audio');
        let urlAudio = await uploadFilePromise(
          fileUrl,
          audios,
          errorUploadFile
        );

        return urlAudio;
      }
    } catch (error) {
      handleShowLoader(false);
      handleShowScreen(false);
    }
  };

  const errorUploadFile = () => {
    handleOpenModal({
      configProps: {
        maxWidth: 'sm'
      },
      body: <Alert message={intl.formatMessage(messages.uploadFileError)} />
    });
    handleShowLoader(false);
  };

  const handleUpdate = async (data) => {
    try {
      handleShowScreen(false);

      let newArrayAudio = data.map((item) => {
        const id = item.information_item_id;
        const url = item.audio_url.find((item) => item.audio_url !== undefined);

        return {
          information_item_id: id,
          audio_url: url.audio_url
        };
      });

      const response = await robotUpdate({
        body: {
          information_items: newArrayAudio
        }
      });
      const headerResponse = get(response, 'headerResponse', {});

      if (headerResponse.code === 200)
        modalSuccess(
          intl.formatMessage(messages.titleSuccessModal),
          intl.formatMessage(messages.textInfoSucces),
          () => {
            history.push('/robots');
          }
        );
    } catch (error) {
      handleShowScreen(false);
    }
  };

  return { dataTable, handleInformation, handleRobotId, handleOnSubmit };
};
