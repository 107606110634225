import React from 'react';
import { useIntl } from 'react-intl';
import { Typography, Grid, Button } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import { Card } from 'components/Card';
import { useStyles } from './ClientsListStyles';
import { messages } from './ClientsListMessages';
import { useHistory } from 'react-router-dom';
import { useClientsList } from './useClientsList';
import { useModalConfirmation } from 'components/ModalConfirmation';

const ClientsList = () => {
  const { formatMessage: f } = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const { clients, handleEditClient, handleDeleteClient } = useClientsList();
  const { modalConfirmation } = useModalConfirmation();

  return (
    <div className={classes.container}>
      <Grid container spacing={2} className={classes.contentHeader}>
        <Grid item xs={12} lg={9}>
          <Typography className={classes.tittleView}>
            {f(messages.titleView)}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={3} className={classes.contentButton}>
          <Button
            className={classes.button}
            endIcon={<AddCircle />}
            onClick={() => history.push('/client/form/0')}
          >
            {f(messages.buttonAddClient)}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row">
        {clients.map((client) => (
          <Grid item md={3}>
            <Card
              id={client.client_id}
              isClient
              image={client.image}
              name={client.name}
              numRobots={0}
              dueDate={0}
              permissions={0}
              space={0}
              status="Activo"
              onEdit={handleEditClient}
              onDelete={(e) => {
                modalConfirmation(
                  f(messages.titleModal),
                  f(messages.infoTextModal),
                  () => {
                    handleDeleteClient(e);
                  }
                );
              }}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export { ClientsList };
