/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react/jsx-props-no-spreading */
import PropTypes from 'prop-types';

import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Checkbox,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell
} from '@material-ui/core';
import {
  SentimentDissatisfied,
  SentimentSatisfied,
  SentimentSatisfiedAlt,
  SentimentVeryDissatisfied,
  SentimentVerySatisfied
} from '@material-ui/icons';

import { Rating } from '@material-ui/lab';

import { useIntl } from 'react-intl';

import { useStyles } from './PreviewStyles';
import { messages } from './PreviewMessages';

export const renderOptions = (item, index) => {
  const question = item.data;
  const options = question.options.filter((opc) => opc.trim() != '');

  return (
    <Grid container spacing={2} key={index} style={{ marginBottom: '32px' }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {`${index + 1}.- ${question.question}`}
      </Grid>
      <RadioGroup>
        {options.map((option, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            key={index}
            style={{ marginLeft: '32px' }}
          >
            <FormControlLabel
              value={option}
              control={<Radio />}
              label={option}
            />
          </Grid>
        ))}
      </RadioGroup>
    </Grid>
  );
};

const IconContainer = ({ value }) => {
  const intl = useIntl();
  const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfied />,
      label: intl.formatMessage(messages.veryDissatisfied)
    },
    2: {
      icon: <SentimentDissatisfied />,
      label: intl.formatMessage(messages.dissatisfied)
    },
    3: {
      icon: <SentimentSatisfied />,
      label: intl.formatMessage(messages.neutral)
    },
    4: {
      icon: <SentimentSatisfiedAlt />,
      label: intl.formatMessage(messages.satisfied)
    },
    5: {
      icon: <SentimentVerySatisfied />,
      label: intl.formatMessage(messages.verySatisfied)
    }
  };

  return customIcons[value].icon;
};

IconContainer.propTypes = {
  value: PropTypes.number.isRequired
};

const IconContainerNumeric = ({ value }) => {
  const classes = useStyles();
  const intl = useIntl();

  const customIconsNumericForm = {
    1: {
      icon: (
        <FormControlLabel
          value={'1'}
          control={<Radio />}
          label={'1'}
          labelPlacement="top"
          classes={{ root: classes.MuiFormControlLabelRoot }}
        />
      ),
      label: intl.formatMessage(messages.veryDissatisfied)
    },
    2: {
      icon: (
        <FormControlLabel
          value={'2'}
          control={<Radio />}
          label={'2'}
          labelPlacement="top"
          classes={{ root: classes.MuiFormControlLabelRoot }}
        />
      ),
      label: intl.formatMessage(messages.dissatisfied)
    },
    3: {
      icon: (
        <FormControlLabel
          value={'3'}
          control={<Radio />}
          label={'3'}
          labelPlacement="top"
          classes={{ root: classes.MuiFormControlLabelRoot }}
        />
      ),
      label: intl.formatMessage(messages.neutral)
    },
    4: {
      icon: (
        <FormControlLabel
          value={'4'}
          control={<Radio />}
          label={'4'}
          labelPlacement="top"
          classes={{ root: classes.MuiFormControlLabelRoot }}
        />
      ),
      label: intl.formatMessage(messages.satisfied)
    },
    5: {
      icon: (
        <FormControlLabel
          value={'5'}
          control={<Radio />}
          label={'5'}
          labelPlacement="top"
          classes={{ root: classes.MuiFormControlLabelRoot }}
        />
      ),
      label: intl.formatMessage(messages.verySatisfied)
    }
  };

  return customIconsNumericForm[value].icon;
};

const renderType = (value, question, setValue) => {
  const renderT = {
    stars: (
      <Rating
        name="simple-controlled"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      />
    ),
    emotes: (
      <Rating
        value={value}
        IconContainerComponent={IconContainer}
        highlightSelectedOnly
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      />
    ),
    numeric: (
      <RadioGroup row name="numeric">
        <Rating
          value={value}
          IconContainerComponent={IconContainerNumeric}
          highlightSelectedOnly
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
        />
      </RadioGroup>
    )
  };

  return renderT[question.type];
};

export const renderScale = (item, index, value, setValue) => {
  const question = item.data;

  return (
    <Grid container spacing={2} key={index} style={{ marginBottom: '32px' }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {`${index + 1}.- ${question.question}`}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginLeft: '32px' }}>
        {renderType(value, question, setValue)}
      </Grid>
    </Grid>
  );
};

export const renderYesNo = (item, index) => {
  const question = item.data;

  return (
    <Grid container spacing={2} key={index} style={{ marginBottom: '32px' }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {`${index + 1}.- ${question.question}`}
      </Grid>
      <RadioGroup>
        {['Sí', 'No'].map((option, index) => (
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            key={index}
            style={{ marginLeft: '32px' }}
          >
            <FormControlLabel
              value={option}
              control={<Radio />}
              label={option}
            />
          </Grid>
        ))}
      </RadioGroup>
    </Grid>
  );
};

export const renderSection = (item, index) => {
  const section = item.data;

  return (
    <Grid container spacing={2} key={index} style={{ marginBottom: '32px' }}>
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        style={{ fontWeight: 'bold', fontSize: '20px' }}
      >
        {section.title}
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {section.description}
      </Grid>
    </Grid>
  );
};

export const renderMultipleOptions = (item, index) => {
  const question = item.data;
  const options = question.options.filter((opc) => opc.trim() != '');

  return (
    <Grid container spacing={2} key={index} style={{ marginBottom: '32px' }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {`${index + 1}.- ${question.question}`}
      </Grid>
      {options.map((option, index) => (
        <Grid item xs={12} key={index} style={{ marginLeft: '32px' }}>
          <FormControlLabel
            value={option}
            control={<Checkbox />}
            label={option}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export const renderSquare = (item, index) => {
  const question = item.data;
  const intl = useIntl();
  const options = question.options.filter((opc) => opc.trim() != '');

  return (
    <Grid container spacing={2} key={index} style={{ marginBottom: '32px' }}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        {`${index + 1}.- ${question.question}`}
      </Grid>
      <TableContainer component={Paper}>
        <Table>
          <TableHead style={{ backgroundColor: '#007FFF' }}>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="center" style={{ color: 'white' }}>
                {intl.formatMessage(messages.yes)}
              </TableCell>
              <TableCell align="center" style={{ color: 'white' }}>
                {intl.formatMessage(messages.no)}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {options.map((option, index) => (
              <TableRow key={index}>
                <TableCell align="right">{option}</TableCell>
                <TableCell align="center">
                  <Radio />
                </TableCell>
                <TableCell align="center">
                  <Radio />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};
