import React, { useState } from 'react';
import {
  PieChart,
  BarChart,
  DonutChart,
  StackedBarChart
} from '@octopy/react-charts';
import { Card } from '@material-ui/core';
import { pieColors } from '../useGraphicSurvey';
import { useStyles } from './ChartSurveyStyles';
import { Images } from 'assets';

const ChartSurvey = ({ title, subtitle, data, type, id }) => {
  const classes = useStyles();

  const { excelIcon } = Images;
  const drawChart = (titleChart, subtitleChart, dataChart, typeChart, id) => {
    let type = typeChart;

    if (typeChart == 'HorizontalBarChart' || typeChart == 'StakedBarChart') {
      type = 'BarChart';
    }

    const options = {
      chart: {
        id: `chart-${id}`,
        toolbar: {
          show: true,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false
          }
        }
      },
      title: {
        align: 'center',
        text: titleChart
      },
      subtitle: {
        text: subtitleChart,
        align: 'center'
      }
    };

    switch (type) {
      case 'BarChart':
        return (
          <BarChart
            showTotal
            maxValuesInSight={dataChart.series.length}
            values={dataChart.series}
            options={options}
          />
        );
        break;
      case 'PieChart':
        return (
          <PieChart type="pie" options={options} values={dataChart.series} />
        );
        break;
      case 'DonutChart':
        return <DonutChart options={options} values={dataChart.series} />;
        break;
      // case 'HorizontalBarChart':
      //   return (
      //     <BarChart values={dataChart.series} horizontal options={options} />
      //   );
      //   break;
      // case 'StakedBarChart':
      //   return (
      //     <StackedBarChart
      //       values={[]}
      //       valuesLabels={dataChart.categories}
      //       options={options}
      //     />
      //   );
      //   break;
    }
  };

  return (
    <Card className={classes.rootCard}>
      <div className={classes.contentPieChart}>
        {drawChart(title, subtitle, data, type, id)}
      </div>
    </Card>
  );
};

ChartSurvey.propTypes = {};

export { ChartSurvey };
