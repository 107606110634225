import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleRobot: {
    id: 'components.Robot.titleRobot',
    defaultMessage: 'ROBOTS'
  },
  textAdd: {
    id: 'components.Robot.textAdd',
    defaultMessage: 'AGREGAR'
  },
  addRobot: {
    id: 'components.Robot.addRobot',
    defaultMessage: 'AGREGAR ROBOT'
  },
  editRobot: {
    id: 'components.Robot.editRobot',
    defaultMessage: 'EDITAR ROBOT'
  },
  robotAlerts: {
    id: 'components.Robot.robotAlerts',
    defaultMessage: 'Alertas'
  },
  robotCatalog: {
    id: 'components.Robot.robotCatalog',
    defaultMessage: 'Catálogo'
  },
  robotInteractions: {
    id: 'components.Robot.robotInteractions',
    defaultMessage: 'Interacciones'
  },
  robotGestures: {
    id: 'components.Robot.robotGestures',
    defaultMessage: 'Gestos e intenciones'
  },
  robotTimes: {
    id: 'components.Robot.robotTimes',
    defaultMessage: 'Tiempos de interaccion'
  },
  robotRelatePlataforms: {
    id: 'components.Robot.robotRelatePlataforms',
    defaultMessage: 'Relacionar plataformas'
  },
  robotBranches: {
    id: 'components.Robot.robotBranches',
    defaultMessage: 'Sucursales'
  },
  robotBranding: {
    id: 'components.Robot.robotBranding',
    defaultMessage: 'Branding'
  },
  robotAudios: {
    id: 'components.Robot.robotAudios',
    defaultMessage: 'Audios para flujos de pantalla'
  },
  robotEvent: {
    id: 'components.Robot.robotEvent',
    defaultMessage: 'Eventos'
  },
  robotDitectory: {
    id: 'components.Robot.robotDitectory',
    defaultMessage: 'Directorio'
  },
  personalInformationTitle: {
    id: 'components.Robot.personalInformationTitle',
    defaultMessage: 'Información para el personal'
  },
  titleModal: {
    id: 'components.Robot.titleModal',
    defaultMessage: 'DESVINCULAR ROBOT'
  },
  descriptionModal: {
    id: 'components.Robot.descriptionModal',
    defaultMessage:
      '¿Seguro que deseas desvincular este robot? Al desvincular se apagará el robot.'
  },
  buttonModal: {
    id: 'components.Robot.buttonModal',
    defaultMessage: 'CONTINUAR'
  }
});
