import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, IconButton, Grid, TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';

const Square = ({
  onChangeData,
  data,
  setQuestions,
  isEnglish,
  addOption,
  messages
}) => {
  const dat = isEnglish ? 'dataEnglish' : 'data';

  const { formatMessage } = useIntl();

  const deleteOption = (index) => {
    let aux = data[dat].options.slice();

    aux.splice(index, 1);
    let newOptions = [];

    aux.map((item) => {
      newOptions.push(item);
    });

    setQuestions(newOptions, data.id, dat);
  };

  const optionsMapper = (option, index) => (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      <div style={{ display: 'flex' }} key={index}>
        <TextField
          value={option}
          label={
            isEnglish
              ? `${formatMessage(messages.optionEnglish)} ${index + 1}`
              : `${formatMessage(messages.option)} ${index + 1}`
          }
          fullWidth
          name={
            isEnglish
              ? `${formatMessage(messages.optionEnglish)}-${index + 1}`
              : `${formatMessage(messages.option)}-${index + 1}`
          }
          onChange={onChangeData}
          variant="outlined"
        />
        <IconButton
          onClick={() => {
            deleteOption(index);
          }}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    </Grid>
  );

  const isEnglishFunction = () => {
    let isLength = false;
    let options = null;

    if (isEnglish) {
      if (data.dataEnglish.options.length) {
        isLength = true;
        options = data.dataEnglish.options;
      }
    } else {
      if (data.data.options.length) {
        isLength = true;
        options = data.data.options;
      }
    }

    return { isLength, options };
  };

  const isLengthFuntion = () => {
    const { isLength, options } = isEnglishFunction();

    let htmlGrid = {};

    if (isLength) {
      htmlGrid = options.map(optionsMapper);
    } else {
      htmlGrid = (
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div style={{ display: 'flex' }} key={1}>
            {/* <Radio></Radio> */}
            <TextField
              value={''}
              label={
                isEnglish
                  ? `${formatMessage(messages.optionEnglish)} 1`
                  : `${formatMessage(messages.option)} 1`
              }
              fullWidth
              name={
                isEnglish
                  ? `${formatMessage(messages.optionEnglish)}-1`
                  : `${formatMessage(messages.option)}-1`
              }
              onChange={onChangeData}
              variant="outlined"
            />
            <IconButton
              onClick={() => {
                deleteOption(0);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </Grid>
      );
    }

    return htmlGrid;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          label={
            isEnglish
              ? formatMessage(messages.writeQuestionEnglish)
              : formatMessage(messages.writeQuestions)
          }
          value={isEnglish ? data.dataEnglish.question : data.data.question}
          fullWidth
          name="question"
          onChange={onChangeData}
          variant="outlined"
        />
      </Grid>
      {isLengthFuntion()}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Button
          color="primary"
          name="button"
          onClick={() => {
            let newOptions = [''];

            if (isEnglish) {
              if (data.dataEnglish.options.length) {
                newOptions = data.dataEnglish.options.slice();
              }
            } else {
              if (data.data.options.length) {
                newOptions = data.data.options.slice();
              }
            }

            newOptions.push('');
            setQuestions(newOptions, data.id, dat);
          }}
        >
          {isEnglish
            ? formatMessage(messages.addOptionEnglish)
            : formatMessage(messages.addOption)}
        </Button>
      </Grid>
    </Grid>
  );
};

Square.propTypes = {};

export default Square;
