import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
      marginTop: theme.spacing(2)
    }
  },
  titleSceneDesign: {
    paddingLeft: theme.typography.pxToRem(20),
    fontSize: theme.typography.pxToRem(26),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 6,
    textTransform: 'uppercase',
    color: theme.palette.primary.main
  },
  textResolution: {
    paddingLeft: theme.typography.pxToRem(20),
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold
  },
  numberResolution: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightRegular
  },
  sceneName: {
    fontSize: theme.typography.pxToRem(26),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 6,
    textTransform: 'uppercase'
  },
  tittleAddMedia: {
    paddingBottom: theme.typography.pxToRem(15),
    fontSize: theme.typography.pxToRem(26),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 6,
    textTransform: 'uppercase'
  },
  buttonsContainer: {
    backgroundColor:
      theme.palette.mode === 'light' ? '#E7E6EB' : theme.palette.primary.main,
    padding: 5,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0
  },
  button: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    margin: 5
  },
  buttonMedia: {
    '&.MuiButton-contained.Mui-disabled': {
      backgroundColor: '#f5f5f5CC'
    }
  },
  buttonSave: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '15px',
    letterSpacing: '1px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  }
}));

export { useStyles };
