export const mapBranches = (values) => {
  const mappedBranches = values.map((branch) => ({
    id: branch.branchoffice_id,
    Nombre: branch.name,
    Teléfono: branch.phone,
    Dirección: branch.direction,
    Apertura: branch.open_at,
    Cierre: branch.until_at,
    Google_Maps: branch.url_maps
  }));

  return mappedBranches;
};
