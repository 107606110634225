import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  upMediaFile: {
    id: 'components.ButtonSimpleMultimedia.upMediaFile',
    defaultMessage: 'Subir archivo'
  },
  fileLarge: {
    id: 'components.ButtonSimpleMultimedia.fileLarge',
    defaultMessage: 'El archivo es demasiado grande'
  },
  fileType: {
    id: 'components.ButtonSimpleMultimedia.fileType',
    defaultMessage: 'Solo puedes subir audio, vídeo o imagen'
  }
});
