import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  Typography,
  Grid,
  Card,
  // FormControlLabel,
  // Checkbox,
  Button
} from '@material-ui/core';
import { TableTransferList } from 'components/TableTransferList';
import { useDirectory } from './useDirectory';
import { useHistory } from 'react-router-dom';
import { messages } from './DirectoryMessages';
import { useStyles } from './DirectoryStyles';
import { useHelper } from './hooks/useHelper';
import { HeaderTitle } from 'components/HeaderTitle';

const Directory = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { tableHead, filters } = useDirectory();
  const history = useHistory();
  const [leftAux, setLeftAux] = useState(null);

  const { location } = history;

  const {
    directory,
    handleDirectoryList,
    handleRobotId,
    handleAddDirectoryRobot
  } = useHelper(leftAux, setLeftAux, location);

  useEffect(() => {
    handleDirectoryList();
    handleRobotId();
  }, []);

  const savePage = () => {
    handleAddDirectoryRobot(leftAux);
  };

  const changeData = (arrayAux) => {
    setLeftAux(arrayAux);
  };

  return (
    <div className={classes.container}>
      <HeaderTitle
        firstTitle={history.location.state.name}
        secondTitle={intl.formatMessage(messages.directoryTitle)}
        redirection="robots"
      />
      <Card className={classes.cardStyle}>
        <Grid container className={classes.gridStyle}>
          <Grid>
            <Typography className={classes.addItem}>
              {intl.formatMessage(messages.addItemsText)}
            </Typography>
          </Grid>
          {/* <Grid>
            <FormControlLabel
              control={
                <Checkbox onchage={() => {}} name="checkedB" color="primary" />
              }
              label={
                <Typography>
                  {intl.formatMessage(messages.apliAllRobots)}
                </Typography>
              }
            />
          </Grid> */}
          <Grid className={classes.gridButton}>
            <Grid className={classes.gridButtonCancel}>
              <Button
                className={classes.cancelButton}
                onClick={() => history.push('/robots')}
              >
                {intl.formatMessage(messages.cancel)}
              </Button>
            </Grid>
            <Grid>
              <Button className={classes.saveButton} onClick={() => savePage()}>
                {intl.formatMessage(messages.save)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <TableTransferList
            data={directory}
            tableHead={tableHead}
            filters={filters}
            labelTotal={intl.formatMessage(messages.totalItems)}
            leftAux={leftAux}
            changeData={changeData}
            edit={false}
            id="id"
          />
        </Grid>
      </Card>
    </div>
  );
};

Directory.propTypes = {
  exampleProp: PropTypes.string
};

export { Directory };
