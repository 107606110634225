import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { useLoader } from '@octopy/react-loader';
import { messages as formMessages } from '@octopy/react-form';
import { Alert, useModal } from '@octopy/react-modal';
import { Publish } from '@material-ui/icons';
import { uploadFilePromise } from 'utils/aws';
import { sources } from 'providers/AWS';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApi } from 'hooks';
import { messages } from './FormRegisterMessages';

export const useFormRegister = ({ onClick, dataEdit = {} }) => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const isSuperAdmin = get(storage, 'user.is_staff', {});
  const clientId = get(storage, 'user.client.client_id', {});
  const bucketName = get(storage, 'user.client.bucket_name', {});

  const { handleShowLoader } = useLoader();
  const { handleOpenModal } = useModal();
  const { images } = sources(bucketName);
  const { formatMessage: f } = useIntl();
  const invalidEmailError = f(formMessages.invalidEmailError);
  const requiredFieldError = f(formMessages.requiredFieldError);
  const passwordNoMatchesError = f(formMessages.passwordNoMatchesError);
  const passwordRegexError = f(messages.passwordRegexError);
  const onlyNumbers = f(messages.onlyNumbers);
  const positiveNumbers = f(messages.positiveNumbers);
  const decimalNumbers = f(messages.decimalNumbers);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#,.:;/])[A-Za-z\d@$!%*?&#,.:;/]{8,}$/;
  const [profilesClient, setProfilesClient] = useState([]);
  const [clientListOptions, setClientListOptions] = useState([]);

  const [getCustomerProfileList] = useApi({
    endpoint: `/management/profiles/profiles_by_client`,
    method: 'get'
  });
  const [clientList] = useApi({
    endpoint: '/management/clients',
    method: 'get'
  });

  const getClientList = async () => {
    try {
      const response = await clientList();

      const dataResponse = get(response, 'payload', []);

      setClientListOptions(dataResponse);
    } catch (error) {}
  };

  useEffect(() => {
    getClientList();
  }, []);

  const handleCustomerProfileList = async () => {
    try {
      const response = await getCustomerProfileList({
        urlParams: formik.values.client
      });
      const dataResponse = get(response, 'payload', []);

      setProfilesClient(dataResponse);
    } catch (error) {}
  };

  const getValidationRules = () =>
    Yup.object().shape({
      name: Yup.string().required(requiredFieldError),
      last_name: Yup.string().required(requiredFieldError),
      tlf: Yup.number()
        .typeError(onlyNumbers)
        .positive(positiveNumbers)
        .integer(decimalNumbers)
        .required(requiredFieldError),
      email: Yup.string().email(invalidEmailError).required(requiredFieldError),
      profile: Yup.string().required(requiredFieldError),
      client: Yup.string().required(requiredFieldError),
      password: dataEdit
        ? Yup.string()
        : Yup.string()
            .required(requiredFieldError)
            .matches(passwordRegex, passwordRegexError),
      confirmPassword: dataEdit
        ? Yup.string()
        : Yup.string()
            .required(requiredFieldError)
            .oneOf([Yup.ref('password'), null], passwordNoMatchesError)
    });

  const getValidationRulesEdit = () =>
    Yup.object().shape({
      name: Yup.string().required(requiredFieldError),
      last_name: Yup.string().required(requiredFieldError),
      tlf: Yup.number()
        .typeError(onlyNumbers)
        .positive(positiveNumbers)
        .integer(decimalNumbers)
        .required(requiredFieldError),
      email: Yup.string().email(invalidEmailError).required(requiredFieldError),
      profile: Yup.string().required(requiredFieldError),
      client: Yup.string().required(requiredFieldError)
    });

  const initialValues = {
    name: '',
    last_name: '',
    tlf: '',
    email: '',
    profile: '',
    client: isSuperAdmin ? '' : clientId.toString(),
    password: '',
    confirmPassword: '',
    image: dataEdit && dataEdit.image ? { url: dataEdit.image } : null,
    user_id: dataEdit ? dataEdit.user_id : 0
  };

  const initialValuesEdit = {
    name: dataEdit ? dataEdit.name : '',
    last_name: dataEdit ? dataEdit.last_name : '',
    tlf: dataEdit ? dataEdit.tlf : '',
    email: dataEdit ? dataEdit.email : '',
    profile: dataEdit ? dataEdit.profile?.profile_id.toString() : '',
    client: dataEdit ? dataEdit.client.client_id.toString() : '',
    image: dataEdit && dataEdit.image ? { url: dataEdit.image } : null,
    user_id: dataEdit ? dataEdit.user_id : 0
  };

  const inputProps = {
    fullWidth: true
  };

  const fieldsFormDataUser = [
    Object.assign(
      {
        type: 'text',
        name: 'name',
        label: f(messages.nameLabel),
        placeholder: f(messages.nameLabel),
        breakpoints: { xs: 12 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'last_name',
        label: f(messages.lastNameLabel),
        placeholder: f(messages.lastNameLabel),
        breakpoints: { xs: 12 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'tlf',
        label: f(messages.phoneLabel),
        placeholder: '(55)00000000',
        breakpoints: { xs: 12 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'email',
        label: f(messages.emailLabel),
        placeholder: f(messages.emailPlaceholder),
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  const fieldsFormPassword = [
    Object.assign(
      {
        type: 'password',
        name: 'password',
        label: f(messages.passwordLabel),
        breakpoints: { xs: 12 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'password',
        name: 'confirmPassword',
        label: f(messages.confirmPasswordLabel),
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  const fieldsFormImage = [
    Object.assign({
      type: 'image',
      name: 'image',
      breakpoints: { xs: 12 },
      icon: Publish,
      label: f(messages.uploadImageLabel),
      multiple: false,
      color: 'primary.main'
    })
  ];

  const fieldsFormProfile = [
    Object.assign(
      {
        section: 'profilePermissions',
        type: 'select',
        name: 'profile',
        label: f(messages.profileLabel),
        placeholder: f(messages.profilePlaceholder),
        options: profilesClient,
        getOptionLabel: (option) => `${option.name || ''}`,
        getOptionValue: (option) => `${option.profile_id || ''}`,
        getOptionSelected: (option, value) => option === value,
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  const fieldClientList = [
    Object.assign(
      {
        section: 'clientList',
        type: 'select',
        name: 'client',
        label: f(messages.clientList),
        placeholder: f(messages.clientListPlaceholder),
        options: clientListOptions,
        getOptionLabel: (option) => `${option.name || ''}`,
        getOptionValue: (option) => `${option.client_id || ''}`,
        getOptionSelected: (option, value) => option === value,
        disabled: !isSuperAdmin,
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  const erroUploadFile = () => {
    handleOpenModal({
      configProps: {
        maxWidth: 'sm'
      },
      body: <Alert message={f(messages.uploadFileError)} />
    });
    handleShowLoader(false);
  };

  const formik = useFormik({
    initialValues: dataEdit ? initialValuesEdit : initialValues,
    validationSchema: dataEdit
      ? getValidationRulesEdit()
      : getValidationRules(),
    onSubmit: async (values) => {
      handleShowLoader(true);

      let body = {
        ...values,
        image: dataEdit ? dataEdit.image : values.image,
        client: parseInt(values.client),
        profile: parseInt(values.profile)
      };

      if (values.image && values.image.file) {
        const urlS3 = await uploadFilePromise(
          values.image.file,
          images,
          erroUploadFile
        );

        body.image = urlS3;

        if (body.user_id !== 0) {
          handleUserEdit(body);
        } else {
          handleCreateUser(body);
        }
      } else {
        if (body.user_id !== 0) {
          handleUserEdit(body);
        } else {
          handleCreateUser(body);
        }
      }
    }
  });

  const [createUser] = useApi({
    endpoint: 'management/users/',
    method: 'post'
  });

  const [editUser] = useApi({
    endpoint: 'management/users',
    method: 'put'
  });

  const handleCreateUser = async (data) => {
    try {
      const response = await createUser({
        body: data
      });

      if (response.headerResponse.code === 200) {
        onClick();
      }
    } catch (error) {}
  };

  const handleUserEdit = async (data) => {
    try {
      const response = await editUser({
        urlParams: `${dataEdit.user_id}/`,
        body: data
      });

      if (response.headerResponse.code === 200) {
        onClick();
      }
    } catch (error) {}
  };

  useEffect(async () => {
    if (formik.values.client !== '') {
      await handleCustomerProfileList();
    }
  }, [formik.values.client]);

  return {
    formik,
    fieldsFormDataUser,
    fieldsFormPassword,
    fieldsFormImage,
    fieldsFormProfile,
    fieldClientList
  };
};
