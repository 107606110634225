import { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useModal } from '@octopy/react-modal';
import { useApi } from 'hooks';
import { useHistory } from 'react-router-dom';
import { responseMapper } from 'utils/responseMapper';

const BranchListMapper = {
  branchoffice_id: 'id',
  name: 'name',
  audio_text: 'audio_text',
  phone: 'phone',
  direction: 'direction',
  url_maps: 'url_maps',
  open_at: 'open_at',
  until_at: 'until_at'
};

export const useApiBranches = () => {
  const history = useHistory();
  const { handleCloseModal } = useModal();
  const [branchList, setBranchList] = useState([]);
  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = storage.user.client.client_id;
  const idBranch = history.location.state ? history.location.state.idBranch : 0;

  const [branchOffices] = useApi({
    endpoint: `/branch_offices/branch_offices/branchoffice_by_client_id/${clientId}`,
    method: 'get'
  });

  const [deleteBranch] = useApi({
    endpoint: 'branch_offices/branch_offices',
    method: 'delete'
  });

  const [createBranche] = useApi({
    endpoint: 'branch_offices/branch_offices/',
    method: 'post'
  });

  const [branchEdit] = useApi({
    endpoint: '/branch_offices/branch_offices',
    method: 'put'
  });

  useEffect(() => {
    getBranchOffices();
  }, []);

  const getBranchOffices = async () => {
    try {
      const response = await branchOffices();

      if (response.headerResponse.code === 200) {
        const dataResponse = get(response, 'payload', []);

        const mappedBranch = responseMapper({
          template: BranchListMapper,
          data: dataResponse
        });

        setBranchList(mappedBranch);
      }
    } catch (error) {}
  };

  const handleDeleteBranch = async (itemId) => {
    const response = await deleteBranch({
      urlParams: `${itemId}`
    });

    if (response.headerResponse.code === 200) {
      handleCloseModal();
      getBranchOffices();
    }
  };

  const handleCreateBranch = async (body) => {
    try {
      const response = await createBranche({
        body: body
      });

      const headerResponse = get(response, 'headerResponse', {});

      if (headerResponse.code === 200) {
        history.push('/branch');
      }
    } catch (error) {}
  };

  const handleEditBranch = async (data) => {
    try {
      const response = await branchEdit({
        urlParams: `${idBranch}/`,
        body: data
      });
      const headerResponse = get(response, 'headerResponse', {});

      if (headerResponse.code === 200) {
        history.push('/branch');
      }
    } catch (error) {}
  };

  return {
    branchList,
    handleCreateBranch,
    handleEditBranch,
    handleDeleteBranch
  };
};
