import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useIntl } from 'react-intl';
import { useDrawer } from 'components/Drawer';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { Grid, Typography, Button } from '@material-ui/core';
import { ExitToApp, AddCircle, Delete } from '@material-ui/icons';
import { CategoriesForm } from './CategoriesForm';
import { Table } from '@octopy/react-table';
import { useHistory } from 'react-router-dom';
import { tableHead, filters } from './utils';
import { useApi } from 'hooks';
import { useStyles } from './CategoriesStyles';
import { messages } from './CategoriesMessages';
import { useExport } from 'hooks/useExport';
import { mapCategories } from './helpers';

const Categories = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { exportToExcel } = useExport();
  const { handleOpenDrawer } = useDrawer();
  const { modalConfirmationDelete } = useModalConfirmation();
  const [categoriesList, setCategoriesList] = useState([]);
  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = storage.user.client.client_id;

  const [getCategoriesList] = useApi({
    endpoint: '/catalogue/category/categories_by_client',
    method: 'get'
  });

  const [categoryDelete] = useApi({
    endpoint: 'catalogue/category',
    method: 'delete'
  });

  const handleCategoriesList = async () => {
    try {
      const response = await getCategoriesList({
        urlParams: clientId
      });
      const dataResponse = get(response, 'payload', []);

      if (response.headerResponse.code === 200) {
        const formatData = dataResponse.map((item) => {
          const categoryId = item.category_id;
          const nameCategorySpanish = item.name[0].text;
          const totalSubcategories = item.sub_categories.length;

          return {
            category_id: categoryId,
            name: nameCategorySpanish,
            sub_categories: totalSubcategories
          };
        });

        setCategoriesList(formatData);
      }
    } catch (error) {}
  };

  const handleCategoryDelete = async (categoryId) => {
    try {
      const response = await categoryDelete({
        urlParams: categoryId
      });

      if (response.headerResponse.code === 200) {
        handleCategoriesList();
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleCategoriesList();
  }, []);

  // const onSelectItems = (items) => {};

  const handleDrawer = (categoryId) => {
    handleOpenDrawer({
      closeButton: true,
      title: (
        <>
          {categoryId ? (
            <Typography className={classes.titleDrawer}>
              {intl.formatMessage(messages.editCategorie)}
            </Typography>
          ) : (
            <Typography className={classes.titleDrawer}>
              {intl.formatMessage(messages.addCategorie)}
            </Typography>
          )}
        </>
      ),
      body: <CategoriesForm categoryId={categoryId} />,
      configProps: {
        anchor: 'right'
      }
    });
  };

  const handleModalDelete = (data) => {
    modalConfirmationDelete(
      intl.formatMessage(messages.titleModal),
      intl.formatMessage(messages.descriptionModal),
      data.name,
      () => {
        handleCategoryDelete(data.category_id);
      }
    );
  };

  const configProps = {
    filters,
    actions: {
      edit: {
        onClick: (item) => handleDrawer(item.category_id),
        disabled: false,
        hidden: false
      },
      customs: [
        {
          text: 'Eliminar',
          onClick: (item) => handleModalDelete(item),
          icon: <Delete />
        },
        {
          text: 'Subcategoria',
          onClick: (item) => history.push('/categories/subcategories', item),
          icon: <AddCircle />
        }
      ]
    },
    selectedOptions: {
      checkboxHidden: true
    }
  };

  return (
    <div className={classes.container}>
      <Grid container className={classes.containerActions}>
        <Grid item xs={12} lg={6} className={classes.contentTitles}>
          <Typography className={classes.catalogTitle}>
            {intl.formatMessage(messages.catalogTitle)}
            &nbsp;-&nbsp;
          </Typography>
          <Typography className={classes.categoriesTitle}>
            {intl.formatMessage(messages.categoriesTitle)}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} className={classes.contentButons}>
            <Grid item>
              <Button
                className={classes.button}
                endIcon={<ExitToApp />}
                onClick={() =>
                  exportToExcel(
                    mapCategories(categoriesList),
                    `${intl.formatMessage(messages.categoriesTitle)}`
                  )
                }
              >
                {intl.formatMessage(messages.exportButton)}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                endIcon={<AddCircle />}
                onClick={() => handleDrawer()}
              >
                {intl.formatMessage(messages.addCategories)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Table
        // mainKey="category_id"
        columns={tableHead}
        data={categoriesList}
        configProps={configProps}
        // onSelectItems={onSelectItems}
      />
    </div>
  );
};

Categories.propTypes = {};

export { Categories };
