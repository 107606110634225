import { useEffect } from 'react';
import { useLoader } from '@octopy/react-loader';
import { useApi } from 'hooks';
import { mapBoxes } from '../AddTakeTurnDrawer/hooks/helper';
import { messages } from './TakeTurnMessages';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { useIntl } from 'react-intl';
import { getItem, setItem } from 'utils/persistentStorage';

export const useTakeTurn = ({
  formik,
  idHasBox,
  setBoxes,
  setHasBox,
  setIdHasBox,
  setNumberBox,
  setNumberTurn,
  numberTurn
}) => {
  const { handleShowLoader } = useLoader();
  const { modalReportProblem } = useModalConfirmation();
  const { formatMessage: f } = useIntl();

  const storage = JSON.parse(localStorage.getItem('session'));

  const [hasAttentionBoxUser] = useApi({
    endpoint: 'turns/attention_box/user_attention_box_assigned',
    method: 'get'
  });
  const [turnsAtentionList] = useApi({
    endpoint: 'turns/attention_box/attention_box_available',
    method: 'get'
  });
  const [updateAttentionBox] = useApi({
    endpoint: 'turns/attention_box',
    method: 'put'
  });
  const [updateTakeTurn] = useApi({
    endpoint: 'turns/turn/take_turn/',
    method: 'put'
  });
  const [finalizeTurn] = useApi({
    endpoint: 'turns/turn/finalize_turn/',
    method: 'put'
  });

  const storageTurns = (numberTurn, update = false) => {
    const session = getItem('session');

    let newSession = {
      ...session,
      turns: {
        numberTurn: numberTurn
      }
    };

    let otherNewSession = {};

    if (update) {
      otherNewSession = {
        ...session,
        ...newSession
      };
    } else {
      const { refreshToken, status, token, user } = session;

      otherNewSession = {
        refreshToken,
        status,
        token,
        user
      };
    }

    setItem('session', JSON.stringify(otherNewSession));
  };

  const handleAttentionBoxUser = async () => {
    try {
      const { headerResponse, payload } = await hasAttentionBoxUser({
        urlParams: `${storage.user.user_id}/`
      });

      if (headerResponse.code === 200 && payload.length > 0) {
        setHasBox(true);
        setIdHasBox(payload[0].attention_box_id);
        setNumberBox(payload[0].box_number);
        formik.setFieldValue('selectBranch', payload[0].branch_office.id); //Se asigna la sucursal
      } else {
        setHasBox(false);
        setIdHasBox(0);
        setNumberBox(0);
        formik.setFieldValue('selectBranch', ''); // se elimina la sucursal cargada
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleAttentionBoxUser();
  }, []);

  const handleUpdateTakeTurn = async () => {
    /*     setNumberTurn(11);
    storageTurns(11, true); */
    try {
      const response = await updateTakeTurn({
        body: { attended_by: storage.user.user_id }
      });

      if (response.headerResponse.code === 400) {
        const a = response.headerResponse.error.toString();
        const b = a.split(/(\d)/);
        const c = b[6].toString();

        const d = c.split('"');

        modalReportProblem(d[3]);
      }

      if (response.headerResponse.code === 200) {
        setNumberTurn(response.payload.turn_assigned);

        storageTurns(response.payload.turn_assigned, true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const session = getItem('session');

    if (session.turns) {
      setNumberTurn(session.turns.numberTurn);
    }
  }, []);

  const handleCloseTurn = async (attended) => {
    /* setNumberTurn(0);
    storageTurns(0, false); */
    try {
      const response = await finalizeTurn({
        body: { attended_by: storage.user.user_id, status: attended }
      });

      if (response.headerResponse.code === 200) {
        setNumberTurn(0);

        storageTurns(0, false);
      }
    } catch (error) {}
  };

  const handleGetListTurnsAtention = async () => {
    if (formik.values.selectBranch) {
      try {
        handleShowLoader(true);
        const { headerResponse, payload } = await turnsAtentionList({
          urlParams: formik.values.selectBranch
        });

        if (headerResponse.code === 200) {
          const mappedBoxes = mapBoxes(payload);

          setBoxes(mappedBoxes);
        }
      } catch (error) {
        handleShowLoader(false);
      }

      handleShowLoader(false);
    }
  };

  useEffect(() => {
    handleGetListTurnsAtention();
  }, [formik.values.selectBranch]);

  const handleUpdateUserBox = async (releaseBox, link = false) => {
    let boxId = idHasBox;

    let body = {
      assigned: link,
      user: storage.user.user_id
    };

    if (link) {
      boxId = releaseBox;

      body = {
        user: storage.user.user_id
      };
    }

    try {
      handleShowLoader(true);
      const { headerResponse, payload } = await updateAttentionBox({
        urlParams: `${boxId}/`,
        body
      });

      if (headerResponse.code === 200 && payload) {
        handleAttentionBoxUser();
      }
    } catch (error) {
      handleShowLoader(false);
    }

    handleShowLoader(false);
  };

  return {
    handleGetListTurnsAtention,
    handleUpdateUserBox,
    handleUpdateTakeTurn,
    handleCloseTurn
  };
};
