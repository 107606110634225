import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  metrics: {
    id: 'components.SideMenu.metrics',
    defaultMessage: 'Métricas'
  },
  robot: {
    id: 'components.SideMenu.robot',
    defaultMessage: 'Robot'
  },
  robotOne: {
    id: 'components.SideMenu.robotOne',
    defaultMessage: 'Robot 1'
  },
  robotAlerts: {
    id: 'components.SideMenu.robotAlerts',
    defaultMessage: 'Alertas'
  },
  robotGestures: {
    id: 'components.SideMenu.robotGestures',
    defaultMessage: 'Gestos'
  },
  robotInteractions: {
    id: 'components.SideMenu.robotinteractions',
    defaultMessage: 'Interacciones'
  },
  robotTimes: {
    id: 'components.SideMenu.robotTimes',
    defaultMessage: 'Tiempos de trayectoria'
  },
  robotBranding: {
    id: 'components.SideMenu.robotBranding',
    defaultMessage: 'Branding'
  },
  robotDasboard: {
    id: 'components.SideMenu.robotDasboard',
    defaultMessage: 'Dasboard'
  },
  robot_audios: {
    id: 'components.SideMenu.robot_audios',
    defaultMessage: 'Audios para flujos'
  },
  surveys: {
    id: 'components.SideMenu.surveys',
    defaultMessage: 'Encuestas'
  },
  advertising: {
    id: 'components.SideMenu.advertising',
    defaultMessage: 'Publicidad'
  },
  advertisingResolutions: {
    id: 'components.SideMenu.advertisingResolutions',
    defaultMessage: 'Resoluciones'
  },
  advertisingEscenes: {
    id: 'components.SideMenu.advertisingEscenes',
    defaultMessage: 'Escenas'
  },
  advertisingCampaigns: {
    id: 'components.SideMenu.advertisingCampaigns',
    defaultMessage: 'Campañas'
  },
  advertisingMediaLibrary: {
    id: 'components.SideMenu.advertisingMediaLibrary',
    defaultMessage: 'Biblioteca de medios'
  },
  advertisingEvents: {
    id: 'components.SideMenu.advertisingEvents',
    defaultMessage: 'Eventos'
  },
  advertisingScreens: {
    id: 'components.SideMenu.advertisingScreens',
    defaultMessage: 'Pantallas'
  },
  catalogue: {
    id: 'components.SideMenu.catalogue',
    defaultMessage: 'Catálogo'
  },
  products: {
    id: 'components.SideMenu.products',
    defaultMessage: 'Productos'
  },
  categories: {
    id: 'components.SideMenu.categories',
    defaultMessage: 'Categorías'
  },
  branch: {
    id: 'components.SideMenu.branch',
    defaultMessage: 'Sucursales'
  },
  directory: {
    id: 'components.SideMenu.directory',
    defaultMessage: 'Directorio'
  },
  turn: {
    id: 'components.SideMenu.turn',
    defaultMessage: 'Turnos'
  },
  turnHistory: {
    id: 'components.SideMenu.turnHistory',
    defaultMessage: 'Historial'
  },
  turnTake: {
    id: 'components.SideMenu.turnTake',
    defaultMessage: 'Tomar turno'
  },
  management: {
    id: 'components.SideMenu.management',
    defaultMessage: 'Gestión'
  },
  users: {
    id: 'components.SideMenu.users',
    defaultMessage: 'Usuarios'
  },
  permissions: {
    id: 'components.SideMenu.permissions',
    defaultMessage: 'Permisos'
  },
  clients: {
    id: 'components.SideMenu.clients',
    defaultMessage: 'Clientes'
  },
  dashboard: {
    id: 'components.SideMenu.dashboard',
    defaultMessage: 'Dashboard'
  },
  fileDistribution: {
    id: 'components.SideMenu.fileDistribution',
    defaultMessage: 'Distribucion de archivos'
  },
  codeExamples: {
    id: 'components.SideMenu.codeExamples',
    defaultMessage: 'Codigos de ejemplo'
  },
  user: {
    id: 'components.SideMenu.user',
    defaultMessage: 'Usuario'
  },
  profile: {
    id: 'components.SideMenu.profile',
    defaultMessage: 'Perfil'
  },
  logout: {
    id: 'components.SideMenu.logout',
    defaultMessage: 'Cerrar sesion'
  },
  changePassword: {
    id: 'components.SideMenu.changePassword',
    defaultMessage: 'Cambiar contraseña'
  },
  table: {
    id: 'components.SideMenu.table',
    defaultMessage: 'Tabla'
  },
  add: {
    id: 'components.SideMenu.add',
    defaultMessage: 'Agregar'
  },
  edit: {
    id: 'components.SideMenu.edit',
    defaultMessage: 'Editar'
  },
  information: {
    id: 'components.SideMenu.information',
    defaultMessage: 'Información'
  },
  informationAdd: {
    id: 'components.SideMenu.information',
    defaultMessage: 'Información'
  }
});
