// Extrae un Array de los datos del agente del robot
export function extractAgentData(payload) {
  if (payload.length <= 0)
    return {
      error: true,
      message: 'There are not data'
    };

  return payload.map((item) => ({
    key: item?.agent_key,
    language: item?.language?.language_id,
    speed: item?.speed,
    pitch: item?.pitch,
    tone: item?.voice_tone
  }));
}

export function extractElementData(payload, idReference) {
  return payload.map((item) => ({
    id: item[idReference],
    audio_text: item?.audio_text.map((textItem) => ({
      language_id: textItem?.language?.language_id,
      text: textItem?.text
    }))
  }));
}

export function mergeAgentAndAudioText(elements, agents) {
  return elements.map((item) => {
    const mergeData = [];

    item?.audio_text
      ?.map((text) => {
        const agentData = agents?.filter(
          (agent) => agent?.language === text?.language_id
        );
        const dataStructure = agentData?.map((data) => ({
          ...data,
          text: text?.text
        }));

        return dataStructure;
      })
      .forEach((item) => {
        item.forEach((subitem) => mergeData.push(subitem));
      });

    return { texts: mergeData, id: item.id };
  });
}

const createBodyUpdateData = async (data) => {
  const { items, idReference, runFetches } = data;
  const dataResult = [];

  for (let item of items) {
    const audio_url = [];

    for (let element of item?.texts) {
      let result = await runFetches(element);

      audio_url.push({ language_id: element.language, audio_url: result });
    }

    dataResult.push({
      [idReference]: item.id,
      audio_url
    });
  }

  return dataResult;
};

export const createDataUpdate = async (data) => {
  const { items, idReference, runFetches, callback } = data;
  const req = await createBodyUpdateData({ items, idReference, runFetches });

  await callback(req);
};

export const createDataUpdateCatalog = async (data) => {
  const objectNew = [];

  await data.items.map((itemData) => {
    itemData.texts.map((itemText) => {
      objectNew.push({
        language_id: itemText.language,
        text: itemText.text,
        object_id: itemData.id
      });
    });
  });

  setTimeout(() => {
    data.runFetches(objectNew);
  }, 1000);
};
