import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  openSideMenu: {
    defaultMessage: 'Abrir menu',
    id: 'components.Header.openSideMenu'
  },
  title: {
    id: 'components.Header.title',
    defaultMessage: 'Robotics'
  },
  subtitle: {
    id: 'components.Header.subtitle',
    defaultMessage: 'Plataforma de gestión'
  },
  exitApp: {
    id: 'components.Header.exitApp',
    defaultMessage: 'Cerrar sesión'
  },
  alertTitle: {
    id: 'components.Header.alertTitle',
    defaultMessage: 'ALERTAS'
  },
  alertNumber: {
    id: 'components.Header.alerNumber',
    defaultMessage: 'Alertas'
  }
});
