const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `0 ${theme.spacing(5)}px`,
    display: 'flex',
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      margin: `0 ${theme.spacing(1.5)}px`,
      marginTop: theme.spacing(3),
      padding: theme.spacing(0)
    }
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  contentTable: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(7)
    }
  }
}));

export { useStyles };
