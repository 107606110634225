import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contentActions: {
    alignItems: 'center',
    paddingLeft: theme.spacing(6),
    paddingRight: theme.spacing(8),
    marginBottom: theme.spacing(3)
  },
  contentTitle: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  title: {
    fontSize: '20px',
    color: theme.palette.common.black,
    fontWeight: 800,
    letterSpacing: '6px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
      letterSpacing: '1.6px'
    }
  },
  subtitle: {
    fontSize: '16px',
    color: theme.palette.common.black,
    marginTop: theme.spacing(3)
  },
  interactions: {
    color: theme.palette.info.light,
    fontSize: '20px',
    fontWeight: 800,
    letterSpacing: '6px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px'
    }
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(1.5)
    }
  },
  button: {
    background: theme.palette.info.light,
    color: theme.palette.common.white,
    padding: theme.spacing(1.5),
    fontSize: '15px',
    letterSpacing: '1px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  },
  addIcon: {
    marginBottom: theme.spacing(0.5)
  },
  titleDrawer: {
    color: theme.palette.common.black,
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800
  }
}));

export { useStyles };
