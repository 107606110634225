import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  advertising: {
    id: 'components.Events.advertising',
    defaultMessage: 'PUBLICIDAD'
  },
  events: {
    id: 'components.Events.events',
    defaultMessage: 'EVENTOS'
  },
  addEvent: {
    id: 'components.Events.addEvent',
    defaultMessage: 'AGREGAR EVENTO'
  },
  titleEvent: {
    id: 'components.Events.titleEvent',
    defaultMessage: 'DETALLES DEL EVENTO'
  },
  title: {
    id: 'components.Events.title',
    defaultMessage: 'Título del evento:'
  },
  startDate: {
    id: 'components.Events.startDate',
    defaultMessage: 'Fecha de inicio:'
  },
  endDate: {
    id: 'components.Events.endDate',
    defaultMessage: 'Fecha de término:'
  },
  campaings: {
    id: 'components.Events.campaings',
    defaultMessage: 'Campañas:'
  },
  next: {
    id: 'components.Events.next',
    defaultMessage: 'Siguiente'
  },
  previous: {
    id: 'components.Events.previous',
    defaultMessage: 'Anterior'
  },
  today: {
    id: 'components.Events.today',
    defaultMessage: 'Hoy'
  },
  month: {
    id: 'components.Events.month',
    defaultMessage: 'Mes'
  },
  week: {
    id: 'components.Events.week',
    defaultMessage: 'Semana'
  },
  day: {
    id: 'components.Events.day',
    defaultMessage: 'Día'
  },
  delete: {
    id: 'components.Events.delete',
    defaultMessage: 'ELIMINAR'
  },
  edit: {
    id: 'components.Events.edit',
    defaultMessage: 'EDITAR'
  },
  search: {
    id: 'components.Events.search',
    defaultMessage: 'BUSCAR'
  },
  cancelAddEvent: {
    id: 'components.Events.cancelAddEvent',
    defaultMessage: 'CANCELAR'
  },
  infoTextCancelAddEvent: {
    id: 'components.Events.infoTextCancelAddEvent',
    defaultMessage: '¿Seguro de que desea salir sin guardar el evento?'
  },
  exit: {
    id: 'components.Events.exit',
    defaultMessage: 'SALIR'
  }
});
