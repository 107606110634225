import React from 'react';
import {
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField
} from '@material-ui/core';
import { useIntl } from 'react-intl';

const LinearScale = ({ onChangeData, data, isEnglish, messages }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          label={
            isEnglish
              ? formatMessage(messages.writeQuestionEnglish)
              : formatMessage(messages.writeQuestions)
          }
          value={isEnglish ? data.dataEnglish.question : data.data.question}
          fullWidth
          name="question"
          onChange={onChangeData}
          variant="outlined"
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <span>
          {isEnglish
            ? formatMessage(messages.sliderTypeEnglish)
            : formatMessage(messages.sliderType)}
        </span>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <RadioGroup
          onChange={onChangeData}
          value={data.data.type}
          row
          aria-label="surveyFormat"
          name="surveyFormat"
        >
          <FormControlLabel
            value="stars"
            name="stars"
            control={<Radio />}
            label={
              isEnglish
                ? formatMessage(messages.starsEnglish)
                : formatMessage(messages.stars)
            }
          />
          <FormControlLabel
            value="emotes"
            name="emotes"
            control={<Radio />}
            label={
              isEnglish
                ? formatMessage(messages.smileyEnglish)
                : formatMessage(messages.smiley)
            }
          />
          <FormControlLabel
            value="numeric"
            name="numeric"
            control={<Radio />}
            label={
              isEnglish
                ? formatMessage(messages.numericalEnglish)
                : formatMessage(messages.numerical)
            }
          />
        </RadioGroup>
      </Grid>
    </Grid>
  );
};

export default LinearScale;
