import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'routes.CreateUpdate.title',
    defaultMessage: 'Crear campaña'
  },
  titleEdit: {
    id: 'routes.CreateUpdate.titleEdit',
    defaultMessage: 'Editar campaña'
  },
  textFieldName: {
    id: 'routes.CreateUpdate.textFieldName',
    defaultMessage: 'Nombre'
  },
  buttonCancel: {
    id: 'routes.CreateUpdate.buttonCancel',
    defaultMessage: 'CANCELAR'
  },
  buttonSave: {
    id: 'routes.CreateUpdate.buttonSave',
    defaultMessage: 'GUARDAR'
  },
  description: {
    id: 'routes.CreateUpdate.description',
    defaultMessage: 'Relacionar escenas a la campaña'
  },
  textFieldSearch: {
    id: 'routes.CreateUpdate.textFieldSearch',
    defaultMessage: 'Buscar'
  },
  name: {
    id: 'routes.CreateUpdate.name',
    defaultMessage: 'Nombre'
  }
});
