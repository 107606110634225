function orderArray(arr) {
  let preguntaIndex = arr.indexOf('Pregunta');

  let newArray = [];

  newArray.push('Pregunta');

  for (let i = preguntaIndex + 1; i < arr.length; i++) {
    if (arr[i] != 'Total') {
      newArray.push(arr[i]);
    }
  }

  newArray.push('Total');

  return newArray;
}

export { orderArray };
