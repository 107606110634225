import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleView: {
    id: 'views.Turns.HistoryTurns.titleView',
    defaultMessage: 'HISTORIAL DE TURNOS'
  },
  buttonExport: {
    id: 'views.Turns.HistoryTurns.buttonExport',
    defaultMessage: 'EXPORTAR'
  },
  id: {
    id: 'views.Turns.HistoryTurns.id',
    defaultMessage: 'ID'
  },
  turn: {
    id: 'views.Turns.HistoryTurns.turn',
    defaultMessage: 'TURNO'
  },
  dateRequest: {
    id: 'views.Turns.HistoryTurns.dateRequest',
    defaultMessage: 'FECHA Y HORA DE SOLICITUD DE TURNO'
  },
  dateAssign: {
    id: 'views.Turns.HistoryTurns.dateAssign',
    defaultMessage: 'FECHA Y HORA DE ASIGNACIÓN'
  },
  standbyTime: {
    id: 'views.Turns.HistoryTurns.standbyTime',
    defaultMessage: 'TIEMPO DE ESPERA POR CLIENTE'
  },
  dateAttention: {
    id: 'views.Turns.HistoryTurns.dateAttention',
    defaultMessage: 'FECHA Y HORA DE FIN DE ATENCIÓN'
  },
  attentionTime: {
    id: 'views.Turns.HistoryTurns.attentionTime',
    defaultMessage: 'TIEMPO DE ATENCIÓN'
  },
  attendedBy: {
    id: 'views.Turns.HistoryTurns.attendedBy',
    defaultMessage: 'ATENDIDO POR'
  }
});
