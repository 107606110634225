/* eslint-disable array-callback-return */
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { messages } from './PermissionsCreateUpateMessages';
import { messages as formMessages } from '@octopy/react-form';
import { useFormik } from 'formik';
import useApiCalls from './useApiCalls';
import { useDrawer } from 'components/Drawer';
import { get } from 'lodash';

export const useHelper = (item, onCreate) => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const isSuperAdmin = get(storage, 'user.is_staff', {});
  const clientId = get(storage, 'user.client.client_id', {});

  const { formatMessage: f } = useIntl();
  const { handleCloseDrawer } = useDrawer();
  const {
    getClientsList,
    getModulesList,
    updateProfile,
    createProfile
  } = useApiCalls();

  //initial Formik Structure
  const requiredFieldError = f(formMessages.requiredFieldError);
  const getValidationRules = () =>
    Yup.object({
      name: Yup.string().required(requiredFieldError)
      // client: Yup.string().when(isSuperAdmin, {
      //   is: true,
      //   then: Yup.string().required(requiredFieldError)
      // })
    });

  const initialValues = {
    name: '',
    client: ''
  };

  const inputProps = {
    variant: 'outlined',
    fullWidth: true,
    size: 'medium'
  };

  const initialFields = [
    Object.assign(
      {
        type: 'text',
        name: 'name',
        label: f(messages.name),
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  // MAIN STATES
  const [mainFieldsForm, setMainFieldsForm] = useState([]);
  const [modulesFieldsForm, setModulesFieldsForm] = useState([]);
  const [valuesForm, setValuesForm] = useState(initialValues);
  const [modules, setModules] = useState([]);
  const [clientSelected, setClientSelected] = useState(0);

  //Formik
  const formik = useFormik({
    initialValues: valuesForm,
    validationSchema: getValidationRules(),
    onSubmit: (values) => handleSubmit(values)
  });

  //Events Create Edit Client
  const handleSubmit = async (values) => {
    const modulesList = [];

    modules.forEach((element) => {
      if (values[element.name]) modulesList.push(element.module_id);
    });

    const dataToSend = {
      name: values.name,
      client: clientSelected,
      modules: modulesList
    };

    if (item.profile_id) {
      await updateProfile(item.profile_id, dataToSend);
    } else {
      await createProfile(dataToSend);
    }

    handleCloseDrawer();
    onCreate();
  };
  ///Add Modules

  const getModules = async () => {
    const modulesResponse = await getModulesList(clientSelected);

    let fieldsFormSwitch = [];
    let modules = formik.values;

    modulesResponse.map((module) => {
      modules[`${module.name}`] = false;
      fieldsFormSwitch.push(
        Object.assign(
          {
            type: 'switch',
            name: module.name,
            label: module.name,
            breakpoints: { xs: 6 }
          },
          inputProps
        )
      );
    });

    setModules(modulesResponse);
    setValuesForm(modules);
    setModulesFieldsForm(fieldsFormSwitch);

    if (item.modules) {
      item.modules.map((module) => {
        formik.setFieldValue(module.name, true);
      });
    }
  };

  //GetFields
  const getFields = async () => {
    let fields = initialFields.slice();

    if (isSuperAdmin) {
      let clients = await getClientsList();

      fields.push({
        type: 'select',
        name: 'client',
        label: f(messages.client),
        placeholder: f(messages.client),
        options: clients,
        getOptionLabel: (option) => `${option.name || ''}`,
        getOptionValue: (option) => `${option.client_id || ''}`,
        getOptionSelected: (option, value) => option === value,
        onChange: (option, value) => {
          formik.setFieldValue(option, `${value}`);
          setClientSelected(value);
        },
        breakpoints: { xs: 12 },
        inputProps: inputProps
      });
    } else {
      setClientSelected(clientId);
    }

    setMainFieldsForm(fields);
  };

  //GetFields
  const fillFormikEdit = () => {
    if (item.profile_id) {
      formik.setValues({
        name: item.name,
        client: `${item.client.client_id}`
      });
      setClientSelected(item.client.client_id);
    }
  };

  useEffect(() => {
    getFields();
  }, []);

  useEffect(async () => {
    if (clientSelected) {
      await getModules();
    }
  }, [clientSelected]);

  useEffect(() => {
    fillFormikEdit();
  }, [item]);

  return {
    formik,
    mainFieldsForm,
    modulesFieldsForm
  };
};
