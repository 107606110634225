import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  container: {
    marginBottom: theme.spacing(2)
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    textTransform: 'uppercase'
  },
  titleModal: {
    fontSize: '20px',
    fontWeight: 800,
    letterSpacing: '6px'
  },
  jsonKey: {
    fontSize: '14px',
    fontWeight: 800
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  preview: {
    fontSize: '14px',
    fontWeight: 800,
    color: theme.palette.common.black
  },
  uploadInfo: {
    marginLeft: theme.spacing(2)
  },
  iconModalSucess: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(80)
  },
  contentActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  }
}));

export { useStyles };
