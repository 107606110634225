import { useState, useEffect } from 'react';
import { get } from 'lodash';
import { useApi } from 'hooks';
import { useHistory } from 'react-router-dom';
import { responseMapper } from 'utils/responseMapper';

const ProductsListMapper = {
  item_id: 'item_id',
  name: 'name[0].text',
  category: 'category.name[0].text',
  sub_category: 'sub_category.name[0].text',
  audio_text: 'audio_text'
};

export const useApiProducts = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = get(storage, 'user.client.client_id', '');

  const history = useHistory();
  const [productsList, setProductsList] = useState([]);
  const idProduct = history.location.state
    ? history.location.state.idProduct
    : 0;

  const [getProductsByClientId] = useApi({
    endpoint: '/catalogue/article/articles_by_client_id',
    method: 'get'
  });

  const [productCreate] = useApi({
    endpoint: '/catalogue/article/',
    method: 'post'
  });

  const [productUpdate] = useApi({
    endpoint: '/catalogue/article',
    method: 'put'
  });

  useEffect(() => {
    handleProductsList();
  }, []);

  const handleProductsList = async () => {
    try {
      const response =
        clientId !== '' &&
        (await getProductsByClientId({ urlParams: clientId }));
      const dataResponse = get(response, 'payload', []);

      if (response.headerResponse.code === 200) {
        const mappedDirectory = responseMapper({
          template: ProductsListMapper,
          data: dataResponse
        });

        setProductsList(mappedDirectory);
      }
    } catch (error) {}
  };

  const handleCreateProduct = async (data) => {
    try {
      const response = await productCreate({
        body: data
      });

      if (response.headerResponse.code === 200) {
        history.push('/products');
      }
    } catch (error) {}
  };

  const handleUpdateProduct = async (data) => {
    try {
      const response = await productUpdate({
        urlParams: `${idProduct}/`,
        body: data
      });

      if (response.headerResponse.code === 200) {
        history.push('/products');
      }
    } catch (error) {}
  };

  return {
    idProduct,
    productsList,
    handleProductsList,
    handleCreateProduct,
    handleUpdateProduct
  };
};
