import { useState, useEffect, useRef } from 'react';
import { useApi } from 'hooks';
import { useHistory } from 'react-router-dom';
import { useAuth } from '@octopy/react-auth';
import { useLoader } from '@octopy/react-loader';
import { useFormik } from 'formik';
import { useValidationSchema } from './useValidationSchema';
import { get } from 'lodash';

const mapperPublicityCampaign = (publicityCampigns) => {
  const mapped = publicityCampigns.map(
    (publicityCampign) => ({
      id: publicityCampign.campaign_id,
      name: publicityCampign.name,
      scenes: publicityCampign.scene.length,
      duration: publicityCampign.duration
    }),
    []
  );

  return mapped;
};

const mapperScence = (scences) => {
  const mapped = scences.map(
    (scence) => ({
      id: scence.scene_id,
      name: scence.name
    }),
    []
  );

  return mapped;
};

const initialValues = {
  nameScence: ''
};

export const useHelper = (
  edit,
  publicityCampaignId,
  scenceLeft,
  setScenceLeft
) => {
  const { auth } = useAuth();
  const formikRef = useRef();
  const history = useHistory();
  const { handleShowLoader } = useLoader();
  const validationSchema = useValidationSchema();
  const [publicityCampaign, setPublicityCampaign] = useState([]);
  const [scene, setScene] = useState([]);
  const [preview, setPreview] = useState(false);
  const [campaignSelected, setCampaignSelected] = useState({});
  const [newValues, setNewValues] = useState({ ...initialValues });
  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = storage.user.client.client_id;

  const [getPublicityCampaign] = useApi({
    endpoint: `publicity/campaign/campaigns_by_client/${clientId}/`,
    method: 'get'
  });
  const [postPublicityCampaign] = useApi({
    endpoint: '/publicity/campaign/',
    method: 'post'
  });
  const [putPublicityCampaign] = useApi({
    endpoint: `/publicity/campaign/${publicityCampaignId}/`,
    method: 'put'
  });
  const [deletePublicityCampaign] = useApi({
    endpoint: 'publicity/campaign',
    method: 'delete'
  });
  const [getScene] = useApi({
    endpoint: 'publicity/scene/scenes_by_client',
    method: 'get'
  });
  const [getOneScence] = useApi({
    endpoint: 'publicity/campaign',
    method: 'get'
  });

  const handleGetPublicityCampaign = async () => {
    const { headerResponse, payload } = await getPublicityCampaign();

    if (headerResponse.code === 200) {
      const mappedPublicityCampaign = mapperPublicityCampaign(payload);

      setPublicityCampaign(mappedPublicityCampaign);
    }
  };

  const handleCreatePublicityCampaign = async (values) => {
    const body = {
      name: values.nameScence,
      scene:
        scenceLeft != null && scenceLeft.length > 0 ? [scenceLeft[0].id] : [],
      client: clientId
    };
    const response = await postPublicityCampaign({ body });
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code === 200) {
      history.push('/advertising/campaigns');
    }

    handleShowLoader(false);
  };

  const handleEditPublicityCampaign = async (values) => {
    const body = {
      name: values.nameScence,
      scene:
        scenceLeft != null && scenceLeft.length > 0 ? [scenceLeft[0].id] : [],
      client: clientId
    };
    const response = await putPublicityCampaign({
      body
    });
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code === 200) {
      history.push('/advertising/campaigns');
    }

    handleShowLoader(false);
  };

  const handleDeletePublicityCamapign = async (idPubliCamp) => {
    const response = await deletePublicityCampaign({
      urlParams: idPubliCamp
    });
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code === 200) {
      handleGetPublicityCampaign();
    }
  };

  const handleGetListScence = async () => {
    const { headerResponse, payload } = await getScene({ urlParams: clientId });

    if (headerResponse.code === 200) {
      const mappedScence = mapperScence(payload);

      setScene(mappedScence);
    }
  };

  const handleGetScencesByClient = async () => {
    handleShowLoader(true);
    const { headerResponse, payload } = await getOneScence({
      urlParams: publicityCampaignId
    });

    if (headerResponse.code === 200) {
      const newValuesService = {
        nameScence: payload.name
      };

      if (payload.scene.length > 0) {
        const newScene = {
          id: payload.scene[0].scene_id,
          name: payload.scene[0].name
        };

        setScenceLeft([newScene]);
      }

      setNewValues(newValuesService);
    }

    handleShowLoader(false);
  };

  const getScenesByCampaign = async (idCampaign) => {
    handleShowLoader(true);
    const { headerResponse, payload } = await getOneScence({
      urlParams: idCampaign
    });

    handleShowLoader(false);
    if (headerResponse.code === 200) {
      return payload.scene;
    } else {
      return [];
    }
  };
  //Scheme mapper Scene to Structure Corrusel
  const dataCarrouselMapper = {
    id: 'media_id',
    name: 'name',
    time: 'duration',
    type: 'file_type',
    url: 'url_file'
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      handleShowLoader(true);
      if (edit) {
        handleEditPublicityCampaign(values);
      } else {
        handleCreatePublicityCampaign(values);
      }
    }
  });

  useEffect(() => {
    formikRef.current = formik;
    (async () => {
      if (edit) {
        formikRef.current.setValues(newValues);
      }
    })();
  }, [newValues]);

  return {
    publicityCampaign,
    handleGetPublicityCampaign,
    handleCreatePublicityCampaign,
    handleDeletePublicityCamapign,
    /** */
    formik,
    scene,
    scenceLeft,
    handleGetListScence,
    handleGetScencesByClient,
    //Carrousel Preview
    getScenesByCampaign,
    preview,
    setPreview,
    campaignSelected,
    setCampaignSelected,
    dataCarrouselMapper
  };
};
