import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { useIntl } from 'react-intl';

const YesNo = ({ onChangeData, data, isEnglish, messages }) => {
  const { formatMessage } = useIntl();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <TextField
          label={
            isEnglish
              ? formatMessage(messages.writeQuestionEnglish)
              : formatMessage(messages.writeQuestions)
          }
          value={isEnglish ? data.dataEnglish.question : data.data.question}
          fullWidth
          name="question"
          onChange={onChangeData}
          variant="outlined"
        />
      </Grid>
    </Grid>
  );
};

export default YesNo;
