import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: '25px'
  },
  grid: {
    display: 'flex',
    alignItems: 'center'
  },
  number: {
    fontSize: '20px',
    fontWeight: 800
  },
  name: {
    fontSize: '14px'
  },
  divider: {
    marginTop: 10
  }
}));

export { useStyles };
