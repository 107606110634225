import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(5),
    width: 350,
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    }
  },
  subtitle: {
    fontSize: '15px',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1)
  },
  infoText: {
    fontSize: '15px',
    color: theme.palette.common.black,
    marginTop: theme.spacing(1)
  },
  contentButtons: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end'
    }
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '15px',
    letterSpacing: '1px',
    padding: theme.spacing(1),
    marginTop: theme.spacing(2),
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  }
}));

export { useStyles };
