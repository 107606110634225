import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0.5),
      paddingTop: theme.spacing(4),
      margin: `0 ${theme.spacing(1)}px`,
      paddingBottom: theme.spacing(10)
    }
  },
  rootCard: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2)
    }
  },
  contentActions: {
    displya: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  infoText: {
    fontSize: '14px',
    opacity: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  branchesTotal: {
    fontSize: '14px',
    fontWeight: 800,
    marginTop: theme.spacing(3)
  },
  cancelButton: {
    padding: theme.spacing(1),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.common.black,
    fontSize: '14px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.common.black}CE`,
      color: theme.palette.common.white
    }
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  gridButtons: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },

  contentModal: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  icon: {
    fontSize: '80px',
    color: theme.palette.primary.main
  },
  titleModal: {
    fontSize: '20px',
    fontWeight: 800,
    letterSpacing: '6px'
  },
  infoTextModal: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  }
}));

export { useStyles };
