import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { messages } from './TakeTurnMessages';
import {
  emitSocket,
  onSocket,
  disconnectionSocket,
  connectionSocket
} from 'providers/socket';
import { format } from 'date-fns';

export const useTable = (idBranch) => {
  const { formatMessage: f } = useIntl();

  const [turns, setTurns] = useState([]);
  const [currentBranch, setCurrentBranch] = useState('');

  //Table configuration
  const tableHead = [
    { key: 'id', label: f(messages.id) },
    { key: 'turn', label: f(messages.turn) },
    { key: 'created_at', label: f(messages.dateRequest) }
  ];

  const configProps = {
    actions: {},
    selectedOptions: {
      checkboxHidden: true
    }
  };

  const displayDisconnection = (reason) => {
    // console.log('SE HA DESCONECTADO EL SOCKET');
  };

  const connectionApp = (reason) => {
    // console.log('se ha establecido la conexion');
  };

  useEffect(() => {
    disconnectionSocket(displayDisconnection);
    connectionSocket(connectionApp);
  }, []);

  useEffect(() => {
    setTurns([]);
    if (idBranch !== '') {
      //Se valida si tienen una sucursal seleccionada

      //validamos algun cambio de sucursal
      if (currentBranch != '' && idBranch != currentBranch) {
        emitSocket('exit_branch_office_room', {
          branch_office: currentBranch
        });
      }

      // se hace el acceso al room
      setCurrentBranch(idBranch);
      emitSocket('enter_branch_office_room', {
        branch_office: idBranch
      });
      onSocket('turns', handleTurns);
    }
  }, [idBranch]);

  const mappedTurns = (turns) => {
    const turnsMapped = turns.map((item) => ({
      id: item.id,
      turn: item.turn,
      created_at: format(
        new Date(item.created_at),
        `${'dd/MM/yyyy'} - ${'HH:mm:ss'}`
      )
    }));

    return turnsMapped;
  };

  const handleTurns = (data) => {
    setTurns(mappedTurns(data.data));
  };

  return { turns, tableHead, configProps };
};
