import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  checkBoxLabel: {
    fontSize: '14px'
  }
}));

export { useStyles };
