/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from 'react';
// import PropTypes from 'prop-types';
import {
  Card,
  Grid,
  Paper,
  Button,
  Typography,
  useMediaQuery
} from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { TodayRounded } from '@material-ui/icons';
import {
  BarChart,
  DonutChart,
  HalfDonutChart,
  PieChart
} from '@octopy/react-charts';
import { get } from 'lodash';
import { useApi } from 'hooks';
import * as Yup from 'yup';
import { Form } from '@octopy/react-form';
import { useIntl } from 'react-intl';
import { donutColors, HalfDonutColors, pieColors } from './utils';
import { useStyles } from './MetricsStyles';
import { messages } from './MetricsMessages';
import { TopMetrics } from 'views/Metrics/TopMetrics';
import { responseMapper } from 'utils/responseMapper';
import { UseMetrics } from './useMetrics';

const robotMapper = {
  value: 'robot_id',
  label: 'name'
};

const Metrics = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const userName = get(storage, 'user.name', 'user.last_name', '');
  const userLastName = get(storage, 'user.last_name', '');
  const profile = get(storage, 'user.profile.name', '');
  const clientId = get(storage, 'user.client.client_id', {});
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.only('xs', 'lg'));
  const intl = useIntl();
  const { formatMessage } = useIntl();
  const classes = useStyles();
  const {
    sumbitFilters,
    // peopleServerd,
    totalinteractions,
    answeredPolls,
    timeInteractions,
    timeInteractionsTotal,
    interactionGender,
    interactionGenderTotal,
    pollsConducted,
    totalpollsConducted,
    topProducts,
    topCategories,
    topIntentions,
    //detector Crossed
    dataCrossed
  } = UseMetrics();

  const [robotL, setRobotL] = useState([]);
  const formikRef = useRef();

  const [robotList] = useApi({
    endpoint: `/robots/robots/robots_by_client/${clientId}/`,
    method: 'get'
  });

  const listRobot = async () => {
    try {
      const responseRobotList = await robotList();

      if (responseRobotList.headerResponse.code === 200) {
        const dataResponseRobotList = get(responseRobotList, 'payload', []);

        const mapper = responseMapper({
          template: robotMapper,
          data: dataResponseRobotList
        });

        setRobotL(mapper);
      }
    } catch (error) {}
  };

  useEffect(() => {
    listRobot();
  }, []);

  const getInitialValues = () => ({
    robot: '',
    dateStart: null,
    dateEnd: null
  });

  const getValidationRules = () =>
    Yup.object({
      robot: Yup.string(),
      dateStart: Yup.date().nullable(),
      dateEnd: Yup.date().nullable()
    });

  const getFields = () => [
    {
      type: 'select',
      name: 'robot',
      label: 'robot',
      placeholder: 'robot',
      options: robotL,
      getOptionLabel: (option) => `${option.label || ''}`,
      getOptionValue: (option) => `${option.value || ''}`,
      getOptionSelected: (option, value) => option === value,
      breakpoints: { xs: 12, lg: 4 }
    },
    {
      type: 'datePicker',
      name: 'dateStart',
      label: 'Fecha inicial',
      placeholder: 'fecha inicial',
      variant: 'inline',
      format: 'dd/MM/yyyy',
      disableToolbar: true,
      inputVariant: 'outlined',
      breakpoints: { xs: 12, lg: 4 },
      InputProps: {
        endAdornment: <TodayRounded />
      }
    },
    {
      type: 'datePicker',
      name: 'dateEnd',
      label: 'Fecha final',
      placeholder: 'fecha final',
      variant: 'inline',
      format: 'dd/MM/yyyy',
      disableToolbar: true,
      inputVariant: 'outlined',
      breakpoints: { xs: 12, lg: 4 },
      InputProps: {
        endAdornment: <TodayRounded />
      }
    }
  ];

  const handleSubmit = (values) => {
    sumbitFilters(values);
  };

  return (
    <div className={classes.root}>
      <Paper>
        <div className={classes.paralaxEffect}>
          <div className={classes.paralaxTextCotainer}>
            <span className={classes.welcomeHeader}>
              {formatMessage(messages.welcomeHeader)}
            </span>
            <span className={classes.username}>
              {userName} {userLastName}
            </span>
            <span className={classes.welcomeRole}>
              {formatMessage(messages.welcomeRole)} {profile}
            </span>
          </div>
        </div>

        {/* <div className={classes.welcomeContainer}>
          <div style={{ position: 'absolute', left: '50px', top: '40%' }}>
            Bienvenido
            <br />
            Nombre de usuario
            <br />
            Has iniciado sesión como ROL DE USUARIO
          </div>
          <img src={robotImage} alt="robotImage" style={{ width: '100%' }} />
        </div> */}
      </Paper>
      <Paper className={classes.paperContainer}>
        <span className={classes.pageTitle}>
          {formatMessage(messages.pageTitle)}
        </span>
        <Grid
          container
          spacing={2}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <Grid item xs={12} lg={8}>
            <Form
              formikRef={formikRef}
              initialValues={getInitialValues()}
              validationRules={getValidationRules()}
              fields={getFields()}
              handleSubmit={handleSubmit}
              showSubmitButton={false}
              inputProps={{ variant: 'outlined', fullWidth: true }}
              withInputsBorder
            />
          </Grid>
          <Grid item lg={2}>
            <Button
              className={classes.button}
              onClick={() => formikRef.current.submitForm()}
            >
              <Typography>{intl.formatMessage(messages.filter)}</Typography>
            </Button>
          </Grid>
          {/* <Grid item lg={2}>
            <Button
              className={classes.button}
              endIcon={<ExitToApp />}
              onClick={() => Alert('hola')}
            >
              <Typography>{intl.formatMessage(messages.export)}</Typography>
            </Button>
          </Grid> */}
        </Grid>
        <Grid container spacing={2} className={classes.metricsContainer}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card className={classes.cardContainer}>
              <span className={classes.cardTitle}>
                {formatMessage(messages.peopleServed)}
              </span>
              <BarChart
                showTotal
                yTitle={formatMessage(messages.titleGraph0)}
                maxValuesInSight={12}
                barWidth={xs ? 10 : 30}
                // values={peopleServerd}
                values={dataCrossed}
                color={'#4ab192'}
              />
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card className={classes.cardContainer}>
              <span className={classes.cardTitle}>
                {formatMessage(messages.totalInteractions)}
              </span>
              <BarChart
                showTotal
                yTitle={formatMessage(messages.titleGraph1)}
                maxValuesInSight={12}
                barWidth={xs ? 10 : 30}
                values={totalinteractions}
                color={'#2AA4D5'}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card className={classes.cardContainer}>
              <span className={classes.cardTitle}>
                {formatMessage(messages.answeredSurveys)}
              </span>
              <BarChart
                showTotal
                yTitle={formatMessage(messages.titleGraph2)}
                maxValuesInSight={12}
                barWidth={xs ? 10 : 30}
                values={answeredPolls}
                color={'#6f5b97'}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card className={classes.cardContainer}>
              <span className={classes.cardTitle}>
                {formatMessage(messages.interactionTime)}
              </span>
              <HalfDonutChart
                showTotal
                colors={HalfDonutColors}
                values={timeInteractions}
                options={{
                  plotOptions: {
                    pie: {
                      startAngle: -90,
                      endAngle: 90,
                      offsetY: 10,
                      donut: {
                        labels: {
                          show: true,
                          total: {
                            show: true,
                            showAlways: true,
                            label: timeInteractionsTotal.label,
                            formatter: () => `${timeInteractionsTotal?.value}`
                          }
                        }
                      }
                    }
                  }
                }}
              />
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card className={classes.cardContainer}>
              <span className={classes.cardTitle}>
                {formatMessage(messages.genderInteractions)}
              </span>
              <DonutChart
                showTotal
                values={interactionGender}
                colors={donutColors}
                options={{
                  legend: { position: 'bottom' },
                  plotOptions: {
                    pie: {
                      donut: {
                        labels: {
                          show: true,
                          total: {
                            show: true,
                            showAlways: true,
                            label: interactionGenderTotal.label,
                            formatter: () => `${interactionGenderTotal?.value}`
                          }
                        }
                      }
                    }
                  }
                }}
              />
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Card className={classes.cardContainer}>
              <span className={classes.cardTitle}>
                {formatMessage(messages.conductedSurveys)}
              </span>
              {totalpollsConducted != ''
                ? `${formatMessage(
                    messages.totalInteractionsLegend
                  )} ${totalpollsConducted}`
                : ''}
              <PieChart
                options={{
                  legend: { position: 'bottom' }
                }}
                values={pollsConducted}
                colors={pieColors}
              />
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card className={classes.cardTop}>
              <Typography className={classes.title}>
                {intl.formatMessage(messages.topProduct)}
              </Typography>
              {topProducts.map((item, index) => (
                <TopMetrics
                  index={index + 1}
                  name={item.length > 0 ? item[0].text : ''}
                />
              ))}
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card className={classes.cardTop}>
              <Typography className={classes.title}>
                {intl.formatMessage(messages.topCategory)}
              </Typography>
              {topCategories.map((item, index) => (
                <TopMetrics
                  index={index + 1}
                  name={
                    item['name'] && item.name.length > 0
                      ? item.name[0].text
                      : ''
                  }
                />
              ))}
            </Card>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Card className={classes.cardTop}>
              <Typography className={classes.title}>
                {intl.formatMessage(messages.topIntent)}
              </Typography>
              {topIntentions.map((item, index) => (
                <TopMetrics index={index + 1} name={item.name} />
              ))}
            </Card>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

Metrics.propTypes = {};

export default Metrics;
