import React from 'react';
import { Navigation } from 'routes/Navigation';
import { AuthProvider } from '@octopy/react-auth';
import { DrawerProvider } from 'components/Drawer';
import { ScreenLoaderProvider } from 'components/ScreenLoader';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import './App.scss';

// TODO: Add auth logic for login & register components
const App = () => (
  <>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <ScreenLoaderProvider>
        <AuthProvider>
          <DrawerProvider>
            <Navigation />
          </DrawerProvider>
        </AuthProvider>
      </ScreenLoaderProvider>
    </MuiPickersUtilsProvider>
  </>
);

export { App };
