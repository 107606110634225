const inputProps = {
  variant: 'outlined',
  fullWidth: true,
  size: 'medium'
};

const tableHead = [
  { key: 'category_id', label: 'ID' },
  { key: 'name', label: 'NOMBRE' },
  { key: 'sub_categories', label: 'SUBCATEGORÍAS', align: 'center' },
  { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false }
];

const filters = [
  { key: 'name', value: 'NOMBRE' },
  { key: 'sub_categories', value: 'SUBCATEGORÍAS' }
];

export { inputProps, tableHead, filters };
