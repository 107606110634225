export const sources = (BucketClientName) => {
  const imagesFolder = 'images';
  const audiosFolder = 'audios';
  const videosFolder = 'videos';

  const audios = `${BucketClientName}/${audiosFolder}/`;

  const images = `${BucketClientName}/${imagesFolder}/`;

  const videos = `${BucketClientName}/${videosFolder}/`;

  return {
    audios,
    images,
    videos
  };
};
