import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
      margin: `0 ${theme.spacing(1)}px`
    }
  },
  title: {
    fontSize: '20px',
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.black
        : theme.palette.common.white,
    fontWeight: 800,
    letterSpacing: '6px',
    marginBottom: theme.spacing(4)
  },
  rootCard: {
    padding: theme.spacing(2),
    borderRadius: '16px'
  },
  titleCard: {
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1.5)
  },
  imageTextInfo: {
    fontSize: '14px',
    marginBottom: theme.spacing(1)
  },

  formatText: {
    fontSize: '14px',
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.black
        : theme.palette.common.white,
    fontWeight: 800
  },
  storageText: {
    fontSize: '14px'
  },
  displaFlex: {
    display: 'flex'
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(4)
    }
  },
  button: {
    marginBottom: theme.spacing(4),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  labelSwitch: {
    fontSize: '14px',
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.black
        : theme.palette.common.white
  }
}));

export { useStyles };
