import { makeStyles } from '@material-ui/core/styles';
import { Images } from 'assets';

const useStyles = makeStyles((theme) => ({
  wrapper: (index) => ({
    position: 'fixed',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: theme.palette.common.black,
    zIndex: 10000,
    padding: theme.spacing(1.5),
    transition: theme.transitions.create('opacity', { duration: 1000 }),
    opacity: index > 5 ? 0 : 1,
    pointerEvents: index > 4 ? 'none' : undefined
  }),
  backgroundImage: (index) => ({
    opacity: index > 0 ? 1 : 0,
    objectFit: 'cover',
    width: '678px',
    height: '189px',
    zIndex: -1,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    transition: theme.transitions.create('opacity', { duration: 1000 }),
    [theme.breakpoints.down('xs')]: {
      transform: 'scale(0.56)'
    }
  }),
  welcome: (index) => ({
    transition: theme.transitions.create(['opacity'], {
      duration: 1000
    }),
    opacity: index > 2 ? 1 : 0,
    zIndex: 10000,
    backgroundImage: `url(${Images.robotFace})`,
    backgroundPosition: '100%',
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    width: '100%',
    height: '100vh',
    overflowX: 'hidden',
    overflow: 'hidden'
  }),

  boxPosition: {
    display: 'flex',
    justifyContent: 'flex-end',
    height: '66%', //66&
    alignItems: 'flex-end',
    marginRight: '31vh', //40vh
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginRight: 0,
      alignItems: 'center',
      height: '100vh'
    }
  },
  boxDirection: {
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  companyLogo: {
    width: '450px', //339
    height: '206px', //95
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '380px',
      height: 'auto'
    }
  },
  welcomeText: (index) => ({
    fontSize: '78px',
    color: theme.palette.primary.contrastText,
    fontWeight: 'bold',
    marginTop: -theme.spacing(2),
    transition: theme.transitions.create('opacity', { duration: 1000 }),
    opacity: index > 3 ? 1 : 0,
    [theme.breakpoints.down('sm')]: {
      fontSize: '53px'
    }
  })
}));

export { useStyles };
