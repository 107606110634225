import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleView: {
    id: 'views.Clients.ClientsList.titleView',
    defaultMessage: 'GESTOR DE CLIENTES'
  },
  buttonAddClient: {
    id: 'views.Clients.ClientsList.buttonAddClient',
    defaultMessage: 'AGREGAR CLIENTE'
  },
  titleModal: {
    id: 'views.Clients.ClientsList.titleModal',
    defaultMessage: '¿Estas seguro de eliminar este cliente?'
  },
  infoTextModal: {
    id: 'views.Clients.ClientsList.infoTextModal',
    defaultMessage: 'Si borras el registro no podrás recuperarlo '
  }
});
