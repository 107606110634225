import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  save: {
    id: 'components.MediaLibraryForm.save',
    defaultMessage: 'GUARDAR'
  },
  infoTextDrawer: {
    id: 'components.MediaLibraryForm.infoTextDrawer',
    defaultMessage: 'Datos de la presentación visual'
  },
  resolution: {
    id: 'components.MediaLibraryForm.resolution',
    defaultMessage:
      'El peso máximo de la imagen debe de ser de 30MB y para video 100MB.'
  },
  nameLabel: {
    id: 'components.MediaLibraryForm.nameLabel',
    defaultMessage: 'Nombre'
  },
  uploadFile: {
    id: 'components.MediaLibraryForm.uploadFile',
    defaultMessage: 'Subir archivo'
  },
  uploadFileError: {
    id: 'views.MediaLibraryForm.uploadFileError',
    defaultMessage:
      'Ha ocurrido un error al cargar el archivo. Intentalo de nuevo más tarde.'
  },
  requiredFieldError: {
    id: 'components.MediaLibraryForm.requiredFieldError',
    defaultMessage: 'Este campo es obligatorio.'
  }
});
