import { messages } from './TakeTurnMessages';
import { messages as messagesAddTakeTurnDrawer } from '../AddTakeTurnDrawer/AddTakeTurnDrawerMessages';

import { useIntl } from 'react-intl';

import { useBranches } from 'views/Branches/useBranches';

export const useField = ({ boxes }) => {
  const { formatMessage: f } = useIntl();
  const { branchList } = useBranches();

  const inputProps = {
    fullWidth: true,
    variant: 'outlined'
  };

  const fieldsTakeTurn = {
    selectBranch: {
      type: 'select',
      name: 'selectBranch',
      label: f(messagesAddTakeTurnDrawer.selectBranch),
      placeholder: f(messagesAddTakeTurnDrawer.selectBranch),
      options: branchList,
      getOptionLabel: (option) => option.name,
      getOptionSelected: (option, id) => option === id,
      getOptionValue: (option) => option.id,
      breakpoints: { xs: 12, md: 4, lg: 3 },
      inputProps
    },
    releaseBox: {
      type: 'text',
      name: 'releaseBox',
      label: f(messages.selectedBox),
      placeholder: f(messages.selectedBox),
      options: boxes,
      getOptionLabel: (option) => option.name,
      getOptionSelected: (option, id) => option === id,
      getOptionValue: (option) => option.id,
      breakpoints: { xs: 12, md: 4, lg: 3 },
      inputProps
    }
  };

  return { fieldsTakeTurn };
};
