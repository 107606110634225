import React from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Table } from '@octopy/react-table';
import { Typography, Grid, Button } from '@material-ui/core';
import { ExitToApp, AddCircle, Delete } from '@material-ui/icons';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { useApiProducts } from './hooks/useApiProducts';
import { tableHead, filters } from './utils';
import { mapProducts } from './helpers';
import { useApi } from 'hooks';
import { useExport } from 'hooks/useExport';
import { messages } from './ProductsMessages';
import { useStyles } from './ProductsStyles';

const Products = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { modalConfirmationDelete } = useModalConfirmation();
  const { productsList, handleProductsList } = useApiProducts();
  const { exportToExcel } = useExport();

  const [deleteProduct] = useApi({
    endpoint: 'catalogue/article',
    method: 'delete'
  });

  const configProps = {
    filters,
    actions: {
      edit: {
        onClick: (item) => {
          history.push('/product/form', {
            idProduct: item.item_id
          });
        },
        disabled: false,
        hidden: false
      },
      customs: {
        text: 'Eliminar',
        onClick: (item) => handleModal(item),
        icon: <Delete />
      }
    },
    selectedOptions: {
      checkboxHidden: true
    }
  };

  const handleModal = (data) => {
    modalConfirmationDelete(
      intl.formatMessage(messages.titleModal),
      intl.formatMessage(messages.infoTextModal),
      data.name,
      () => {
        handleDeleteProduct(data.item_id);
      }
    );
  };

  const handleDeleteProduct = async (itemId) => {
    try {
      const response = await deleteProduct({
        urlParams: `${itemId}/`
      });

      if (response.headerResponse.code === 200) {
        handleProductsList();
      }
    } catch (error) {}
  };

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} lg={6} className={classes.contentTitles}>
          <Typography className={classes.productsTitle}>
            {intl.formatMessage(messages.productsTitle)}
            &nbsp;-&nbsp;
          </Typography>
          <Typography className={classes.itemsTitle}>
            {intl.formatMessage(messages.itemsTitle)}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} className={classes.contentButons}>
            <Grid item>
              <Button
                className={classes.button}
                endIcon={<ExitToApp />}
                onClick={() => {
                  exportToExcel(
                    mapProducts(productsList),
                    `${intl.formatMessage(messages.products)}`
                  );
                }}
              >
                {intl.formatMessage(messages.exportButton)}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                endIcon={<AddCircle />}
                onClick={() =>
                  history.push('/product/form', {
                    idProduct: 0
                  })
                }
              >
                {intl.formatMessage(messages.addItems)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.contentTable}>
        <Table
          columns={tableHead}
          data={productsList}
          configProps={configProps}
        />
      </Grid>
    </div>
  );
};

Products.propTypes = {};

export { Products };
