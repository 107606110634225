import React from 'react';
import { useIntl } from 'react-intl';
import { Backdrop, Typography, Box } from '@material-ui/core';
import { useScreenLoader } from './hooks/useScreenLoader';
import { messages } from './ScreenLoaderMessages';
import { useStyles } from './ScreenLoaderStyles';

const ScreenLoader = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { show } = useScreenLoader();

  return (
    <Backdrop open={show || false} className={classes.backdrop}>
      <Box m={4} mt={30}>
        <Typography variant="h5" className={classes.loadingText}>
          {intl.formatMessage(messages.loading)}
        </Typography>
      </Box>
    </Backdrop>
  );
};

ScreenLoader.propTypes = {};

export { ScreenLoader };
