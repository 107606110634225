/* eslint-disable no-param-reassign */
/* eslint-disable prettier/prettier */
import { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { QuestionTypes } from 'components/Question/QuestionTypes';
import { useValidationSchema } from './Setup/components/SetupHeader/hooks/useValidationSchema';
import { useApi } from 'hooks';
import { useFormik } from 'formik';
import { useLoader } from '@octopy/react-loader';
import cloneDeep from 'lodash/cloneDeep';

const initialValues = {
  surveyTitle: '',
  surveyDescription: '',
  surveyFarewell: '',
  isEnglishSurveys: false,
  surveyTitleEnglish: '',
  surveyDescriptionEnglish: '',
  surveyFarewellEnglish: ''
};

const INITIALQUESTIONS = {
  id: '1',
  type: QuestionTypes.uniqueOption,
  data: {
    question: '',
    options: []
  },
  dataEnglish: {
    question: '',
    options: []
  }
};

const mapperReturnOptions = (itemOptions, _isSection = false) => {
  let options = [''];
  let optionsSect = '';

  if (_isSection) {
    if (itemOptions) {
      optionsSect = itemOptions[0]?.text;
    }
  } else {
    if (itemOptions) {
      options = itemOptions.map((itemOption) => itemOption.text, []);
    }
  }

  return _isSection ? optionsSect : options;
};

const mapperDataReturn = (item) => {
  let data,
    dataEnglish = {};

  if (item.type === 'section') {
    data = {
      title: item.data.question[0].text,
      description: mapperReturnOptions(item.data?.options[0]?.option, true)
    };
    dataEnglish = {
      title: item.data.question[1]?.text ?? '',
      description: mapperReturnOptions(item.data?.options[1]?.option, true)
    };
  } else {
    data = {
      type: item.data.type,
      question: item.data.question[0].text,
      options: mapperReturnOptions(item.data?.options[0].option)
    };

    dataEnglish = {
      question: item.data.question[1]?.text ?? '',
      options: mapperReturnOptions(item.data.options[1]?.option)
    };
  }

  return { data, dataEnglish };
};

const mapperReturnSuver = (question_) =>
  question_.map(
    (item) => ({
      id: item.id.toString(),
      type: item.type,
      ...mapperDataReturn(item)
    }),
    []
  );

const valueMaxQuestions = (_newQuestions) =>
  Math.max(..._newQuestions.map((x) => parseInt(x.id)));

const mapperSurvey = (survey, formikRef, formik) => {
  const { title, description, goodbye_message, questions } = survey;
  const aux1InitialQuestions = cloneDeep(INITIALQUESTIONS);
  let _newQuestions = [aux1InitialQuestions];

  const newHeaderValues = {
    surveyTitle: title[0].text,
    surveyDescription: description[0].text,
    surveyFarewell: goodbye_message[0].text,
    isEnglishSurveys: false,
    surveyTitleEnglish: title[1]?.text,
    surveyDescriptionEnglish: description[1]?.text,
    surveyFarewellEnglish: goodbye_message[1]?.text
  };

  // eslint-disable-next-line no-param-reassign
  formikRef.current = formik;

  formikRef.current.setValues(newHeaderValues);
  let newStructure = false;

  questions.forEach((element) => {
    if (typeof element.data.question !== 'string') {
      newStructure = true;
    }
  });

  if (newStructure) {
    _newQuestions = mapperReturnSuver(questions);
  } else {
    _newQuestions = [aux1InitialQuestions];
  }

  const maxValue = valueMaxQuestions(_newQuestions);

  return { newHeaderValues, _newQuestions, maxValue };
};

const mapOptionsSurveys = (itemOptions, _isEnglishSurveys) => {
  let mapperDataItemOptions = itemOptions.data?.options
    ? itemOptions.data?.options
    : [itemOptions.data?.description];

  let mapperDataEnglishItemOprions = itemOptions.dataEnglish?.options
    ? itemOptions.dataEnglish?.options
    : [itemOptions.dataEnglish?.description];

  let optionsData = mapperDataItemOptions.map(
    (itemData) => ({ language_id: 1, text: itemData ? itemData : '1' }),
    []
  );

  let optionsDataEnglish = mapperDataEnglishItemOprions.map(
    (itemData) => ({ language_id: 2, text: itemData ? itemData : '2' }),
    []
  );

  if (itemOptions.type === 'linearScale') {
    optionsData = [{ language_id: 1, text: '1' }];
    optionsDataEnglish = [{ language_id: 2, text: '2' }];
  }

  return _isEnglishSurveys
    ? [
        {
          option:
            optionsData.length !== 0
              ? optionsData
              : [{ language_id: 1, text: '1' }]
        },
        {
          option:
            optionsDataEnglish.length !== 0
              ? optionsDataEnglish
              : [{ language_id: 2, text: '2' }]
        }
      ]
    : [
        {
          option:
            optionsData.length !== 0
              ? optionsData
              : [{ language_id: 1, text: '1' }]
        }
      ];
};

const mapOptionsAsk = (itemAsk, _isEnglishSurveys) => {
  let ask = [];

  if (itemAsk.type === 'squares') {
    let ask1 = [];
    let ask2 = [];

    let mapperDataItemAsk = itemAsk.data?.options;

    let mapperDataEnglishItemAsk = itemAsk.dataEnglish?.options;

    ask1 = mapperDataItemAsk.map(
      (itemData) => ({
        language_id: 1,
        text: itemData !== '' ? itemData : '1'
      }),
      []
    );

    ask2 = mapperDataEnglishItemAsk.map(
      (itemData) => ({
        language_id: 2,
        text: itemData !== '' ? itemData : '2'
      }),
      []
    );

    ask = ask1.concat(ask2);
  }

  return ask;
};

const defaultTextDat = (item, dat) => {
  let text = '';

  if (item[dat]?.question) {
    text = item[dat]?.question;
  }

  if (item[dat]?.title) {
    text = item[dat]?.title;
  }

  return text;
};

const mapperSaveSurvey = (question_, _isEnglishSurveys) =>
  question_.map(
    (item) => ({
      id: parseInt(item.id),
      type: item.type,
      data: {
        type: item.data?.type ? item.data?.type : '',
        question: _isEnglishSurveys
          ? [
              {
                language_id: 1,
                text: defaultTextDat(item, 'data')
              },
              {
                language_id: 2,
                text: defaultTextDat(item, 'dataEnglish')
              }
            ]
          : [
              {
                language_id: 1,
                text: defaultTextDat(item, 'data')
              }
            ],
        options: mapOptionsSurveys(item, _isEnglishSurveys),
        ask: mapOptionsAsk(item, _isEnglishSurveys)
      }
    }),
    []
  );

const mapperHeaderSurveys = (values, _isEnglishSurveys) => {
  const headerSetUp = {
    title: [
      {
        language_id: 1,
        text: values.surveyTitle
      }
    ],
    description: [
      {
        language_id: 1,
        text: values.surveyDescription
      }
    ],
    goodbye_message: [
      {
        language_id: 1,
        text: values.surveyFarewell
      }
    ]
  };

  const headerSetUpEnglish = {
    title: [
      {
        language_id: 1,
        text: values.surveyTitle
      },
      {
        language_id: 2,
        text: values.surveyTitleEnglish ?? ''
      }
    ],
    description: [
      {
        language_id: 1,
        text: values.surveyDescription
      },
      {
        language_id: 2,
        text: values.surveyDescriptionEnglish ?? ''
      }
    ],
    goodbye_message: [
      {
        language_id: 1,
        text: values.surveyFarewell
      },
      {
        language_id: 2,
        text: values.surveyFarewellEnglish ?? ''
      }
    ]
  };

  return _isEnglishSurveys ? headerSetUpEnglish : headerSetUp;
};

export const useHelper = (edit, surveyId, activeSurvey) => {
  const validationSchema = useValidationSchema();
  const formikRef = useRef();
  const history = useHistory();
  const { handleShowLoader } = useLoader();
  const storage = JSON.parse(localStorage.getItem('session'));
  const idClient = storage.user.client.client_id;

  const [questions, setQuestions] = useState([]);
  //const [lenguaje, setLenguaje] = useState([]);
  /**
   * Variable que sirve para guardar el ID consecutivo al crear preguntar o secciones
   */
  const [indexLastQuestion, setIndexLastQuestion] = useState(1);

  const [getOnepoll] = useApi({
    endpoint: '/polls/poll',
    method: 'get'
  });
  const [createPollspoll] = useApi({
    endpoint: '/polls/poll/',
    method: 'post'
  });
  const [updateSurvey] = useApi({
    endpoint: '/polls/poll',
    method: 'put'
  });
  const [deleteSurvey] = useApi({
    endpoint: 'polls/poll',
    method: 'delete'
  });
  const [listLanguage] = useApi({
    endpoint: '/language/',
    method: 'get'
  });

  /**
  const handleLanguageList = async () => {
    const responseLanguage = await listLanguage();
    const responseList = get(responseLanguage, 'payload', []);

    setLenguaje(responseList);
  }; */

  const handleEditSurvey = async () => {
    const { headerResponse, payload } = await getOnepoll({
      urlParams: surveyId
    });

    if (headerResponse.code === 200) {
      const { _newQuestions, maxValue } = mapperSurvey(
        payload,
        formikRef,
        formik
      );

      setIndexLastQuestion(maxValue);
      setQuestions(_newQuestions);
    }

    handleShowLoader(false);
  };

  useEffect(() => {
    if (edit) {
      handleShowLoader(true);
      handleEditSurvey();
    } else {
      const aux2InitialQuestions = cloneDeep(INITIALQUESTIONS);

      setQuestions([aux2InitialQuestions]);
    }

    //handleLanguageList();
  }, []);

  const validateQuestion = ({
    itemQuestions,
    typeQuestion,
    dat,
    concatRequiredDat
  }) => {
    if (itemQuestions[dat][typeQuestion] === '') {
      itemQuestions = {
        ...itemQuestions,
        [dat]: { ...itemQuestions[dat], required: true }
      };

      concatRequiredDat = concatRequiredDat.concat(itemQuestions.id);
    } else {
      itemQuestions = {
        ...itemQuestions,
        [dat]: { ...itemQuestions[dat], required: false }
      };
    }

    return { itemQuestions, concatRequiredDat };
  };

  const validateQuestionsEmpty = (questions, isEnglishSurveys) => {
    const _questions = cloneDeep(questions);

    let concatRequiredData = [];
    let concatRequiredDataEnglish = [];

    let _requiredQuestions = _questions;
    let aux = {};

    _requiredQuestions = _questions.map((itemQuestions) => {
      if (itemQuestions.type === 'section') {
        aux = validateQuestion({
          itemQuestions,
          typeQuestion: 'title',
          dat: 'data',
          concatRequiredDat: concatRequiredData
        });

        if (isEnglishSurveys) {
          aux = validateQuestion({
            itemQuestions: aux.itemQuestions,
            typeQuestion: 'title',
            dat: 'dataEnglish',
            concatRequiredDat: concatRequiredDataEnglish
          });
        }

        concatRequiredData = aux.concatRequiredDat;
        concatRequiredDataEnglish = aux.concatRequiredDat;
      } else {
        aux = validateQuestion({
          itemQuestions,
          typeQuestion: 'question',
          dat: 'data',
          concatRequiredDat: concatRequiredData
        });

        if (isEnglishSurveys) {
          aux = validateQuestion({
            itemQuestions: aux.itemQuestions,
            typeQuestion: 'question',
            dat: 'dataEnglish',
            concatRequiredDat: concatRequiredDataEnglish
          });
        }

        concatRequiredData = aux.concatRequiredDat;
        concatRequiredDataEnglish = aux.concatRequiredDat;
      }

      return aux.itemQuestions;
    }, []);

    return {
      _requiredQuestions,
      concatRequiredData,
      concatRequiredDataEnglish
    };
  };

  /**
   * Funcion que crea Encuentas
   * @param {object} values
   */
  const handleCeateEditSurvey = async (values) => {
    let response = { headerResponse: { code: 0 } };

    let request = {};

    const {
      _requiredQuestions,
      concatRequiredData,
      concatRequiredDataEnglish
    } = validateQuestionsEmpty(questions, values.isEnglishSurveys);

    if (
      values &&
      concatRequiredData.length === 0 &&
      concatRequiredDataEnglish.length === 0
    ) {
      request = {
        state: activeSurvey,
        client: idClient,
        ...mapperHeaderSurveys(values, values.isEnglishSurveys),
        questions: mapperSaveSurvey(questions, values.isEnglishSurveys)
      };

      if (edit) {
        response = await updateSurvey({
          urlParams: `${surveyId}/`,
          body: request
        });
      } else {
        response = await createPollspoll({ body: request });
      }

      if (response.headerResponse.code === 200) {
        const aux3InitialQuestions = cloneDeep(INITIALQUESTIONS);

        setQuestions([aux3InitialQuestions]);
        history.push('/surveys');
      }
    } else {
      setQuestions(_requiredQuestions);
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => handleCeateEditSurvey(values)
  });

  return {
    formik,
    questions,
    setQuestions,
    indexLastQuestion,
    setIndexLastQuestion
  };
};
