import React, { useEffect, useState } from 'react';
import Setup from './Setup/Setup';
import Preview from './Preview/Preview';
import { useLoader } from '@octopy/react-loader';
import { useHistory } from 'react-router-dom';
import { useHelper } from './useHelper';

const CreateSurvey = () => {
  const { handleShowLoader } = useLoader();
  const history = useHistory();

  const [preview, setPreview] = useState(false);
  const [activeSurvey, setActiveSurvey] = useState(true);

  const edit = history.location.state.edit;
  const surveyId = history.location.state.edit
    ? history.location.state.surveyId
    : null;

  const {
    formik,
    questions,
    setQuestions,
    indexLastQuestion,
    setIndexLastQuestion
  } = useHelper(edit, surveyId, activeSurvey);

  const init = async () => {
    await setTimeout(() => {
      handleShowLoader(false);
    }, 1000);
  };

  useEffect(() => {
    handleShowLoader(true);
    init();
  }, [preview]);

  return (
    <div>
      {preview ? (
        <Preview setPreview={setPreview} questions={questions} />
      ) : (
        <Setup
          edit={edit}
          formik={formik}
          questions={questions}
          setQuestions={setQuestions}
          setPreview={setPreview}
          activeSurvey={activeSurvey}
          setActiveSurvey={setActiveSurvey}
          indexLastQuestion={indexLastQuestion}
          setIndexLastQuestion={setIndexLastQuestion}
        />
      )}
    </div>
  );
};

CreateSurvey.propTypes = {};

export default CreateSurvey;
