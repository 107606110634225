import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleReportProblem: {
    id: 'views.Scenes.Advertising.Scenes.MainSceneDesign.titleReportProblem',
    defaultMessage: 'ERROR AL GUARDAR ESCENA'
  },
  descriptionProblem: {
    id: 'views.Scenes.Advertising.Scenes.MainSceneDesign.descriptionProblem',
    defaultMessage: 'La escena debe contener por lo menos un medio.'
  }
});
