import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.GraphicSurvey.title',
    defaultMessage: 'ESTADÍSTICAS DE LA ENCUESTA'
  },
  subtitle: {
    id: 'components.GraphicSurvey.subtitle',
    defaultMessage: 'Respuestas de la encuesta'
  },
  mainCardTitle: {
    id: 'components.GraphicSurvey.mainCardTitle',
    defaultMessage: 'RESPUESTAS'
  },
  mainCardSubtitle: {
    id: 'components.GraphicSurvey.mainCardSubtitle',
    defaultMessage: 'Resúmen de la encuesta'
  },
  chartSubtitle: {
    id: 'components.GraphicSurvey.chartSubtitle',
    defaultMessage: 'respuestas'
  },
  selectBot: {
    id: 'components.GraphicSurvey.selectBot',
    defaultMessage: 'Robot'
  },
  startDate: {
    id: 'components.GraphicSurvey.startDate',
    defaultMessage: 'Fecha inicial'
  },
  endDate: {
    id: 'components.GraphicSurvey.endDate',
    defaultMessage: 'Fecha final'
  },
  status: {
    id: 'components.GraphicSurvey.status',
    defaultMessage: 'Mostrar'
  },
  optionComplete: {
    id: 'components.GraphicSurvey.optionComplete',
    defaultMessage: 'Completas'
  },
  optionIncomplete: {
    id: 'components.GraphicSurvey.optionIncomplete',
    defaultMessage: 'Incompletas'
  },
  buttonAccept: {
    id: 'components.GraphicSurvey.buttonAccept',
    defaultMessage: 'Filtrar'
  },
  español: {
    id: 'components.GraphicSurvey.español',
    defaultMessage: 'Español'
  },
  ingles: {
    id: 'components.GraphicSurvey.ingles',
    defaultMessage: 'Ingles'
  },
  language: {
    id: 'components.GraphicSurvey.language',
    defaultMessage: 'Idioma'
  }
});
