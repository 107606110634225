import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      marginTop: theme.spacing(3),
      margin: `0 ${theme.spacing(1)}px`
    }
  },
  contentTitles: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  directoryTitle: {
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800
  },
  contentButons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      marginTop: '15px'
    }
  },
  exportButton: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 10.3
  },
  addItemButton: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 10.3
  },
  textButon: {
    fontSize: '15px',
    fontWeight: 800
  },
  contentTable: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(7)
    }
  }
}));

export { useStyles };
