import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  hello: {
    id: 'components.EmailForm.hello',
    defaultMessage: 'Hello {name}!!'
  },
  recoveryText: {
    id: 'components.EmailForm.recoveryText',
    defaultMessage: 'RESTABLECER'
  },
  passwordText: {
    id: 'components.EmailForm.passwordText',
    defaultMessage: 'CONTRASEÑA'
  },
  informationText: {
    id: 'components.EmailForm.informationText',
    defaultMessage:
      'Descuida. Te enviaremos un email con el enlace para cambiar la contraseña de tu cuenta.'
  },
  send: {
    id: 'components.EmailForm.send',
    defaultMessage: 'ENVIAR'
  },
  cancel: {
    id: 'components.EmailForm.cancel',
    defaultMessage: 'CANCELAR'
  },
  emailText: {
    id: 'components.EmailForm.emailText',
    defaultMessage:
      'Se ha enviado un enlace de restablecimiento de contraseña. Por favor, revisa tu correo electrónico.'
  },
  acept: {
    id: 'components.EmailForm.acept',
    defaultMessage: 'ACEPTAR'
  },
  checkEmailMessage: {
    id: 'components.Auth.Login.checkEmailMessage',
    defaultMessage:
      'Te hemos enviado un correo con las instrucciones para recuperar tu contraseña.'
  }
});
