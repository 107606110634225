export const useDirectory = () => {
  const tableHead = [
    { key: 'id', label: 'ID' },
    { key: 'name', label: 'NOMBRE' },
    { key: 'specialty', label: 'ESPECIALIDAD' },
    { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false }
  ];
  const filters = [
    { key: 'name', value: 'NOMBRE' },
    { key: 'specialty', value: 'ESPECIALIDAD' }
  ];

  return { tableHead, filters };
};
