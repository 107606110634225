const ScenesMapper = {
  id: 'scene_id',
  name: 'name',
  duration: 'duration',
  create: 'created_by.name',
  createDate: 'created_date',
  modification: 'modified_by.name',
  modificationDate: 'modified_date',
  status: 'state'
};

const esceneListMapper = {
  id: 'scene_id',
  name: 'name',
  duration: 'duration'
};

export { ScenesMapper, esceneListMapper };
