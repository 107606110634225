import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.5),
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(8)
    }
  },
  formContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3)
  },
  tittleAddMedia: {
    paddingBottom: theme.typography.pxToRem(15),
    fontSize: theme.typography.pxToRem(26),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 6,
    textTransform: 'uppercase'
  },
  textAddVideo: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: theme.typography.fontWeightBold
  },
  buttonCancel: {
    backgroundColor: '#000000',
    color: '#ffffff',
    marginRight: theme.typography.pxToRem(10),
    marginLeft: theme.typography.pxToRem(10),
    boxShadow: '0 8px 16px 0 rgb(0 0 0 / 30%)',
    '&:hover': {
      backgroundColor: '#484848',
      color: '#ffffff',
      boxshadow: 'none'
    }
  },
  buttonSave: {
    boxShadow: '0 8px 16px 0 rgb(0 127 255 / 30%)',
    marginRight: theme.typography.pxToRem(10),
    marginLeft: theme.typography.pxToRem(10)
  },
  fieldSearch: {
    width: '50%'
  }
}));

export { useStyles };
