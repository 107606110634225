/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { useStyles } from './RelatePlatformsStyles';
import { messages } from './RelatePlatformsMessages';
import { DialogFlowForm } from 'components/DialogFlowForm';
import { useHistory, useLocation } from 'react-router-dom';
import { useApi } from 'hooks';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { HeaderTitle } from 'components/HeaderTitle';

export const mappedSessionList = (sessions) => {
  const mappedSessions = sessions.map((session) => ({
    value: session.session_id,
    label: session.session_name,
    client: session.client
  }));

  return mappedSessions;
};

const RelatePlatforms = () => {
  const classes = useStyles();
  const intl = useIntl();
  const location = useLocation();
  const history = useHistory();

  const [sessionOptions, setSessionOptions] = useState([]);
  const [newAgents, setNewAgents] = useState([]);

  const [getAgentsByRobot] = useApi({
    endpoint: `/agents/agents_by_robot/${get(
      location,
      ['state', 'robot_id'],
      ''
    )}`,
    method: 'get'
  });

  const [getSessionListByClient] = useApi({
    endpoint: 'session_client/session_client/sessions_by_client',
    method: 'get'
  });

  const handleGetSessionList = async () => {
    try {
      const { headerResponse, payload } = await getSessionListByClient({
        urlParams: get(location, ['state', 'client'], '')
      });

      if (headerResponse.code === 200 && payload.length !== 0) {
        const mappedSession = mappedSessionList(payload);

        setSessionOptions(mappedSession);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleGetSessionList();
  }, []);

  const handleGetAgentsByRobot = async () => {
    try {
      const responseAgents = await getAgentsByRobot();

      if (responseAgents.headerResponse.code === 200) {
        const responseList = get(responseAgents, 'payload', []);

        const cards = sessionOptions.map((item) => {
          const auxResponseList = cloneDeep(responseList);

          const agentFound = auxResponseList.find(
            (agent) => agent?.session_type?.session_name === item.label
          );

          if (agentFound) {
            return {
              id: item.value,
              title: item.label,
              agent_id: agentFound.agent_id,
              agent_key: agentFound.agent_key,
              name: agentFound.name,
              language: agentFound.language,
              test_phrase: agentFound.test_phrase,
              robot: agentFound.robot,
              speed: agentFound.speed,
              pitch: agentFound.pitch,
              voice_tone: agentFound.voice_tone,
              session_type: agentFound.session_type.session_id,
              eligibleDefault: agentFound.default,
              disabledAgent: false,
              updateAgentRobot: true
            };
          } else {
            return {
              id: item.value,
              title: item.label,
              agent_id: '',
              agent_key: '',
              name: '',
              language: '',
              test_phrase: '',
              robot: '',
              speed: 0.25,
              pitch: -20,
              voice_tone: '',
              session_type: item.value,
              eligibleDefault: false,
              disabledAgent: false,
              updateAgentRobot: false
            };
          }
        });

        disabledAgents(cards);
      }
    } catch (error) {}
  };

  useEffect(async () => {
    handleGetAgentsByRobot();
  }, [sessionOptions]);

  const handleSetDefault = (indice, eligibleDefault) => {
    const auxAgents = cloneDeep(newAgents);

    auxAgents[indice].eligibleDefault = eligibleDefault;

    disabledAgents(auxAgents);
  };

  const disabledAgents = (auxAgents) => {
    let thereIsEligible = false;
    let position = null;

    for (let index = 0; index < auxAgents.length; index++) {
      const element = auxAgents[index];

      if (element.eligibleDefault) {
        position = element.id;
        thereIsEligible = true;
        break;
      }
    }

    const otherNewAgents = cloneDeep(auxAgents);

    if (thereIsEligible) {
      otherNewAgents.forEach((element) => {
        element.disabledAgent = true;

        if (element.id === position) {
          element.disabledAgent = false;
        }
      });
    } else {
      otherNewAgents.forEach((element) => {
        element.disabledAgent = false;
      });
    }

    setNewAgents(otherNewAgents);
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <HeaderTitle
            firstTitle={history.location.state.name}
            secondTitle={intl.formatMessage(messages.subtitle)}
            redirection="robots"
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <span className={classes.sectionTitle}>
            {intl.formatMessage(messages.sectionTitle)}
          </span>
        </Grid>
        {newAgents.map((item, index) => (
          <Grid key={index} item xs={12} sm={12} md={6} lg={6}>
            <DialogFlowForm
              id={location.state.robot_id}
              title={item.title}
              indice={index}
              eligibleDefault={item.eligibleDefault}
              handleSetDefault={handleSetDefault}
              disabledAgent={item.disabledAgent}
              agentByRobot={item}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default RelatePlatforms;
