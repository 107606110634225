import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: 'center',
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down('sm')]: {
      margin: `0 ${theme.spacing(1)}px`,
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  contentHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  arrowBack: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  title: {
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800
  },
  rootCard: {
    borderRadius: '16px',
    padding: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  titleCard: {
    fontSize: '20px',
    letterSpacing: '6px',
    color: theme.palette.primary.main,
    fontWeight: 800,
    marginBottom: theme.spacing(2)
  },
  infoText: {
    fontSize: '15px',
    opacity: 1,
    marginBottom: theme.spacing(2)
  },
  profile: {
    fontSize: '15px',
    fontWeight: 800,
    marginBottom: theme.spacing(2)
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(8)
    }
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    },
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1)
    }
  }
}));

export { useStyles };
