import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import './FullScreenLayout.scss';
import { ErrorBoundary } from 'components/ErrorBoundary/ErrorBoundary';
import { Modal } from '@octopy/react-modal';
import { Loader } from '@octopy/react-loader';
import { SplashScreen } from 'views/SplashScreen';
import { Footer } from 'components/Footer';
import { setItem } from 'utils/persistentStorage';
import { useState } from 'react';

function FullScreenLayout({ children, withSplashScreen = false }) {
  const [splashScreen, setSplashScreen] = useState(withSplashScreen);
  const storage = JSON.parse(localStorage.getItem('withSplashScreen'));

  useEffect(() => {
    if (storage) {
      setSplashScreen(false);
    } else {
      setItem('withSplashScreen', true);
    }
  }, [storage]);

  return (
    <ErrorBoundary>
      <Modal />
      <Loader />
      {splashScreen && <SplashScreen />}
      <div className="full-screen-layout">
        {children}
        <Footer variant="dark" />
      </div>
    </ErrorBoundary>
  );
}

FullScreenLayout.propTypes = {
  children: PropTypes.node.isRequired
};

export { FullScreenLayout };
