import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Table } from '@octopy/react-table';
import { useLoader } from '@octopy/react-loader';
import { Typography, Grid, Button } from '@material-ui/core';
import { AddCircle, Visibility, GetApp } from '@material-ui/icons';
import { messages } from './SurveysMessages';
import { useStyle } from './SurveysStyles';
import { useHistory } from 'react-router-dom';
import { useHelper } from './useHelper';

const Surveys = () => {
  const history = useHistory();
  const intl = useIntl();
  const classes = useStyle();
  const { handleShowLoader } = useLoader();
  const { surveys, handleAnswersExcel } = useHelper();

  const storage = JSON.parse(localStorage.getItem('session'));
  const idClient = storage.user.client.client_id;

  console.log('surveysglel', surveys, storage.user.client.client_id);
  const fetchData = async () => {
    await setTimeout(() => {
      handleShowLoader(false);
    }, 1000);
  };

  useEffect(() => {
    handleShowLoader(true);
    fetchData();
  }, []);

  const tableHead = [
    { key: 'id', label: 'ID' },
    { key: 'status', label: 'ESTATUS' },
    { key: 'title', label: 'TÍTULO' },
    { key: 'creationDate', label: 'FECHA DE CREACIÓN' },
    { key: 'owner', label: 'PROPIETARIO' },
    { key: '', label: '' }
  ];

  const filters = [
    { key: 'status', value: 'ESTATUS' },
    { key: 'title', value: 'TÍTULO' },
    { key: 'creationDate', value: 'FECHA DE CREACIÓN' }
  ];

  const onSelectItems = async (item) => console.log('items', item);

  const configProps = {
    filters,
    actions: {
      edit: {
        onClick: async (item) => {
          history.push('/surveys/edit', {
            edit: true,
            surveyId: item.id
          });
        },
        disabled: false,
        hidden: false
      },
      customs: [
        {
          onClick: (item) => {
            history.push('/surveys/metrics', {
              idSurvey: item.id
            });
          },
          text: intl.formatMessage(messages.surveyMetrics),
          icon: <Visibility />,
          disabled: false,
          hidden: false
        },
        {
          text: intl.formatMessage(messages.downloadExcel),
          onClick: (item) => handleAnswersExcel({ poll: item.id, idClient }),
          icon: <GetApp />,
          disabled: false,
          hidden: false
        }
      ]
    },
    selectedOptions: {
      checkboxHidden: true
    }
  };

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} lg={6} className={classes.contentTitles}>
          <Typography className={classes.Title}>
            {intl.formatMessage(messages.surveysTitle)}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={6} className={classes.contentButtons}>
          <Button
            className={classes.button}
            endIcon={<AddCircle />}
            onClick={() =>
              history.push('/surveys/create', {
                edit: false
              })
            }
          >
            {intl.formatMessage(messages.createQuiz)}
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.contentTable}>
        <Table
          columns={tableHead}
          data={surveys}
          configProps={configProps}
          onSelectItems={onSelectItems}
        />
      </Grid>
    </div>
  );
};

Surveys.propTypes = {};

export default Surveys;
