const specialtysMapperSpanish = {
  name: 'specialty_id',
  label: 'name[0].text'
};

const specialtysMapperEnglish = {
  name: 'specialty_id',
  label: 'name[1].text'
};

const formikMapper = {
  nameDoctor: 'name',
  phone: 'phone',
  specialty: 'specialty.specialty_id',
  address: 'direction',
  description: 'description[0].text',
  text: 'audio_text[0].text',
  // robot: '',
  specialtyEnglish: 'specialty.specialty_id',
  textEnglish: 'audio_text[1].text'
  // robotEnglish: ''
};

export { specialtysMapperSpanish, specialtysMapperEnglish, formikMapper };
