const tableHead = [
  { key: 'notification_id', label: 'ID' },
  { key: 'origin_created_date', label: 'FECHA' },
  { key: 'origin_created_hour', label: 'HORA' },
  { key: 'alert', label: 'ALERTA' },
  { key: 'type', label: 'TIPO' },
  { key: 'description', label: 'DESCRIPCIÓN' },
  { key: 'priority', label: 'PRIORIDAD' }
];

const filters = [
  { key: 'origin_created_date', value: 'FECHA' },
  { key: 'origin_created_hour', value: 'HORA' },
  { key: 'alert', value: 'ALERTA' }
];

export { tableHead, filters };
