const screensData = [
  {
    id: 1,
    screen: 'Octopy01',
    state: 'cargando',
    authorized: 'Autorizado',
    connected: 'Activa',
    lastAcces: '27/04/22 14:54',
    ip: '189.203.141.123',
    mac: '189.203.141.123'
  },
  {
    id: 2,
    screen: 'Octopy02',
    state: 'descargado',
    authorized: 'No autorizado',
    connected: 'Inactivo',
    lastAcces: '26/04/22 14:54',
    ip: '189.203.141.123',
    mac: '189.203.141.123'
  }
];

export { screensData };
