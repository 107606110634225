/* eslint-disable array-callback-return */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Table } from '@octopy/react-table';
import { useModal } from '@octopy/react-modal';
import { useLoader } from '@octopy/react-loader';
import { messages as formMessages } from '@octopy/react-form';
import {
  renderTextField,
  renderAutocomplete,
  renderDatePicker,
  renderTimePicker,
  Form
} from '@octopy/react-form';
import { Typography, Grid, Button, IconButton, Card } from '@material-ui/core';
import { Delete, Close } from '@material-ui/icons';
import { useModalConfirmation } from 'components/ModalConfirmation';
import * as Yup from 'yup';
import { useAddEvent } from './useAddEvent';
import { tableHead } from './helpers';
import { messages } from './AddEventMessages';
import { useStyles } from './AddEventStyles';

const AddEvent = ({ backSuccess, dataEdit = null, getRobot }) => {
  const intl = useIntl();
  const classes = useStyles();
  const formikRef = useRef();
  const [dataEvents, setDataEvents] = useState([]);
  const [eventId, setEventId] = useState(null);
  const {
    formik,
    campaings,
    fieldFormName,
    fieldFormHour,
    fieldFormStartDate,
    fieldFormatEndDate
  } = useAddEvent({
    dataEvents,
    dataEdit,
    eventId,
    getRobot,
    backSuccess,
    setEventId
  });
  const { handleShowLoader } = useLoader();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { modalConfirmation } = useModalConfirmation();
  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );

  useEffect(() => {
    handleShowLoader(true);
    setTimeout(() => {
      handleShowLoader(false);
    }, 1000);
  }, []);

  useEffect(() => {
    if (dataEdit !== null) {
      // let fecha = dataEdit.start_hour.split(':');
      // let fecha2 = dataEdit.end_hour.split(':');

      // let initHour = new Date().setHours(fecha[0], fecha[1], 0, 0);
      // let endHour = new Date().setHours(fecha2[0], fecha2[1], 0, 0);

      formik.setValues({
        name: dataEdit.title,
        hour: dataEdit.always === true ? '1' : '2',
        startDate: dataEdit.start ? dataEdit.start : null,
        endDate: dataEdit.end ? dataEdit.end : null,
        // startTime: initHour,
        // endTime: endHour
        startTime:
          dataEdit.always !== true
            ? new Date(
                null,
                null,
                null,
                dataEdit.start_hour.split(':')[0],
                dataEdit.start_hour.split(':')[1]
              )
            : null,
        endTime:
          dataEdit.always !== true
            ? new Date(
                null,
                null,
                null,
                dataEdit.end_hour.split(':')[0],
                dataEdit.end_hour.split(':')[1]
              )
            : null
      });
      const formatEditCampaings = dataEdit.campaings.map((item) => {
        const campaingEditId = item.campaign_id;
        const positionEditCampaing = item.position;
        const nameEditCampaing = item.name;
        const durationEditCampaing = item.duration;

        return {
          campaing_id: campaingEditId,
          position: positionEditCampaing,
          name: nameEditCampaing,
          duration: durationEditCampaing
        };
      });

      setEventId(dataEdit.event_id);
      setDataEvents(formatEditCampaings);
    }
  }, [dataEdit]);

  const configProps = {
    actions: {
      customs: {
        onClick: (item) =>
          modalConfirmation(
            intl.formatMessage(messages.titleModal),
            intl.formatMessage(messages.infoTextModal),
            () => {
              handleDeleteEvent(item);
            }
          ),
        text: intl.formatMessage(messages.deleteCampaing),
        icon: <Delete />
      }
    },
    selectedOptions: {
      checkboxHidden: true
    }
  };

  const handleCancelAddEvent = () => {
    handleOpenModal({
      title: (
        <Grid className={classes.contentModal}>
          <Grid className={classes.contentIconClose}>
            <IconButton onClick={() => handleCloseModal()}>
              <Close className={classes.iconClose} />
            </IconButton>
          </Grid>
          <Typography className={classes.titleModal}>
            {intl.formatMessage(messages.cancelAddEvent)}
          </Typography>
        </Grid>
      ),
      body: (
        <Grid style={{ marginBottom: 30 }}>
          <Typography className={classes.infoTextModal}>
            {intl.formatMessage(messages.infoTextCancelAddEvent)}
          </Typography>
          <Grid className={classes.contentButtonsModal}>
            <Button
              className={classes.button}
              onClick={() => {
                backSuccess();
                handleCloseModal();
              }}
            >
              {intl.formatMessage(messages.exit)}
            </Button>
          </Grid>
        </Grid>
      ),
      configProps: {
        maxWidth: 'lg'
      }
    });
  };

  const handleDeleteEvent = (value) => {
    handleShowLoader(true);

    let newCheckedArray = [];
    let position = 1;

    dataEvents.map((item) => {
      if (item !== value) {
        newCheckedArray.push({
          ...item,
          position: position
        });

        position = position + 1;
      }
    });

    setTimeout(() => {
      setDataEvents(newCheckedArray);
      handleShowLoader(false);
      handleCloseModal();
    }, 1000);
  };

  const fieldsMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'text' && renderTextField({ index, formik, field })}
    </Grid>
  );

  const fieldsMapperHour = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'select' &&
        renderAutocomplete({
          index,
          formik,
          field,
          inputProps: { variant: 'outlined', fullWidth: true }
        })}
    </Grid>
  );

  const fieldsMapperTimePicker = (field, index) => (
    <Grid item {...field.breakpoints} style={{ marginTop: 20 }}>
      {field.type === 'datePicker' &&
        renderDatePicker({
          index,
          formik,
          field
        })}
      {field.type === 'timePicker' &&
        renderTimePicker({
          index,
          formik,
          field
        })}
    </Grid>
  );

  const getInitialValues = () => ({
    campaing: ''
  });

  const getValidationRules = () =>
    Yup.object({
      campaing: Yup.string().required(requiredFieldError)
    });

  const getFields = () => [
    {
      type: 'select',
      name: 'campaing',
      label: intl.formatMessage(messages.campaingLabel),
      placeholder: intl.formatMessage(messages.campaingPlaceholder),
      options: campaings,
      getOptionLabel: (option) => `${option.name || ''}`,
      getOptionValue: (option) => `${option.campaing_id || ''}`,
      getOptionSelected: (option, value) => option === value,
      breakpoints: { xs: 12 }
    }
  ];

  const handleSubmit = (values) => {
    const idCampaing = parseInt(values.campaing);

    let dataCampaing = campaings.find(
      (item) => item.campaing_id === idCampaing
    );

    handleShowLoader(true);

    setTimeout(() => {
      let aux = dataEvents.slice();

      aux.push({
        position: dataEvents.length + 1,
        name: dataCampaing.name,
        duration: dataCampaing.duration,
        campaing_id: dataCampaing.campaing_id
      });

      setDataEvents(aux);
      formikRef.current.resetForm();
      handleShowLoader(false);
    }, 1500);
  };

  return (
    <>
      <Grid>
        <Typography className={classes.title}>
          {dataEdit
            ? intl.formatMessage(messages.editEvent)
            : intl.formatMessage(messages.addEvent)}
        </Typography>
      </Grid>
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        <Grid item xs={12} md={6}>
          <Grid container direction="column">
            <Typography className={classes.subtitle}>
              {intl.formatMessage(messages.eventData)}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                {fieldFormName.map(fieldsMapper)}
              </Grid>
              <Grid item xs={12} md={6}>
                {fieldFormHour.map(fieldsMapperHour)}
              </Grid>
            </Grid>
            <Grid item>
              {formik.values.hour === '2' ? (
                <div style={{ marginTop: 25 }}>
                  <Typography className={classes.titleDate}>
                    {intl.formatMessage(messages.startDate)}
                  </Typography>
                  {fieldFormStartDate.map(fieldsMapperTimePicker)}
                  <Grid style={{ marginTop: 20 }}>
                    <Typography className={classes.titleDate}>
                      {intl.formatMessage(messages.endDate)}
                    </Typography>
                    {fieldFormatEndDate.map(fieldsMapperTimePicker)}
                  </Grid>
                </div>
              ) : null}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6}>
          <Grid container direction="column">
            <Grid item xs={12} sm={12}>
              <Card className={classes.rootCard}>
                <Typography className={classes.subtitle}>
                  {intl.formatMessage(messages.campaings)}
                </Typography>
                <Grid
                  container
                  spacing={2}
                  className={classes.contentCampaings}
                >
                  <Grid item xs={12} sm={12} md={8}>
                    <Form
                      formikRef={formikRef}
                      initialValues={getInitialValues()}
                      validationRules={getValidationRules()}
                      fields={getFields()}
                      handleSubmit={handleSubmit}
                      showSubmitButton={false}
                      withInputsBorder
                      inputProps={{
                        variant: 'outlined',
                        fullWidth: true
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                    className={classes.contentButtonCreate}
                  >
                    <Button
                      className={classes.button}
                      onClick={() => formikRef.current.submitForm()}
                    >
                      {intl.formatMessage(messages.add)}
                    </Button>
                  </Grid>
                </Grid>
                <Grid item style={{ marginBottom: 25 }}>
                  <Table
                    data={dataEvents}
                    columns={tableHead}
                    configProps={configProps}
                  />
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.conentButtons} container spacing={2}>
        <Grid item>
          <Button
            className={classes.buttonCancel}
            onClick={() => handleCancelAddEvent()}
          >
            {intl.formatMessage(messages.cancel)}
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            onClick={() => formik.submitForm()}
          >
            {intl.formatMessage(messages.save)}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

AddEvent.propTypes = {
  backSuccess: PropTypes.func,
  getRobot: PropTypes.func
};

AddEvent.defaultProps = {
  backSuccess: () => {},
  getRobot: () => {}
};

export { AddEvent };
