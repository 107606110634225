import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useApi } from 'hooks';

import * as Yup from 'yup';

import { TodayRounded } from '@material-ui/icons';

import { messages } from './InteractionsMessages';
import { messages as formMessages } from '@octopy/react-form';

import { useLoader } from '@octopy/react-loader';

import { useFormik } from 'formik';
import { format } from 'date-fns';

import { get } from 'lodash';

export const useInteractions = ({ idRobot }) => {
  const [interactionsData, setInteractionsData] = useState([]);

  const { handleShowLoader } = useLoader();

  const { formatMessage: f } = useIntl();

  const requiredFieldError = f(formMessages.requiredFieldError);

  const [getInteractions] = useApi({
    endpoint: `/interaction/interaction/interaction_by_robot/${idRobot}/`,
    method: 'get'
  });
  const [getInteractionByDateRange] = useApi({
    endpoint: '/interaction/interaction/interaction_by_date_range',
    method: 'get'
  });

  useEffect(() => {
    handleShowLoader(true);
    handleInteractions();
  }, []);

  const mapperInteractions = (dataResponse) => {
    const mapper = dataResponse.map((item) => {
      const id = item.id;
      const date = item.date;
      const startHour = item.interaction_start_time;
      const gender = item.gender ? item.gender : 'N/A';
      const age = item.age ? item.age : 'N/A';
      const pollName = item.poll_name?.map((pollName) => {
        let lenguajeTxt = '';

        if (pollName.language.name === 'Español') {
          lenguajeTxt = pollName.text;
        }

        return lenguajeTxt;
      });
      const pollId = item.poll_id;
      const endHour = item.interaction_end_time;
      const totalTime = `${item.total_interaction_time} segundo(s)`;

      return {
        id: id,
        date: date,
        interaction_start_time: startHour,
        gender: gender,
        age: age,
        poll_name: pollName,
        poll_id: pollId,
        interaction_end_time: endHour,
        total_interaction_time: totalTime
      };
    });

    return mapper;
  };

  const handleInteractions = async () => {
    try {
      const response = await getInteractions();
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', {});

      setTimeout(() => {
        handleShowLoader(false);

        if (headerResponse.code === 200) {
          const mapper = mapperInteractions(dataResponse);

          setInteractionsData(mapper);
        }
      }, 1000);
    } catch (error) {}
  };
  const handleGetInteractionsByDateRange = async (values) => {
    try {
      const response = await getInteractionByDateRange({
        urlParams: `${format(values.start_date, 'yyyy-MM-dd')}/${format(
          values.end_date,
          'yyyy-MM-dd'
        )}/`
      });
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', {});

      if (headerResponse.code === 200) {
        const mapper = mapperInteractions(dataResponse);

        setInteractionsData(mapper);
      }
    } catch (error) {}
  };

  const getValidationRules = () =>
    Yup.object({
      start_date: Yup.string().nullable().required(requiredFieldError),
      end_date: Yup.string().nullable().required(requiredFieldError)
    });

  const initialValues = {
    start_date: null,
    end_date: null
  };

  const inputProps = {
    fullWidth: true
  };
  const fieldsForm = [
    Object.assign(
      {
        type: 'datePicker',
        name: 'start_date',
        label: f(messages.date),
        placeholder: f(messages.date),
        variant: 'inline',
        format: 'dd/MM/yyyy',
        disableToolbar: true,
        inputVariant: 'outlined',
        breakpoints: { xs: 3 },
        InputProps: {
          endAdornment: <TodayRounded />
        }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'datePicker',
        name: 'end_date',
        label: f(messages.date),
        placeholder: f(messages.date),
        variant: 'inline',
        format: 'dd/MM/yyyy',
        disableToolbar: true,
        inputVariant: 'outlined',
        breakpoints: { xs: 3 },
        InputProps: {
          endAdornment: <TodayRounded />
        }
      },
      inputProps
    )
  ];

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationRules(),
    onSubmit: (values) => handleGetInteractionsByDateRange(values)
  });

  return {
    interactionsData,
    fieldsForm,
    formik
  };
};
