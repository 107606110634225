import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';

import { useStyles } from './TextInputStyles';

const TextInput = (props) => {
  const {
    formik,
    label = '',
    labelInput = '',
    name,
    placeholder = '',
    separatedLabel = true
  } = props;
  const classes = useStyles();

  if (separatedLabel) {
    return (
      <>
        <label className={classes.label} htmlFor={name}>
          {label}
        </label>
        <TextField
          label={labelInput}
          className={classes.input}
          error={formik?.touched[name] && Boolean(formik?.errors[name])}
          fullWidth
          helperText={formik?.touched[name] && formik?.errors[name]}
          name={name}
          onChange={formik?.handleChange}
          placeholder={placeholder}
          value={formik?.values[name]}
          variant="outlined"
        />
      </>
    );
  } else {
    return (
      <TextField
        label={label}
        className={classes.input}
        error={formik?.touched[name] && Boolean(formik?.errors[name])}
        fullWidth
        helperText={formik?.touched[name] && formik?.errors[name]}
        name={name}
        onChange={formik?.handleChange}
        placeholder={placeholder}
        value={formik?.values[name]}
        variant="outlined"
      />
    );
  }
};

TextInput.propTypes = {
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired
};

export default TextInput;
