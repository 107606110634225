/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { messages } from './TextBotSelectMessages';
import { responseMapper } from 'utils/responseMapper';
import { get } from 'lodash';
import { useApi } from 'hooks';
import { Alert, useModal } from '@octopy/react-modal';
import { robotsMapper, agentMapper } from './schemesMapper';
import { useModalConfirmation } from 'components/ModalConfirmation';
import {
  SESSION_ENGLISH,
  SESSION_SPANISH,
  SESSION_QUIMIC
} from 'utils/sessionLanguage';

export const useTextBotSelect = (idBot, isEnglish, isQuimico) => {
  const intl = useIntl();
  const { formatMessage: f } = useIntl();
  const { handleOpenModal } = useModal();
  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = get(storage, 'user.client.client_id', {});

  //States
  const [preview, setPreview] = useState(false);
  const [audioUrl, setAudioUrl] = useState('');
  const [robots, setRobots] = useState([]);
  const [agent, setAgent] = useState({});
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState(idBot);
  const [disabledButton, setDisabledButton] = useState(false);

  const { modalReportProblem } = useModalConfirmation();

  //Get Robots
  const [getRobotsList] = useApi({
    endpoint: `/robots/robots/robots_by_client/${clientId}/`,
    method: 'get'
  });

  const getRobots = async () => {
    try {
      const response = await getRobotsList();

      const robotsResponse = get(response, 'payload', []);
      const mappedRobots = responseMapper({
        template: robotsMapper,
        data: robotsResponse
      });

      setRobots(mappedRobots);
    } catch (error) {
      console.error(error);
    }
  };

  ///Get data agents by robot
  const [getAgents] = useApi({
    endpoint: 'agents/agents_by_robot',
    method: 'get'
  });

  const getAgentsData = async () => {
    try {
      setDisabledButton(false);
      const response = await getAgents({
        urlParams: `${idBot}/`
      });
      const dataResponse = get(response, 'payload', []);
      const headerResponse = get(response, 'headerResponse', {});

      if (headerResponse.code === 200) {
        if (dataResponse.length > 0) {
          let agentSelected = {};

          dataResponse.map((item) => {
            switch (item.session_type.session_name) {
              case SESSION_SPANISH:
                if (!isEnglish && !isQuimico) {
                  agentSelected = item;
                }

                break;
              case SESSION_ENGLISH:
                if (isEnglish && !isQuimico) {
                  agentSelected = item;
                }

                break;

              case SESSION_QUIMIC:
                if (isQuimico) {
                  agentSelected = item;
                }

                break;

              default:
                break;
            }
          });

          if (JSON.stringify(agentSelected) === '{}') {
            setDisabledButton(true);
          } else setDisabledButton(false);

          const mappedAgent = responseMapper({
            template: agentMapper,
            data: agentSelected
          });

          setAgent(mappedAgent);
        } else {
          modalReportProblem(
            intl.formatMessage(messages.errorRobot),
            intl.formatMessage(messages.infoText),
            intl.formatMessage(messages.suggestionText)
          );

          setDisabledButton(true);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  //Create speech data by agent
  const [createSpeechByAgent] = useApi({
    endpoint: '/speech/',
    method: 'post'
  });

  const createSpeechData = async (data) => {
    console.log('createssssSpeechData', data);
    const response = await createSpeechByAgent({
      body: data
    });

    const dataResponse = get(response, 'payload', {});
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code === 200) {
      setPreview(true);
      setAudioUrl(dataResponse);
    } else {
      handleOpenModal({
        configProps: {
          maxWidth: 'sm'
        },
        body: <Alert message={f(messages.generateAudioError)} />
      });
    }
  };

  const handlePreviewSpeech = (textBot) => {
    const formatData = {
      ...agent,
      text: textBot
    };

    createSpeechData(formatData);
  };

  useEffect(() => {
    getRobots();
  }, []);

  useEffect(() => {
    if (idBot) {
      getAgentsData();
    }
  }, [idBot]);

  return {
    disabledButton,
    robots,
    inputValue,
    setInputValue,
    value,
    setValue,
    preview,
    audioUrl,
    handlePreviewSpeech,
    getAgentsData,
    setPreview
  };
};
