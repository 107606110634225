import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleAddMedical: {
    id: 'components.DirectoryForm.titleMedical',
    defaultMessage: 'AGREGAR MÉDICO'
  },
  titleEditMedical: {
    id: 'components.DirectoryForm.titleEditMedical',
    defaultMessage: 'EDITAR MÉDICO'
  },
  labelSpanishNameDoctor: {
    id: 'components.DirectoryForm.labelSpanishNameDoctor',
    defaultMessage: 'Nombre'
  },
  placeholderSpanishNameDoctor: {
    id: 'components.DirectoryForm.placeholderSpanishNameDoctor',
    defaultMessage: 'Nombre Completo'
  },
  labelSpanishPhone: {
    id: 'components.DirectoryForm.labelSpanishPhone',
    defaultMessage: 'Teléfono'
  },
  placeholderSpanishPhone: {
    id: 'components.DirectoryForm.placeholderSpanishPhone',
    defaultMessage: 'Teléfono'
  },
  labelSpanishSpecialty: {
    id: 'components.DirectoryForm.labelSpanishSpecialty',
    defaultMessage: 'Especialidad'
  },
  placeholderSpanishSpecialty: {
    id: 'components.DirectoryForm.placeholderSpanishSpecialty',
    defaultMessage: 'Especialidad'
  },
  labelSpanishAddress: {
    id: 'components.DirectoryForm.labelSpanishAddress',
    defaultMessage: 'Dirección'
  },
  placeholderSpanishAddress: {
    id: 'components.DirectoryForm.placeholderSpanishAddress',
    defaultMessage: 'Dirección'
  },
  labelSpanishDescription: {
    id: 'components.DirectoryForm.labelSpanishDescription',
    defaultMessage: 'Agregar descripción'
  },
  placeholderSpanishDescription: {
    id: 'components.DirectoryForm.placeholderSpanishDescription',
    defaultMessage: 'Agregar descripción'
  },
  labelSpanishText: {
    id: 'components.DirectoryForm.labelSpanishText',
    defaultMessage: 'Texto para robot'
  },
  placeholderSpanishText: {
    id: 'components.DirectoryForm.placeholderSpanishText',
    defaultMessage: 'Texto para bot'
  },
  labelSpanishRobot: {
    id: 'components.DirectoryForm.labelSpanishRobot',
    defaultMessage: 'Selecciona robot'
  },
  placeholderSpanishRobot: {
    id: 'components.DirectoryForm.labelSpanishRobot',
    defaultMessage: 'Selecciona robot'
  },
  labelEnglishSpecialty: {
    id: 'components.DirectoryForm.labelEnglishSpecialty',
    defaultMessage: 'Specialty'
  },
  placeholderEnglishSpecialty: {
    id: 'components.DirectoryForm.placeholderEnglishSpecialty',
    defaultMessage: 'Specialty'
  },
  labelEnglishText: {
    id: 'components.DirectoryForm.labelEnglishText',
    defaultMessage: 'Text'
  },
  placeholderEnglishText: {
    id: 'components.DirectoryForm.placeholderEnglishText',
    defaultMessage: 'Text'
  },
  labelEnglishRobot: {
    id: 'components.DirectoryForm.labelEnglishRobot',
    defaultMessage: 'Selected robot'
  },
  placeholderEnglishRobot: {
    id: 'components.DirectoryForm.placeholderEnglishRobot',
    defaultMessage: 'Selected robot'
  },
  descriptionContact: {
    id: 'components.DirectoryForm.descriptionContact',
    defaultMessage: 'Agrega información de contacto'
  },
  titleDays: {
    id: 'components.DirectoryForm.titleDays',
    defaultMessage: 'Días de atención:'
  },
  titleTexBot: {
    id: 'components.DirectoryForm.titleTexBot',
    defaultMessage: 'Agrega un texto para el bot'
  },
  descriptionTexBot: {
    id: 'components.DirectoryForm.descriptionTexBot',
    defaultMessage:
      'Este texto se reproducirá cuando el usuario vea la información'
  },
  englishTitle: {
    id: 'components.DirectoryForm.englishTitle',
    defaultMessage: 'INFORMACIÓN QUE SE MOSTRARÁ EN INGLÉS'
  },
  englishPart: {
    id: 'components.DirectoryForm.englishPart',
    defaultMessage: 'Agregar información en inglés'
  },
  saveButton: {
    id: 'components.DirectoryForm.saveButton',
    defaultMessage: 'GUARDAR'
  },
  privacy: {
    id: 'components.DirectoryForm.privacy',
    defaultMessage:
      '* Antes de agregar a un especialista debe tener autorización de ella o él. Octopy no se hace responsable de como el cliente'
  },
  privacyComplete: {
    id: 'components.DirectoryForm.privacyComplete',
    defaultMessage: 'usa estas funcionalidades.'
  },
  errorModalTitle: {
    id: 'components.DirectoryForm.errorModalTitle',
    defaultMessage: 'ERROR AL SOLICITAR PETICIÓN.'
  },
  errorModalDescription: {
    id: 'components.DirectoryForm.errorModalDescription',
    defaultMessage:
      'Para continuar es necesario guardar la información de contacto en ingles.'
  }
});
