import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Typography, Box } from '@material-ui/core';
import { messages } from './SplashScreenMessages';
import { useStyles } from './SplashScreenStyles';
import { Images } from 'assets';

const SplashScreen = () => {
  const intl = useIntl();
  const [index, setIndex] = useState(0);

  const classes = useStyles(index);

  useEffect(() => {
    if (index < 6) {
      const timeout = setTimeout(() => setIndex((index) => index + 1), 1000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [index]);

  return (
    <>
      <div className={classes.wrapper}>
        <img
          src={Images.companyLogo}
          alt="octopy-platform"
          className={classes.backgroundImage}
        />

        <div className={classes.welcome}>
          <Box className={classes.boxPosition}>
            <Box lassName={classes.boxDirection}>
              <img
                src={Images.companyLogo}
                alt="logo"
                className={classes.companyLogo}
              />
              <Typography className={classes.welcomeText}>
                {intl.formatMessage(messages.welcomeText)}
              </Typography>
            </Box>
          </Box>
        </div>
      </div>
    </>
  );
};

SplashScreen.propTypes = {};

export { SplashScreen };
