import React from 'react';
import { TextField, Typography } from '@material-ui/core';
import { useStyles } from './FieldStyles';

const Field = ({ label, ...props }) => {
  const classes = useStyles();

  return (
    <div>
      {/* <Typography className={classes.label} variant="body1">
        {label}
      </Typography> */}
      <TextField
        {...props}
        className={classes.fields}
        size="medium"
        label={label}
      />
    </div>
  );
};

export { Field };
