import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useLoader } from '@octopy/react-loader';
import { Table } from '@octopy/react-table';
import { useDrawer } from 'components/Drawer';
import { Typography, Grid, Button } from '@material-ui/core';
import { AddCircle, Delete } from '@material-ui/icons';
import { FormResolutions } from './FormResolutions';
import { resolutions } from './utils';
import { messages } from './ResolutionsMessages';
import { useStyles } from './ResolutionsStyles';

const Resolutions = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenDrawer } = useDrawer();
  const { handleShowLoader } = useLoader();

  const fetchData = async () => {
    // call api

    // assign data to state
    await setTimeout(() => {
      handleShowLoader(false);
    }, 1000);
  };

  useEffect(() => {
    handleShowLoader(true);
    fetchData();
  }, []);

  const tableHead = [
    { key: 'id', label: 'ID' },
    { key: 'resolution', label: 'RESOLUCIÓN' },
    { key: 'wide', label: 'ANCHO' },
    { key: 'height', label: 'ALTO' },
    { key: 'ability', label: 'HABILITADO' }
  ];

  const filters = [
    { key: 'resolution', value: 'RESOLUCIÓN' },
    { key: 'ability', value: 'HABILITADO' }
  ];

  const configProps = {
    filters,
    actions: {
      edit: {
        onClick: (item) => handleDrawer(item),
        disabled: false,
        hidden: false
      },
      customs: {
        text: 'Eliminar',
        onClick: (item) => alert('ELIMINAR'),
        icon: <Delete />
      }
    }
  };

  const onSelectItems = (items) => {
    console.log('items: ', items);
  };

  const handleDrawer = (item) => {
    handleOpenDrawer({
      closeButton: true,
      title: (
        <Typography className={classes.titleDrawer}>
          {intl.formatMessage(messages.addResolution)}
        </Typography>
      ),
      body: <FormResolutions dataEdit={item} />,
      configProps: {
        anchor: 'right'
      }
    });
  };

  return (
    <div>
      <Grid container className={classes.contentActions}>
        <Grid item xs={12} md={8} lg={6} className={classes.contentTitle}>
          <Typography className={classes.title}>
            {intl.formatMessage(messages.advertising)} &nbsp; - &nbsp;
          </Typography>
          <Typography className={classes.interactions}>
            {intl.formatMessage(messages.resolutions)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} lg={6} className={classes.contentButton}>
          <Button
            className={classes.button}
            endIcon={<AddCircle className={classes.addIcon} />}
            onClick={() => handleDrawer()}
          >
            {intl.formatMessage(messages.add)}
          </Button>
        </Grid>
      </Grid>
      <Table
        columns={tableHead}
        data={resolutions}
        configProps={configProps}
        onSelectItems={onSelectItems}
      />
    </div>
  );
};

Resolutions.propTypes = {};

export { Resolutions };
