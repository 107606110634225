import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { useLoader } from '@octopy/react-loader';
import { Table } from '@octopy/react-table';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { Typography, Grid, Button } from '@material-ui/core';
import { AddCircle, Delete } from '@material-ui/icons';
import { FormRegister } from './FormRegister';
import { useApi } from 'hooks';
import { tableHead, filters } from './utils';
import { messages } from './UsersMessages';
import { useStyles } from './UsersStyles';

const Users = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const isSuperAdmin = get(storage, 'user.is_staff', {});
  const clientId = get(storage, 'user.client.client_id', {});
  const intl = useIntl();
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const [userslist, setUsersList] = useState([]);
  const [dataUpdate, setDataUpdate] = useState(null);
  const { handleShowLoader } = useLoader();
  const { modalConfirmationDelete } = useModalConfirmation();

  const [allUsersList] = useApi({
    endpoint: '/management/users',
    method: 'get'
  });

  const [usersList] = useApi({
    endpoint: '/management/users/user_by_client',
    method: 'get'
  });

  const [userById] = useApi({
    endpoint: '/management/users',
    method: 'get'
  });

  const [deleteUser] = useApi({
    endpoint: 'management/users',
    method: 'delete'
  });

  const getUserData = async (idUser) => {
    const response = await userById({
      urlParams: idUser
    });
    const userResponse = get(response, 'payload', []);

    setDataUpdate(userResponse);
    setSuccess(true);
  };

  const getUsersList = async () => {
    try {
      let response;

      if (isSuperAdmin) {
        response = await allUsersList();
      } else {
        response = await usersList({
          urlParams: clientId
        });
      }

      const usersResponse = get(response, 'payload', []);
      const newDataFormat = usersResponse.map((item) => {
        const lastLogin = item.last_login
          ? format(new Date(item.last_login), `${'dd/MM/yyyy'} - ${'HH:mm:ss'}`)
          : '';

        return {
          ...item,
          last_login: lastLogin
        };
      });

      setUsersList(newDataFormat);
      handleShowLoader(false);
    } catch (error) {}
  };

  const handleDeleteUser = async (itemId) => {
    try {
      const response = await deleteUser({
        urlParams: itemId
      });
      const headerResponse = get(response, 'headerResponse', '');

      if (headerResponse.code === 200) getUsersList();
    } catch (error) {}
  };

  useEffect(() => {
    handleShowLoader(true);
    getUsersList();
  }, [success]);

  const configProps = {
    filters,
    selectedOptions: {
      checkboxHidden: true
    },
    actions: {
      edit: {
        onClick: (item) => {
          getUserData(item.user_id);
        },
        disabled: false,
        hidden: false
      },
      customs: {
        text: intl.formatMessage(messages.delete),
        onClick: (item) => handleModal(item),
        icon: <Delete />
      }
    }
  };

  const handleModal = (data) => {
    modalConfirmationDelete(
      intl.formatMessage(messages.titleModal),
      intl.formatMessage(messages.infoTextModal),
      data.name,
      () => {
        handleDeleteUser(data.user_id);
      }
    );
  };

  return (
    <div className={classes.container}>
      {success ? (
        <FormRegister
          onClick={() => {
            setSuccess(!success);
            setDataUpdate(null);
          }}
          dataEdit={dataUpdate}
        />
      ) : (
        <>
          <Grid container className={classes.contentActions}>
            <Grid item sm={6} lg={6}>
              <Typography className={classes.title}>
                {intl.formatMessage(messages.users)}
              </Typography>
            </Grid>
            <Grid item sm={6} lg={6}>
              <Grid container spacing={3} className={classes.contentButtons}>
                <Grid item>
                  <Button
                    className={classes.button}
                    endIcon={<AddCircle />}
                    onClick={() => setSuccess(true)}
                  >
                    {intl.formatMessage(messages.createUser)}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid className={classes.contentTable}>
            <Table
              columns={tableHead}
              data={userslist}
              configProps={configProps}
            />
          </Grid>
        </>
      )}
    </div>
  );
};

Users.propTypes = {
  formikRef: PropTypes.any.isRequired
};

export { Users };
