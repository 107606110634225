import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Button,
  Grid,
  Dialog,
  Typography,
  IconButton,
  Paper
} from '@material-ui/core';
import {
  AddCircle as AddCircleIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  Close
} from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { Table } from '@octopy/react-table';
import { useModal } from '@octopy/react-modal';
import { Carrousel } from 'components/Carrousel';
import { useStyles } from './CampaignsStyles';
import { messages } from './CampaignsMessages';
import { tableHead, filters } from './useTable';
import { useHelper } from './hooks/useHelper';
import { responseMapper } from 'utils/responseMapper';

const Campaigns = () => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();
  const { handleCloseModal } = useModal();

  const {
    publicityCampaign,
    handleGetPublicityCampaign,
    handleDeletePublicityCamapign,
    //Carrousel Preview
    getScenesByCampaign,
    preview,
    setPreview,
    campaignSelected,
    setCampaignSelected,
    dataCarrouselMapper
  } = useHelper();

  useEffect(() => {
    handleGetPublicityCampaign();
  }, []);

  const GetMultimedia = async (id) => {
    let scenes = await getScenesByCampaign(id);
    let allMultimedia = [];

    for (let i = 0; i < scenes.length; i++) {
      if (scenes[i].media.length > 0) {
        allMultimedia = allMultimedia.concat(scenes[i].media);
      }
    }

    const mappedMultimedia = responseMapper({
      template: dataCarrouselMapper,
      data: allMultimedia
    });

    return mappedMultimedia;
  };

  const actionFunctions = {
    edit: {
      text: intl.formatMessage(messages.edit),
      icon: <EditIcon />,
      onClick: async (item) => {
        history.push('/advertising/campaigns/create', {
          edit: true,
          publicityCampaignId: item.id,
          nameScence: item.name
        });
      }
    },
    deleteAction: {
      text: intl.formatMessage(messages.delete),
      icon: <DeleteIcon />,
      onClick: (item) => {
        handleDeletePublicityCamapign(item.id);
        handleCloseModal();
      }
    },
    customs: {
      text: intl.formatMessage(messages.preview),
      icon: <VisibilityIcon />,
      onClick: async (item) => {
        let multimedia = await GetMultimedia(item.id);

        setCampaignSelected({
          name: item.name,
          media: multimedia
        });

        setPreview(true);
      }
    }
  };

  const configProps = {
    filters,
    actions: actionFunctions,
    selectedOptions: {
      checkboxHidden: true
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <div className={classes.pageTitle}>
            {intl.formatMessage(messages.title)}{' '}
            <span className={classes.subtitle}>
              {intl.formatMessage(messages.subtitle)}
            </span>
          </div>
        </Grid>
        <Grid
          container
          xs={12}
          sm={12}
          md={6}
          lg={6}
          direction="column"
          alignItems="flex-end"
        >
          <Button
            className={classes.button}
            endIcon={<AddCircleIcon />}
            onClick={() => {
              history.push(`${location.pathname}/create`, {
                edit: false,
                publicityCampaignId: null,
                nameScence: null
              });
            }}
          >
            {intl.formatMessage(messages.add)}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          style={{ paddingBottom: 50 }}
          className={classes.contentTable}
        >
          <Table
            data={publicityCampaign}
            columns={tableHead}
            configProps={configProps}
          />
        </Grid>
      </Grid>
      <Dialog
        fullScreen
        open={preview}
        onClose={() => {
          setPreview(false);
          setCampaignSelected({});
        }}
        className={classes.modalContainer}
      >
        <Grid
          containerdirection="column"
          justifyContent="space-around"
          alignItems="stretch"
        >
          <Grid container item xs={12}>
            <Grid item xs={9}>
              <Typography variant="h2" className={classes.previewTitle}>
                {campaignSelected.name}
              </Typography>
            </Grid>
            <Grid container item xs={3} justify="flex-end">
              <IconButton
                onClick={() => {
                  setPreview(false);
                  setCampaignSelected({});
                }}
              >
                <Close style={{ color: 'white' }} fontSize="large" />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container item xs={12} justify="center">
            {campaignSelected.media ? (
              <Paper className={classes.paperContainer}>
                <Carrousel
                  play={preview && campaignSelected.media.length > 0}
                  data={campaignSelected.media}
                  stopMedia={(res) => {
                    setPreview(false);
                    setCampaignSelected({});
                  }}
                  isPreview={true}
                />
              </Paper>
            ) : null}
          </Grid>
        </Grid>
      </Dialog>
    </div>
  );
};

Campaigns.propTypes = {};

export default Campaigns;
