import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  idTitle: {
    id: 'components.TableSelect.TableTitle.idTitle',
    defaultMessage: 'ID'
  },
  nameTitle: {
    id: 'components.TableSelect.TableTitle.nameTitle',
    defaultMessage: 'NOMBRE'
  },
  typeTitle: {
    id: 'components.TableSelect.TableTitle.typeTitle',
    defaultMessage: 'TIPO'
  },
  imageLabel: {
    id: 'components.TableSelect.SelectImage.imageLabel',
    defaultMessage: 'Subir imagen'
  },
  libraryText: {
    id: 'components.TableSelect.SelectImage.libraryText',
    defaultMessage: 'Se agregará automáticamente a la biblioteca de medios.'
  },
  maxSizeImageText: {
    id: 'components.TableSelect.SelectImage.maxSizeImageText',
    defaultMessage: 'El peso máximo de la imagen debe de ser de 30MB.'
  },
  maxSizeVideoText: {
    id: 'components.TableSelect.SelectImage.maxSizeVideoText',
    defaultMessage: 'El peso máximo del video debe de ser de 100MB.'
  }
});
