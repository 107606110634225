import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginRight: theme.spacing(6)
  },
  approverSubtitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5)
  },
  buttonS: {
    marginTop: theme.spacing(4)
  },
  spaceBet: {
    marginTop: theme.spacing(2)
  }
}));

export { useStyles };
