import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  advertising: {
    id: 'components.Scenes.advertising',
    defaultMessage: 'PUBLICIDAD'
  },
  escenes: {
    id: 'components.Scenes.escenes',
    defaultMessage: 'ESCENAS'
  },
  add: {
    id: 'components.Scenes.add',
    defaultMessage: 'AGREGAR'
  },
  addEscenes: {
    id: 'components.Scenes.addEscenes',
    defaultMessage: 'AGREGAR ESCENA'
  },
  editEscenes: {
    id: 'components.Scenes.editEscenes',
    defaultMessage: 'EDITAR ESCENA'
  },
  titleModal: {
    id: 'components.Scenes.titleModal',
    defaultMessage: 'ELIMINAR ESCENA'
  },
  infoTextModal: {
    id: 'components.Scenes.infoTextModal',
    defaultMessage: '¿Seguro que quiere eliminar esta escena '
  },
  cancel: {
    id: 'components.Scenes.cancel',
    defaultMessage: 'CANCELAR'
  },
  delete: {
    id: 'components.Scenes.delete',
    defaultMessage: 'ELIMINAR'
  }
});
