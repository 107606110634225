import React from 'react';
import { utils, writeFile } from 'xlsx';

const useExport = () => {
  const exportToExcel = (data, nameFile) => {
    const wb = utils.book_new();
    const ws = utils.json_to_sheet(data);
    const date = new Date().toLocaleDateString();
    const time = new Date().toLocaleTimeString();

    utils.book_append_sheet(wb, ws, nameFile);
    writeFile(wb, `${nameFile} ${date}_${time}.xlsx`);
  };

  const exportToMultipleSheetExcel = (data, nameFile) => {
    const wb = utils.book_new();

    data.map((item, index) => {
      const ws = utils.json_to_sheet([item]);

      utils.book_append_sheet(
        wb,
        ws,
        `${index + 1}_${item.Pregunta.substring(0, 20)}`
      );
    });

    const date = new Date().toLocaleDateString();
    const time = new Date().toLocaleTimeString();

    writeFile(wb, `${nameFile} ${date}_${time}.xlsx`);
  };

  return {
    exportToExcel,
    exportToMultipleSheetExcel
  };
};

export { useExport };
