import { get, toUpper } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Box, ButtonBase, Typography } from '@material-ui/core';
import { useStyles } from './ProfilePreviewStyles';
import { useSideMenu } from '..';
import { Link } from 'react-router-dom';
import { getItem } from 'utils/persistentStorage';

const ProfilePreview = ({ onClick }) => {
  const { expanded } = useSideMenu();
  const classes = useStyles({ expanded });

  const session = getItem('session');
  const firstLetter = toUpper(get(session, ['name', 0]));
  const user = {
    name: get(session, 'user.name', ''),
    role: get(session, 'role', 'Administrador'),
    image: get(session, 'user.image')
  };

  return (
    <ButtonBase
      component={Link}
      className={classes.container}
      to="/my-account"
      onClick={() => onClick()}
    >
      <Avatar
        src={user.image}
        variant="circular"
        className={classes.avatar}
        alt={firstLetter}
      />
      <Box pl={1.5} className={classes.textContainer}>
        <Typography variant="subtitle2">{user.name}</Typography>
        <Typography component="Box" variant="body2" color="textSecondary">
          {user.role}
        </Typography>
      </Box>
    </ButtonBase>
  );
};

ProfilePreview.propTypes = { onClick: PropTypes.func };

export { ProfilePreview };
