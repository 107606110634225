import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  hello: {
    id: 'components.Login.hello',
    defaultMessage: 'Hello {name}!!'
  },
  buttonSubmit: {
    id: 'components.Login.buttonSubmit',
    defaultMessage: 'LOGIN'
  },
  passwordRecovery: {
    id: 'components.Login.passwordRecovery',
    defaultMessage: 'Olvide mi contraseña'
  },
  passwordCharacters: {
    id: 'components.Login.passwordCharacters',
    defaultMessage: 'Tu contraseña debe de tener 8 caracteres'
  },
  title: {
    id: 'components.Login.title',
    defaultMessage: 'Robotics'
  },
  subTitle: {
    id: 'components.Login.subtitle',
    defaultMessage: 'Plataforma de gestión'
  }
});
