import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  from: {
    id: 'components.TimePicker.from',
    defaultMessage: 'De:'
  },
  to: {
    id: 'components.TimePicker.to',
    defaultMessage: 'A:'
  }
});
