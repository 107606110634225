/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { format } from 'date-fns';
import { useModal } from '@octopy/react-modal';
import { useLoader } from '@octopy/react-loader';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Button, Card, IconButton } from '@material-ui/core';
import { AddCircle, Close, Edit, Delete, ArrowBack } from '@material-ui/icons';
import { Calendar } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { AddEvent } from './AddEvent';
import { localizer } from './utils';
import { useApi } from 'hooks';
import { messages } from './EventsMessages';
import { useStyles } from './EventsStyles';

const Events = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const [success, setSuccess] = useState(false);
  const [dataEdit, setDataEdit] = useState(null);
  const [events, setEvents] = useState([]);
  const { handleShowLoader } = useLoader();
  const { handleOpenModal, handleCloseModal } = useModal();
  const nameRobot = history.location.state.name;
  const idRobot = history.location.state.robot_id;

  const [getEventsByIdRobot] = useApi({
    endpoint: `/publicity/event/events_by_robot_id/${idRobot}`,
    method: 'get'
  });

  const [deleteEvent] = useApi({
    endpoint: 'publicity/event',
    method: 'delete'
  });

  useEffect(() => {
    getRobot();
  }, []);

  useEffect(() => {
    handleShowLoader(true);
    setTimeout(() => {
      handleShowLoader(false);
    }, 1000);
  }, [success]);

  const getRobot = async () => {
    try {
      const response = await getEventsByIdRobot();
      const headerResponse = get(response, 'headerResponse', '');

      if (headerResponse.code === 200) {
        const dataResponse = get(response, 'payload', {});
        const formatEvents = [];

        dataResponse.map((item) => {
          const startHour = item.start_hour;
          const startDate = new Date(
            `${item.start_day}, ${
              item.start_hour ? item.start_hour : '12:00:00'
            }`
          );
          const endDate = new Date(
            `${item.end_day}, ${item.end_hour ? item.end_hour : '12:00:00'}`
          );
          const endHour = item.end_hour;
          const nameEvent = item.name;
          const activeEvent = item.active;
          const alwaysEvent = item.always;
          const campaings = item.campaigns;
          const eventId = item.event_id;

          formatEvents.push({
            start: startDate,
            end: endDate,
            start_hour: startHour,
            end_hour: endHour,
            title: nameEvent,
            active: activeEvent,
            always: alwaysEvent,
            campaings: campaings,
            event_id: eventId
          });
        });

        setEvents(formatEvents);
      }
    } catch (error) {}
  };

  const handleDeleteEvent = async (eventId) => {
    try {
      const response = await deleteEvent({
        urlParams: `${eventId}/`
      });
      const headerResponse = get(response, 'headerResponse', '');

      if (headerResponse.code === 200) getRobot();
    } catch (error) {}
  };

  const handleModal = (data) => {
    handleShowLoader(true);

    setTimeout(() => {
      handleOpenModal({
        title: (
          <Grid className={classes.contentModal}>
            <Grid className={classes.contentIconClose}>
              <IconButton onClick={() => handleCloseModal()}>
                <Close className={classes.iconClose} />
              </IconButton>
            </Grid>
            <Typography className={classes.titleModal}>
              {intl.formatMessage(messages.titleEvent)}
            </Typography>
          </Grid>
        ),
        body: (
          <>
            <Grid style={{ display: 'flex' }}>
              <Typography className={classes.infoModalTitle}>
                {intl.formatMessage(messages.title)} &nbsp;
              </Typography>
              <Typography className={classes.infoModal}>
                {data.title}
              </Typography>
            </Grid>
            <Grid style={{ display: 'flex' }}>
              <Typography className={classes.infoModalTitle}>
                {intl.formatMessage(messages.campaings)} &nbsp;
              </Typography>
              <Typography className={classes.infoModal}>
                {data.campaings.length}
              </Typography>
            </Grid>
            <Grid style={{ display: 'flex' }}>
              <Typography className={classes.infoModalTitle}>
                {intl.formatMessage(messages.startDate)} &nbsp;
              </Typography>
              <Typography className={classes.infoModal}>
                {`${format(data.start, 'dd/MM/yyyy')} - ${
                  data.start_hour ? data.start_hour : '00:00:00'
                } Hrs.`}
              </Typography>
            </Grid>
            <Grid style={{ display: 'flex' }}>
              <Typography className={classes.infoModalTitle}>
                {intl.formatMessage(messages.endDate)} &nbsp;
              </Typography>
              <Typography className={classes.infoModal}>
                {`${format(data.end, 'dd/MM/yyyy')} - ${
                  data.end_hour ? data.end_hour : '12:00:00'
                } Hrs.`}
              </Typography>
            </Grid>
            <Grid className={classes.contentButtonsModal}>
              <Button
                className={classes.deleteButton}
                endIcon={<Delete />}
                onClick={() => {
                  handleDeleteEvent(data.event_id);
                  handleCloseModal();
                }}
              >
                {intl.formatMessage(messages.delete)}
              </Button>
              <Button
                className={classes.button}
                endIcon={<Edit />}
                onClick={() => {
                  setSuccess(true);
                  handleCloseModal();
                  setDataEdit(data);
                }}
              >
                {intl.formatMessage(messages.edit)}
              </Button>
            </Grid>
          </>
        ),
        configProps: {
          maxWidth: 'md'
        }
      });
      handleShowLoader(false);
    }, 1000);
  };

  const handleCancelAddEvent = () => {
    handleOpenModal({
      title: (
        <Grid className={classes.contentModal}>
          <Grid className={classes.contentIconClose}>
            <IconButton onClick={() => handleCloseModal()}>
              <Close className={classes.iconClose} />
            </IconButton>
          </Grid>
          <Typography className={classes.titleModal}>
            {intl.formatMessage(messages.cancelAddEvent)}
          </Typography>
        </Grid>
      ),
      body: (
        <Grid style={{ marginBottom: 30 }}>
          <Typography className={classes.infoTextModal}>
            {intl.formatMessage(messages.infoTextCancelAddEvent)}
          </Typography>
          <Grid className={classes.contentButtonsModal}>
            <Button
              className={classes.button}
              onClick={() => {
                setSuccess(!success);
                handleCloseModal();
              }}
            >
              {intl.formatMessage(messages.exit)}
            </Button>
          </Grid>
        </Grid>
      ),
      configProps: {
        maxWidth: 'lg'
      }
    });
  };

  return (
    <div className={classes.container}>
      <Grid container className={classes.contentActions}>
        <Grid item xs={10} className={classes.contentTitle}>
          <Typography className={classes.title}>{nameRobot} /&nbsp;</Typography>
          <span className={classes.events}>
            {intl.formatMessage(messages.events)}
          </span>
        </Grid>
        <Grid item xs={2} className={classes.contentIconClose}>
          <IconButton
            onClick={() => {
              if (!success) {
                window.history.back();
              } else {
                handleCancelAddEvent();
              }
            }}
          >
            <ArrowBack className={classes.iconBack} />
          </IconButton>
        </Grid>

        {!success && (
          <Grid item xs={12} className={classes.contentButton}>
            <Button
              className={classes.button}
              endIcon={<AddCircle className={classes.addIcon} />}
              onClick={() => setSuccess(true)}
            >
              {intl.formatMessage(messages.addEvent)}
            </Button>
          </Grid>
        )}
      </Grid>
      {success ? (
        <AddEvent
          backSuccess={() => {
            setSuccess(!success);
            setDataEdit(null);
          }}
          dataEdit={dataEdit}
          getRobot={getRobot}
        />
      ) : (
        <Card className={classes.rootCard}>
          <Calendar
            // showMultiDayTimes
            step={30}
            selectable
            culture={'es'}
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            popup
            className={classes.calendar}
            onDoubleClickEvent={(item) => handleModal(item)}
            messages={{
              next: intl.formatMessage(messages.next),
              previous: intl.formatMessage(messages.previous),
              today: intl.formatMessage(messages.today),
              month: intl.formatMessage(messages.month),
              week: intl.formatMessage(messages.week),
              day: intl.formatMessage(messages.day)
            }}
          />
        </Card>
      )}
    </div>
  );
};

Events.propTypes = {};

export { Events };
