import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `0 ${theme.spacing(5)}px`,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      margin: `0 ${theme.spacing(0)}px`,
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(8)
    }
  },
  pageTitle: {
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bold',
    letterSpacing: theme.typography.pxToRem(8)
  },
  subtitle: {
    color: theme.palette.primary.main
  },
  projectKey: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold'
  },
  primaryButton: {
    marginTop: theme.spacing(1)
  },
  sectionTitle: {
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: theme.typography.pxToRem(8)
  }
}));

export { useStyles };
