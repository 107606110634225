import { QuestionTypes } from 'components/Question/QuestionTypes';

export const addSection = (
  questions,
  setQuestions,
  indexLastQuestion,
  setIndexLastQuestion
) => {
  setQuestions([
    ...questions,
    {
      type: QuestionTypes.section,
      id: (indexLastQuestion + 1).toString(),
      data: {
        title: '',
        description: ''
      },
      dataEnglish: {
        title: '',
        description: ''
      }
    }
  ]);
  setIndexLastQuestion(indexLastQuestion + 1);
};

export const addQuestion = (
  questions,
  setQuestions,
  indexLastQuestion,
  setIndexLastQuestion
) => {
  setQuestions([
    ...questions,
    {
      type: QuestionTypes.uniqueOption,
      id: (indexLastQuestion + 1).toString(),
      data: {
        question: '',
        options: []
      },
      dataEnglish: {
        question: '',
        options: []
      }
    }
  ]);
  setIndexLastQuestion(indexLastQuestion + 1);
};

export const deleteQuestion = (index, questions, setQuestions) => {
  if (questions.length > 1) {
    const result = Array.from(questions);
    const [removed] = result.splice(index, 1);

    setQuestions(result);
  }
};

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);

  result.splice(endIndex, 0, removed);

  return result;
};

export const onDragEnd = ({ destination, source }, questions, setQuestions) => {
  if (!destination) return;

  const newItems = reorder(questions, source.index, destination.index);

  setQuestions(newItems);
};

export const setQuestionOption = (target, questionsElement, dat) => {
  const response = questionsElement;

  if (target.name === 'question') {
    response[dat].question = target.value;
  } else {
    const optionPosition = target.name.split('-')[1];

    if (optionPosition > response[dat].options.length) {
      response[dat].options.push(target.value);
    } else {
      response[dat].options[optionPosition - 1] = target.value;
    }
  }

  return response;
};

export const setQuestionScale = (target, questionsElement, dat) => {
  const response = questionsElement;

  if (target.name === 'question') {
    response[dat].question = target.value;
  } else {
    response[dat].type = target.value;
  }

  return response;
};

export const setQuestionYesNo = (target, questionsElement, dat) => {
  const response = questionsElement;

  if (target.name === 'question') {
    response[dat].question = target.value;
  }

  return response;
};

export const setSection = (target, questionsElement, dat) => {
  const response = questionsElement;

  if (target.name === 'title') {
    response[dat].title = target.value;
  } else {
    response[dat].description = target.value;
  }

  return response;
};
