import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { messages } from './InformationFormMessages';
import { useStyles } from './InformationFormStyles';
import { HeaderTitle } from 'components/HeaderTitle';
import { useApiInformationForm } from './hook/useApiInformationForm';
import { Grid, Button } from '@material-ui/core';
import { TextBotSelect } from 'components/TextBotSelect';

import { renderTextField, renderAutocomplete } from '@octopy/react-form';

import ReactQuill, { Quill, Mixin, Toolbar } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { EDITOR_MODULES, EDITOR_FORMATS } from './helper';

const InformationForm = () => {
  const intl = useIntl();
  const classes = useStyles();

  const {
    formik,
    fieldsMainFormik,
    fieldsRobot,
    fieldError,
    idInformation,
    handleChangeTextRobot,
    handleChangeRobotSeleted,
    handleChangeDescriptionInformation
  } = useApiInformationForm();

  const fieldsMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'title' && field.text}
      {field.type === 'text' && renderTextField({ index, formik, field })}
      {field.type === 'textarea' && renderTextField({ index, formik, field })}
      {field.type === 'select' &&
        renderAutocomplete({
          index,
          formik,
          field,
          inputProps: { variant: 'outlined', fullWidth: true }
        })}
    </Grid>
  );

  return (
    <div className={classes.container}>
      <HeaderTitle
        firstTitle={intl.formatMessage(messages.title)}
        redirection={'information'}
      />

      <Grid container spacing={2}>
        {fieldsMainFormik.map(fieldsMapper)}
      </Grid>

      <Grid item xs={12} md={12} lg={12} className={classes.quill}>
        <ReactQuill
          theme="snow"
          value={formik.values.descriptionInformation}
          onChange={handleChangeDescriptionInformation}
          modules={EDITOR_MODULES}
          formats={EDITOR_FORMATS}
          placeholder={'Escribe algo aquí...'}
        />
        {fieldError.map(fieldsMapper)}
      </Grid>

      <Grid container spacing={2}>
        {fieldsRobot.map(fieldsMapper)}
      </Grid>

      <TextBotSelect
        textBot={formik.values.textRobot}
        idBot={formik.values.robotSeleted}
        textBotMessage={formik.touched.textRobot && formik.errors.textRobot}
        idBotMessage={formik.touched.robotSeleted && formik.errors.robotSeleted}
        onHandleChangeText={handleChangeTextRobot}
        onHandleChangeBot={handleChangeRobotSeleted}
        isEnglish={false}
      />

      <Grid className={classes.contentButton}>
        <Button className={classes.button} onClick={() => formik.submitForm()}>
          {idInformation === 0
            ? intl.formatMessage(messages.add)
            : intl.formatMessage(messages.save)}
        </Button>
      </Grid>
    </div>
  );
};

InformationForm.propTypes = {};

export { InformationForm };
