/* eslint-disable valid-typeof */
import React, { useState, useEffect, memo, useRef } from 'react';
import { Table } from '@octopy/react-table';
import { useTable } from './useTable';
import {
  Grid,
  Typography,
  Table as TableMUI,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Button
} from '@material-ui/core';
import { renderImagePicker } from '@octopy/react-form';
import { messages } from './TableSelectMessages';
import { useStyles } from './TableSelectStyles';
import { useIntl } from 'react-intl';
import xorBy from 'lodash/xorBy';
import { TYPE_IMAGEN } from 'utils/TypeSource';
import { ButtonSimpleMultimedia } from 'components/ButtonSimpleMultimedia';

function not(a, b) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const TableSelect = ({
  data,
  textSearch,
  formik,
  changeData,
  scenceLeft,
  edit,
  type,
  formikButtonRef = null,
  tableHead,
  keyID = 'media_id'
}) => {
  const { formatMessage: f } = useIntl();
  const classes = useStyles();
  const [checked, setChecked] = useState([]);
  const [left, setLeft] = useState([]);
  const [right, setRight] = useState([]);
  const [rightAux, setRightAux] = useState([]);
  const [isEmpty, setIsEmpty] = useState(true);
  const [handle, setHandle] = useState(false);

  useEffect(() => {
    if (edit) {
      let newRight = xorBy(data, scenceLeft, keyID);

      setRight(newRight);
      setRightAux(newRight);
    } else {
      setRight(data);
      setRightAux(data);
    }
  }, [data]);

  useEffect(() => {
    let aux = rightAux.filter((x) =>
      x.name.toLocaleLowerCase().includes(textSearch.toLocaleLowerCase())
    );

    setRight(aux);
  }, [textSearch]);

  useEffect(() => {
    left.length === 0 ? setIsEmpty(true) : setIsEmpty(false);

    //Si se carga una imagen desde el image picker
    if (formik) {
      if (left.length === 0) {
        formik.setFieldValue('idMedia', 0);
        formik.setFieldValue('name', '');
      } else {
        const item = left[0];

        formik.setFieldValue('idMedia', item[keyID]);
        formik.setFieldValue('name', item.name);
      }
    }
  }, [left]);

  useEffect(() => {
    if (edit && scenceLeft && !handle) {
      let scenceLeftApi = scenceLeft;

      setLeft(scenceLeftApi);

      let newRight = xorBy(right, scenceLeft, keyID);

      setRightAux(newRight);
      setRight(newRight);
    }
  }, [scenceLeft]);

  const handleToggleAdd = (value) => {
    setHandle(true);
    const currentIndex = checked.indexOf(value);
    const newChecked = checked.slice();

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    //se ejecuta automatico para pasar los valores a la lista de la izquierda
    let aux = intersection(newChecked, right);

    setLeft(left.concat(aux));
    changeData(left.concat(aux));
    setRight(not(right, aux));
    setRightAux(not(rightAux, aux));
    setChecked(not(newChecked, aux));
  };

  const handleToggleDelete = (value) => {
    setRight(right.concat(value));
    setRightAux(rightAux.concat(value));
    setLeft([]);
    changeData([]);
    setChecked([]);
  };

  const { configPropsLeft, configPropsRight, fieldImageForm } = useTable(
    handleToggleAdd,
    handleToggleDelete,
    isEmpty
  );

  const fieldsMapper = (field, index) => (
    <div className={classes.imageInputContainer}>
      {field.type === 'image' && renderImagePicker({ index, formik, field })}
    </div>
  );

  const customList = (items, isLeft) => {
    if (isLeft) {
      return isEmpty && formik ? (
        <TableContainer className={classes.containerTable}>
          <TableMUI>
            <TableHead className={classes.headContainer}>
              <TableRow>
                <TableCell>{f(messages.idTitle)}</TableCell>
                <TableCell>{f(messages.nameTitle)}</TableCell>
                <TableCell>{f(messages.typeTitle)}</TableCell>
                <TableCell>{''}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  align="center"
                  colSpan={4}
                  className={classes.bodyContainer}
                >
                  {type === TYPE_IMAGEN ? (
                    fieldImageForm.map(fieldsMapper)
                  ) : (
                    <div className={classes.imageInputContainer}>
                      <ButtonSimpleMultimedia
                        audio={false}
                        video={true}
                        formikRef={formikButtonRef}
                      />
                    </div>
                  )}
                  <Typography
                    variant="h6"
                    className={classes.text}
                    display="block"
                  >
                    {f(messages.libraryText)}
                  </Typography>
                  <Typography
                    variant="h6"
                    className={classes.text}
                    display="block"
                  >
                    {type === TYPE_IMAGEN
                      ? f(messages.maxSizeImageText)
                      : f(messages.maxSizeVideoText)}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </TableMUI>
        </TableContainer>
      ) : (
        <Table columns={tableHead} data={items} configProps={configPropsLeft} />
      );
    } else {
      return (
        <Table
          columns={tableHead}
          data={items}
          configProps={configPropsRight}
        />
      );
    }
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="flex-start"
      xs={12}
    >
      <Grid item xs={12} md={6}>
        {customList(left, true)}
      </Grid>
      <Grid item xs={12} md={6}>
        {customList(right, false)}
      </Grid>
    </Grid>
  );
};

export { TableSelect };
