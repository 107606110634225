import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  productsTitle: {
    id: 'components.Information.productsTitle',
    defaultMessage: 'CATÁLOGO'
  },
  itemsTitle: {
    id: 'components.Information.itemsTitle',
    defaultMessage: 'INFORMACIÓN PARA PERSONAL DEL LABORATORIO'
  },
  exportButton: {
    id: 'components.Information.exportButton',
    defaultMessage: 'EXPORTAR'
  },
  addItems: {
    id: 'components.Information.addItems',
    defaultMessage: 'AGREGAR ÍTEM'
  },
  titleModal: {
    id: 'components.Information.titleModal',
    defaultMessage: 'ELIMINAR PRODUCTO'
  },
  infoTextModal: {
    id: 'components.Information.infoTextModal',
    defaultMessage: '¿Seguro que quiere eliminar este producto'
  },
  cancel: {
    id: 'components.Information.cancel',
    defaultMessage: 'CANCELAR'
  },
  delete: {
    id: 'components.Information.delete',
    defaultMessage: 'ELIMINAR'
  },
  products: {
    id: 'components.Information.products',
    defaultMessage: 'PRODUCTOS'
  }
});
