import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  Robot: {
    id: 'components.Alerts.Robot',
    defaultMessage: 'ROBOT 001'
  },
  alertTitle: {
    id: 'components.Alerts.alertTitle',
    defaultMessage: 'ALERTAS'
  },
  Search: {
    id: 'components.Alerts.Search',
    defaultMessage: 'BUSCAR'
  },
  error: {
    id: 'components.Alerts.error',
    defaultMessage: 'Error'
  },
  warning: {
    id: 'components.Alerts.warning',
    defaultMessage: 'Warning'
  },
  success: {
    id: 'components.Alerts.error',
    defaultMessage: 'Success'
  },
  high: { id: 'components.Alerts.high', defaultMessage: 'Alta' },
  medium: { id: 'components.Alerts.medium', defaultMessage: 'Media' }
});
