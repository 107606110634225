import { useEffect, useState } from 'react';
import { useLoader } from '@octopy/react-loader';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { useApi } from 'hooks';
import { useDrawer } from 'components/Drawer';
import { responseMapper } from 'utils/responseMapper';
import { ScenesMapper, esceneListMapper } from './ScenesMapper';
import { useModal } from '@octopy/react-modal';

export const useScenes = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const idClient = storage.user.client.client_id;
  const history = useHistory();
  const { handleCloseModal } = useModal();
  const { handleCloseDrawer } = useDrawer();
  const { handleShowLoader } = useLoader();
  //State Scenes List
  const [scenes, setScenes] = useState([]);
  const [sceneIntents, setSceneIntents] = useState([]);

  //Get scene List
  const [getScenesList] = useApi({
    endpoint: `publicity/scene/scenes_by_client`,
    method: 'get'
  });

  const getScenesData = async () => {
    const response = await getScenesList({ urlParams: idClient });

    const scenesResponse = get(response, 'payload', []);

    let scenesFormat = [];

    scenesResponse.map((scene) => {
      scenesFormat.push({
        ...scene,
        state: scene.state ? 'Si' : 'No'
      });
    });

    const scenesList = responseMapper({
      template: ScenesMapper,
      data: scenesFormat
    });

    const sceneListIntents = responseMapper({
      template: esceneListMapper,
      data: scenesFormat
    });

    setScenes(scenesList);
    setSceneIntents(sceneListIntents);
    handleShowLoader(false);
  };

  useEffect(() => {
    handleShowLoader(true);
    getScenesData();
  }, []);

  //Delete scene
  const [deleteScene] = useApi({
    endpoint: `publicity/scene`,
    method: 'delete'
  });

  const handleDeleteScene = async (item) => {
    handleShowLoader(true);
    const response = await deleteScene({ urlParams: item });

    if (response.headerResponse.code === 200) {
      handleCloseModal();
      await getScenesData();
    } else {
      handleShowLoader(false);
    }
  };

  //Main when is success Create/Edit
  const handleSuccessForm = (idScene, nameScene, goDesign) => {
    handleCloseDrawer();
    if (goDesign) {
      history.push('/advertising/scenes/design', {
        idScene,
        nameScene
      });
    } else {
      getScenesData();
    }
  };

  // Table structure
  const tableHead = [
    { key: 'id', label: 'ID' },
    { key: 'name', label: 'NOMBRE' },
    { key: 'duration', label: 'DURACIÓN' },
    { key: 'create', label: 'CREADO POR' },
    { key: 'createDate', label: 'FECHA' },
    { key: 'modification', label: 'MODIFICADO POR' },
    { key: 'modificationDate', label: 'FECHA' },
    { key: 'status', label: 'ESTATUS' },
    { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false },
    { key: '', label: '' }
  ];

  const filters = [
    { key: 'name', value: 'NOMBRE' },
    { key: 'status', value: 'ESTATUS' },
    { key: '', value: '' }
  ];

  return {
    scenes,
    sceneIntents,
    handleDeleteScene,
    handleSuccessForm,
    tableHead,
    filters
  };
};
