/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { messages as formMessages } from '@octopy/react-form';
import { Publish } from '@material-ui/icons';
import { Alert, useModal } from '@octopy/react-modal';
import { useLoader } from '@octopy/react-loader';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApi } from 'hooks';
import { sources } from 'providers/AWS';
import { uploadFilePromise } from 'utils/aws';
import { responseMapper } from 'utils/responseMapper';
import { userMapper } from './utils';
import { useStyles } from './MyAccountStyles';
import { messages } from './MyAccountMessages';
import { Typography } from '@material-ui/core';

export const useMyAccount = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const dataUser = get(storage, 'user', {});
  const userId = get(dataUser, 'user_id', '');
  const bucketName = get(storage, 'user.client.bucket_name', {});

  const classes = useStyles();
  const { images } = sources(bucketName);
  const { handleShowLoader } = useLoader();
  const { handleOpenModal } = useModal();
  const { formatMessage: f } = useIntl();
  const [userData, setUserData] = useState({});
  const invalidEmailError = f(formMessages.invalidEmailError);
  const requiredFieldError = f(formMessages.requiredFieldError);
  const onlyNumbers = f(messages.onlyNumbers);
  const positiveNumbers = f(messages.positiveNumbers);
  const decimalNumbers = f(messages.decimalNumbers);

  const [getUserData] = useApi({
    endpoint: `/management/users/${userId}/`,
    method: 'get'
  });

  const [updateUser] = useApi({
    endpoint: `/management/users/${userId}/`,
    method: 'put'
  });

  useEffect(() => {
    if (userId) getUser();
  }, [userId]);

  const getUser = async () => {
    try {
      const response = await getUserData();
      const dataResponse = get(response, 'payload', {});
      const headerResponse = get(response, 'headerResponse', {});

      if (headerResponse.code === 200) {
        const mapperUser = responseMapper({
          template: userMapper,
          data: dataResponse
        });

        formik.setValues({
          ...valuesForm,
          name: mapperUser.name ? mapperUser.name : '',
          last_name: mapperUser ? mapperUser.last_name : '',
          tlf: mapperUser.tlf ? mapperUser.tlf : '',
          email: mapperUser.email ? mapperUser.email : '',
          image: mapperUser.image ? { url: mapperUser.image } : null
        });

        mapperUser.modules.map((module) => {
          formik.setFieldValue(module.name, true);
        });

        setUserData(mapperUser);
        getModules(mapperUser.modules);
      }
    } catch (error) {}
  };

  const handleUpdateUser = async (data) => {
    try {
      const response = await updateUser({
        body: data
      });
      const userResponse = get(response, 'payload', []);
      const headerResponse = get(response, 'headerResponse', {});

      if (headerResponse.code === 200) {
        let newData = { ...storage };
        let userData = newData.user;
        let accountData = {
          ...userData,
          name: userResponse.name,
          last_name: userResponse.last_name,
          tlf: userResponse.tlf,
          email: userResponse.email,
          image: userResponse.image
        };

        newData.user = accountData;
        window.localStorage.setItem('session', JSON.stringify(newData));
        window.location.reload();
      }
    } catch (error) {}
  };

  const getValidationRules = () =>
    Yup.object().shape({
      name: Yup.string().required(requiredFieldError),
      last_name: Yup.string().required(requiredFieldError),
      tlf: Yup.number()
        .typeError(onlyNumbers)
        .positive(positiveNumbers)
        .integer(decimalNumbers)
        .required(requiredFieldError),
      email: Yup.string().email(invalidEmailError).required(requiredFieldError),
      image: Yup.object()
        .shape({ url: Yup.string() })
        .nullable()
        .required(requiredFieldError)
    });

  const initialValues = {
    name: '',
    last_name: '',
    tlf: '',
    email: '',
    image: null
  };

  const inputProps = {
    fullWidth: true
  };

  const fieldsFormDataUser = [
    Object.assign(
      {
        type: 'text',
        name: 'name',
        label: f(messages.nameLabel),
        placeholder: f(messages.nameLabel),
        breakpoints: { xs: 12 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'last_name',
        label: f(messages.lastNameLabel),
        placeholder: f(messages.lastNameLabel),
        breakpoints: { xs: 12 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'tlf',
        label: f(messages.phoneLabel),
        placeholder: '(55)00000000',
        breakpoints: { xs: 12 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'email',
        label: f(messages.emailLabel),
        placeholder: f(messages.emailPlaceholder),
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  const fieldsFormImage = [
    Object.assign({
      type: 'image',
      name: 'image',
      breakpoints: { xs: 12 },
      icon: Publish,
      label: f(messages.uploadImageLabel),
      multiple: false,
      color: 'primary.main'
    })
  ];

  const [modulesFieldsForm, setModulesFieldsForm] = useState([]);
  const [valuesForm, setValuesForm] = useState(initialValues);

  const getModules = (modulesData) => {
    let fieldsFormSwitch = [];
    let modules = initialValues;

    modulesData.map((module) => {
      modules[`${module.name}`] = true;
      fieldsFormSwitch.push(
        Object.assign(
          {
            type: 'switch',
            name: module.name,
            label: (
              <Typography className={classes.labelSwitch}>
                {module.name}
              </Typography>
            ),
            breakpoints: { xs: 12, sm: 6 },
            disabled: true
          },
          inputProps
        )
      );
    });
    setValuesForm(modules);
    setModulesFieldsForm(fieldsFormSwitch);
  };

  const formik = useFormik({
    initialValues: valuesForm,
    validationSchema: getValidationRules(),
    onSubmit: async (values) => {
      handleShowLoader(true);

      let body = {
        name: values.name,
        last_name: values.last_name,
        email: values.email,
        tlf: values.tlf,
        image: values.image.url
      };

      if (values.image && values.image.file) {
        const urlS3 = await uploadFilePromise(
          values.image.file,
          images,
          errorUploadFile
        );

        body.image = urlS3;

        handleUpdateUser(body);
      } else {
        handleUpdateUser(body);
      }
    }
  });

  const errorUploadFile = () => {
    handleOpenModal({
      configProps: {
        maxWidth: 'sm'
      },
      body: <Alert message={f(messages.uploadFileError)} />
    });
    handleShowLoader(false);
  };

  return {
    userData,
    formik,
    fieldsFormDataUser,
    fieldsFormImage,
    modulesFieldsForm
  };
};
