import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      margin: `0 ${theme.spacing(1)}px`,
      padding: theme.spacing(0),
      marginTop: theme.spacing(3)
    }
  },
  title: {
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800,
    marginBottom: '20px'
  },
  contentButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(8),
    marginTop: theme.spacing(2)
  },
  description: {
    fontSize: '14px',
    fontWeight: 500
  },
  titleSection: {
    fontSize: '14px',
    fontWeight: 800
  },
  englishPart: {
    fontSize: '14px'
  },
  saveButton: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  textPrivacy: {
    fontSize: '14px',
    opacity: 1,
    color: theme.palette.grey.A700
  },
  nameClient: {
    fontSize: '14px',
    opacity: 1,
    color: theme.palette.grey.A700,
    textTransform: 'uppercase',
    fontWeight: 800
  }
}));

export { useStyles };
