import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
    width: 400,
    [theme.breakpoints.down('xs')]: {
      width: 'auto'
    }
  },
  buttonSave: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    },
    marginTop: theme.spacing(4)
  }
}));

export { useStyles };
