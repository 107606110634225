import React, { useRef } from 'react';
import { useIntl } from 'react-intl';
import { Form, messages as formMessages } from '@octopy/react-form';
import { Typography, Card, Button, Grid } from '@material-ui/core';
import * as Yup from 'yup';
import { messages } from './ChangePasswordMessages';
import { useStyles } from './ChangePasswordStyles';
import { useApi } from 'hooks';
import { get } from 'lodash';
import { useModalConfirmation } from 'components/ModalConfirmation';

const ChangePassword = ({ idUser }) => {
  const intl = useIntl();
  const classes = useStyles();
  const formikRef = useRef();
  const { modalSuccess } = useModalConfirmation();

  const getInitialValues = () => ({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });

  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );
  const passwordNoMatchesError = intl.formatMessage(
    formMessages.passwordNoMatchesError
  );
  const passwordRegexError = intl.formatMessage(messages.passwordRegexError);
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#,.:;/])[A-Za-z\d@$!%*?&#,.:;/]{8,}$/;

  const getValidationRules = () =>
    Yup.object({
      currentPassword: Yup.string().required(requiredFieldError),
      newPassword: Yup.string()
        .required(requiredFieldError)
        .matches(passwordRegex, passwordRegexError),
      confirmPassword: Yup.string()
        .required(requiredFieldError)
        .oneOf([Yup.ref('newPassword'), null], passwordNoMatchesError)
    });

  const getFields = () => [
    {
      type: 'password',
      name: 'currentPassword',
      label: intl.formatMessage(messages.currentPassword),
      placeholder: intl.formatMessage(messages.currentPassword),
      breakpoints: { xs: 12 }
    },
    {
      type: 'password',
      name: 'newPassword',
      label: intl.formatMessage(messages.newPassword),
      placeholder: intl.formatMessage(messages.newPassword),
      breakpoints: { xs: 12 }
    },
    {
      type: 'password',
      name: 'confirmPassword',
      label: intl.formatMessage(messages.confirmPassword),
      placeholder: intl.formatMessage(messages.confirmPassword),
      breakpoints: { xs: 12 }
    }
  ];

  const [changePassword] = useApi({
    endpoint: `/management/users/change_password/${idUser}/`,
    method: 'put'
  });

  const handleSubmit = async (data) => {
    let body = {
      old_password: data.currentPassword,
      new_password: data.confirmPassword
    };

    const response = await changePassword({
      body
    });

    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code === 200) {
      modalSuccess(
        intl.formatMessage(messages.changeSuccessTitle),
        intl.formatMessage(messages.changeSuccessText),
        () => {
          formikRef.current.resetForm();
        },
        () => {},
        false
      );
    }
  };

  return (
    <Card className={classes.rootCard}>
      <Typography className={classes.changePassword}>
        {intl.formatMessage(messages.changePassword)}
      </Typography>
      <Form
        formikRef={formikRef}
        initialValues={getInitialValues()}
        validationRules={getValidationRules()}
        fields={getFields()}
        handleSubmit={handleSubmit}
        showSubmitButton={false}
        inputProps={{
          variant: 'outlined',
          fullWidth: true,
          size: 'medium'
        }}
        withInputsBorder
      />
      <Grid className={classes.contentButton}>
        <Button
          className={classes.button}
          onClick={() => formikRef.current.submitForm()}
        >
          {intl.formatMessage(messages.change)}
        </Button>
      </Grid>
    </Card>
  );
};

ChangePassword.propTypes = {};

export { ChangePassword };
