import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      paddingTop: theme.spacing(4),
      margin: `0 ${theme.spacing(1.5)}px`
    }
  },
  tabTitle: {
    paddingLeft: '5px',
    fontSize: '14px'
  },
  boxTitle: {
    display: 'flex'
  },
  contentTable: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(7)
    }
  }
}));

export { useStyles };
