const tableHead = [
  { key: 'item_id', label: 'ID' },
  { key: 'name', label: 'NOMBRE' },
  { key: 'category', label: 'CATEGORÍA' },
  { key: 'sub_category', label: 'SUBCATEGORÍA' },
  { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false }
];

const filters = [
  { key: 'name', value: 'NOMBRE' },
  { key: 'category', value: 'CATEGORÍA' },
  { key: 'sub_category', value: 'SUBCATEGORÍA' }
];

export { tableHead, filters };
