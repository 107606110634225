import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import { useIntl } from 'react-intl';

import { useStyles } from './PreviewStyles';
import { messages } from './PreviewMessages';
import { QuestionTypes } from 'components/Question/QuestionTypes';
import {
  renderMultipleOptions,
  renderOptions,
  renderScale,
  renderSection,
  renderYesNo,
  renderSquare
} from './helpers';

const Preview = ({ setPreview, questions }) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [rateValue, setRateValue] = useState(0);

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <div className={classes.pageTitle}>
            {formatMessage(messages.title)}
          </div>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6}>
          <span className={classes.helperText}>
            {formatMessage(messages.helperText)}
          </span>
        </Grid>
        <Grid
          container
          justify="flex-end"
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className={classes.responsiveButton}
        >
          <Button onClick={() => setPreview(false)} className={classes.button}>
            {formatMessage(messages.previewSurvey)}
          </Button>
        </Grid>
      </Grid>
      {questions.map((item, index) => {
        switch (item.type) {
          case QuestionTypes.uniqueOption:
            return renderOptions(item, index);
          case QuestionTypes.multipleChoice:
            return renderMultipleOptions(item, index);
          case QuestionTypes.linearScale:
            return renderScale(item, index, rateValue, setRateValue);
          case QuestionTypes.yesNo:
            return renderYesNo(item, index);
          case QuestionTypes.section:
            return renderSection(item, index);
          case QuestionTypes.squares:
            return renderSquare(item, index);
          default:
            return renderOptions(item, index);
        }
      })}
    </div>
  );
};

Preview.propTypes = {
  setPreview: PropTypes.func,
  questions: PropTypes.array
};

export default Preview;
