import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  Robot: {
    id: 'components.Directory.Robot',
    defaultMessage: 'ROBOT 001'
  },
  directoryTitle: {
    id: 'components.Directory.directoryTitle',
    defaultMessage: 'DIRECTORIO'
  },
  addItemsText: {
    id: 'components.Directory.addItemsText',
    defaultMessage:
      'Selecciona el personal que deseas agregar al directorio en este robot'
  },
  apliAllRobots: {
    id: 'components.Directory.apliAllRobots',
    defaultMessage: 'Aplicar a todos los robots'
  },
  cancel: {
    id: 'components.Directory.cancel',
    defaultMessage: 'CANCELAR'
  },
  save: {
    id: 'components.Directory.save',
    defaultMessage: 'GUARDAR'
  },
  totalItems: {
    id: 'components.Directory.totalItems',
    defaultMessage: 'Personal agregado:'
  },
  titleSuccessModal: {
    id: 'routes.Directory.titleSuccessModal',
    defaultMessage: 'Datos guardados con éxito'
  },
  textInfoSucces: {
    id: 'routes.Directory.textInfoSucces',
    defaultMessage: 'Los directorios se han agregado exitosamente.'
  },
  acept: {
    id: 'routes.Directory.acept',
    defaultMessage: 'ACEPTAR'
  },
  emptyAgents: {
    id: 'routes.Directory.emptyAgents',
    defaultMessage: 'NO EXISTEN AGENTES PARA ESTE ROBOT'
  },
  infoReportProblem: {
    id: 'routes.Directory.infoReportProblem',
    defaultMessage: 'Consulta a soporte técnico.'
  },
  infoReportProblemIsStaff: {
    id: 'routes.Directory.infoReportProblemIsStaff',
    defaultMessage:
      'Genere agentes para este robot en: "Relacionar Plataformas", posteriormente, vuelva a intentarlo.'
  },
  uploadFileError: {
    id: 'views.Directory.uploadFileError',
    defaultMessage:
      'Ha ocurrido un error al cargar el archivo. Intentalo de nuevo más tarde.'
  }
});
