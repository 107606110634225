import { makeStyles } from '@material-ui/core/styles';
import { Images } from 'assets';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundImage: `url(${Images.technologyRobot})`,
    backgroundSize: 'cover',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(1),
      height: 'auto'
    }
  },
  formContainer: {
    maxWidth: theme.typography.pxToRem(340),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  contentTitles: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginBottom: theme.spacing(3)
  },
  octopyLogo: {
    width: '100%',
    height: 'auto',
    marginBottom: theme.spacing(4)
  },
  infoText: {
    fontSize: '16px',
    opacity: 1,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(3),
    textAlign: 'center'
  },
  passwordRecoveryLink: {
    marginTop: theme.spacing(10),
    color: theme.palette.info.dark,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: theme.typography.fontWeightBold,
    textAlign: 'center',
    width: '100%',
    '& span': {
      margin: theme.spacing(1)
    }
  },
  submitButton: {
    marginTop: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:active': {
      background: theme.palette.info.dark
    },
    '&:hover': {
      background: theme.palette.info.dark
    }
  },
  recoveryPasswordTexts: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    fontSize: '26px !important',
    fontWeight: 800,
    letterSpacing: '6px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px'
    }
  },
  infoTextModal: {
    fontSize: '16px',
    color: theme.palette.common.black,
    textAlign: 'center',
    opacity: 1,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'center'
  }
}));

export { useStyles };
