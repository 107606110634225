export const inputProps = {
  variant: 'filled',
  fullWidth: true,
  size: 'small'
};

export const buttonSubmitProps = {
  variant: 'contained',
  size: 'medium',
  color: 'secondary',
  fullWidth: true,
  labelColor: 'white'
};

export const fieldBreakpoints = {
  xl: 6,
  lg: 6,
  sm: 6,
  xs: 12
};
