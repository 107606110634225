const formikMapper = {
  nameSpanish: 'name[0].text',
  text: 'audio_text[0].text',
  descriptionSpanish: 'description[0].text',
  priceSpanish: 'price[0].price',
  nameEnglish: 'name[1].text',
  textEnglish: 'audio_text[1].text',
  descriptionEnglish: 'description[1].text',
  priceEnglish: 'price[1].price'
};

const currencySpanish = {
  language_id: 1,
  currency: 'MXN'
};

const currencyEnglish = {
  language_id: 2,
  currency: 'USD'
};

const inputProps = {
  fullWidth: true
};

export { formikMapper, currencySpanish, currencyEnglish, inputProps };
