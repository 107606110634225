import { useFormik } from 'formik';

import { useIntl } from 'react-intl';

import * as Yup from 'yup';

import { messages as formMessages } from '@octopy/react-form';

export const useFormikTakeTurns = ({ onSubmit }) => {
  const { formatMessage: f } = useIntl();

  const requiredFieldError = f(formMessages.requiredFieldError);

  const validationSchema = Yup.object({
    selectBranch: Yup.string().required(requiredFieldError),
    releaseBox: Yup.string().required(requiredFieldError)
  });

  const initialValues = {
    selectBranch: '',
    releaseBox: ''
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema,
    onSubmit: (values) => onSubmit(values.releaseBox, true)
  });

  return { formik };
};
