import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  surveyFormat: {
    id: 'routes.Setup.surveyFormat',
    defaultMessage: 'Formato de encuesta:'
  },
  logoInstruction: {
    id: 'routes.Setup.logoInstruction',
    defaultMessage: 'Agrega logotipo de la empresa o campaña:'
  },
  logoRestrictions: {
    id: 'routes.Setup.logoRestrictions',
    defaultMessage: 'Formatos soportados JPG, JPEG, PNG. Máximo 5 MB.'
  },
  saveButton: {
    id: 'routes.Setup.saveButton',
    defaultMessage: 'Guardar'
  },
  updateButton: {
    id: 'routes.Setup.updateButton',
    defaultMessage: 'Actualizar'
  }
});
