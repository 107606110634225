import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[200] : '#0A1929',
    width: '100%',
    marginTop: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      width: '300px'
    }
  },
  rootGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  gridButtons: {
    display: 'flex',
    justifyContent: 'center'
  },
  rootImg: {
    display: 'flex',
    alignItems: 'center'
  },
  nameRobot: {
    display: 'flex',
    fontSize: '20px',
    fontWeight: 800
  },
  icons: {
    height: '35px',
    width: '35px',
    '&:hover': {
      color: theme.palette.info.light,
      transition: 'color 0.1s ease-out'
    }
  },
  Alert: {
    display: 'flex',
    marginTop: '14px',
    marginLeft: '12px'
  },
  Interaction: {
    display: 'flex',
    marginTop: '14px',
    marginLeft: '12px'
  },
  alertRobot: {
    fontSize: '14px',
    fontWeight: 500,
    marginLeft: '15px'
  },
  gridDivider: {
    marginTop: '10px'
  },
  Divider: {
    backgroundColor: theme.palette.info.light,
    fontWeight: 1000
  },
  powerEdit: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  ButonPower: {
    display: 'flex'
  },
  powerOn: {
    height: '35px',
    width: '35px',
    color: theme.palette.info.light
  },
  powerOff: {
    height: '35px',
    width: '35px',
    '&:hover': {
      color: theme.palette.info.light,
      transition: 'color 0.1s ease-out'
    }
  },
  statusText: {
    flexDirection: 'column'
  },
  Status: {
    fontSize: '14px',
    fontWeight: 800
  },
  statusOff: {
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800
  },
  textItemMenu: {
    fontSize: '16px',
    fontWeight: 500
  },
  contentModal: {
    width: 'auto',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  iconClose: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5)
    }
  },
  titleModal: {
    fontSize: '20px',
    fontWeight: 800,
    letterSpacing: '6px'
  },
  descriptionModal: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2.5)
  },
  gridButtonModal: {
    display: 'flex',
    justifyContent: 'space-evenly',
    maxWidth: '100%'
  },
  buttonCanel: {
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.common.black}CE`,
      color: theme.palette.common.white
    }
  },
  buttonModal: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  containerLabel: {
    marginLeft: theme.typography.pxToRem(23),
    padding: 10,
    marginTop: '-1rem'
  },
  labelWithoutIcon: {
    fontSize: 14,
    fontWeight: 500
  },
  robotTitleDrawer: {
    display: 'flex',
    fontSize: '20px',
    fontWeight: 800,
    color: theme.palette.common.black,
    letterSpacing: '6px'
  },
  relateSurveyDrawer: {
    display: 'flex',
    fontSize: '20px',
    fontWeight: 800,
    color: theme.palette.primary.main,
    letterSpacing: '6px'
  }
}));

export { useStyles };
