import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(3)
  },
  buttonSave: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    marginTop: theme.spacing(4),
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  description: {
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: theme.spacing(2)
  },
  titleSectionEnglish: {
    fontSize: '20px',
    fontWeight: 800,
    letterSpacing: '6px',
    marginBottom: theme.spacing(2)
  }
}));

export { useStyles };
