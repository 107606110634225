const tableHead = [
  { key: 'information_item_id', label: 'ID' },
  { key: 'title', label: 'NOMBRE' },
  { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false },
  { key: '', label: '' }
];

const filters = [{ key: 'title', value: 'NOMBRE' }];

const informationListMapper = {
  information_item_id: 'information_item_id',
  title: 'title',
  // text: 'text',
  audio_text: 'audio_text'
};

const agentMapped = {
  key: 'agent_key',
  language: 'language.language_id',
  tone: 'voice_tone',
  speed: 'speed',
  pitch: 'pitch'
  // text: 'test_phrase'
};

export { tableHead, filters, informationListMapper, agentMapped };
