import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: `0 ${theme.spacing(5)}px`,
    display: 'flex',
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      margin: `0 ${theme.spacing(1)}px`,
      marginTop: theme.spacing(2)
    }
  },
  pageTitle: {
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bold',
    letterSpacing: theme.typography.pxToRem(8)
  },
  subtitle: {
    color: theme.palette.primary.main
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  modalContainer: {
    '& .MuiDialog-paperFullScreen': {
      background:
        'linear-gradient(to bottom, rgba(0,0,0,1), rgba(255,255,255,0))'
    }
  },
  paperContainer: {
    backgroundColor: 'black',
    minHeight: theme.typography.pxToRem(800),
    height: theme.typography.pxToRem(800),
    maxHeight: theme.typography.pxToRem(800),
    minwidth: theme.typography.pxToRem(500),
    width: theme.typography.pxToRem(500),
    maxwidth: theme.typography.pxToRem(500)
  },
  previewTitle: {
    paddingTop: theme.typography.pxToRem(20),
    paddingRight: theme.typography.pxToRem(20),
    paddingLeft: theme.typography.pxToRem(20),
    fontSize: theme.typography.pxToRem(28),
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 6,
    textTransform: 'uppercase',
    color: theme.palette.common.white
  }
}));

export { useStyles };
