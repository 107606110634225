import React from 'react';
import { useIntl } from 'react-intl';
import { renderTextField } from '@octopy/react-form';
import { Button, Grid } from '@material-ui/core';
import { useCategoriesForm } from './useCategoriesForm';
import { messages } from './CategoriesFormMessages';
import { useStyles } from './CategoriesFormStyles';

const CategoriesForm = ({ categoryId = 0 }) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    formik,
    fieldNameSpanishFormik,
    fieldNameEnglishFormik
  } = useCategoriesForm({ categoryId });

  const fieldNameMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'title' && field.text}
      {field.type === 'text' && renderTextField({ index, formik, field })}
    </Grid>
  );

  return (
    <div className={classes.container}>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={10}>
          {fieldNameSpanishFormik.map(fieldNameMapper)}
        </Grid>
        <Grid item xs={12} sm={10}>
          {fieldNameEnglishFormik.map(fieldNameMapper)}
        </Grid>
      </Grid>

      <Button
        className={classes.buttonSave}
        onClick={() => formik.submitForm()}
      >
        {intl.formatMessage(messages.saveButton)}
      </Button>
    </div>
  );
};

CategoriesForm.propTypes = {};

export { CategoriesForm };
