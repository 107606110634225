import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  containerText: {
    padding: `0px ${theme.spacing(2)}px !important`,
    [theme.breakpoints.down('xs')]: {
      padding: `${theme.spacing(1)}px !important`
    }
  },
  tableTransferList: {
    '& .MuiBox-root': {
      marginBottom: '0px!important'
    }
  }
}));

export { useStyles };
