import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  subtitle: {
    id: 'components.FormResolutions.subtitle',
    defaultMessage: 'Agrega un nombre y dimensiones de la nueva resolución.'
  },
  nameLabel: {
    id: 'components.FormResolutions.nameLabel',
    defaultMessage: 'Nombre'
  },
  namePlaceholder: {
    id: 'components.FormResolutions.namePlaceholder',
    defaultMessage: 'Nombre'
  },
  wideLabel: {
    id: 'components.FormResolutions.wideLabel',
    defaultMessage: 'Ancho'
  },
  heightLabel: {
    id: 'components.FormResolutions.heightLabel',
    defaultMessage: 'Alto'
  },
  switchText: {
    id: 'components.FormResolutions.switchText',
    defaultMessage: '¿Deseas activar esta resolución para su uso?'
  },
  activate: {
    id: 'components.FormResolutions.activate',
    defaultMessage: 'Activar'
  },
  save: {
    id: 'components.FormResolutions.save',
    defaultMessage: 'GUARDAR'
  }
});
