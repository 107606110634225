import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLoader } from '@octopy/react-loader';
import { useApi } from 'hooks';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { TYPE_VIEW_DESIGN } from 'utils/TypeViewScene';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { messages } from './MainSceneDesignMessages';

export const useMainSceneDesign = (idScene, nameScene) => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const idClient = storage.user.client.client_id;

  const { handleShowLoader } = useLoader();
  const { modalReportProblem } = useModalConfirmation();
  const history = useHistory();
  const intl = useIntl();

  //States
  const [scene, setScene] = useState({});
  const [media, setMedia] = useState([]);
  const [mediaSelected, setMediaSelected] = useState({});
  //states Components
  const [typeView, setTypeView] = useState(TYPE_VIEW_DESIGN);

  //Utils Media Convert to Drag drop
  const setDragDropData = (sources) => {
    let dragDropData = [];

    sources.map((source, index) => {
      dragDropData.push({
        id: source.media_id,
        position: index + 1,
        name: source.name,
        url: source.url_file,
        type: source.file_type,
        time: source.duration
      });
    });

    return dragDropData;
  };

  const getDragDropData = (sources) => {
    let requestData = [];

    sources.map((source, index) => {
      requestData.push({
        media_id: source.id,
        position: index + 1,
        duration: source.time
      });
    });

    return requestData;
  };

  //Create Scene
  const [createScene] = useApi({
    endpoint: `publicity/scene/`,
    method: 'post'
  });

  const handleCreateScene = async (body) => {
    const response = await createScene({
      body
    });
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code == 200) {
      history.push(`/advertising/scenes`);
    } else {
      handleShowLoader(false);
    }
  };

  //Edit Scene
  const [editScene] = useApi({
    endpoint: `publicity/scene/${idScene}/`,
    method: 'put'
  });

  const handleEditScene = async (body) => {
    try {
      const response = await editScene({
        body
      });

      const headerResponse = get(response, 'headerResponse', {});

      if (headerResponse.code == 200) {
        history.push(`/advertising/scenes`);
      } else {
        handleShowLoader(false);
      }
    } catch (error) {}
  };

  const submitScene = () => {
    let body = {
      client: idClient,
      name: scene.name,
      media: getDragDropData(media)
    };

    if (body.media.length === 0) {
      modalReportProblem(
        intl.formatMessage(messages.titleReportProblem),
        intl.formatMessage(messages.descriptionProblem),
        () => {}
      );
    } else {
      if (idScene != 0) {
        handleEditScene(body);
      } else if (nameScene != '') {
        handleCreateScene(body);
      }
    }
  };

  //Get scene data
  const [getScene] = useApi({
    endpoint: `publicity/scene`,
    method: 'get'
  });

  const handleGetScene = async () => {
    const response = await getScene({ urlParams: idScene });
    const sceneResponse = get(response, 'payload', {});
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code == 404) {
      history.push(`/advertising/scenes`);
    } else if (headerResponse.code == 200) {
      setScene(sceneResponse);
      setMedia(setDragDropData(sceneResponse.media));
    }

    handleShowLoader(false);
  };

  useEffect(() => {
    if (idScene != 0) {
      handleShowLoader(true);
      handleGetScene();
    } else if (nameScene != '') {
      setScene({ name: nameScene });
    } else {
      history.push(`/advertising/scenes`);
    }
  }, []);

  return {
    scene,
    media,
    setMedia,
    mediaSelected,
    setMediaSelected,
    typeView,
    setTypeView,
    submitScene
  };
};
