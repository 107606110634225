import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import { Typography, Card, Grid } from '@material-ui/core';
import {
  Close,
  ErrorOutline,
  CheckCircleOutline,
  ReportProblemOutlined
} from '@material-ui/icons';
import { useApi } from 'hooks';
import { messages } from './NotificationsMessages';
import { useStyles } from './NotificationsStyles';

const Notifications = () => {
  const intl = useIntl();
  const classes = useStyles();
  // const [displayClose, setDisplayClose] = useState(false);
  // const [typeNotification, setTypeNotification] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const [getNotifications] = useApi({
    endpoint: '/notifications/notifications',
    method: 'get'
  });

  const [deleteNotifications] = useApi({
    endpoint: 'notifications/notifications',
    method: 'delete'
  });

  useEffect(() => {
    getNotificationsRobot();
  }, []);

  const getNotificationsRobot = async () => {
    try {
      const response = await getNotifications();
      const headerResponse = get(response, 'headerResponse', '');

      if (headerResponse.code === 200) {
        const dataResponse = get(response, 'payload', []);

        const mapped = dataResponse.map((item) => {
          const mappedRobot = item.robot.name;
          const dateFormat = format(
            new Date(item.origin_created_date),
            `${'dd/MM/yyyy'} - ${'HH:mm:ss'}`
          );

          return {
            ...item,
            robot: mappedRobot,
            origin_created_date: dateFormat
          };
        });

        setNotifications(mapped);
      }
    } catch (error) {}
  };

  const handleDeleteNotifications = async (id) => {
    try {
      const response = await deleteNotifications({
        urlParams: id
      });

      if (response.headerResponse.code === 200) {
        getNotificationsRobot();
      }
    } catch (error) {}
  };

  const handleType = (type) => {
    switch (type) {
      case 'error':
        return (
          <ErrorOutline style={{ color: 'red' }} className={classes.error} />
        );

      case 'warning':
        return (
          <ReportProblemOutlined
            style={{ color: 'orange' }}
            className={classes.warning}
          />
        );

      default:
        return (
          <CheckCircleOutline
            style={{ color: 'green' }}
            className={classes.success}
          />
        );
    }
  };

  return (
    <div className={classes.container}>
      <Typography className={classes.alertNumber}>
        {notifications.length} &nbsp; {intl.formatMessage(messages.alertNumber)}
      </Typography>
      {notifications.map((item, index) => (
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card
              className={classes.cardNotification}
              // onMouseEnter={() => setDisplayClose(true)}
              // onMouseLeave={() => setDisplayClose(false)}
            >
              <Grid
                container
                display="flex"
                justify="space-between"
                alignItems="center"
              >
                <Grid item lg={10} className={classes.contentHeader}>
                  {handleType(item.type)}
                  &nbsp;
                  <Typography className={classes.title}>
                    {item.title}
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={2}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  {/* {displayClose && ( */}
                  <Grid>
                    <Close
                      className={classes.icon}
                      onClick={() =>
                        handleDeleteNotifications(item.notification_id)
                      }
                    />
                  </Grid>
                  {/* )} */}
                </Grid>
              </Grid>
              <Grid>
                <Typography className={classes.alertText}>
                  {item.robot}: {item.description}
                </Typography>
                {item.solution && (
                  <Typography className={classes.infoText}>
                    {intl.formatMessage(messages.solution)} {item.solution}
                  </Typography>
                )}
                {item.priority !== null && (
                  <Typography className={classes.alertText}>
                    {item.priority === 'high'
                      ? intl.formatMessage(messages.high)
                      : intl.formatMessage(messages.medium)}
                  </Typography>
                )}
              </Grid>
              <Grid className={classes.containerDate}>
                <Typography className={classes.dateAlert}>
                  {item.origin_created_date}
                </Typography>
              </Grid>
            </Card>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};

Notifications.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { Notifications };
