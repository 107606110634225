import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { useLoader } from '@octopy/react-loader';
import { useModal, Alert } from '@octopy/react-modal';
import { useHistory } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApi } from 'hooks';
import { uploadFilePromise } from 'utils/aws';
import { responseMapper } from 'utils/responseMapper';
import { sources } from 'providers/AWS';
import { formikMapper } from './schemesMapper';
import { useApiBranches } from '../hooks/useApiBranches';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { useStyles } from './CreateBranchStyles';
import { messages } from './CreateBranchMessages';

export const useHelper = () => {
  const classes = useStyles();
  const intl = useIntl();
  const history = useHistory();
  const { handleShowLoader } = useLoader();
  const { handleOpenModal } = useModal();
  const { modalReportProblem } = useModalConfirmation();
  const { handleCreateBranch, handleEditBranch } = useApiBranches();
  const idBranch = history.location.state ? history.location.state.idBranch : 0;
  const storage = JSON.parse(localStorage.getItem('session'));
  const idClient = storage.user.client.client_id;
  const bucketName = get(storage, 'user.client.bucket_name', {});
  const { images } = sources(bucketName);

  const [getBranchId] = useApi({
    endpoint: '/branch_offices/branch_offices',
    method: 'get'
  });

  useEffect(() => {
    if (idBranch !== 0) getBranchData();
  }, []);

  const inputProps = {
    fullWidth: true
  };

  const errorUploadFile = () => {
    handleOpenModal({
      configProps: {
        maxWidth: 'sm'
      },
      body: <Alert message={intl.formatMessage(messages.uploadFileError)} />
    });
    handleShowLoader(false);
  };

  const requiredFieldError = intl.formatMessage(messages.required);

  const initialValues = {
    name: '',
    phone: '',
    open_at: null,
    until_at: null,
    direction: '',
    url_maps: '',
    latitude: '',
    longitude: '',
    descriptionSpanish: '',
    image: null,
    text: '',
    robot: '',
    addEnglish: false,
    textEnglish: '',
    robotEnglish: '',
    descriptionEnglish: ''
  };

  const getValidationRules = () =>
    Yup.object({
      name: Yup.string().required(requiredFieldError),
      phone: Yup.number()
        .typeError(intl.formatMessage(messages.onlyNumbers))
        .positive(intl.formatMessage(messages.positiveNumber))
        .integer(intl.formatMessage(messages.decimalNumbers))
        .required(requiredFieldError),
      open_at: Yup.date().nullable().required(requiredFieldError),
      until_at: Yup.date().nullable().required(requiredFieldError),
      direction: Yup.string().required(requiredFieldError),
      url_maps: Yup.string().required(requiredFieldError),
      latitude: Yup.string().required(requiredFieldError),
      longitude: Yup.string().required(requiredFieldError),
      descriptionSpanish: Yup.string().required(requiredFieldError),
      image: Yup.object()
        .shape({ url: Yup.string() })
        .nullable()
        .required(requiredFieldError),
      text: Yup.string().required(requiredFieldError),
      robot: Yup.string().required(requiredFieldError),
      textEnglish: Yup.string().when('addEnglish', {
        is: true,
        then: Yup.string().required(requiredFieldError)
      }),
      robotEnglish: Yup.string().when('addEnglish', {
        is: true,
        then: Yup.string().required(requiredFieldError)
      }),
      descriptionEnglish: Yup.string().when('addEnglish', {
        is: true,
        then: Yup.string().required(requiredFieldError)
      })
    });

  const fieldsMainFormik = [
    Object.assign(
      {
        type: 'text',
        name: 'name',
        label: intl.formatMessage(messages.nameLabel),
        placeholder: intl.formatMessage(messages.nameLabel),
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 7 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'phone',
        label: intl.formatMessage(messages.phoneLabel),
        placeholder: '(55)00000000',
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 5 }
      },
      inputProps
    )
  ];

  const fieldsHourForm = [
    Object.assign(
      {
        type: 'timePicker',
        name: 'open_at',
        label: intl.formatMessage(messages.fromLabel),
        placeholder: intl.formatMessage(messages.fromLabel),
        variant: 'inline',
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 6 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'timePicker',
        name: 'until_at',
        label: intl.formatMessage(messages.toLabel),
        placeholder: intl.formatMessage(messages.toLabel),
        variant: 'inline',
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 6 }
      },
      inputProps
    )
  ];

  const fieldsDirectionsFomr = [
    Object.assign(
      {
        section: 'contact',
        type: 'text',
        name: 'direction',
        label: intl.formatMessage(messages.addressLabel),
        placeholder: intl.formatMessage(messages.addressLabel),
        breakpoints: { xs: 12, lg: 6 }
      },
      inputProps
    ),
    Object.assign(
      {
        section: 'contact',
        type: 'text',
        name: 'url_maps',
        label: intl.formatMessage(messages.googleMapsLabel),
        breakpoints: { xs: 12, lg: 6 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'latitude',
        label: intl.formatMessage(messages.latitudeLabel),
        placeholder: intl.formatMessage(messages.latitudePlaceholder),
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 6 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'longitude',
        label: intl.formatMessage(messages.lengthLabel),
        placeholder: intl.formatMessage(messages.lengthPlaceholder),
        breakpoints: { xs: 12, sm: 6, md: 6, lg: 6 }
      },
      inputProps
    )
  ];

  const fieldsFormImage = [
    Object.assign({
      type: 'title',
      breakpoints: { xs: 12 },
      text: (
        <Typography className={classes.subTitle}>
          {intl.formatMessage(messages.addImage)}
        </Typography>
      )
    }),
    Object.assign({
      type: 'image',
      name: 'image',
      breakpoints: { xs: 12 },
      icon: Publish,
      label: intl.formatMessage(messages.uploadImageLabel),
      multiple: false,
      color: 'primary.main'
    })
  ];

  const fieldAddEnglishFormik = [
    Object.assign(
      {
        type: 'checkbox',
        name: 'addEnglish',
        label: (
          <Typography className={classes.infoText}>
            {intl.formatMessage(messages.englishPart)}
          </Typography>
        ),
        breakpoints: { xs: 12 },
        color: 'primary'
      },
      inputProps
    )
  ];

  const fieldDescriptionSpanishFormik = [
    Object.assign(
      {
        type: 'text',
        name: 'descriptionSpanish',
        label: intl.formatMessage(messages.descriptionLabel),
        placeholder: intl.formatMessage(messages.descriptionLabel),
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  const fieldDescriptionEnglishFormik = [
    Object.assign(
      {
        type: 'text',
        name: 'descriptionEnglish',
        label: intl.formatMessage(messages.descriptionLabel),
        placeholder: intl.formatMessage(messages.descriptionLabel),
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  //Formik
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationRules(),
    onSubmit: async (values) => {
      if (formik.values.addEnglish === false) {
        modalReportProblem(
          intl.formatMessage(messages.errorModalTitle),
          intl.formatMessage(messages.errorModalDescription),
          () => {}
        );
      } else {
        handleShowLoader(true);

        let data = {
          ...values,
          open_at: values.open_at
            ? new Date(values.open_at).toLocaleTimeString()
            : '',
          until_at: values.until_at
            ? new Date(values.until_at).toLocaleTimeString()
            : '',
          client: idClient,
          image: idBranch === 0 ? values.image : values.image.url,
          description: [
            {
              language_id: 1,
              text: values.descriptionSpanish
            },
            {
              language_id: 2,
              text: values.descriptionEnglish
            }
          ],
          audio_text: [
            {
              language_id: 1,
              text: values.text
            },
            {
              language_id: 2,
              text: values.textEnglish
            }
          ]
        };

        if (values.image && values.image.file) {
          const urlS3 = await uploadFilePromise(
            values.image.file,
            images,
            errorUploadFile
          );

          data.image = urlS3;

          if (idBranch === 0) {
            handleCreateBranch(data);
          } else {
            handleEditBranch(data);
          }
        } else {
          if (idBranch === 0) {
            handleCreateBranch(data);
          } else {
            handleEditBranch(data);
          }
        }
      }
    }
  });

  const handleChangeText = (value) => {
    formik.setFieldValue('text', value);
  };

  const handleChangeRobot = (value) => {
    formik.setFieldValue('robot', value.name);
  };

  const handleChangeTextEnglish = (value) => {
    formik.setFieldValue('textEnglish', value);
  };

  const handleChangeRobotEnglish = (value) => {
    formik.setFieldValue('robotEnglish', value.name);
  };

  const getBranchData = async () => {
    const response = await getBranchId({ urlParams: idBranch });
    const dataResponse = get(response, 'payload', {});
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code === 404) {
      history.push('/branch');
    } else {
      const mappedFormikValues = responseMapper({
        template: formikMapper,
        data: dataResponse
      });

      formik.setValues({
        ...initialValues,
        ...mappedFormikValues,
        image: { url: dataResponse.image },
        open_at: new Date(
          null,
          null,
          null,
          dataResponse.open_at.split(':')[0],
          dataResponse.open_at.split(':')[1]
        ),
        until_at: new Date(
          null,
          null,
          null,
          dataResponse.until_at.split(':')[0],
          dataResponse.until_at.split(':')[1]
        ),
        addEnglish: true
      });
    }
  };

  return {
    idBranch,
    formik,
    fieldsMainFormik,
    fieldsHourForm,
    fieldsDirectionsFomr,
    fieldAddEnglishFormik,
    fieldsFormImage,
    fieldDescriptionSpanishFormik,
    fieldDescriptionEnglishFormik,
    handleChangeText,
    handleChangeRobot,
    handleChangeTextEnglish,
    handleChangeRobotEnglish
  };
};
