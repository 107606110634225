import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  Robot: {
    id: 'components.Gestures.Robot',
    defaultMessage: 'ROBOT 001'
  },
  gesturesTitle: {
    id: 'components.Gestures.gesturesTitle',
    defaultMessage: 'GESTOS E INTENCIONES'
  },
  Gestures: {
    id: 'components.Gestures.Gestures',
    defaultMessage: 'Gestos'
  },
  agentOne: {
    id: 'components.Gestures.agentOne',
    defaultMessage: 'Agente 1'
  },
  agentTwo: {
    id: 'components.Gestures.agentTwo',
    defaultMessage: 'Agente 2'
  },
  agentThree: {
    id: 'components.Gestures.agentThree',
    defaultMessage: 'Agente 3'
  }
});
