/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-duplicate-case */
/* eslint-disable no-param-reassign */
import React, { Fragment, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import DraggableList from 'components/DraggableList/DraggableList';
import { useIntl } from 'react-intl';
import { useStyles } from './SetupStyles';
import { messages } from './SetupMessages';
import { QuestionTypes } from 'components/Question/QuestionTypes';
import DraggableListItem from 'components/DraggableList/DraggableListItem';
import { SetupActions } from './components/SetupActions';
import { SetupHeader } from './components/SetupHeader';
import { renderCheckbox } from '@octopy/react-form';
import {
  addQuestion,
  addSection,
  deleteQuestion,
  onDragEnd,
  setQuestionOption,
  setQuestionScale,
  setQuestionYesNo,
  setSection
} from './helpers';

import { useFields } from './components/SetupHeader/hooks/useFields';

const Setup = ({
  edit,
  formik,
  questions,
  setQuestions,
  setPreview,
  activeSurvey,
  setActiveSurvey,
  indexLastQuestion,
  setIndexLastQuestion
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const [isDragDisabled, setIsDragDisabled] = useState(true);

  const textButton = edit ? messages.updateButton : messages.saveButton;

  const { fields, fieldIsEnglishFormik, fieldsEnglish } = useFields(formik);

  const onChangeData = (event, index, dat) => {
    const questionsClone = questions?.slice();
    let response = {};

    switch (questions[index].type) {
      case QuestionTypes.uniqueOption:
        response = setQuestionOption(event.target, questionsClone[index], dat);
        break;
      case QuestionTypes.multipleChoice:
        response = setQuestionOption(event.target, questionsClone[index], dat);
        break;
      case QuestionTypes.linearScale:
        if (event.target.type === 'radio') {
          dat = 'data';
        }

        response = setQuestionScale(event.target, questionsClone[index], dat);
        break;
      case QuestionTypes.yesNo:
        response = setQuestionYesNo(event.target, questionsClone[index], dat);
        break;
      case QuestionTypes.section:
        response = setSection(event.target, questionsClone[index], dat);
        break;
      case QuestionTypes.multipleChoice:
        response = setQuestionOption(event.target, questionsClone[index], dat);
        break;
      case QuestionTypes.squares:
        response = setQuestionOption(event.target, questionsClone[index], dat);
        break;
      default:
        response = setQuestionOption(event.target, questionsClone[index], dat);
        break;
    }

    questionsClone[index] = response;
    setQuestions(questionsClone);
  };

  const handlesetQuestions = (options, id, dat) => {
    let aux = questions?.slice();

    aux.map((question) => {
      if (question.id == id) {
        question[dat].options = options.length > 0 ? options : [];
      }
    });

    setQuestions(aux);
  };

  const handlesetTypeQuestions = (type, id) => {
    let aux = questions?.slice();

    aux.map((question) => {
      if (question.id == id) {
        question.type = type;
        if (type == QuestionTypes.linearScale) {
          question.data.type = 'stars';
          question.dataEnglish.type = 'stars';
        } else {
          question.data.type = '';
          question.dataEnglish.type = '';
        }
      }
    });
    setQuestions(aux);
  };

  const generateQuestionsList = (isEnglishSurveys = false) =>
    questions?.map((item, index) => (
      <DraggableListItem
        onChangeData={(e) =>
          onChangeData(e, index, isEnglishSurveys ? 'dataEnglish' : 'data')
        }
        item={item}
        index={index}
        key={item.id}
        isDragDisabled={isDragDisabled}
        endDrag={(event) => {
          event.stopPropagation();
          setIsDragDisabled(true);
        }}
        startDrag={(event) => {
          event.stopPropagation();
          setIsDragDisabled(false);
        }}
        onDelete={(event) => {
          event.stopPropagation();
          deleteQuestion(index, questions, setQuestions);
        }}
        setQuestions={handlesetQuestions}
        setType={handlesetTypeQuestions}
        isEnglishSurveys={isEnglishSurveys}
      />
    ));

  const fieldCheckboxMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'checkbox' && renderCheckbox({ index, formik, field })}
    </Grid>
  );

  return (
    <Fragment>
      <SetupActions
        addQuestion={addQuestion}
        addSection={addSection}
        questions={questions}
        setQuestions={setQuestions}
        indexLastQuestion={indexLastQuestion}
        setIndexLastQuestion={setIndexLastQuestion}
      />

      <div className={classes.root}>
        <Grid container spacing={3}>
          <SetupHeader
            setPreview={setPreview}
            fields={fields}
            fieldsEnglish={fieldsEnglish}
            isEnglish={false}
            edit={edit}
            activeSurvey={activeSurvey}
            setActiveSurvey={setActiveSurvey}
          />
          <DraggableList
            item={questions}
            onDragEnd={(e) => onDragEnd(e, questions, setQuestions)}
          >
            {generateQuestionsList()}
          </DraggableList>

          <Grid container style={{ marginTop: 30 }}>
            <Grid item>{fieldIsEnglishFormik.map(fieldCheckboxMapper)}</Grid>
          </Grid>

          {formik.values.isEnglishSurveys && (
            <Fragment>
              <SetupHeader
                setPreview={setPreview}
                fields={fields}
                fieldsEnglish={fieldsEnglish}
                isEnglish={true}
                edit={edit}
                activeSurvey={activeSurvey}
                setActiveSurvey={setActiveSurvey}
              />

              <DraggableList
                item={questions}
                onDragEnd={(e) => onDragEnd(e, questions, setQuestions)}
              >
                {generateQuestionsList(formik.values.isEnglishSurveys)}
              </DraggableList>
            </Fragment>
          )}

          <Grid
            className={classes.lastRow}
            container
            justify="flex-end"
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Button className={classes.saveButton} onClick={formik.submitForm}>
              {formatMessage(textButton)}
            </Button>
          </Grid>
        </Grid>
      </div>
    </Fragment>
  );
};

Setup.propTypes = {};

export default Setup;
