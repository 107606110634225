import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.5),
      margin: `0 ${theme.spacing(1)}px`,
      marginTop: theme.spacing(3)
    }
  },
  title: {
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800,
    marginBottom: '20px'
  },
  description: {
    fontSize: '14px',
    fontWeight: 500
  },
  titleImage: {
    fontSize: '14px',
    fontWeight: 800
  },
  descriptionImage: {
    fontSize: '14px',
    marginBottom: '10px',
    marginTop: '10px'
  },
  titleTextBot: {
    fontSize: '14px',
    fontWeight: 800,
    marginBottom: '10px',
    marginTop: '10px'
  },
  descriptionTextBot: {
    fontSize: '14px',
    marginBottom: '10px'
  },

  contentButton: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(8)
  },
  button: {
    marginTop: theme.spacing(6),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  }
}));

export { useStyles };
