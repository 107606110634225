import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { messages as formMessages } from '@octopy/react-form';
import { useDrawer } from 'components/Drawer';
import { Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApi } from 'hooks';
import { inputProps } from '../utils';
import { formikMapper } from './schemesMapper';
import { responseMapper } from 'utils/responseMapper';
import { messages } from './SubcategoriesFormMessages';
import { useStyles } from './SubcategoriesFormStyles';

export const useSubcategoriesForm = ({
  categoryId,
  subcategoryId,
  handleGetSubcategoryList
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleCloseDrawer } = useDrawer();

  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );

  const [subcategoryCreate] = useApi({
    endpoint: '/catalogue/subcategory/',
    method: 'post'
  });

  const [subcategoryUpdate] = useApi({
    endpoint: 'catalogue/subcategory',
    method: 'put'
  });

  const [getSubcategoryId] = useApi({
    endpoint: '/catalogue/subcategory',
    method: 'get'
  });

  const handleGetSubcategory = async () => {
    const response = await getSubcategoryId({
      urlParams: subcategoryId
    });

    if (response.headerResponse.code === 200) {
      const dataResponse = get(response, 'payload', []);

      const mappedFormikValues = responseMapper({
        template: formikMapper,
        data: dataResponse
      });

      formik.setValues(mappedFormikValues);
    }
  };

  useEffect(() => {
    if (subcategoryId !== 0) handleGetSubcategory();
  }, [subcategoryId]);

  const getValidationRules = () =>
    Yup.object({
      nameSpanish: Yup.string().required(requiredFieldError),
      nameEnglish: Yup.string().required(requiredFieldError)
    });

  const formik = useFormik({
    initialValues: {
      nameSpanish: '',
      nameEnglish: ''
    },
    validationSchema: getValidationRules(),
    onSubmit: (values) => {
      let body = {
        category: categoryId,
        name: [
          {
            language_id: 1,
            text: values.nameSpanish
          },
          {
            language_id: 2,
            text: values.nameEnglish
          }
        ]
      };

      if (subcategoryId === 0) {
        handleCreateSubcategory(body);
      } else {
        handleUpdateSubcategory(body);
      }
    }
  });

  const handleCreateSubcategory = async (data) => {
    const response = await subcategoryCreate({
      body: data
    });

    if (response.headerResponse.code === 200) {
      handleGetSubcategoryList();
      handleCloseDrawer(false);
    }
  };

  const handleUpdateSubcategory = async (data) => {
    const response = await subcategoryUpdate({
      urlParams: `${subcategoryId}/`,
      body: data
    });

    if (response.headerResponse.code === 200) {
      handleGetSubcategoryList();
      handleCloseDrawer(false);
    }
  };

  const fieldNameSpanishFormik = [
    Object.assign({
      type: 'title',
      breakpoints: { xs: 12 },
      text: (
        <Typography className={classes.description}>
          {intl.formatMessage(messages.descriptionAddSubcategory)}
        </Typography>
      )
    }),
    Object.assign(
      {
        type: 'text',
        name: 'nameSpanish',
        label: intl.formatMessage(messages.nameLabel),
        placeholder: intl.formatMessage(messages.namePlaceholder),
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  const fieldNameEnglishFormik = [
    Object.assign({
      type: 'title',
      breakpoints: { xs: 12 },
      text: (
        <Typography className={classes.titleSectionEnglish}>
          {intl.formatMessage(messages.titleEnglish)}
        </Typography>
      )
    }),
    Object.assign(
      {
        type: 'text',
        name: 'nameEnglish',
        label: intl.formatMessage(messages.nameEnglishLabel),
        placeholder: intl.formatMessage(messages.nameEnglishPlaceholder),
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  return {
    formik,
    fieldNameSpanishFormik,
    fieldNameEnglishFormik
  };
};
