import logo from './svg/logo.svg';
import jsIcon from './svg/javascript-icon.svg';
import reactIcon from './svg/react-js-icon.svg';
import scssIcon from './svg/sass-icon.svg';
import svgIcon from './svg/sun-icon.svg';
import folderIcon from './svg/file-manager.svg';
import folderOpenIcon from './svg/folder-open-icon.svg';
import imageIcon from './svg/image-icon.svg';
import bracketsIcon from './svg/brackets-icon.svg';
import iconLogo from './svg/icon-logo.svg';
import nameLogo from './svg/name-logo.svg';
import companyLogo from './svg/octopy-logo.svg';
import robotFace from './svg/robot-face.svg';
import recoveryPasswordLogo from './png/recovery-avatar.png';
import robotIcon from './svg/icon-robot.svg';
import robotHand from './images/robot_hand.jpg';
import technologyRobot from './images/technology_robot.jpg';
import octopy from './svg/octopy.svg';
import platformIcon from './svg/management-platform.svg';
import zipIcon from './svg/metro-file-zip.svg';
import excelIcon from './svg/simple-microsoftexcel.svg';

const Images = {
  logo,
  jsIcon,
  reactIcon,
  scssIcon,
  svgIcon,
  folderIcon,
  folderOpenIcon,
  imageIcon,
  bracketsIcon,
  iconLogo,
  nameLogo,
  companyLogo,
  robotFace,
  recoveryPasswordLogo,
  robotIcon,
  robotHand,
  technologyRobot,
  octopy,
  platformIcon,
  excelIcon,
  zipIcon
};

export { Images };
