const intentionsMapped = {
  agent_id: 'agent.agent_id',
  audios: 'audios',
  gestures: 'gestures',
  id: 'id',
  name: 'name'
};

const agentMapped = {
  agent_key: 'agent_key',
  language: 'language.language_id',
  tone: 'voice_tone',
  speed: 'speed',
  pitch: 'pitch'
};

const tableHead = [
  { key: 'position', label: 'ORDEN' },
  { key: 'text', label: 'TEXTO' },
  { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false },
  { key: '', value: '' }
];

export { intentionsMapped, agentMapped, tableHead };
