import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Grid,
  Typography,
  Button,
  TextField,
  IconButton
} from '@material-ui/core';
import { AddCircle, Search, Close } from '@material-ui/icons';
import { useDrawer } from 'components/Drawer';
import { Card } from 'components/Card';
import { FormRobot } from './FormRobot';
import { useMenuRobot } from './menuRobot';
import { useModal } from '@octopy/react-modal';
import { messages } from './RobotMessages';
import { useStyles } from './RobotStyles';
import { useRobots } from './hooks/useRobots';
import { get } from 'lodash';

const Robot = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const isSuperAdmin = get(storage, 'user.is_staff', {});

  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenDrawer, handleCloseDrawer } = useDrawer();
  const { handleOpenModal, handleCloseModal } = useModal();
  const menu = useMenuRobot();
  const {
    newData,
    data,
    clientsList,
    handleDelete,
    handleOnchange,
    getRobotList,
    getSuperAdminRobotList,
    getClients,
    handleRobotPowerOff
  } = useRobots();

  useEffect(() => {
    if (isSuperAdmin) {
      getSuperAdminRobotList();
      getClients();
    } else {
      getRobotList();
    }
  }, [isSuperAdmin]);

  const handleDrawer = (item) => {
    handleOpenDrawer({
      closeButton: true,
      title: (
        <>
          {item ? (
            <Typography className={classes.titleRobot}>
              {intl.formatMessage(messages.editRobot)}
            </Typography>
          ) : (
            <Typography className={classes.titleRobot}>
              {intl.formatMessage(messages.addRobot)}
            </Typography>
          )}
        </>
      ),
      body: (
        <>
          <FormRobot
            item={item}
            clientsList={clientsList}
            getRobotList={getRobotList}
            getSuperAdminRobotList={getSuperAdminRobotList}
            handleCloseDrawer={() => handleCloseDrawer()}
          />
          {console.log('</>', item)}
        </>
      ),
      configProps: {
        anchor: 'right'
      }
    });
  };

  const handleunlink = (idRobot) => {
    handleOpenModal({
      body: (
        <Grid className={classes.contentModal}>
          <Grid className={classes.closeButton}>
            <IconButton onClick={() => handleCloseModal()}>
              <Close />
            </IconButton>
          </Grid>
          <Typography className={classes.titleModal}>
            {intl.formatMessage(messages.titleModal)}
          </Typography>
          <Typography className={classes.descriptionModal}>
            {intl.formatMessage(messages.descriptionModal)}
          </Typography>
          <Grid className={classes.gridButtonModal}>
            <Button
              className={classes.buttonModal}
              onClick={() => {
                handleRobotPowerOff(idRobot);
              }}
            >
              {intl.formatMessage(messages.buttonModal)}
            </Button>
          </Grid>
        </Grid>
      ),
      configProps: {
        maxWidth: 'lg'
      }
    });
  };

  return (
    <div className={classes.container}>
      <Grid container justify="center" className={classes.contentHeader}>
        <Grid item xs={12} lg={3} className={classes.contentTitle}>
          <Typography className={classes.titleRobot}>
            {intl.formatMessage(messages.titleRobot)}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={9}>
          {isSuperAdmin && (
            <Grid container className={classes.gridContainerActions}>
              <Grid
                item
                lg={4}
                sm={4}
                xs={12}
                className={classes.gridItemSearch}
              >
                <TextField
                  type="text"
                  id="data"
                  name="data"
                  value={data}
                  onChange={handleOnchange}
                  InputProps={{
                    startAdornment: <Search />
                  }}
                  variant="outlined"
                  label="Buscar"
                  placeholder="Buscar"
                />
              </Grid>
              <Grid
                item
                lg={2}
                sm={2}
                xs={12}
                className={classes.gridItemButton}
              >
                <Button
                  className={classes.Button}
                  endIcon={<AddCircle />}
                  onClick={() => handleDrawer()}
                >
                  {intl.formatMessage(messages.textAdd)}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2} className={classes.contentCards}>
        {newData.map((item) => (
          <Grid item md={4} xs={12}>
            <Card
              id={item.robot_id}
              data={item}
              robot
              list={menu.menuRobot(item)}
              name={item.name}
              image={item.image}
              status={item.is_active}
              onClick={() => handleDrawer(item)}
              onClickAcept={() => handleDelete(item.robot_id)}
              onClickUnlink={handleunlink}
              getRobotList={getRobotList}
              isSuperAdmin={isSuperAdmin}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

Robot.propTypes = {};

export { Robot };
