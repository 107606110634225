import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  assignedPermissions: {
    id: 'routes.Permissions.assignedPermissions',
    defaultMessage: 'Permisos asignados:'
  },
  textInfo: {
    id: 'routes.Permissions.textInfo',
    defaultMessage: 'Módulos permitidos y que puede vizalizar el usuario'
  },
  client: {
    id: 'routes.Permissions.client',
    defaultMessage: 'Cliente'
  },
  name: {
    id: 'routes.Permissions.name',
    defaultMessage: 'Nombre'
  },
  save: {
    id: 'routes.Permissions.save',
    defaultMessage: 'GUARDAR'
  },
  required: {
    id: 'routes.Permissions.required',
    defaultMessage: 'Este campo es requerido'
  }
});
