import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  floatingCardContainer: {
    position: 'fixed',
    top: 'calc(50% - 25px)',
    right: '0',
    padding: `${theme.spacing(2)}${theme.spacing(1)}`,
    borderRadius: '15px 0 0 15px',
    zIndex: 1
  },
  iconButton: {
    color: theme.palette.text.primary
  }
}));

export { useStyles };
