import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { useStyles } from './SceneImageStyles';
import { messages } from './SceneImageMessages';
import { renderTextField } from '@octopy/react-form';
import { TableSelect } from 'components/TableSelect';
import { useSceneImageForm } from './useSceneImageForm';
import { TYPE_IMAGEN } from 'utils/TypeSource';
import { tableHead } from './utils';
import { TYPE_VIEW_DESIGN } from 'utils/TypeViewScene';

const SceneImage = ({
  mediaSelected,
  media,
  handleChangeMedia,
  handleChangeView,
  handleSetMediaSelected
}) => {
  const { formatMessage: f } = useIntl();
  const classes = useStyles();
  const [search, setSearch] = useState('');

  const { formik, fieldsForm, mediaData, selectedItem } = useSceneImageForm(
    mediaSelected,
    media,
    handleChangeMedia,
    handleChangeView,
    handleSetMediaSelected
  );

  const fieldsMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'text' && renderTextField({ index, formik, field })}
    </Grid>
  );

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12}>
        <Typography variant="h1" className={classes.tittleAddMedia}>
          {mediaSelected.id && mediaSelected.id != 0
            ? f(messages.titleEditImage)
            : f(messages.titleAddImage)}
        </Typography>
      </Grid>
      <Grid item xs={12} md={12}>
        <Card>
          <CardContent>
            <Grid
              item
              container
              xs={12}
              md={12}
              spacing={2}
              className={classes.formContainer}
            >
              <Grid item container spacing={2} xs={12} md={9}>
                {fieldsForm.map(fieldsMapper)}
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justify="center"
                xs={12}
                md={3}
              >
                <Button
                  className={classes.buttonCancel}
                  onClick={() => {
                    handleSetMediaSelected({});
                    handleChangeView(TYPE_VIEW_DESIGN);
                  }}
                >
                  {f(messages.formButtonCancel)}
                </Button>
                <Button
                  className={classes.buttonSave}
                  color="primary"
                  onClick={() => formik.submitForm()}
                >
                  {f(messages.formButtonSave)}
                </Button>
              </Grid>
            </Grid>
            <Grid container xs={12} md={12} spacing={2}>
              <Grid item container spacing={2} xs={12} md={9}>
                <Grid item container xs={12} md={8} alignItems="center">
                  <Typography
                    variant="h1"
                    className={classes.textAddImage}
                    display="inline"
                  >
                    {f(messages.textAddImage)}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    label={f(messages.textSearch)}
                    placeholder={f(messages.textSearch)}
                    variant="outlined"
                    fullWidth
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search />
                        </InputAdornment>
                      )
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <TableSelect
              data={mediaData}
              textSearch={search}
              formik={formik}
              edit={mediaSelected.id && mediaSelected.id != 0}
              scenceLeft={selectedItem}
              type={TYPE_IMAGEN}
              changeData={() => {}}
              tableHead={tableHead}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export { SceneImage };
