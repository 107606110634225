import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'routes.Preview.title',
    defaultMessage: 'Encuesta'
  },
  helperText: {
    id: 'routes.Preview.helperText',
    defaultMessage: 'Vista previa de preguntas'
  },
  previewSurvey: {
    id: 'routes.Preview.previewSurvey',
    defaultMessage: 'Continuar creando encuesta'
  },
  yes: {
    id: 'routes.Preview.yes',
    defaultMessage: 'Sí'
  },
  no: {
    id: 'routes.Preview.no',
    defaultMessage: 'No'
  },
  veryDissatisfied: {
    id: 'routes.Preview.VeryDissatisfied',
    defaultMessage: 'Very Dissatisfied'
  },
  dissatisfied: {
    id: 'routes.Preview.dissatisfied',
    defaultMessage: 'Dissatisfied'
  },
  neutral: {
    id: 'routes.Preview.neutral',
    defaultMessage: 'Neutral'
  },
  satisfied: {
    id: 'routes.Preview.satisfied',
    defaultMessage: 'Satisfied'
  },
  verySatisfied: {
    id: 'routes.Preview.verySatisfied',
    defaultMessage: 'Very Satisfied'
  }
});
