import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { messages as formMessages } from '@octopy/react-form';
import { messages } from './SceneVideoMessages';
import { useLoader } from '@octopy/react-loader';
import { useApi } from 'hooks';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { Alert, useModal } from '@octopy/react-modal';
import { sources } from 'providers/AWS';
import { uploadFilePromise } from 'utils/aws';
import { TYPE_VIDEO } from 'utils/TypeSource';
import { TYPE_VIEW_DESIGN } from 'utils/TypeViewScene';

export const useSceneVideoForm = (
  mediaSelected,
  mediaResources,
  handleChangeMedia,
  handleChangeView,
  handleSetMediaSelected
) => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const idClient = storage.user.client.client_id;
  const bucketName = get(storage, 'user.client.bucket_name', {});
  const { videos } = sources(bucketName);

  const { handleShowLoader } = useLoader();
  const { handleOpenModal } = useModal();
  const history = useHistory();
  const { formatMessage: f } = useIntl();

  //States
  const [mediaData, setMediaData] = useState([]);
  const [selectedItem, setSelectedItem] = useState([]);

  //Formik structure
  const requiredFieldError = f(formMessages.requiredFieldError);
  const onlyNumberRegex = /^\d/gm;

  const initialValues = {
    source: null,
    name: '',
    time: '',
    idMedia: 0
  };

  const getValidationRules = () =>
    Yup.object().shape({
      name: Yup.string().max(60).required(requiredFieldError),
      time: Yup.string()
        .matches(onlyNumberRegex, {
          message: f(messages.validationTime),
          excludeEmptyString: true
        })
        .required(requiredFieldError)
    });

  const inputProps = {
    fullWidth: true
  };

  //Init Formik
  const formik = useFormik({
    initialValues,
    validationSchema: getValidationRules(),
    onSubmit: (values) => handleSubmit(values)
  });

  const fieldsForm = [
    Object.assign(
      {
        type: 'text',
        name: 'name',
        label: f(messages.formName),
        breakpoints: { md: 8 },
        disabled: formik.values.idMedia != 0
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'time',
        label: f(messages.formTime),
        breakpoints: { md: 4 }
      },
      inputProps
    )
  ];

  const handleSubmit = async (values) => {
    let fileList = [];

    if (values.idMedia != 0) {
      let findItem = mediaData.find((x) => x.media_id == values.idMedia);

      handleAddMedia(
        values.idMedia,
        values.name,
        values.time,
        findItem.url_file
      );
    } else {
      handleShowLoader(true);
      if (values.source && values.source.file) {
        const fileSource = values.source.file;
        const urlS3 = await uploadFilePromise(
          fileSource,
          videos,
          erroUploadFile
        );

        fileList.push({
          name: values.name,
          url_file: urlS3,
          extention: fileSource.type.split('/')[1],
          file_type: TYPE_VIDEO,
          duration: 0,
          resolution: '1080x1920'
        });
      }

      let body = {
        client: idClient,
        files: fileList
      };

      handleSetMediaSelected({});
      handleCreateVideoMedia(body, values.time);
    }
  };

  //Util Error upload file
  const erroUploadFile = () => {
    handleOpenModal({
      configProps: {
        maxWidth: 'sm'
      },
      body: <Alert message={f(messages.uploadFileError)} />
    });
    handleShowLoader(false);
  };

  //Get Media Video
  const [getVideoMedia] = useApi({
    endpoint: `publicity/media_library/media_by_file_type_client/${TYPE_VIDEO}/${idClient}/`,
    method: 'get'
  });

  const handleGetVideoMedia = async () => {
    const response = await getVideoMedia();
    const mediaResponse = get(response, 'payload', {});
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code == 404) {
      history.push(`/advertising/scenes`);
    } else if (headerResponse.code == 200) {
      setMediaData(mediaResponse);

      if (mediaSelected.id && mediaSelected.id != 0) {
        let findItem = mediaResponse.find(
          (x) => x.media_id == mediaSelected.id
        );
        let selectedArray = [findItem ?? findItem];

        formik.setFieldValue('time', mediaSelected.time);
        setSelectedItem(selectedArray);
      }
    }
  };

  //Create Media Video
  const [createVideoMedia] = useApi({
    endpoint: `publicity/media_library/`,
    method: 'post'
  });

  const handleCreateVideoMedia = async (body, duration) => {
    const response = await createVideoMedia({ body });
    const mediaCreatedResponse = get(response, 'payload', []);
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code == 200) {
      let createdImage = mediaCreatedResponse[0];

      handleAddMedia(
        createdImage.media_id,
        createdImage.name,
        duration,
        createdImage.url_file
      );
    } else {
      handleShowLoader(false);
    }
  };

  //Utils EditScene Data
  const handleAddMedia = async (idMedia, name, duration, mediaUrl) => {
    let newMediaScene = [];
    let exits = false;

    mediaResources.map((media, index) => {
      if (media.id == idMedia && mediaSelected.position == index + 1) {
        exits = true;
        newMediaScene.push({
          id: media.id,
          name: name,
          type: media.type,
          time: duration,
          position: index + 1,
          url: media.url
        });
      } else {
        if (
          mediaSelected.id != idMedia &&
          mediaSelected.position == index + 1
        ) {
          exits = true;
          newMediaScene.push({
            id: idMedia,
            name: name,
            type: media.type,
            time: duration,
            position: index + 1,
            url: media.url
          });
        } else {
          newMediaScene.push({
            id: media.id,
            name: media.name,
            type: media.type,
            time: media.time,
            position: index + 1,
            url: media.url
          });
        }
      }
    });

    let lastPosition = newMediaScene.length + 1;

    if (!exits) {
      newMediaScene.push({
        id: idMedia,
        name: name,
        type: TYPE_VIDEO,
        time: duration,
        position: lastPosition,
        url: mediaUrl
      });
    }

    console.log({ newMediaScene });
    handleChangeMedia(newMediaScene);
    handleChangeView(TYPE_VIEW_DESIGN);
  };

  useEffect(async () => {
    handleShowLoader(true);
    await handleGetVideoMedia();
    handleShowLoader(false);
  }, []);

  return {
    formik,
    fieldsForm,
    mediaData,
    selectedItem
  };
};
