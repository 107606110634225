import React from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Table } from '@octopy/react-table';
import { Button, Grid, Typography } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons/';
import { tableHead } from './utils';
import { useHistory } from 'react-router-dom';
import { messages } from './InteractionsMessages';
import { useStyles } from './InteractionsStyles';
import { useExport } from 'hooks/useExport';
import { mapInteractions } from './utils';
import { HeaderTitle } from 'components/HeaderTitle';
import { useInteractions } from './useInteractions';
import { renderDatePicker } from '@octopy/react-form';

const Interactions = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { exportToExcel } = useExport();
  const history = useHistory();
  const idRobot = history.location.state.robot_id;

  const { interactionsData, fieldsForm, formik } = useInteractions({ idRobot });

  const configProps = {
    //filters,
    selectedOptions: {
      checkboxHidden: true
    }
  };

  const fieldsMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'datePicker' &&
        renderDatePicker({
          index,
          formik,
          field
        })}
    </Grid>
  );

  return (
    <div className={classes.container}>
      <HeaderTitle
        firstTitle={history.location.state.name}
        secondTitle={intl.formatMessage(messages.interactions)}
        redirection="robots"
      />
      <Grid className={classes.contentButton}>
        <Button
          className={classes.exportButton}
          endIcon={<ExitToApp />}
          onClick={() =>
            exportToExcel(
              mapInteractions(interactionsData),
              intl.formatMessage(messages.title)
            )
          }
        >
          {intl.formatMessage(messages.exportCsv)}
        </Button>
      </Grid>
      <Grid container spacing={2}>
        {fieldsForm.map(fieldsMapper)}
        <Grid item xs={6} direction="row-reverse">
          <Button
            className={classes.button}
            onClick={() => formik.submitForm()}
          >
            <Typography>{intl.formatMessage(messages.search)}</Typography>
          </Button>
        </Grid>
      </Grid>

      <Grid className={classes.contentTable}>
        <div className={`tab-0`}>
          <Table
            columns={tableHead}
            data={interactionsData}
            configProps={configProps}
          />
        </div>
      </Grid>
    </div>
  );
};

Interactions.propTypes = {};

export { Interactions };
