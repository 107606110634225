/* eslint-disable array-callback-return */
import React, { useState, useRef } from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Table } from '@octopy/react-table';
import { useLoader } from '@octopy/react-loader';
import {
  TextField,
  Button,
  Grid,
  Typography,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import { SoundPlayer } from 'components/SoundPlayer';
import { ButtonSimpleMultimedia } from 'components/ButtonSimpleMultimedia';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { useHistory } from 'react-router-dom';
import { useApi } from 'hooks';
import { useStyles } from './CreateAudioFlowStyles';
import { messages } from './CreateAudioFlowMessages';
import { tableHead } from './utils';
import { useCreateAudioFlow } from './hooks/useCreateAudioFlow';
import { get } from 'lodash';
import { HeaderTitle } from 'components/HeaderTitle';

const CreateAudioFlow = () => {
  const classes = useStyles();
  const intl = useIntl();
  const formikButtonRef = useRef();
  const history = useHistory();
  const [enableSelectAudio, setEnableSelectAudio] = useState(false);
  const { handleShowLoader } = useLoader();
  const { modalSuccess } = useModalConfirmation();
  const {
    formik,
    valueInputName,
    dataAudios,
    showSoundPlayer,
    previewSpeech,
    setDataAudios,
    handlePreviewSpeech
  } = useCreateAudioFlow({
    setEnableSelectAudio,
    clientBucketName: history.location.state.clientBucketName
  });
  const idIntention = history.location.state
    ? history.location.state.idIntention
    : history.push('/robots');

  const [updateIntention] = useApi({
    endpoint: '/intents',
    method: 'put'
  });

  const configProps = {
    actions: {
      customs: {
        onClick: (item) => handleDeleteAudio(item),
        text: intl.formatMessage(messages.delete),
        icon: <Delete />
      }
    },
    selectedOptions: {
      checkboxHidden: true
    }
  };

  const handleDeleteAudio = (value) => {
    handleShowLoader(true);

    let newCheckedArray = [];
    let position = 1;

    dataAudios.map((item) => {
      if (item !== value) {
        newCheckedArray.push({
          ...item,
          position: position
        });

        position = position + 1;
      }
    });

    setTimeout(() => {
      setDataAudios(newCheckedArray);
      handleShowLoader(false);
    }, 1000);
  };

  const handleSubmit = async () => {
    try {
      let audiosArray = [];

      dataAudios.map((item) => {
        audiosArray.push({
          text: item.text,
          s3_url: item.s3_url
        });
      });

      const response = await updateIntention({
        urlParams: `${idIntention}/`,
        body: { audios: audiosArray }
      });
      const headerResponse = get(response, 'headerResponse', '');

      if (headerResponse.code === 200) {
        modalSuccess(
          intl.formatMessage(messages.titleModal),
          intl.formatMessage(messages.textInfoSucces),
          () => {
            window.history.back();
          }
        );
      }
    } catch (error) {}
  };

  return (
    <div className={classes.container}>
      <HeaderTitle firstTitle={intl.formatMessage(messages.titleEdit)} />
      <Grid container spacing={2} style={{ marginTop: 20 }}>
        <Grid item xs={12} sm={6} md={6}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <label className={classes.optionLabel}>
                {intl.formatMessage(messages.intentionName)}
              </label>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              <TextField
                disabled
                fullWidth
                variant="outlined"
                label={intl.formatMessage(messages.name)}
                value={valueInputName}
              />
              <Typography className={classes.infoInputText}>
                {intl.formatMessage(messages.infoInputText)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <label className={classes.optionLabel}>
                {intl.formatMessage(messages.optionsText)}
              </label>
            </Grid>
            <Grid item xs={12} style={{ marginTop: 10 }}>
              {!enableSelectAudio && (
                <TextField
                  fullWidth
                  variant="outlined"
                  type="text"
                  label={intl.formatMessage(messages.optionsTextPlaceholder)}
                  name="text"
                  id="text"
                  value={formik.values.text}
                  onChange={formik.handleChange}
                  error={formik.touched.text && Boolean(formik.errors.text)}
                  helperText={formik.touched.text && formik.errors.text}
                />
              )}

              <Grid style={{ marginTop: 10 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.uploadAudio}
                      color="primary"
                      onChange={(e) => {
                        setEnableSelectAudio(!enableSelectAudio);
                        formik.handleChange(e);
                      }}
                      id="uploadAudio"
                    />
                  }
                  label={
                    <Typography className={classes.checkBoxLabel}>
                      {intl.formatMessage(messages.audioSelection)}
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            <Grid container>
              {enableSelectAudio && (
                <Grid item>
                  <ButtonSimpleMultimedia
                    audio={true}
                    videoImage={false}
                    formikRef={formikButtonRef}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        justify="flex-end"
        alignItems={showSoundPlayer ? 'center' : 'flex-end'}
        spacing={2}
        style={{ marginBottom: 20, marginTop: 20 }}
      >
        <Grid item>
          {showSoundPlayer && <SoundPlayer src={previewSpeech} />}
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            disabled={
              formik.values.text.length < 5 && formik.values.text === ''
            }
            onClick={() => handlePreviewSpeech()}
          >
            {intl.formatMessage(messages.preview)}
          </Button>
        </Grid>
        <Grid item>
          <Button
            className={classes.button}
            onClick={async () => {
              if (formik.values.uploadAudio === true) {
                let validForm = await formik.validateForm(); //{}
                let validSource = await formikButtonRef.current.validateForm(); // {audioVideo:'Campo Requerido'}
                let validateAudioErrors =
                  Object.entries(validSource).length === 0;
                let validateFormErrors = Object.entries(validForm).length === 0;

                if (validateAudioErrors && validateFormErrors) {
                  let file = formikButtonRef.current.values.audioVideo;

                  formik.setFieldValue('source', {
                    url: URL.createObjectURL(file),
                    file
                  });
                  formik.submitForm();
                }
              } else {
                formik.submitForm();
              }
            }}
          >
            {intl.formatMessage(messages.add)}
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.contentTable}>
        <Grid>
          <Typography className={classes.title}>
            {intl.formatMessage(messages.addedAudio)}
          </Typography>
          <Typography className={classes.optionLabel}>
            {intl.formatMessage(messages.audioSequence)}
          </Typography>

          <Table
            data={dataAudios}
            columns={tableHead}
            configProps={configProps}
          />
        </Grid>
        <Grid
          container
          justify="flex-end"
          alignItems="flex-end"
          style={{ marginTop: 15 }}
        >
          <Button className={classes.button} onClick={() => handleSubmit()}>
            {intl.formatMessage(messages.submitButton)}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

CreateAudioFlow.propTypes = {};

export default CreateAudioFlow;
