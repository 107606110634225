const tableHead = [
  { key: 'media_id', label: 'ID' },
  { key: 'name', label: 'NOMBRE' },
  { key: 'file_type', label: 'TIPO' }
];

const inputProps = {
  variant: 'outlined',
  fullWidth: true,
  size: 'medium'
};

export { tableHead, inputProps };
