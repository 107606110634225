import React, { useState } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import {
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  Typography
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const DragDropListItem = ({ item, index, options }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Draggable draggableId={`${item.id}_${index}`} index={index}>
      {(provided, snapshot) => (
        <TableRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <TableCell>{item.id}</TableCell>
          <TableCell>{item.name}</TableCell>
          <TableCell>{item.type}</TableCell>
          <TableCell>{item.time} seg</TableCell>
          <TableCell>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            >
              {options.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={() => {
                    option.onClick(item);
                  }}
                >
                  <ListItemIcon>{option.icon}</ListItemIcon>
                  <Typography variant="inherit" noWrap>
                    {option.text}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </TableCell>
        </TableRow>
      )}
    </Draggable>
  );
};

export { DragDropListItem };
