import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleModal: {
    fontSize: '20px',
    fontWeight: 800,
    color: theme.palette.common.black,
    letterSpacing: '6px'
  },

  container: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(3),
      padding: 0,
      margin: `0 ${theme.spacing(1)}px`
    }
  },
  contentActions: {
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  title: {
    fontSize: '20px',
    fontWeight: 800,
    letterSpacing: '6px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
      letterSpacing: '1.6px'
    }
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  contentButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      paddingTop: theme.spacing(2),
      justifyContent: 'center'
    }
  },
  titleDrawer: {
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800,
    color: theme.palette.common.black
  },
  contentTable: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(7)
    }
  }
}));

export { useStyles };
