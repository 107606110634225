/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { messages as formMessages } from '@octopy/react-form';
import { TodayRounded } from '@material-ui/icons';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApi } from 'hooks';
import { CampaingsListMapper } from './helpers';
import { responseMapper } from 'utils/responseMapper';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { messages } from './AddEventMessages';

export const useAddEvent = ({
  dataEdit,
  dataEvents,
  eventId,
  getRobot,
  backSuccess,
  setEventId
}) => {
  const history = useHistory();
  const { formatMessage: f } = useIntl();
  const { modalReportProblem } = useModalConfirmation();
  const [campaings, setCampaings] = useState([]);
  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = storage.user.client.client_id;
  const requiredFieldError = f(formMessages.requiredFieldError);
  const idRobot = history.location.state.robot_id;

  const [getCampaingsByClient] = useApi({
    endpoint: `publicity/campaign/campaigns_by_client/${clientId}/`,
    method: 'get'
  });

  const [createEvent] = useApi({
    endpoint: '/publicity/event/',
    method: 'post'
  });

  const [updateEvent] = useApi({
    endpoint: `/publicity/event/${eventId}/`,
    method: 'put'
  });

  useEffect(() => {
    getCampaings();
  }, []);

  const getCampaings = async () => {
    try {
      const response = await getCampaingsByClient();
      const dataResponse = get(response, 'payload', []);
      const headerResponse = get(response, 'headerResponse', {});

      if (headerResponse.code === 200) {
        const mappedCampaings = responseMapper({
          template: CampaingsListMapper,
          data: dataResponse
        });

        setCampaings(mappedCampaings);
      }
    } catch (error) {}
  };

  const getValidationRules = () =>
    Yup.object().shape({
      name: Yup.string().required(requiredFieldError),
      hour: Yup.string().required(requiredFieldError),
      startDate: Yup.date()
        .nullable()
        .when('hour', {
          is: initialValues.hour === '2',
          then: Yup.date().nullable().required(requiredFieldError)
        }),
      startTime: Yup.date()
        .nullable()
        .when('hour', {
          is: initialValues.hour === '2',
          then: Yup.date().nullable().required(requiredFieldError)
        }),
      endDate: Yup.date()
        .nullable()
        .when('hour', {
          is: initialValues.hour === '2',
          then: Yup.date().nullable().required(requiredFieldError)
        }),
      endTime: Yup.date()
        .nullable()
        .when('hour', {
          is: initialValues.hour === '2',
          then: Yup.date().nullable().required(requiredFieldError)
        })
    });

  const initialValues = {
    name: '',
    hour: '',
    startDate: null,
    startTime: null,
    endDate: null,
    endTime: null
  };

  const inputProps = {
    fullWidth: true
  };

  const fieldFormName = [
    Object.assign(
      {
        type: 'text',
        name: 'name',
        label: f(messages.nameLabel),
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  const fieldFormHour = [
    Object.assign(
      {
        type: 'select',
        name: 'hour',
        label: f(messages.timeRaneg),
        placeholder: f(messages.campaingPlaceholder),
        options: [
          {
            label: 'Siempre',
            name: '1'
          }
          // {
          //   label: 'Personalizado',
          //   name: '2'
          // }
        ],
        getOptionLabel: (option) => `${option.label || ''}`,
        getOptionValue: (option) => `${option.name || ''}`,
        getOptionSelected: (option, value) => option === value,
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  const fieldFormStartDate = [
    Object.assign(
      {
        type: 'datePicker',
        name: 'startDate',
        label: f(messages.startDateLabel),
        placeholder: f(messages.startDatePlaceholder),
        variant: 'inline',
        format: 'dd/MM/yyyy',
        disableToolbar: true,
        inputVariant: 'outlined',
        minDate: new Date().setDate(new Date().getDate() + 0),
        InputProps: {
          endAdornment: <TodayRounded />
        }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'timePicker',
        name: 'startTime',
        label: f(messages.startTimeLabel),
        placeholder: f(messages.startTimePlaceholder),
        variant: 'inline'
      },
      inputProps
    )
  ];

  const fieldFormatEndDate = [
    Object.assign(
      {
        type: 'datePicker',
        name: 'endDate',
        label: f(messages.endDateLabel),
        placeholder: f(messages.endDatePlaceholder),
        variant: 'inline',
        format: 'dd/MM/yyyy',
        disableToolbar: true,
        inputVariant: 'outlined',
        minDate: new Date().setDate(new Date().getDate() + 0),
        InputProps: {
          endAdornment: <TodayRounded />
        }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'timePicker',
        name: 'endTime',
        label: f(messages.endTimeLabel),
        placeholder: f(messages.endTimePlaceholder),
        variant: 'inline'
      },
      inputProps
    )
  ];

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationRules(),
    onSubmit: (values) => {
      let arrayCampaings = [];

      dataEvents.map((item) => {
        arrayCampaings.push({
          campaign_id: item.campaing_id,
          position: item.position
        });
      });

      let body = {
        robot: idRobot,
        name: values.name,
        always: values.hour === '1' ? true : false,
        start_day:
          values.hour === '2'
            ? values.startDate
              ? format(values.startDate, 'dd-MM-yyyy', es)
              : ''
            : null,
        start_hour:
          values.hour === '2'
            ? values.startTime
              ? new Date(values.startTime).toLocaleTimeString()
              : ''
            : null,
        end_day:
          values.hour === '2'
            ? values.endDate
              ? format(values.endDate, 'dd-MM-yyyy', es)
              : ''
            : null,
        end_hour:
          values.hour === '2'
            ? values.endTime
              ? new Date(values.endTime).toLocaleTimeString()
              : ''
            : null,
        campaigns: arrayCampaings
      };

      if (dataEvents.length === 0) {
        modalReportProblem(f(messages.errorSave), f(messages.warningText));
      } else {
        if (dataEdit === null) {
          handleCreateEvent(body);
        } else {
          handleUpdateEvent(body);
        }
      }
    }
  });

  const handleCreateEvent = async (body) => {
    try {
      const response = await createEvent({
        body: body
      });

      const headerResponse = get(response, 'headerResponse', '');

      if (headerResponse.code === 200) {
        getRobot();
        backSuccess();
      }
    } catch (error) {}
  };

  const handleUpdateEvent = async (body) => {
    try {
      const response = await updateEvent({
        body: body
      });
      const headerResponse = get(response, 'headerResponse', '');

      if (headerResponse.code === 200) {
        getRobot();
        backSuccess();
        setEventId(null);
      }
    } catch (error) {}
  };

  return {
    formik,
    campaings,
    fieldFormName,
    fieldFormHour,
    fieldFormStartDate,
    fieldFormatEndDate
  };
};
