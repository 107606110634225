import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { renderTextField } from '@octopy/react-form';
import { Button, Grid } from '@material-ui/core';
import { useSubcategoriesForm } from './useSubcategoriesForm';
import { messages } from './SubcategoriesFormMessages';
import { useStyles } from './SubcategoriesFormStyles';

const SubcategoriesForm = ({
  categoryId,
  subcategoryId = 0,
  handleGetSubcategoryList
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    formik,
    fieldNameSpanishFormik,
    fieldNameEnglishFormik
  } = useSubcategoriesForm({
    categoryId,
    subcategoryId,
    handleGetSubcategoryList
  });

  const fieldNameMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'title' && field.text}
      {field.type === 'text' && renderTextField({ index, formik, field })}
    </Grid>
  );

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={10}>
          {fieldNameSpanishFormik.map(fieldNameMapper)}
        </Grid>
        <Grid item xs={12} sm={10}>
          {fieldNameEnglishFormik.map(fieldNameMapper)}
        </Grid>
      </Grid>
      <Button
        className={classes.buttonSave}
        onClick={() => formik.submitForm()}
      >
        {intl.formatMessage(messages.saveButton)}
      </Button>
    </div>
  );
};

SubcategoriesForm.propTypes = {
  categoryId: PropTypes.number
};

export { SubcategoriesForm };
