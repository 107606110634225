import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleView: {
    id: 'views.Turns.TakeTurn.titleView',
    defaultMessage: 'TURNOS'
  },
  titleSecondView: {
    id: 'views.Turns.TakeTurn.titleSecondView',
    defaultMessage: 'TOMAR TURNO'
  },
  fieldCheckouAssign: {
    id: 'views.Turns.HistoryTurns.fieldCheckouAssign',
    defaultMessage: 'Caja asignada'
  },
  titleTable: {
    id: 'views.Turns.TakeTurn.titleTable',
    defaultMessage: 'TURNOS EN ESPERA'
  },
  id: {
    id: 'views.Turns.HistoryTurns.id',
    defaultMessage: 'ID'
  },
  turn: {
    id: 'views.Turns.HistoryTurns.turn',
    defaultMessage: 'TURNO'
  },
  dateRequest: {
    id: 'views.Turns.HistoryTurns.dateRequest',
    defaultMessage: 'FECHA Y HORA DE SOLICITUD DE TURNO'
  },
  createTurn: {
    id: 'views.Turns.HistoryTurns.createTurn',
    defaultMessage: 'Crear turno'
  },
  adminBox: {
    id: 'views.Turns.HistoryTurns.adminBox',
    defaultMessage: 'Admin cajas'
  },
  assignBox: {
    id: 'views.Turns.TakeTurn.assignBox',
    defaultMessage: 'Asignar caja'
  },
  selectedBox: {
    id: 'views.Turns.TakeTurn.selectedBox',
    defaultMessage: 'Seleccionar caja'
  },
  releaseBox: {
    id: 'views.Turns.TakeTurn.releaseBox',
    defaultMessage: 'Liberar caja'
  },
  withoutRobot: {
    id: 'views.Turns.TakeTurn.withoutRobot',
    defaultMessage: 'No hay robot asignado a esa sucursal'
  }
});
