import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  containerTable: {
    marginTop: theme.spacing(5),
    overflow: 'hidden',
    transition:
      theme.palette.mode === 'light'
        ? 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        : 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: 16,
    boxShadow:
      theme.palette.mode === 'light'
        ? '0 0 2px 0 rgb(170 180 190 / 30%), 0 16px 32px -4px rgb(170 180 190 / 30%)'
        : '0 0 2px 0 rgb(0 0 0 / 25%), 0 16px 32px -4px rgb(0 0 0 / 25%)',
    height: '100%'
  },
  headContainer: {
    height: theme.typography.pxToRem(50)
  },
  imageInputContainer: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  bodyContainer: {
    padding: theme.spacing(5)
  },
  text: {
    fontSize: theme.typography.pxToRem(16),
    color: theme.palette.mode === 'light' ? '#5C5C5C' : theme.palette.secondary
  }
}));

export { useStyles };
