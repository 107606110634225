import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  positionTitle: {
    id: 'components.DragDropList.TableTitle.positionTitle',
    defaultMessage: 'ID'
  },
  nameTitle: {
    id: 'components.DragDropList.TableTitle.nameTitle',
    defaultMessage: 'NOMBRE'
  },
  typeTitle: {
    id: 'components.DragDropList.TableTitle.typeTitle',
    defaultMessage: 'TIPO'
  },
  timeTitle: {
    id: 'components.DragDropList.TableTitle.timeTitle',
    defaultMessage: 'TIEMPO'
  },
  actionTitle: {
    id: 'components.DragDropList.TableTitle.actionTitle',
    defaultMessage: 'ACCIÓN'
  }
});
