import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import Slider from 'components/MUI/Slider/Slider';
import {
  Typography,
  Grid,
  Card,
  Button,
  TextField,
  MenuItem,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { messages as formMessages } from '@octopy/react-form';
import * as Yup from 'yup';
import { useApi } from 'hooks';
import { useFormik } from 'formik';
import { SoundPlayer } from 'components/SoundPlayer';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { messages } from './DialogFlowFormMessages';
import { useStyles } from './DialogFlowFormStyles';

const DialogFlowForm = ({
  title,
  id,
  indice,
  handleSetDefault,
  eligibleDefault,
  disabledAgent,
  agentByRobot
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const { modalSuccess, modalReportProblem } = useModalConfirmation();
  const [file, setFile] = useState(null);
  const [speedValue, setSpeedValue] = useState(0.25);
  const [pitchValue, setPitchValue] = useState(-20);
  const [showSoundPlayer, setShowSoundPlayer] = useState(false);
  const [urlSpeech, setUrlSpeech] = useState('');
  const [language, setLanguage] = useState([]);
  const [nameFile, setNameFile] = useState(null);

  const [createAgent] = useApi({
    endpoint: '/agents/',
    method: 'post'
  });
  const [updateAgent] = useApi({
    endpoint: '/agents',
    method: 'put'
  });
  const [createSpeech] = useApi({
    endpoint: '/speech/',
    method: 'post'
  });
  const [listLanguage] = useApi({
    endpoint: '/language/',
    method: 'get'
  });

  useEffect(() => {
    handleLanguageList();
  }, []);

  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );

  const validationSchema = Yup.object({
    name: Yup.string().required(requiredFieldError),
    language: Yup.string().required(requiredFieldError),
    test_phrase: Yup.string().required(requiredFieldError),
    speed: Yup.string(),
    pitch: Yup.string(),
    voice_tone: Yup.string().required(requiredFieldError),
    session_type: Yup.string().required(requiredFieldError)
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      language: '',
      test_phrase: '',
      robot: id,
      speed: '',
      pitch: '',
      voice_tone: '',
      session_type: ''
    },
    validationSchema,
    onSubmit: (values) => {
      if (file === null) {
        modalReportProblem(
          intl.formatMessage(messages.titleModal),
          intl.formatMessage(messages.infoTextModal)
        );
      } else {
        const newData = {
          ...values,
          session_type: values.session_type,
          agent_key: file,
          speed: speedValue,
          pitch: pitchValue,
          default: eligibleDefault
        };

        if (agentByRobot.updateAgentRobot) {
          handleUpdate(newData);
        } else {
          handleSubmit(newData);
        }
      }
    }
  });

  const voiceTone = [
    { value: 'neutral', label: 'Neutral' },
    { value: 'female', label: 'Mujer' },
    { value: 'male', label: 'Hombre' }
  ];

  const handleSubmit = async (values) => {
    try {
      const responseCreateAgent = await createAgent({
        body: values
      });

      if (responseCreateAgent.headerResponse.code === 200) {
        setUrlSpeech('');
        setShowSoundPlayer(false);
        modalSuccess(
          intl.formatMessage(messages.titleSuccessModal),
          intl.formatMessage(messages.textSuccessModal)
        );
      }
    } catch (error) {}
  };

  const handleUpdate = async (values) => {
    try {
      const responseCreateAgent = await updateAgent({
        urlParams: `${agentByRobot.agent_id}/`,
        body: values
      });

      if (responseCreateAgent.headerResponse.code === 200) {
        setUrlSpeech('');
        setShowSoundPlayer(false);
        modalSuccess(
          intl.formatMessage(messages.titleSuccessModal),
          intl.formatMessage(messages.textSuccessModal)
        );
      }
    } catch (error) {}
  };

  const handleSubmitShowSoundPlayer = async () => {
    try {
      const formatData = {
        key: file,
        language: formik.values.language,
        tone: formik.values.voice_tone,
        speed: speedValue,
        pitch: pitchValue,
        text: formik.values.test_phrase
      };

      if (file === null) {
        modalReportProblem(
          intl.formatMessage(messages.titleModal),
          intl.formatMessage(messages.infoTextModalPreview)
        );
      } else {
        const responseShowSoundPlayer = await createSpeech({
          body: formatData
        });

        if (responseShowSoundPlayer.headerResponse.code === 200) {
          setShowSoundPlayer(true);
          const urlResponse = get(responseShowSoundPlayer, 'payload', []);

          setUrlSpeech(urlResponse);
        }
      }
    } catch (error) {}
  };

  const handleLanguageList = async () => {
    const responseLanguage = await listLanguage();
    const responseList = get(responseLanguage, 'payload', []);

    setLanguage(responseList);
  };

  const handleUploadFile = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    if (file.name) {
      reader.onload = () => {
        setFile(JSON.parse(reader.result));
      };

      reader.readAsText(file);
      setNameFile(file.name);
    }
  };

  useEffect(() => {
    formik.setValues({
      name: agentByRobot.name,
      language: agentByRobot.language.language_id,
      test_phrase: agentByRobot.test_phrase,
      robot: id,
      voice_tone: agentByRobot.voice_tone,
      session_type: `${agentByRobot.session_type}`
    });

    setPitchValue(agentByRobot.pitch);
    setSpeedValue(agentByRobot.speed);
    setFile(agentByRobot.agent_key);
    setNameFile(agentByRobot.agent_key.project_id);
  }, []);

  return (
    <Card className={classes.root}>
      <Grid container spacing={2} className={classes.container}>
        <Grid
          className={classes.sectionTitle}
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
        >
          <span>{title}</span>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            label={intl.formatMessage(messages.nameInput)}
            placeholder={intl.formatMessage(messages.nameInput)}
            type="text"
            name="name"
            id="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid style={{ display: 'flex' }}>
            <Typography className={classes.jsonKey}>
              {intl.formatMessage(messages.labelButtonFile)}
            </Typography>
            <label className={classes.uploadInfo}>
              {nameFile === null
                ? intl.formatMessage(messages.uploadInfo)
                : nameFile}
            </label>
          </Grid>
          <Grid item xs={12} md={12}>
            <input
              accept=".json"
              id={`'documentFile'${indice}`}
              multiple
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => handleUploadFile(e)}
            />
            <label htmlFor={`'documentFile'${indice}`}>
              <Button color="primary" component="span">
                {intl.formatMessage(messages.buttonFile)}
              </Button>
            </label>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            label={
              <Typography>{intl.formatMessage(messages.language)}</Typography>
            }
            name="language"
            id="language"
            select
            onChange={formik.handleChange}
            value={formik.values.language}
            className={classes.rootSelect}
            error={formik.touched.language && Boolean(formik.errors.language)}
            helperText={formik.touched.language && formik.errors.language}
          >
            {language.map((option) => (
              <MenuItem key={option.language_id} value={option.language_id}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            label={
              <Typography>{intl.formatMessage(messages.voiceTone)}</Typography>
            }
            name="voice_tone"
            id="voice_tone"
            select
            onChange={formik.handleChange}
            value={formik.values.voice_tone}
            className={classes.rootSelect}
            error={
              formik.touched.voice_tone && Boolean(formik.errors.voice_tone)
            }
            helperText={formik.touched.voice_tone && formik.errors.voice_tone}
          >
            {voiceTone.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                className={classes.root}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <Slider
            key={`${agentByRobot.agent_id}-speed`}
            label={
              <Typography>{intl.formatMessage(messages.speed)}</Typography>
            }
            min={0.25}
            max={4}
            stepSlider={0.25}
            initialValueSpeed={speedValue}
            speedValue={(value) => setSpeedValue(value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Slider
            key={`${agentByRobot.agent_id}-pitch`}
            label={
              <Typography>{intl.formatMessage(messages.pitch)}</Typography>
            }
            min={-20}
            max={20}
            stepSlider={1}
            initialValuePitch={pitchValue}
            pitchValue={(data) => setPitchValue(data)}
            sliderPitch={true}
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <TextField
            variant="outlined"
            fullWidth
            label={intl.formatMessage(messages.testPhrase)}
            placeholder={intl.formatMessage(messages.textPlaceholder)}
            type="text"
            name="test_phrase"
            id="test_phrase"
            value={formik.values.test_phrase}
            onChange={formik.handleChange}
            error={
              formik.touched.test_phrase && Boolean(formik.errors.test_phrase)
            }
            helperText={formik.touched.test_phrase && formik.errors.test_phrase}
          />
        </Grid>
        {/* <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            variant="outlined"
            label={
              <Typography>
                {intl.formatMessage(messages.optionTitle)}
              </Typography>
            }
            name="session_type"
            id="session_type"
            select
            onChange={formik.handleChange}
            value={formik.values.session_type}
            className={classes.rootSelect}
            error={
              formik.touched.session_type && Boolean(formik.errors.session_type)
            }
            helperText={
              formik.touched.session_type && formik.errors.session_type
            }
          >
            {sessionOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                className={classes.root}
              >
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid> */}
        <Grid item xs={12} md={6}>
          <FormControlLabel
            control={
              <Checkbox
                checked={eligibleDefault}
                onChange={() => handleSetDefault(indice, !eligibleDefault)}
                name="checkedB"
                color="primary"
                disabled={disabledAgent}
              />
            }
            labelPlacement="start"
            label={
              <Typography style={{ fontSize: '15px' }}>
                {intl.formatMessage(messages.checkTitle)}
              </Typography>
            }
          />
        </Grid>
        <Grid container spacing={2} className={classes.contentActions}>
          <Grid item>{showSoundPlayer && <SoundPlayer src={urlSpeech} />}</Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  className={classes.button}
                  onClick={() => {
                    handleSubmitShowSoundPlayer();
                  }}
                >
                  {formatMessage(messages.preview)}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  className={classes.button}
                  onClick={() => formik.submitForm()}
                >
                  {agentByRobot.updateAgentRobot
                    ? formatMessage(messages.update)
                    : formatMessage(messages.buttonSave)}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

DialogFlowForm.propTypes = {
  title: PropTypes.string,
  id: PropTypes.node,
  indice: PropTypes.node,
  handleSetDefault: PropTypes.func,
  eligibleDefault: PropTypes.bool,
  disabledAgent: PropTypes.bool
};

DialogFlowForm.defaultProps = {
  handleSetDefault: () => {}
};

export { DialogFlowForm };
