import React, { useEffect } from 'react';

import PropTypes from 'prop-types';

import Button from '@material-ui/core/Button';
import PublishIcon from '@material-ui/icons/Publish';

import { useStyles } from './ButtonSimpleMultimediaStyles';

import { useForm } from './hooks/useForm';

const ButtonSimpleMultimedia = ({ audio, video, videoImage, formikRef }) => {
  const classes = useStyles();
  const formikReference = formikRef;

  let typeAccept = '';

  switch (true) {
    case audio:
      typeAccept = 'audio/*';
      break;
    case video:
      typeAccept = 'video/*';
      break;
    case videoImage:
      typeAccept = 'video/*, image/*';
      break;
    default:
      break;
  }

  const { formik, field } = useForm();
  const { audioVideo } = field;

  const handleAudioVideo = (event) => {
    /**SI se require validar multiples archivos sugieron que se haga aquí  */
    if (event.target.files.length !== 0) {
      const file = event.target.files[0];

      formik.setFieldValue('audioVideo', file);
    }
  };

  useEffect(() => {
    if (!!formikReference) formikReference.current = formik;
  }, [formik.values.audioVideo]);

  return (
    <div className={classes.root}>
      <input
        type="file"
        id={audioVideo.name}
        name={audioVideo.name}
        accept={typeAccept}
        className={classes.input}
        onChange={handleAudioVideo}
      />
      <label className={classes.labelButtn} htmlFor={audioVideo.name}>
        <Button
          variant="contained"
          color="primary"
          component="span"
          startIcon={<PublishIcon />}
        >
          {field.audioVideo.label}
        </Button>
        <div className={classes.info}>
          {formik.values.audioVideo !== null && !formik.errors.audioVideo
            ? formik.values.audioVideo?.name
            : ''}
        </div>
        <div className={classes.error}>
          {formik.errors.audioVideo ? formik.errors.audioVideo : ''}
        </div>
      </label>
    </div>
  );
};

ButtonSimpleMultimedia.propTypes = {
  audio: PropTypes.bool,
  video: PropTypes.bool,
  videoImage: PropTypes.bool
};

ButtonSimpleMultimedia.defaultProps = {
  audio: true,
  video: false,
  videoImage: false
};

export { ButtonSimpleMultimedia };
