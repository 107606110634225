import React from 'react';
import { App } from 'components/App';
import { LanguageProvider } from 'components/Language';
import { ThemeProvider } from 'components/Theme';
import { ModalProvider } from '@octopy/react-modal';
import { LoaderProvider } from '@octopy/react-loader';
import { SideMenuProvider } from 'components/SideMenu';
// import { ContextLogger } from 'components/ContextLogger';
import { config } from 'providers/config';
import { translations } from './translations';
// import appContext from './context';

const contextConfig = { objectDiffs: true, arrayDiffs: false };

export function Root() {
  return (
    <>
      <ThemeProvider defaultTheme={config.siteConfig.defaultTheme}>
        <LanguageProvider
          locale={config.siteConfig.languageCode}
          translations={translations}
        >
          <LoaderProvider>
            <ModalProvider>
              <SideMenuProvider>
                <App />
                {/* <ContextLogger contexts={appContext} config={contextConfig} /> */}
              </SideMenuProvider>
            </ModalProvider>
          </LoaderProvider>
        </LanguageProvider>
      </ThemeProvider>
    </>
  );
}
