const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles((theme) => ({
  label: {
    fontWeight: 'bold',
    fontSize: theme.typography.pxToRem(14)
  },
  input: {
    marginTop: theme.spacing(1)
  }
}));

export { useStyles };
