const robotsMapper = {
  name: 'robot_id',
  label: 'name'
};

const agentMapper = {
  key: 'agent_key',
  language: 'language.language_id',
  tone: 'voice_tone',
  speed: 'speed',
  pitch: 'pitch'
};

export { robotsMapper, agentMapper };
