import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { messages as formMessages } from '@octopy/react-form';
import { useDrawer } from 'components/Drawer';
import { Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApi } from 'hooks';
import { inputProps } from '../utils';
import { formikMapper } from './schemesMapper';
import { responseMapper } from 'utils/responseMapper';
import { messages } from './CategoriesFormMessages';
import { useStyles } from './CategoriesFormStyles';

export const useCategoriesForm = ({ categoryId }) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { handleCloseDrawer } = useDrawer();
  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = storage.user.client.client_id;

  const [categoryCreate] = useApi({
    endpoint: '/catalogue/category/',
    method: 'post'
  });

  const [categoryUpdate] = useApi({
    endpoint: '/catalogue/category',
    method: 'put'
  });

  const [getCategory] = useApi({
    endpoint: '/catalogue/category',
    method: 'get'
  });

  const handleGetCategory = async () => {
    const response = await getCategory({
      urlParams: categoryId
    });

    if (response.headerResponse.code === 200) {
      const dataResponse = get(response, 'payload', []);

      const mappedFormikValues = responseMapper({
        template: formikMapper,
        data: dataResponse
      });

      formik.setValues(mappedFormikValues);
    }
  };

  useEffect(() => {
    if (categoryId !== 0) handleGetCategory();
  }, [categoryId]);

  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );

  const getValidationRules = () =>
    Yup.object({
      nameSpanish: Yup.string().required(requiredFieldError),
      nameEnglish: Yup.string().required(requiredFieldError)
    });

  const formik = useFormik({
    initialValues: {
      nameSpanish: '',
      nameEnglish: ''
    },
    validationSchema: getValidationRules(),
    onSubmit: (values) => {
      let body = {
        client: clientId,
        name: [
          {
            language_id: 1,
            text: values.nameSpanish
          },
          {
            language_id: 2,
            text: values.nameEnglish
          }
        ]
      };

      if (categoryId === 0) {
        handleCreateCategory(body);
      } else {
        handleUpdateCategory(body);
      }
    }
  });

  const handleCreateCategory = async (data) => {
    const response = await categoryCreate({
      body: data
    });

    if (response.headerResponse.code === 200) {
      handleCloseDrawer(false);
      history.push('/categories');
    }
  };

  const handleUpdateCategory = async (data) => {
    const response = await categoryUpdate({
      urlParams: `${categoryId}/`,
      body: data
    });

    if (response.headerResponse.code === 200) {
      handleCloseDrawer(false);
      history.push('/categories');
    }
  };

  const fieldNameSpanishFormik = [
    Object.assign({
      type: 'title',
      breakpoints: { xs: 12 },
      text: (
        <Typography className={classes.description}>
          {intl.formatMessage(messages.descriptionAdd)}
        </Typography>
      )
    }),
    Object.assign(
      {
        type: 'text',
        name: 'nameSpanish',
        label: intl.formatMessage(messages.nameLabel),
        placeholder: intl.formatMessage(messages.namePlaceholder),
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  const fieldNameEnglishFormik = [
    Object.assign({
      type: 'title',
      breakpoints: { xs: 12 },
      text: (
        <Typography className={classes.titleSectionEnglish}>
          {intl.formatMessage(messages.titleEnglish)}
        </Typography>
      )
    }),
    Object.assign(
      {
        type: 'text',
        name: 'nameEnglish',
        label: intl.formatMessage(messages.nameEnglishLabel),
        placeholder: intl.formatMessage(messages.nameEnglishPlaceholder),
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  return {
    formik,
    fieldNameSpanishFormik,
    fieldNameEnglishFormik
  };
};
