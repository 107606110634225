import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      paddingTop: theme.spacing(3),
      margin: `0 ${theme.spacing(1.5)}px`
    }
  },
  bottonStyle: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 10,
    letterSpacing: '1px'
  },
  typeRed: {
    color: theme.palette.error.main,
    fontSize: '14px'
  },
  typeOrange: { color: theme.palette.warning.main, fontSize: '14px' },
  typeGreen: { color: theme.palette.success.main, fontSize: '14px' },
  contentTable: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(7)
    }
  }
}));

export { useStyles };
