import { useApi } from 'hooks';

const useApiCalls = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const idClient = storage.user.client.client_id;
  const [getAllPermissions] = useApi({
    endpoint: '/management/profiles/',
    method: 'get'
  });

  const getAllPermissionsList = async () => {
    const response = await getAllPermissions();

    return response.payload;
  };

  const [getPermissions] = useApi({
    endpoint: '/management/profiles/profiles_by_client',
    method: 'get'
  });

  const getPermissionsList = async () => {
    const response = await getPermissions({ urlParams: idClient });

    return response.payload;
  };

  const [deletePermission] = useApi({
    endpoint: '/management/profiles',
    method: 'delete'
  });

  const deletePermissionById = async (itemId) => {
    await deletePermission({ urlParams: itemId });
  };

  return { getAllPermissionsList, getPermissionsList, deletePermissionById };
};

export default useApiCalls;
