import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  mainIcon: {
    fontSize: theme.typography.pxToRem(100),
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.black
        : theme.palette.common.white
  },
  tittleView: {
    fontSize: theme.typography.pxToRem(20),
    letterSpacing: theme.typography.pxToRem(6),
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.black
        : theme.palette.common.white,
    fontWeight: 800
  },
  noAssign: {
    fontSize: theme.typography.pxToRem(14),
    color: theme.palette.grey
  },
  numberTextNoAssign: {
    display: 'block',
    marginTop: `-${theme.typography.pxToRem(10)}`,
    fontSize: theme.typography.pxToRem(40),
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.black
        : theme.palette.common.white,
    fontWeight: 800
  },
  secondIcon: {
    fontSize: theme.typography.pxToRem(100),
    color: theme.palette.primary.main
  },
  numberText: {
    fontSize: theme.typography.pxToRem(40),
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.black
        : theme.palette.common.white,
    fontWeight: 800
  },
  contentButton: {
    display: 'flex',
    alignItems: 'flex-end',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  buttonAttend: {
    width: '50%',
    margin: `${theme.typography.pxToRem(10)}`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  },
  textButton: {
    fontSize: theme.typography.pxToRem(16)
  },
  buttonNext: {
    margin: `${theme.typography.pxToRem(10)}`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  buttonNoWait: {
    width: '70%',
    margin: `${theme.typography.pxToRem(10)}`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%'
    }
  }
}));

export { useStyles };
