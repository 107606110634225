import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography
} from '@material-ui/core';
import { Delete, ExpandMore, MoreHoriz } from '@material-ui/icons';

import { useStyles } from './SurveySectionStyles';
import { messages as MessagesSetup } from '../../views/Surveys/CreateSurveys/Setup/components/SetupHeader/SetupHeaderMessages';
import { useIntl } from 'react-intl';

const SurveySection = ({
  onChangeData,
  data,
  onDelete,
  startDrag,
  endDrag,
  isEnglish,
  messages
}) => {
  const classes = useStyles();
  const { formatMessage } = useIntl();

  const validacionMensajeRequired = () => {
    let messageRequired = '';

    if (isEnglish) {
      if (data.dataEnglish?.required) {
        messageRequired = formatMessage(messages.requiredErrorEnglish);
      }
    } else {
      if (data.data?.required) {
        messageRequired = formatMessage(messages.requiredError);
      }
    }

    return messageRequired;
  };

  return (
    <Card className={classes.container}>
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMore />}>
          <Grid item xs={12} sm={12} md={10} lg={10}>
            <Typography color="error">{validacionMensajeRequired()}</Typography>
          </Grid>
          <Grid container justify="flex-end" xs={12} sm={12} md={2} lg={2}>
            <IconButton className={classes.icon} onClick={onDelete}>
              <Delete />
            </IconButton>
            <IconButton
              className={classes.icon}
              onMouseOver={startDrag}
              onMouseLeave={endDrag}
            >
              <MoreHoriz />
            </IconButton>
          </Grid>
        </AccordionSummary>
        <Divider variant="middle" />
        <AccordionDetails>
          <Grid container spacing={2} className={classes.questionZone}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    label={
                      isEnglish
                        ? formatMessage(messages.sectionTitleEnglish)
                        : formatMessage(messages.sectionTitle)
                    }
                    value={isEnglish ? data.dataEnglish.title : data.data.title}
                    fullWidth
                    name="title"
                    onChange={onChangeData}
                    variant="outlined"
                  />
                  <span className={classes.maxCharactersHelperText}>
                    {isEnglish
                      ? formatMessage(MessagesSetup.max100CharactersEnglish)
                      : formatMessage(MessagesSetup.max100Characters)}
                  </span>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <TextField
                    label={
                      isEnglish
                        ? formatMessage(messages.descriptionEnglish)
                        : formatMessage(messages.description)
                    }
                    value={
                      isEnglish
                        ? data.dataEnglish.description
                        : data.data.description
                    }
                    fullWidth
                    name="description"
                    onChange={onChangeData}
                    variant="outlined"
                  />
                  <span className={classes.maxCharactersHelperText}>
                    {isEnglish
                      ? formatMessage(MessagesSetup.max500CharactersEnglish)
                      : formatMessage(MessagesSetup.max500Characters)}
                  </span>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Card>
  );
};

SurveySection.propTypes = {};

export default SurveySection;
