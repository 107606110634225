/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { DragDropContext } from 'react-beautiful-dnd';
import { Droppable } from 'react-beautiful-dnd';

const DraggableList = React.memo(({ children, onDragEnd }) => (
  <DragDropContext onDragEnd={onDragEnd}>
    <Droppable droppableId="droppable-list">
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.droppableProps}
          style={{ width: '100%' }}
        >
          {children}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  </DragDropContext>
));

DraggableList.propTypes = {
  children: PropTypes.any,
  onDragEnd: PropTypes.any
};

export default DraggableList;
