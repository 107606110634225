import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.InformationForm.title',
    defaultMessage: 'Agregar información para el personal de laboratorio'
  },
  descriptionItem: {
    id: 'components.InformationForm.descriptionItem',
    defaultMessage: 'Agrega información del producto'
  },
  nameInformation: {
    id: 'components.InformationForm.nameInformation',
    defaultMessage: 'Nombre'
  },
  namePlaceholderInformation: {
    id: 'components.InformationForm.namePlaceholderInformation',
    defaultMessage: 'Nombre'
  },
  descriptionInformation: {
    id: 'components.InformationForm.descriptionInformation',
    defaultMessage: 'Descripción'
  },
  descriptionPlaceholderInformation: {
    id: 'components.InformationForm.descriptionPlaceholderInformation',
    defaultMessage: 'Descripción'
  },
  descriptionRobot: {
    id: 'components.InformationForm.descriptionRobot',
    defaultMessage: 'Agrega un texto para el robot'
  },
  descriptionRobotSubtitle: {
    id: 'components.InformationForm.descriptionRobot',
    defaultMessage:
      'Este texto se producirá cuando el usuario vea este producto'
  },
  add: {
    id: 'components.ProductForm.add',
    defaultMessage: 'CREAR'
  },
  save: {
    id: 'components.ProductForm.save',
    defaultMessage: 'GUARDAR'
  }
});
