import React from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useModal } from '@octopy/react-modal';
import { Table } from '@octopy/react-table';
import { Typography, Button, Grid, IconButton } from '@material-ui/core';
import { AddCircle, Close, Delete, ExitToApp } from '@material-ui/icons';
import { filters, tableHead } from './BranchesTableConfig';
import { useApiBranches } from './hooks/useApiBranches';
import { useStyles } from './BranchesStyles';
import { messages } from './BranchesMessages';
import { useExport } from 'hooks/useExport';
import { mapBranches } from './helpers';

const Branches = () => {
  const classes = useStyles();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { branchList, handleDeleteBranch } = useApiBranches();
  const { exportToExcel } = useExport();
  const intl = useIntl();
  const history = useHistory();

  const handleModal = async (data) => {
    handleOpenModal({
      title: (
        <Grid className={classes.contentModal}>
          <Grid className={classes.contentIconClose}>
            <IconButton onClick={() => handleCloseModal()}>
              <Close className={classes.iconClose} />
            </IconButton>
          </Grid>
          <Typography className={classes.titleModal}>
            {intl.formatMessage(messages.titleModal)}
          </Typography>
        </Grid>
      ),
      body: (
        <Grid style={{ marginBottom: 30 }}>
          <Typography className={classes.infoTextModal}>
            {intl.formatMessage(messages.infoTextModal)} &nbsp;
            <Typography className={classes.nameData}>{data.name}</Typography>?
          </Typography>
          <Grid className={classes.contentButtonsModal}>
            <Button
              className={classes.buttonCanel}
              onClick={() => handleCloseModal()}
            >
              {intl.formatMessage(messages.cancel)}
            </Button>
            <Button
              className={classes.button}
              onClick={() => handleDeleteBranch(data.branchoffice_id)}
              endIcon={<Delete />}
            >
              {intl.formatMessage(messages.delete)}
            </Button>
          </Grid>
        </Grid>
      ),
      configProps: {
        maxWidth: 'lg'
      }
    });
  };

  const configProps = {
    filters,
    actions: {
      edit: {
        onClick: async (item) => {
          history.push('branch/form', { idBranch: item.branchoffice_id });
        },
        disabled: false,
        hidden: false
      },
      customs: {
        text: 'Eliminar',
        onClick: (item) => handleModal(item),
        icon: <Delete />
      }
    },
    selectedOptions: {
      checkboxHidden: true
    }
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={6}>
          <div className={classes.pageTitle}>
            {intl.formatMessage(messages.title)}
          </div>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} className={classes.contentButons}>
            <Grid item>
              <Button
                className={classes.button}
                onClick={() =>
                  exportToExcel(
                    mapBranches(branchList),
                    intl.formatMessage(messages.title)
                  )
                }
                endIcon={<ExitToApp />}
              >
                {intl.formatMessage(messages.exportButton)}
              </Button>
            </Grid>
            <Grid item>
              <Button
                className={classes.button}
                onClick={() =>
                  history.push('branch/form', {
                    idBranch: 0
                  })
                }
              >
                {intl.formatMessage(messages.addBranch)}
                <AddCircle style={{ marginLeft: '8px', fontSize: '16px' }} />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.contentTable}>
        <Table
          data={branchList}
          columns={tableHead}
          configProps={configProps}
        />
      </Grid>
    </div>
  );
};

Branches.propTypes = {};

export default Branches;
