import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'routes.Branches.title',
    defaultMessage: 'Sucursales'
  },
  addBranch: {
    id: 'routes.Branches.addBranch',
    defaultMessage: 'AGREGAR'
  },
  titleModal: {
    id: 'components.Branches.titleModal',
    defaultMessage: 'ELIMINAR SUCURSALES'
  },
  infoTextModal: {
    id: 'components.Branches.infoTextModal',
    defaultMessage: '¿Confirma que desea eliminar estos ítems'
  },
  cancel: {
    id: 'components.Branches.cancel',
    defaultMessage: 'CANCELAR'
  },
  delete: {
    id: 'components.Branches.delete',
    defaultMessage: 'ELIMINAR'
  },
  exportButton: {
    id: 'components.Branches.exportButton',
    defaultMessage: 'EXPORTAR'
  }
});
