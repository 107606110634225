import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    //paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '15px'
    }
  },
  contentTitles: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  advertisingTitle: {
    fontSize: '26px',
    letterSpacing: '6px',
    color: theme.palette.common.black,
    fontWeight: 800
  },
  screensTitle: {
    fontSize: '26px',
    letterSpacing: '6px',
    color: theme.palette.info.light,
    fontWeight: 800
  },
  contentButons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-start',
      marginTop: '15px'
    }
  },
  downloadButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: 10.3
  },
  updateButton: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.common.white,
    padding: 10.3
  },
  textButon: {
    fontSize: '15px',
    fontWeight: 800
  },
  checkColor: {
    color: theme.palette.success.light
  },
  clearColor: {
    color: theme.palette.error.main
  },
  cloudColor: {
    color: theme.palette.info.light
  }
}));

export { useStyles };
