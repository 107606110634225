import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  solution: {
    id: 'components.Notifications.solution',
    defaultMessage: 'Solución:'
  },
  high: {
    id: 'components.Notifications.high',
    defaultMessage: 'Prioridad: Alta'
  },
  medium: {
    id: 'components.Notifications.medium',
    defaultMessage: 'Prioridad: Media'
  },
  alertNumber: {
    id: 'components.Notifications.alerNumber',
    defaultMessage: 'Alertas'
  }
});
