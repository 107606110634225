import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  welcomeHeader: {
    id: 'components.WelcomeScreen.welcomeHeader',
    defaultMessage: 'Bienvenido'
  },
  welcomeRole: {
    id: 'components.WelcomeScreen.welcomeRole',
    defaultMessage: 'Has iniciado sesión como:'
  }
});
