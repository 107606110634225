import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  input: {
    display: 'none'
  },
  labelButtn: {
    display: 'flex',
    alignItems: 'center'
  },
  info: {
    marginLeft: theme.spacing(1),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minWidth: 0
  },
  error: {
    color: theme.palette.error.main
  }
}));

export { useStyles };
