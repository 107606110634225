import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleAddVideo: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.Video.titleAddMedia',
    defaultMessage: 'AGREGAR VIDEO'
  },
  titleEditVideo: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.Video.titleEditVideo',
    defaultMessage: 'EDITAR VIDEO'
  },
  textAddVideo: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.Video.textAddVideo',
    defaultMessage: 'Agrega un video o selecciona uno de la biblioteca'
  },
  formName: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.Video.Form.formName',
    defaultMessage: 'Nombre'
  },
  formTime: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.Video.Form.formTime',
    defaultMessage: 'Tiempo'
  },
  formButtonCancel: {
    id:
      'views.Scenes.Advertising.Scenes.SceneDesign.Video.Form.formButtonCancel',
    defaultMessage: 'CANCELAR'
  },
  formButtonSave: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.Video.Form.formButtonSave',
    defaultMessage: 'GUARDAR'
  },
  validationTime: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.Video.Form.validationTime',
    defaultMessage: 'El número de segundos es inválido'
  },
  textSearch: {
    id: 'views.Scenes.Advertising.Scenes.SceneDesign.Video.Form.textSearch',
    defaultMessage: 'Buscar'
  }
});
