import { useEffect } from 'react';
import { useLoader } from '@octopy/react-loader';
import { get } from 'lodash';
import { useApi } from 'hooks';
import { messages as formMessages } from '@octopy/react-form';
import { messages } from './FormScenesMessages';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';

export const useFormScene = (idScene, isDesign, callback, formikRef) => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const idClient = storage.user.client.client_id;
  const { formatMessage: f } = useIntl();
  const { handleShowLoader } = useLoader();

  //Form structure
  const getInitialValues = () => ({
    client: idClient,
    name: '',
    media: []
  });

  const requiredFieldError = f(formMessages.requiredFieldError);

  const getValidationRules = () =>
    Yup.object({
      name: Yup.string().required(requiredFieldError)
    });

  const getFields = () => [
    {
      type: 'text',
      name: 'name',
      label: f(messages.nameLabel),
      placeholder: f(messages.nameLabel),
      breakpoints: { xs: 12, lg: 12 }
    }
  ];

  //inputProps Form
  const inputProps = {
    variant: 'outlined',
    fullWidth: true,
    size: 'medium'
  };

  //Get Scene
  const [getScene] = useApi({
    endpoint: `publicity/scene`,
    method: 'get'
  });

  const handleGetScene = async () => {
    const response = await getScene({ urlParams: idScene });
    const sceneResponse = get(response, 'payload', {});
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code == 200) {
      formikRef.current.setValues({
        client: idClient,
        name: sceneResponse.name,
        media: getRequestMediaData(sceneResponse.media)
      });
    }

    handleShowLoader(false);
  };

  useEffect(() => {
    if (idScene != 0) {
      handleShowLoader(true);
      handleGetScene();
    }
  }, []);

  //Edit Scene
  const [editScene] = useApi({
    endpoint: `publicity/scene/${idScene}/`,
    method: 'put'
  });

  const handleEditScene = async (body) => {
    const response = await editScene({
      body
    });
    const headerResponse = get(response, 'headerResponse', {});
    const sceneResponse = get(response, 'payload', {});

    if (headerResponse.code == 200) {
      callback(sceneResponse.scene_id, '', isDesign);
    } else {
      handleShowLoader(false);
    }
  };

  const handleSubmit = (values) => {
    if (idScene == 0) {
      callback(0, values.name, isDesign);
    } else {
      handleShowLoader(true);
      handleEditScene(values);
    }
  };

  //Utils
  const getRequestMediaData = (sources) => {
    let requestData = [];

    sources.map((source, index) => {
      requestData.push({
        media_id: source.media_id,
        position: index + 1,
        duration: source.duration
      });
    });

    return requestData;
  };

  return {
    getInitialValues,
    getValidationRules,
    getFields,
    inputProps,
    handleSubmit
  };
};
