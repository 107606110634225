import { useIntl } from 'react-intl';

import { messages } from '../SetupHeaderMessages';

export const useFields = (formik) => {
  const intl = useIntl();

  const commonBreakpoints = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6
  };

  const fields = {
    surveyTitle: {
      type: 'text',
      name: 'surveyTitle',
      value: formik.values.surveyTitle,
      label: intl.formatMessage(messages.surveyTitle),
      onChange: formik.handleChange,
      error: formik.touched.surveyTitle && formik.errors.surveyTitle,
      helperText:
        formik.touched.surveyTitle && !!formik.errors.surveyTitle
          ? formik.errors.surveyTitle
          : intl.formatMessage(messages.max100Characters),
      breakpoints: commonBreakpoints
    },
    surveyDescription: {
      type: 'text',
      name: 'surveyDescription',
      value: formik.values.surveyDescription,
      label: intl.formatMessage(messages.surveyDescription),
      onChange: formik.handleChange,
      error:
        formik.touched.surveyDescription && !!formik.errors.surveyDescription,
      helperText:
        formik.touched.surveyDescription && !!formik.errors.surveyDescription
          ? formik.errors.surveyDescription
          : intl.formatMessage(messages.max500Characters),
      breakpoints: commonBreakpoints
    },
    surveyFarewell: {
      type: 'text',
      name: 'surveyFarewell',
      value: formik.values.surveyFarewell,
      label: intl.formatMessage(messages.surveyFarewell),
      onChange: formik.handleChange,
      error: formik.touched.surveyFarewell && !!formik.errors.surveyFarewell,
      helperText:
        formik.touched.surveyFarewell && !!formik.errors.surveyFarewell
          ? formik.errors.surveyFarewell
          : intl.formatMessage(messages.max500Characters),
      breakpoints: commonBreakpoints
    }
  };

  const fieldIsEnglishFormik = [
    Object.assign(
      {
        type: 'checkbox',
        name: 'isEnglishSurveys',
        label: intl.formatMessage(messages.englishSurveys),
        breakpoints: { xs: 12 },
        color: 'Primary'
      },
      { inputProps: { fullWidth: true } }
    )
  ];

  const fieldsEnglish = {
    surveyTitleEnglish: {
      type: 'text',
      name: 'surveyTitleEnglish',
      value: formik.values.surveyTitleEnglish,
      label: intl.formatMessage(messages.titleEnglish),
      onChange: formik.handleChange,
      error:
        formik.touched.surveyTitleEnglish && formik.errors.surveyTitleEnglish,
      helperText:
        formik.touched.surveyTitleEnglish && !!formik.errors.surveyTitleEnglish
          ? formik.errors.surveyTitleEnglish
          : intl.formatMessage(messages.max100CharactersEnglish),
      breakpoints: commonBreakpoints
    },
    surveyDescriptionEnglish: {
      type: 'text',
      name: 'surveyDescriptionEnglish',
      value: formik.values.surveyDescriptionEnglish,
      label: intl.formatMessage(messages.surveyDescriptionEnglish),
      onChange: formik.handleChange,
      error:
        formik.touched.surveyDescriptionEnglish &&
        !!formik.errors.surveyDescriptionEnglish,
      helperText:
        formik.touched.surveyDescriptionEnglish &&
        !!formik.errors.surveyDescriptionEnglish
          ? formik.errors.surveyDescriptionEnglish
          : intl.formatMessage(messages.max500CharactersEnglish),
      breakpoints: commonBreakpoints
    },
    surveyFarewellEnglish: {
      type: 'text',
      name: 'surveyFarewellEnglish',
      value: formik.values.surveyFarewellEnglish,
      label: intl.formatMessage(messages.surveyFarewellEnglish),
      onChange: formik.handleChange,
      error:
        formik.touched.surveyFarewellEnglish &&
        !!formik.errors.surveyFarewellEnglish,
      helperText:
        formik.touched.surveyFarewellEnglish &&
        !!formik.errors.surveyFarewellEnglish
          ? formik.errors.surveyFarewellEnglish
          : intl.formatMessage(messages.max500CharactersEnglish),
      breakpoints: commonBreakpoints
    }
  };

  return { fields, fieldIsEnglishFormik, fieldsEnglish };
};
