/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useIntl } from 'react-intl';

import * as Yup from 'yup';

import { messages as formMessages } from '@octopy/react-form';
import { messages } from '../InformationFormMessages';
import { inputProps } from '../helper';

import { useFormik } from 'formik';

import { useStyles } from '../InformationFormStyles';

import { Typography } from '@material-ui/core';

import { useApi } from 'hooks';

import { get } from 'lodash';

export const useApiInformationForm = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();

  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = get(storage, 'user.client.client_id', {});

  const idInformation = history.location.state
    ? history.location.state.idInformation
    : 0;

  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );

  const [createInformation] = useApi({
    endpoint: 'information/information/',
    method: 'post'
  });
  const [getInformation] = useApi({
    endpoint: 'information/information',
    method: 'get'
  });
  const [updateInformation] = useApi({
    endpoint: 'information/information',
    method: 'put'
  });

  const handleCreateInformation = async ({
    nameInformation,
    descriptionInformation,
    textRobot,
    robotSeleted
  }) => {
    const { headerResponse } = await createInformation({
      body: {
        client: clientId,
        title: nameInformation,
        description: descriptionInformation,
        text: textRobot
      }
    });

    if (headerResponse.code === 200) {
      history.push('/information');
    }
  };
  const handleGetInformation = async () => {
    const { headerResponse, payload } = await getInformation({
      urlParams: idInformation
    });

    if (headerResponse.code === 200) {
      const { description, text, title, client } = payload;

      formik.setValues({
        ...initialValues,
        nameInformation: title,
        descriptionInformation: description,
        textRobot: text
        //robotSeleted: client.client_id
      });
    }
  };
  const handleUpdateInformation = async (data) => {
    const { headerResponse } = await updateInformation({
      urlParams: `${idInformation}/`,
      body: {
        client: clientId,
        title: data.nameInformation,
        description: data.descriptionInformation,
        text: data.textRobot
      }
    });

    if (headerResponse.code === 200) {
      history.push('/information');
    }
  };

  useEffect(() => {
    if (idInformation !== 0) {
      handleGetInformation();
    }
  }, [idInformation]);

  const initialValues = {
    nameInformation: '',
    descriptionInformation: '',
    textRobot: '',
    robotSeleted: ''
  };

  const getValidationRules = () =>
    Yup.object({
      nameInformation: Yup.string().required(requiredFieldError),
      descriptionInformation: Yup.string().required(requiredFieldError),
      textRobot: Yup.string().required(requiredFieldError),
      robotSeleted: Yup.string().required(requiredFieldError)
    });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationRules(),
    onSubmit: async (values) => {
      if (idInformation === 0) {
        handleCreateInformation(values);
      } else {
        handleUpdateInformation(values);
      }
    }
  });

  const handleChangeTextRobot = (value) => {
    formik.setFieldValue('textRobot', value);
  };
  const handleChangeRobotSeleted = (value) => {
    formik.setFieldValue('robotSeleted', value.name);
  };
  const handleChangeDescriptionInformation = (value) => {
    if (value === '<p><br></p>') {
      value = value.replace('<p><br></p>', '');
    }

    formik.setFieldValue('descriptionInformation', value);
  };

  const fieldsMainFormik = [
    Object.assign({
      type: 'title',
      breakpoints: { xs: 12 },
      text: (
        <Typography className={classes.description}>
          {intl.formatMessage(messages.descriptionItem)}
        </Typography>
      )
    }),
    Object.assign(
      {
        type: 'text',
        name: 'nameInformation',
        label: intl.formatMessage(messages.nameInformation),
        placeholder: intl.formatMessage(messages.namePlaceholderInformation),
        breakpoints: { xs: 12, md: 3, lg: 6 }
      },
      inputProps
    )
  ];

  const fieldsRobot = [
    Object.assign({
      type: 'title',
      breakpoints: { xs: 12 },
      text: (
        <Typography className={classes.description}>
          {intl.formatMessage(messages.descriptionRobot)}
        </Typography>
      )
    }),
    Object.assign({
      type: 'title',
      breakpoints: { xs: 12 },
      text: (
        <Typography className={classes.descriptionSubtitle}>
          {intl.formatMessage(messages.descriptionRobotSubtitle)}
        </Typography>
      )
    })
  ];

  const fieldError = [
    Object.assign({
      type: 'title',
      breakpoints: { xs: 12 },
      text: (
        <Typography className={classes.descriptionError}>
          {formik.errors.descriptionInformation}
        </Typography>
      )
    })
  ];

  return {
    formik,
    fieldsMainFormik,
    fieldsRobot,
    fieldError,
    idInformation,
    handleChangeTextRobot,
    handleChangeRobotSeleted,
    handleChangeDescriptionInformation
  };
};
