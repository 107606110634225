/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { useLoader } from '@octopy/react-loader';
import { messages as formMessages } from '@octopy/react-form';
import { useModal, Alert } from '@octopy/react-modal';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { Typography } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApi } from 'hooks';
import {
  formikMapper,
  currencySpanish,
  currencyEnglish,
  inputProps
} from './schemesMapper';
import { responseMapper } from 'utils/responseMapper';
import {
  mappedCategoriesSpanish,
  mappedCategoriesEnglish,
  mappedSubcategoriesSpanish,
  mappedSubcategoriesEnglish
} from './utils';
import { uploadFilePromise } from 'utils/aws';
import { sources } from 'providers/AWS';
import { useStyles } from './ProductFormStyles';
import { messages } from './ProductFormMessages';

export const useHelper = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = get(storage, 'user.client.client_id', {});
  const bucketName = get(storage, 'user.client.bucket_name', {});
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { modalReportProblem } = useModalConfirmation();
  const { handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();
  const { images } = sources(bucketName);
  const [categoriesSpanish, setCategoriesSpanish] = useState([]);
  const [categoriesEnglish, setCategoriesEnglish] = useState([]);
  const [subcategoriesSpanish, setsubcategoriesSpanish] = useState([]);
  const [subcategoriesEnglish, setsubcategoriesEnglish] = useState([]);
  const [categoryId, setCategoryId] = useState(null);
  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );
  const idProduct = history.location.state
    ? history.location.state.idProduct
    : 0;

  const [getCategoriesList] = useApi({
    endpoint: '/catalogue/category/categories_by_client',
    method: 'get'
  });

  const [getSubcategoriesList] = useApi({
    endpoint: '/catalogue/subcategory/subcategories_by_category',
    method: 'get'
  });

  const [getProduct] = useApi({
    endpoint: '/catalogue/article',
    method: 'get'
  });

  const [productCreate] = useApi({
    endpoint: '/catalogue/article/',
    method: 'post'
  });

  const [productUpdate] = useApi({
    endpoint: '/catalogue/article',
    method: 'put'
  });

  useEffect(() => {
    if (categoryId !== null) handleGetSubcategoriesId();
  }, [categoryId]);

  useEffect(() => {
    handleGetCategoriesList();
  }, []);

  useEffect(() => {
    if (idProduct !== 0) handleGetProduct();
  }, []);

  const handleGetProduct = async () => {
    try {
      const response = await getProduct({
        urlParams: idProduct
      });
      const dataResponse = get(response, 'payload', []);

      if (response.headerResponse.code === 200) {
        let arrayImages = [];

        dataResponse.images.map((item) => {
          arrayImages.push({ url: item });
        });
        const mappedFormikValues = responseMapper({
          template: formikMapper,
          data: dataResponse
        });

        const responseSubcategory = await getSubcategoriesList({
          urlParams: dataResponse.category.category_id
        });
        const subcategoryResponse = get(responseSubcategory, 'payload', []);
        const headerResponseSubcategory = get(
          responseSubcategory,
          'headerResponse',
          {}
        );

        if (headerResponseSubcategory.code === 200) {
          const subcategoriesSpanish = mappedSubcategoriesSpanish(
            subcategoryResponse
          );
          const subcategoriesEnglish = mappedSubcategoriesEnglish(
            subcategoryResponse
          );

          setsubcategoriesSpanish(subcategoriesSpanish);
          setsubcategoriesEnglish(subcategoriesEnglish);
        }

        formik.setValues({
          ...initialValues,
          ...mappedFormikValues,
          categorySpanish: `${dataResponse.category.category_id}`,
          subcategorySpanish: `${dataResponse.sub_category.subcategory_id}`,
          currencySpanish: `${currencySpanish.language_id}`,

          imagesProduct: arrayImages,
          categoryEnglish: `${dataResponse.category.category_id}`,
          subcategoryEnglish: `${dataResponse.sub_category.subcategory_id}`,
          currencyEnglish: `${currencyEnglish.language_id}`,
          addEnglish: true
        });
      } else {
        history.push('/products');
      }
    } catch (error) {}
  };

  const handleGetCategoriesList = async () => {
    try {
      const response = await getCategoriesList({
        urlParams: clientId
      });
      const dataResponse = get(response, 'payload', []);

      if (response.headerResponse.code === 200) {
        const categoriesSpanish = mappedCategoriesSpanish(dataResponse);
        const categoriesEnglish = mappedCategoriesEnglish(dataResponse);

        setCategoriesSpanish(categoriesSpanish);
        setCategoriesEnglish(categoriesEnglish);
      }
    } catch (error) {}
  };

  const handleGetSubcategoriesId = async () => {
    try {
      const response = await getSubcategoriesList({
        urlParams: categoryId
      });
      const dataResponse = get(response, 'payload', []);

      if (response.headerResponse.code === 200) {
        const subcategoriesSpanish = mappedSubcategoriesSpanish(dataResponse);
        const subcategoriesEnglish = mappedSubcategoriesEnglish(dataResponse);

        setsubcategoriesSpanish(subcategoriesSpanish);
        setsubcategoriesEnglish(subcategoriesEnglish);
      }
    } catch (error) {}
  };

  const initialValues = {
    nameSpanish: '',
    categorySpanish: '',
    subcategorySpanish: '',
    priceSpanish: '',
    currencySpanish: '',
    imagesProduct: null,
    descriptionSpanish: '',
    text: '',
    robot: '',
    addEnglish: false,
    nameEnglish: '',
    categoryEnglish: '',
    subcategoryEnglish: '',
    priceEnglish: '',
    currencyEnglish: '',
    descriptionEnglish: '',
    textEnglish: '',
    robotEnglish: ''
  };

  const getValidationRules = () =>
    Yup.object({
      nameSpanish: Yup.string().required(requiredFieldError),
      categorySpanish: Yup.string().required(requiredFieldError),
      subcategorySpanish: Yup.string().required(requiredFieldError),
      priceSpanish: Yup.string()
        .matches(/^\d+.?\d{1,2}$/, 'numero invalido')
        .required(requiredFieldError),
      currencySpanish: Yup.string().required(requiredFieldError),
      imagesProduct: Yup.array()
        .of(Yup.object({ url: Yup.string() }))
        .nullable()
        .min(1, requiredFieldError)
        .max(5, 'Maximo 5 imagenes')
        .required(requiredFieldError),
      descriptionSpanish: Yup.string().required(requiredFieldError),
      text: Yup.string().required(requiredFieldError),
      robot: Yup.string().required(requiredFieldError),
      nameEnglish: Yup.string().when('addEnglish', {
        is: true,
        then: Yup.string().required(requiredFieldError)
      }),
      categoryEnglish: Yup.string().when('addEnglish', {
        is: true,
        then: Yup.string().required(requiredFieldError)
      }),
      subcategoryEnglish: Yup.string().when('addEnglish', {
        is: true,
        then: Yup.string().required(requiredFieldError)
      }),
      priceEnglish: Yup.string().when('addEnglish', {
        is: true,
        then: Yup.string().required(requiredFieldError)
      }),
      currencyEnglish: Yup.string().when('addEnglish', {
        is: true,
        then: Yup.string().required(requiredFieldError)
      }),
      descriptionEnglish: Yup.string().when('addEnglish', {
        is: true,
        then: Yup.string().required(requiredFieldError)
      }),
      textEnglish: Yup.string().when('addEnglish', {
        is: true,
        then: Yup.string().required(requiredFieldError)
      }),
      robotEnglish: Yup.string().when('addEnglish', {
        is: true,
        then: Yup.string().required(requiredFieldError)
      })
    });

  const erroUploadFile = () => {
    handleOpenModal({
      configProps: {
        maxWidth: 'sm'
      },
      body: <Alert message={intl.formatMessage(messages.uploadFileError)} />
    });
    handleShowLoader(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: getValidationRules(),
    onSubmit: async (values) => {
      if (formik.values.addEnglish === false) {
        modalReportProblem(
          intl.formatMessage(messages.errorModalTitle),
          intl.formatMessage(messages.errorModalDescription),
          () => {}
        );
      } else {
        if (values.robot === values.robotEnglish) {
          handleShowLoader(true);

          const body = {
            client: clientId,
            category: parseInt(values.categorySpanish),
            sub_category: parseInt(values.subcategorySpanish),
            images: await Promise.all(
              values.imagesProduct.map(async (item) =>
                item.file
                  ? await uploadFilePromise(item.file, images, erroUploadFile)
                  : item.url
              )
            ),
            name: [
              {
                language_id: 1,
                text: values.nameSpanish
              },
              {
                language_id: 2,
                text: values.nameEnglish
              }
            ],
            price: [
              {
                language_id: parseInt(values.currencySpanish),
                price: values.priceSpanish
              },
              {
                language_id: parseInt(values.currencyEnglish),
                price: values.priceEnglish
              }
            ],
            description: [
              {
                language_id: 1,
                text: values.descriptionSpanish
              },
              {
                language_id: 2,
                text: values.descriptionEnglish
              }
            ],
            audio_text: [
              {
                language_id: 1,
                text: values.text
              },
              {
                language_id: 2,
                text: values.textEnglish
              }
            ]
          };

          if (idProduct === 0) {
            handleCreateProduct(body);
          } else {
            handleUpdateProduct(body);
          }
        } else {
          modalReportProblem(
            intl.formatMessage(messages.errorRobot),
            intl.formatMessage(messages.infoTextSelect)
          );
        }
      }
    }
  });

  const handleCreateProduct = async (data) => {
    try {
      const response = await productCreate({
        body: data
      });

      if (response.headerResponse.code === 200) {
        history.push('/products');
      }
    } catch (error) {}
  };

  const handleUpdateProduct = async (data) => {
    try {
      const response = await productUpdate({
        urlParams: `${idProduct}/`,
        body: data
      });

      if (response.headerResponse.code === 200) {
        history.push('/products');
      }
    } catch (error) {}
  };

  const fieldsMainFormik = [
    Object.assign({
      type: 'title',
      breakpoints: { xs: 12 },
      text: (
        <Typography className={classes.description}>
          {intl.formatMessage(messages.descriptionItem)}
        </Typography>
      )
    }),
    Object.assign(
      {
        type: 'text',
        name: 'nameSpanish',
        label: intl.formatMessage(messages.nameLabelSpanish),
        placeholder: intl.formatMessage(messages.namePlaceholderSpanish),
        breakpoints: { xs: 12, md: 4, lg: 6 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'select',
        name: 'categorySpanish',
        label: intl.formatMessage(messages.categoryLabelSpanish),
        placeholder: intl.formatMessage(messages.categoryPlaceholderSpanish),
        breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
        options: categoriesSpanish,
        getOptionLabel: (option) => `${option.name || ''}`,
        getOptionValue: (option) => `${option.category_id || ''}`,
        getOptionSelected: (option, value) => option === value,
        onChange: (option, value) => {
          formik.setFieldValue(option, `${value}`);
          formik.setFieldValue('categoryEnglish', `${value}`);
          setCategoryId(value);
        }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'select',
        name: 'subcategorySpanish',
        label: intl.formatMessage(messages.subcategoryLabelSpanish),
        placeholder: intl.formatMessage(messages.subcategoryPlaceholderSpanish),
        breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
        options: subcategoriesSpanish,
        getOptionLabel: (option) => `${option.name || ''}`,
        getOptionValue: (option) => `${option.subcategory_id || ''}`,
        getOptionSelected: (option, value) => option === value,
        onChange: (option, value) => {
          formik.setFieldValue(option, `${value}`);
          formik.setFieldValue('subcategoryEnglish', `${value}`);
        }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'priceSpanish',
        label: intl.formatMessage(messages.priceLabelSpanish),
        placeholder: '00.00',
        breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'select',
        name: 'currencySpanish',
        label: intl.formatMessage(messages.currencyLabelSpanish),
        placeholder: intl.formatMessage(messages.currencyPlaceholderSpanish),
        breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
        options: [currencySpanish],
        getOptionLabel: (option) => `${option.currency || ''}`,
        getOptionValue: (option) => `${option.language_id || ''}`,
        getOptionSelected: (option, value) => option === value
      },
      inputProps
    )
  ];

  const fieldsImageFormik = [
    Object.assign({
      type: 'title',
      breakpoints: { xs: 12 },
      text: (
        <Typography className={classes.titleImage}>
          {intl.formatMessage(messages.titleImage)}
        </Typography>
      )
    }),
    Object.assign({
      type: 'subTitle',
      breakpoints: { xs: 12 },
      text: (
        <Typography className={classes.descriptionImage}>
          {intl.formatMessage(messages.descriptionImage)}
        </Typography>
      )
    }),
    Object.assign({
      type: 'image',
      name: 'imagesProduct',
      label: intl.formatMessage(messages.uploadImage),
      multiple: true,
      color: 'primary.main',
      icon: Publish,
      breakpoints: { xs: 12, lg: 6 },
      onChange: (value) => console.log('VALUE', value)
    })
  ];

  const fieldsDescriptionSpanishFormik = [
    Object.assign(
      {
        type: 'text',
        name: 'descriptionSpanish',
        label: intl.formatMessage(messages.descriptionLabelSpanish),
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  const fieldAddEnglishFormik = [
    Object.assign(
      {
        type: 'checkbox',
        name: 'addEnglish',
        label: intl.formatMessage(messages.englishPart),
        breakpoints: { xs: 12 },
        color: 'Primary'
      },
      inputProps
    )
  ];

  const fieldsMainEnglishFormik = [
    Object.assign(
      {
        type: 'text',
        name: 'nameEnglish',
        label: intl.formatMessage(messages.nameLabelEnglish),
        placeholder: intl.formatMessage(messages.namePlaceholderEnglish),
        breakpoints: { xs: 12, md: 4, lg: 6 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'select',
        name: 'categoryEnglish',
        label: intl.formatMessage(messages.categoryLabelEnglish),
        placeholder: intl.formatMessage(messages.categoryPlaceholderEnglish),
        breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
        options: categoriesEnglish,
        getOptionLabel: (option) => `${option.name || ''}`,
        getOptionValue: (option) => `${option.category_id || ''}`,
        getOptionSelected: (option, value) => option === value,
        onChange: (option, value) => {
          formik.setFieldValue(option, `${value}`);
          formik.setFieldValue('categorySpanish', `${value}`);
          setCategoryId(value);
        }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'select',
        name: 'subcategoryEnglish',
        label: intl.formatMessage(messages.subcategoryLabelEnglish),
        placeholder: intl.formatMessage(messages.subcategoryPlaceholderEnglish),
        breakpoints: { xs: 12, sm: 6, md: 4, lg: 3 },
        options: subcategoriesEnglish,
        getOptionLabel: (option) => `${option.name || ''}`,
        getOptionValue: (option) => `${option.subcategory_id || ''}`,
        getOptionSelected: (option, value) => option === value,
        onChange: (option, value) => {
          formik.setFieldValue(option, `${value}`);
          formik.setFieldValue('subcategorySpanish', `${value}`);
        }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'priceEnglish',
        label: intl.formatMessage(messages.priceLabelEnglish),
        placeholder: '00.00',
        breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'select',
        name: 'currencyEnglish',
        label: intl.formatMessage(messages.currencyLabelEnglish),
        placeholder: intl.formatMessage(messages.currencyPlaceholderEnglish),
        breakpoints: { xs: 12, sm: 6, md: 3, lg: 3 },
        options: [currencyEnglish],
        getOptionLabel: (option) => `${option.currency || ''}`,
        getOptionValue: (option) => `${option.language_id || ''}`,
        getOptionSelected: (option, value) => option === value
      },
      inputProps
    )
  ];

  const fieldsDescriptionEnglishFormik = [
    Object.assign(
      {
        type: 'text',
        name: 'descriptionEnglish',
        label: intl.formatMessage(messages.descriptionLabelEnglish),
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  const handleChangeText = (value) => {
    formik.setFieldValue('text', value);
  };

  const handleChangeRobot = (value) => {
    formik.setFieldValue('robot', value.name);
  };

  const handleChangeTextEnglish = (value) => {
    formik.setFieldValue('textEnglish', value);
  };

  const handleChangeRobotEnglish = (value) => {
    formik.setFieldValue('robotEnglish', value.name);
  };

  return {
    idProduct,
    formik,
    fieldsMainFormik,
    fieldsImageFormik,
    fieldsDescriptionSpanishFormik,
    fieldAddEnglishFormik,
    fieldsMainEnglishFormik,
    fieldsDescriptionEnglishFormik,
    handleChangeText,
    handleChangeRobot,
    handleChangeTextEnglish,
    handleChangeRobotEnglish
  };
};
