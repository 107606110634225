export const metas = {
  '/octopy': [
    {
      name: 'description',
      content: 'Octopy'
    }
  ],
  '/login': [
    {
      name: 'description',
      content: 'Login component'
    }
  ],
  '/metrics': [
    {
      name: 'description',
      content: 'Metrics component'
    }
  ],
  '/robot1/interactions': [
    {
      name: 'description',
      content: 'Interactions component'
    }
  ],
  '/robot1/audios': [
    {
      name: 'description',
      content: 'AudioFlow component'
    }
  ],
  '/robot1/audios/create': [
    {
      name: 'description',
      content: 'CreateAudioFlow component'
    }
  ],
  '/guideline/file-distribution': [
    {
      name: 'description',
      content: 'FileDistribution component'
    }
  ],
  '/guideline/code-examples': [
    {
      name: 'description',
      content: 'CodeExamples component'
    }
  ]
};
