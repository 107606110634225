import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  minCharacters: {
    id: 'routes.DialogFlowForm.minCharacters',
    defaultMessage: 'Minimo 5 caracateres'
  },
  maxCharacters: {
    id: 'routes.DialogFlowForm.maxCharacters',
    defaultMessage: 'Maximo 150 caracateres'
  },
  requiredField: {
    id: 'routes.DialogFlowForm.requiredField',
    defaultMessage: 'Campo requerido'
  },
  nameInput: {
    id: 'routes.DialogFlowForm.nameInput',
    defaultMessage: 'Nombre'
  },
  labelButtonFile: {
    id: 'routes.DialogFlowForm.labelButtonFile',
    defaultMessage: 'Llave del agente'
  },
  buttonFile: {
    id: 'routes.DialogFlowForm.buttonFile',
    defaultMessage: 'SELECCIONAR ARCHIVO JSON'
  },
  buttonSave: {
    id: 'routes.DialogFlowForm.buttonSave',
    defaultMessage: 'GUARDAR'
  },
  titleModal: {
    id: 'routes.DialogFlowForm.titleModal',
    defaultMessage: 'ERROR EN LLAVE DEL AGENTE'
  },
  infoTextModal: {
    id: 'routes.DialogFlowForm.infoTextModal',
    defaultMessage: 'Se requiere un archivo .json para guardar el agente.'
  },
  infoTextModalPreview: {
    id: 'routes.DialogFlowForm.infoTextModalPreview',
    defaultMessage: 'Se requiere un archivo .json para previsualizar el audio.'
  },
  buttonAcept: {
    id: 'routes.DialogFlowForm.buttonAcept',
    defaultMessage: 'ACEPTAR'
  },
  language: {
    id: 'routes.DialogFlowForm.language',
    defaultMessage: 'Seleccione Idioma'
  },
  voiceTone: {
    id: 'routes.DialogFlowForm.voiceTone',
    defaultMessage: 'Tono de voz'
  },
  speed: {
    id: 'routes.DialogFlowForm.speed',
    defaultMessage: 'Speed'
  },
  pitch: {
    id: 'routes.DialogFlowForm.pitch',
    defaultMessage: 'Pitch'
  },
  testPhrase: {
    id: 'routes.DialogFlowForm.testPhrase',
    defaultMessage: 'Texto de prueba para robot'
  },
  textPlaceholder: {
    id: 'routes.DialogFlowForm.textPlaceholder',
    defaultMessage: 'Texto'
  },
  preview: {
    id: 'routes.DialogFlowForm.preview',
    defaultMessage: 'PREVIZUALIZAR'
  },
  uploadInfo: {
    id: 'routes.DialogFlowForm.buttonUpload',
    defaultMessage: 'Seleccionar archivo Json'
  },
  titleSuccessModal: {
    id: 'routes.DialogFlowForm.titleSuccessModal',
    defaultMessage: 'Datos guardados con éxito'
  },
  textSuccessModal: {
    id: 'routes.DialogFlowForm.textSuccessModal',
    defaultMessage: 'El agente ha sido creado exitosamente.'
  },
  optionTitle: {
    id: 'routes.DialogFlowForm.optionTitle',
    defaultMessage: 'Sesión cliente'
  },
  checkTitle: {
    id: 'routes.DialogFlowForm.checkTitle',
    defaultMessage: 'Elegir como default'
  },
  update: {
    id: 'routes.DialogFlowForm.update',
    defaultMessage: 'ACTUALIZAR'
  }
});
