import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `0px ${theme.spacing(5)}px`,
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      paddingTop: theme.spacing(4),
      margin: `0px ${theme.spacing(1)}px`,
      paddingBottom: theme.spacing(8)
    }
  },
  addItem: {
    fontSize: '14px',
    marginTop: '10px'
  },
  cancelButton: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: 10.3,
    '&:hover': {
      backgroundColor: `${theme.palette.common.black}CE`,
      color: theme.palette.common.white
    }
  },
  saveButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: 10.3,
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  cardStyle: {
    padding: '20px',
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1.5)
    }
  },
  gridStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alingItems: 'center'
  },
  gridButton: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end'
    }
  },
  gridButtonCancel: {
    marginRight: '10px'
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },

  contentModal: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  icon: {
    fontSize: '80px',
    color: theme.palette.primary.main
  },
  titleModal: {
    fontSize: '20px',
    fontWeight: 800,
    letterSpacing: '6px'
  },
  infoTextModal: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  }
}));

export { useStyles };
