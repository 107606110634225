import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useIntl } from 'react-intl';
import { useHistory, useLocation } from 'react-router-dom';
import { useLoader } from '@octopy/react-loader';
import { Alert, useModal } from '@octopy/react-modal';
import { Typography, Grid, Card, Button } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import { TableTransferList } from 'components/TableTransferList';
import { useScreenLoader } from 'components/ScreenLoader';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { uploadFilePromise } from 'utils/aws';
import { sources } from 'providers/AWS';
import { getFileFromUrl } from 'utils/fileAudioUrl';
import { useApiBranches } from 'views/Branches/hooks/useApiBranches';
import { useApi } from 'hooks';
import {
  extractAgentData,
  extractElementData,
  mergeAgentAndAudioText,
  createDataUpdate
} from 'utils/robot';
import { useBranches } from './utils';
import { responseMapper } from 'utils/responseMapper';
import { messages } from './BranchesMessages';
import { useStyles } from './BranchesStyles';
import { renderAutocomplete } from '@octopy/react-form';
import { HeaderTitle } from 'components/HeaderTitle';

const Branches = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const bucketName = get(storage, 'user.client.bucket_name', {});
  const isStaff = get(storage, 'user.is_staff', '');

  const intl = useIntl();
  const classes = useStyles();
  const { modalSuccess } = useModalConfirmation();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { handleShowLoader } = useLoader();
  const { handleShowScreen } = useScreenLoader();
  const { branchList } = useApiBranches();
  const { audios } = sources(bucketName);
  const history = useHistory();
  const location = useLocation();
  const [leftAux, setLeftAux] = useState([]);
  const idRobot = location.state.robot_id;

  const [getRobotId] = useApi({
    endpoint: `/robots/robots/${idRobot}`,
    method: 'get'
  });
  const [robotUpdate] = useApi({
    endpoint: `/robots/robots/${idRobot}/`,
    method: 'put'
  });
  const [getAgents] = useApi({
    endpoint: 'agents/agents_by_robot',
    method: 'get'
  });
  const [createSpeech] = useApi({
    endpoint: '/speech/',
    method: 'post'
  });
  const [branchById] = useApi({
    endpoint: 'branch_offices/branch_offices',
    method: 'get'
  });

  useEffect(() => {
    handleDataRobotId();
  }, []);

  const erroUploadFile = () => {
    handleOpenModal({
      configProps: {
        maxWidth: 'sm'
      },
      body: <Alert message={intl.formatMessage(messages.uploadFileError)} />
    });
    handleShowLoader(false);
  };

  const createSucursales = async (dataItems) => {
    let mappedBranchesAux = [];

    for (const item of dataItems) {
      const response = await branchById({
        urlParams: item.branchoffice_id
      });

      const dataResponse = get(response, 'payload', []);

      const mappedBranches = responseMapper({
        template: BranchListMapper,
        data: dataResponse
      });

      mappedBranchesAux.push({
        item_id: item.branchoffice_id,
        ...mappedBranches
      });
    }

    return mappedBranchesAux;
  };

  const handleDataRobotId = async () => {
    try {
      handleShowLoader(true);
      const response = await getRobotId();
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', []);
      const dataBranches = get(dataResponse, 'branchoffices', []);
      const dataAgents = get(dataResponse, 'agents', []);
      const mainBranchRobot = get(dataResponse, 'main_branch_office', '');

      if (headerResponse.code === 200) {
        if (dataAgents.length >= 1) {
          const result = await createSucursales(dataBranches);

          setLeftAux(result);
          formik.setFieldValue('mainBranch', `${mainBranchRobot}`);
          handleShowLoader(false);
        } else {
          handleOpenModal({
            title: (
              <Grid className={classes.contentModal}>
                <ReportProblemOutlined className={classes.icon} />
                <Typography className={classes.titleModal}>
                  {intl.formatMessage(messages.emptyAgents)}
                </Typography>
              </Grid>
            ),
            body: (
              <Grid style={{ marginBottom: 20, textAlign: 'center' }}>
                <Typography className={classes.infoTextModal}>
                  {!isStaff
                    ? intl.formatMessage(messages.infoReportProblem)
                    : intl.formatMessage(messages.infoReportProblemIsStaff)}
                </Typography>
                <Button
                  className={classes.button}
                  onClick={() => {
                    history.push('/robots');
                    handleCloseModal();
                  }}
                >
                  {intl.formatMessage(messages.acept)}
                </Button>
              </Grid>
            )
          });
        }
      }
    } catch (error) {
      handleShowLoader(false);
    }
  };

  const handleOnSubmit = async () => {
    try {
      handleShowScreen(true);

      const responseBranchesData = extractElementData(
        leftAux,
        'branchoffice_id'
      );

      const response = await getAgents({
        urlParams: idRobot
      });
      const dataResponse = get(response, 'payload', []);
      const responseAgentData = extractAgentData(dataResponse);
      const audioTextData = mergeAgentAndAudioText(
        responseBranchesData,
        responseAgentData
      );

      createDataUpdate({
        items: audioTextData,
        idReference: 'branchoffice_id',
        runFetches: handleCreateSpeech,
        callback: handleUpdate
      });
    } catch (error) {
      handleShowScreen(false);
    }
  };

  const handleCreateSpeech = async (data) => {
    try {
      handleShowLoader(false);

      const response = await createSpeech({
        body: data
      });

      const dataResponse = get(response, 'payload', '');
      const fileUrl = await getFileFromUrl(dataResponse, 'audio');
      let urlAudio = await uploadFilePromise(fileUrl, audios, erroUploadFile);

      return urlAudio;
    } catch (error) {}
  };

  const handleUpdate = async (data) => {
    try {
      const response = await robotUpdate({
        body: {
          branchoffices: data
        }
      });
      const headerResponse = get(response, 'headerResponse', {});

      if (headerResponse.code === 200)
        modalSuccess(
          intl.formatMessage(messages.titleSuccessModal),
          intl.formatMessage(messages.textInfoSucces),
          () => {
            history.push('/robots');
          }
        );
    } catch (error) {
      handleShowScreen(false);
    } finally {
      handleShowScreen(false);
    }
  };

  const handleOnChange = (arrayAux) => {
    setLeftAux(arrayAux);
  };

  const {
    tableHead,
    filters,
    BranchListMapper,
    formik,
    formikfields
  } = useBranches(branchList, robotUpdate);

  const fieldsMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'select' && renderAutocomplete({ index, formik, field })}
    </Grid>
  );

  return (
    <div className={classes.container}>
      <HeaderTitle
        firstTitle={history.location.state.name}
        secondTitle={intl.formatMessage(messages.branches)}
        redirection="robots"
      />
      <Card className={classes.rootCard}>
        <Grid container className={classes.contentActions}>
          <Typography className={classes.infoText}>
            {intl.formatMessage(messages.mainBranchSubtitle)}
          </Typography>
        </Grid>
        <Grid container className={classes.contentActions}>
          {formikfields.map(fieldsMapper)}
          <Grid className={classes.gridButtons}>
            <Button
              className={classes.button}
              onClick={() => formik.submitForm()}
            >
              {intl.formatMessage(messages.save)}
            </Button>
          </Grid>
        </Grid>
      </Card>
      <Card className={classes.rootCard}>
        <Grid container className={classes.contentActions}>
          <Typography className={classes.infoText}>
            {intl.formatMessage(messages.infoText)}
          </Typography>
          {/* <Grid>
            <FormControlLabel 
              value="end"
              control={<Checkbox color="primary" />}
              label={
                <Typography className={classes.infoText}>
                  {intl.formatMessage(messages.checkBoxText)}
                </Typography>
              }
              labelPlacement="end"
            />
          </Grid> */}
          <Grid className={classes.gridButtons}>
            <Grid style={{ marginRight: 20 }}>
              <Button
                className={classes.cancelButton}
                onClick={() => history.push('/robots')}
              >
                {intl.formatMessage(messages.cancel)}
              </Button>
            </Grid>
            <Button className={classes.button} onClick={() => handleOnSubmit()}>
              {intl.formatMessage(messages.save)}
            </Button>
          </Grid>
        </Grid>
        <Grid style={{ marginBottom: 20 }}>
          <TableTransferList
            data={branchList}
            tableHead={tableHead}
            filters={filters}
            changeData={handleOnChange}
            leftAux={leftAux}
            labelTotal={intl.formatMessage(messages.branchesTotal)}
            id="branchoffice_id"
          />
        </Grid>
      </Card>
    </div>
  );
};

Branches.propTypes = {};

export { Branches };
