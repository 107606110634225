import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    borderRadius: '16px',
    padding: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  changePassword: {
    fontSize: '20px',
    letterSpacing: '6px',
    color: theme.palette.primary.main,
    fontWeight: 800,
    marginBottom: theme.spacing(1)
  },
  contentButton: {
    marginTop: theme.spacing(1),
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end'
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1)
    }
  }
}));

export { useStyles };
