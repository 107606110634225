import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  addEvent: {
    id: 'components.AddEvent.addEvent',
    defaultMessage: 'AGREGAR EVENTO'
  },
  editEvent: {
    id: 'components.AddEvent.editEvent',
    defaultMessage: 'EDITAR EVENTO'
  },
  eventData: {
    id: 'components.AddEvent.eventData',
    defaultMessage: 'Datos del evento'
  },
  campaings: {
    id: 'components.AddEvent.campaings',
    defaultMessage: 'Campañas relacionadas para evento'
  },
  add: {
    id: 'components.AddEvent.add',
    defaultMessage: 'AGREGAR'
  },
  cancel: {
    id: 'components.AddEvent.cancel',
    defaultMessage: 'CANCELAR'
  },
  save: {
    id: 'components.AddEvent.save',
    defaultMessage: 'GUARDAR'
  },
  titleModal: {
    id: 'components.AddEvent.titleModal',
    defaultMessage: 'ELIMINAR CAMPAÑA'
  },
  infoTextModal: {
    id: 'components.AddEvent.infoTextModal',
    defaultMessage: ' ¿Seguro que deseas eliminar esta campaña?'
  },
  delete: {
    id: 'components.AddEvent.delete',
    defaultMessage: 'ELIMINAR'
  },
  cancelAddEvent: {
    id: 'components.AddEvent.cancelAddEvent',
    defaultMessage: 'CANCELAR'
  },
  infoTextCancelAddEvent: {
    id: 'components.AddEvent.infoTextCancelAddEvent',
    defaultMessage: '¿Seguro de que desea salir sin guardar el evento?'
  },
  exit: {
    id: 'components.AddEvent.exit',
    defaultMessage: 'SALIR'
  },
  nameLabel: {
    id: 'components.AddEvent.nameLabel',
    defaultMessage: 'Nombre'
  },
  startDate: {
    id: 'components.AddEvent.startDate',
    defaultMessage: 'Fecha y hora de inicio:'
  },
  endDate: {
    id: 'components.AddEvent.endDate',
    defaultMessage: 'Fecha y hora de termino:'
  },
  startDateLabel: {
    id: 'components.AddEvent.startDateLabel',
    defaultMessage: 'Fecha de inicio'
  },
  startDatePlaceholder: {
    id: 'components.AddEvent.startDatePlaceholder',
    defaultMessage: 'Seleccione la fecha de inicio'
  },
  startTimeLabel: {
    id: 'components.AddEvent.startTimeLabel',
    defaultMessage: 'Hora de inicio'
  },
  startTimePlaceholder: {
    id: 'components.AddEvent.startTimePlaceholder',
    defaultMessage: 'Seleccione la hora de inicio'
  },
  endDateLabel: {
    id: 'components.AddEvent.endDateLabel',
    defaultMessage: 'Fecha de termino'
  },
  endDatePlaceholder: {
    id: 'components.AddEvent.endDatePlaceholder',
    defaultMessage: 'Seleccione la fecha de termino'
  },
  endTimeLabel: {
    id: 'components.AddEvent.endTimeLabel',
    defaultMessage: 'Hora de termino'
  },
  endTimePlaceholder: {
    id: 'components.AddEvent.endTimePlaceholder',
    defaultMessage: 'Seleccione la hora de termino'
  },
  campaingLabel: {
    id: 'components.AddEvent.campaingLabel',
    defaultMessage: 'Campañas'
  },
  campaingPlaceholder: {
    id: 'components.AddEvent.campaingPlaceholder',
    defaultMessage: 'Seleccione una opción'
  },
  deleteCampaing: {
    id: 'components.AddEvent.deleteCampaing',
    defaultMessage: 'Eliminar'
  },
  timeRaneg: {
    id: 'components.AddEvent.timeRaneg',
    defaultMessage: 'Rango de horario'
  },
  errorSave: {
    id: 'components.AddEvent.errorSave',
    defaultMessage: 'ERROR AL GUARDAR CAMPOS'
  },
  acept: {
    id: 'components.AddEvent.acept',
    defaultMessage: 'ACEPTAR'
  },
  warningText: {
    id: 'components.AddEvent.warningText',
    defaultMessage:
      'El evento a guardar, debe de contener por lo menos una campaña. Vuelva a intentarlo.'
  }
});
