import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  labelSurvey: {
    id: 'components.RelateSurveyForm.labelSurvey',
    defaultMessage: 'Encuesta'
  },
  placeholderSurvey: {
    id: 'components.RelateSurveyForm.placeholderSurvey',
    defaultMessage: 'Encuesta'
  },
  textSelectSurvey: {
    id: 'components.RelateSurveyForm.textSelectSurvey',
    defaultMessage: 'Selecciona la encuesta que deseas relacionar:'
  },
  applyToAllRobots: {
    id: 'components.RelateSurveyForm.applyToAllRobots',
    defaultMessage: 'Aplicar a todos los robots'
  },
  saveButton: {
    id: 'components.RelateSurveyForm.saveButton',
    defaultMessage: 'GUARDAR'
  }
});
