import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleUser: {
    id: 'components.RegisterUser.titleUser',
    defaultMessage: 'CREAR USUARIO'
  },
  userEdit: {
    id: 'components.RegisterUser.userEdit',
    defaultMessage: 'EDITAR USUARIO'
  },
  userData: {
    id: 'components.FormRegister.userData',
    defaultMessage: 'DATOS DEL USUARIO'
  },
  profilePermissions: {
    id: 'components.FormRegister.profilePermissions',
    defaultMessage: 'PERFIL Y PERMISOS'
  },
  profile: {
    id: 'components.FormRegister.profile',
    defaultMessage: 'Asignar Perfil:'
  },
  imageProfile: {
    id: 'components.FormRegister.imageProfile',
    defaultMessage: 'IMAGEN DEL PERFIL'
  },
  imageLabel: {
    id: 'components.FormRegister.imageLabel',
    defaultMessage: 'Subir Foto'
  },
  infoText: {
    id: 'components.FormRegister.infoText',
    defaultMessage:
      'Selecciona una foto desde tu ordenador, de lo contrario se mostrará una imagen preestablecida:'
  },
  password: {
    id: 'components.FormRegister.password',
    defaultMessage: 'CONTRASEÑA'
  },
  sectionDescription: {
    id: 'components.FormRegister.sectionDescription',
    defaultMessage:
      'Debe contar con al menos 8 caracteres, una letra mayúscula, un número y un signo.'
  },
  passwordRegexError: {
    id: 'components.FormRegister.regexError',
    defaultMessage:
      'La contraseña no cumple con la política de seguridad (Min. 8 caracteres, 1 mayúscula, 1 número y 1 símbolo).'
  },
  uploadFileError: {
    id: 'views.FormRegister.uploadFileError',
    defaultMessage:
      'Ha ocurrido un error al cargar el archivo. Intentalo de nuevo más tarde.'
  },
  save: {
    id: 'components.RegisterUser.save',
    defaultMessage: 'GUARDAR'
  },
  nameLabel: {
    id: 'components.RegisterUser.nameLabel',
    defaultMessage: 'Nombre'
  },
  lastNameLabel: {
    id: 'components.RegisterUser.lastNameLabel',
    defaultMessage: 'Apellidos'
  },
  phoneLabel: {
    id: 'components.RegisterUser.phoneLabel',
    defaultMessage: 'Teléfono'
  },
  emailLabel: {
    id: 'components.RegisterUser.emailLabel',
    defaultMessage: 'Email'
  },
  emailPlaceholder: {
    id: 'components.RegisterUser.emailPlaceholder',
    defaultMessage: 'email@dominio.com'
  },
  passwordLabel: {
    id: 'components.RegisterUser.passwordLabel',
    defaultMessage: 'Contraseña'
  },
  confirmPasswordLabel: {
    id: 'components.RegisterUser.confirmPasswordLabel',
    defaultMessage: 'Confirmar contraseña'
  },
  uploadImageLabel: {
    id: 'components.RegisterUser.uploadImageLabel',
    defaultMessage: 'Subir imagen'
  },
  profileLabel: {
    id: 'components.RegisterUser.profileLabel',
    defaultMessage: 'Perfil'
  },
  profilePlaceholder: {
    id: 'components.RegisterUser.profilePlaceholder',
    defaultMessage: 'Seleccione un perfil'
  },
  onlyNumbers: {
    id: 'routes.RegisterUser.onlyNumbers',
    defaultMessage: 'Escriba unicamente números'
  },
  positiveNumbers: {
    id: 'routes.RegisterUser.positiveNumbers',
    defaultMessage: 'Escriba unicamente números positivos'
  },
  decimalNumbers: {
    id: 'routes.RegisterUser.decimalNumbers',
    defaultMessage: 'Número no válido'
  },
  minNumber: {
    id: 'routes.RegisterUser.maxNumber',
    defaultMessage: 'Teléfono mínimo de 10 digitos'
  },
  clientTitle: {
    id: 'components.RegisterUser.clientTitle',
    defaultMessage: 'CLIENTES'
  },
  assignClient: {
    id: 'components.FormRegister.assignClient',
    defaultMessage: 'Asignar Cliente:'
  },
  clientList: {
    id: 'components.FormRegister.clientList',
    defaultMessage: 'Clientes'
  },
  clientListPlaceholder: {
    id: 'components.FormRegister.clientListPlaceholder',
    defaultMessage: 'Seleccione un cliente'
  },
  client: {
    id: 'components.RegisterUser.client',
    defaultMessage: 'Cliente'
  }
});
