import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  advertising: {
    id: 'components.MediaLibrary.advertising',
    defaultMessage: 'PUBLICIDAD'
  },
  mediaLibrary: {
    id: 'components.MediaLibrary.mediaLibrary',
    defaultMessage: 'BIBLIOTECA DE MEDIOS'
  },
  add: {
    id: 'components.MediaLibrary.add',
    defaultMessage: 'AGREGAR'
  },
  addMedium: {
    id: 'components.MediaLibrary.addMedium',
    defaultMessage: 'AGREGAR MEDIO'
  },
  titleModal: {
    id: 'components.MediaLibrary.titleModal',
    defaultMessage: 'ELIMINAR MEDIO'
  },
  infoTextModal: {
    id: 'components.MediaLibrary.infoTextModal',
    defaultMessage: '¿Seguro que quiere eliminar'
  },
  cancel: {
    id: 'components.MediaLibrary.cancel',
    defaultMessage: 'CANCELAR'
  },
  delete: {
    id: 'components.MediaLibrary.delete',
    defaultMessage: 'Eliminar'
  }
});
