import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  TextLabelSpanish: {
    id: 'components.TextBotSelect.Spanish.TextLabelSpanish',
    defaultMessage: 'Texto para robot'
  },
  BotLabelSpanish: {
    id: 'components.TextBotSelect.Spanish.BotLabelSpanish',
    defaultMessage: 'Selecciona robot'
  },
  TextLabelEnglish: {
    id: 'components.TextBotSelect.English.TextLabelEnglish',
    defaultMessage: 'Text for robot'
  },
  BotLabelEnglish: {
    id: 'components.TextBotSelect.English.BotLabelEnglish',
    defaultMessage: 'Selected robot'
  },
  previewSpanish: {
    id: 'components.TextBotSelect.Spanish.preview',
    defaultMessage: 'Previsualizar'
  },
  previewEnglish: {
    id: 'components.TextBotSelect.English.preview',
    defaultMessage: 'Preview'
  },
  descriptionLabelEnglish: {
    id: 'components.TextBotSelect.English.descriptionLabelEnglish',
    defaultMessage: 'Description'
  },
  descriptionLabelSpanish: {
    id: 'components.TextBotSelect.Spanish.descriptionLabelSpanish',
    defaultMessage: 'Descripción'
  },
  errorRobot: {
    id: 'components.TextBotSelect.errorRobot',
    defaultMessage: 'ERROR AL SELECCIONAR ROBOT'
  },
  infoText: {
    id: 'components.TextBotSelect.infoText',
    defaultMessage:
      'El robot seleccionado, no cuenta con  agentes previo a su selección.'
  },
  suggestionText: {
    id: 'components.TextBotSelect.suggestionText',
    defaultMessage:
      'Verifique que el robot que selecciono cuente con el agente que esta solicitando y posteriormente vuelva a intentarlo.'
  }
});
