import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Slider as MUISlider, TextField } from '@material-ui/core';

import { useStyles } from './SliderStyles';

const Slider = (props) => {
  const classes = useStyles();
  const {
    label,
    min,
    max,
    initialValueSpeed,
    initialValuePitch,
    speedValue,
    pitchValue,
    sliderPitch,
    stepSlider
  } = props;

  const [speed, setSpeed] = useState(initialValueSpeed);
  const [pitch, setPitch] = useState(initialValuePitch);

  useEffect(() => {
    setSpeed(initialValueSpeed);
  }, [initialValueSpeed]);

  useEffect(() => {
    setPitch(initialValuePitch);
  }, [initialValuePitch]);

  const handleChange = (event, newValue) => {
    if (sliderPitch) {
      setPitch(newValue);
      pitchValue(newValue);
    } else {
      setSpeed(newValue);
      speedValue(newValue);
    }
  };

  const handleInputChange = (event) => {
    if (sliderPitch) {
      setPitch(event.target.value === '' ? '' : Number(event.target.value));
      pitchValue(Number(event.target.value));
    } else {
      setSpeed(event.target.value === '' ? '' : Number(event.target.value));
      speedValue(Number(event.target.value));
    }
  };

  const handleBlur = () => {
    if (sliderPitch) {
      if (pitch < -20) {
        setPitch(-20);
      } else if (pitch > 20) {
        setPitch(20);
      }
    } else {
      if (speed < 0.25) {
        setSpeed(0.25);
      } else if (speed > 4) {
        setSpeed(4);
      }
    }
  };

  return (
    <>
      <span>{label}</span>
      <Grid container spacing={2}>
        <Grid item xs>
          <MUISlider
            value={typeof speed || pitch === 'number' ? speed || pitch : 0}
            onChange={handleChange}
            aria-labelledby="input-slider"
            step={stepSlider}
            min={min}
            max={max}
          />
        </Grid>
        <Grid item>
          <TextField
            className={classes.input}
            value={sliderPitch ? pitch : speed}
            onChange={handleInputChange}
            onBlur={handleBlur}
            variant="filled"
            style={{ width: '80px', padding: '0' }}
            inputProps={{
              step: 1,
              min: min,
              max: max,
              type: 'number',
              'aria-labelledby': 'input-slider',
              padding: 0
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

Slider.propTypes = {
  label: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  initialValueSpeed: PropTypes.number,
  initialValuePitch: PropTypes.number,
  speedValue: PropTypes.func,
  pitchValue: PropTypes.func,
  sliderPitch: PropTypes.bool
};

export default Slider;
