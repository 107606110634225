import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';

import {
  Typography,
  Grid,
  Box,
  Card,
  CardContent,
  Button
} from '@material-ui/core';
import { MeetingRoom, PeopleAlt } from '@material-ui/icons';

import { messages } from './CardTakeTurnMessages';
import { useStyles } from './CardTakeTurnStyles';

const CardTakeTurn = ({
  idHasBox,
  numberBox,
  handleUpdateTakeTurn,
  numberTurn,
  handleCloseTurn
}) => {
  const { formatMessage: f } = useIntl();
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Grid container spacing={2} xs={12}>
            <Grid item container xs={12} lg={3}>
              <MeetingRoom className={classes.mainIcon} />
              <Box>
                <Typography className={classes.tittleView} display="block">
                  {f(messages.checkoutAssigned)}
                </Typography>
                <Typography className={classes.noAssign} display="block">
                  {idHasBox ? f(messages.assign) : f(messages.notAssign)}
                  <span className={classes.numberTextNoAssign}>
                    {idHasBox ? numberBox : '-'}
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item container xs={12} lg={3}>
              <PeopleAlt className={classes.secondIcon} />
              <Box>
                <Typography className={classes.tittleView} display="block">
                  {f(messages.turnAssigned)}
                </Typography>
                <Typography className={classes.numberText} display="block">
                  <span className={classes.numberTextNoAssign}>
                    {numberTurn ? numberTurn : '-'}
                  </span>
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={6} className={classes.contentButton}>
              <Button
                fullWidth
                className={classes.buttonAttend}
                size="Large"
                disabled={idHasBox === 0 || numberTurn === 0 ? true : false}
                onClick={() => handleCloseTurn('attended')}
              >
                <Typography className={classes.textButton}>
                  {f(messages.buttonAttended)}
                </Typography>
              </Button>
              <Button
                fullWidth
                className={classes.buttonNoWait}
                size="Large"
                disabled={idHasBox === 0 || numberTurn === 0 ? true : false}
                onClick={() => handleCloseTurn('cancelled')}
              >
                <Typography className={classes.textButton}>
                  {f(messages.buttonNoWait)}
                </Typography>
              </Button>
              <Button
                fullWidth
                className={classes.buttonNext}
                size="Large"
                disabled={idHasBox === 0 || numberTurn !== 0 ? true : false}
                onClick={handleUpdateTakeTurn}
              >
                <Typography className={classes.textButton}>
                  {f(messages.buttonNextTurn)}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

CardTakeTurn.propTypes = {
  idHasBox: PropTypes.number,
  numberBox: PropTypes.number,
  handleUpdateTakeTurn: PropTypes.func,
  numberTurn: PropTypes.number,
  handleCloseTurn: PropTypes.func
};

export { CardTakeTurn };
