const resolutions = [
  {
    id: 1,
    name: 'Full HD',
    resolution: 'Full HD 1920x1080',
    wide: 1920,
    height: 1080,
    status: true,
    ability: 'Si'
  },
  {
    id: 2,
    name: 'Full HD 2k',
    resolution: 'Full HD 2k',
    wide: 2048,
    height: 1080,
    status: true,
    ability: 'Si'
  },
  {
    id: 3,
    name: 'Full HD 4k',
    resolution: 'Full HD 4k',
    wide: 3840,
    height: 2160,
    status: false,
    ability: 'No'
  },
  {
    id: 4,
    name: 'Full UHD',
    resolution: 'Full UHD',
    wide: 1920,
    height: 1080,
    status: false,
    ability: 'No'
  },
  {
    id: 5,
    name: 'Full VGA',
    resolution: 'VGA',
    wide: 800,
    height: 600,
    status: true,
    ability: 'Si'
  }
];

const inputProps = {
  variant: 'outlined',
  fullWidth: true,
  size: 'medium'
};

export { resolutions, inputProps };
