/* eslint-disable no-param-reassign */
import React, { Fragment } from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import {
  Typography,
  Button,
  Grid,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import { Field } from './Field';
import { messages } from './SetupHeaderMessages';
import { useStyles } from './SetupHeaderStyles';
import { HeaderTitle } from 'components/HeaderTitle';

export const SetupHeader = ({
  setPreview,
  fields,
  fieldsEnglish,
  isEnglish,
  edit,
  activeSurvey,
  setActiveSurvey
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const helperTxt = edit ? messages.helperTextEdit : messages.helperText;

  const htmlSetupHeader = (
    <Fragment>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <HeaderTitle firstTitle={<FormattedMessage {...messages.title} />} />
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6}>
        <Typography className={classes.helperText} variant="subtitle2">
          <FormattedMessage {...helperTxt} />
        </Typography>
      </Grid>
      <Grid
        container
        justify="flex-end"
        xs={12}
        sm={12}
        md={6}
        lg={6}
        className={classes.fieldsItems}
      >
        <Button
          className={classes.button}
          onClick={() => setPreview(true)}
          endIcon={<Visibility className={classes.icon} />}
        >
          {intl.formatMessage(messages.previewSurvey)}
        </Button>
        <FormControlLabel
          checked={activeSurvey}
          label={intl.formatMessage(messages.enableSurvey)}
          control={<Switch color="primary" />}
          labelPlacement="start"
          onChange={(e) => setActiveSurvey(e.target.checked)}
        />
      </Grid>
      <Grid item {...fields.surveyTitle.breakpoints}>
        <Field {...fields.surveyTitle} />
      </Grid>
      <Grid item {...fields.surveyDescription.breakpoints}>
        <Field {...fields.surveyDescription} />
      </Grid>
      <Grid item {...fields.surveyFarewell.breakpoints}>
        <Field {...fields.surveyFarewell} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography
          className={classes.questionSectionTitle}
          variant="subtitle2"
        >
          <FormattedMessage {...messages.questionSectionTitle} />
        </Typography>
      </Grid>
    </Fragment>
  );

  const htmlSetupHeaderEnglish = (
    <Fragment>
      <Grid item xs={12}>
        <Typography className={classes.title}>
          {intl.formatMessage(messages.englishInfo)}
        </Typography>
      </Grid>
      <Grid item {...fieldsEnglish.surveyTitleEnglish.breakpoints}>
        <Field {...fieldsEnglish.surveyTitleEnglish} />
      </Grid>
      <Grid item {...fieldsEnglish.surveyDescriptionEnglish.breakpoints}>
        <Field {...fieldsEnglish.surveyDescriptionEnglish} />
      </Grid>
      <Grid item {...fieldsEnglish.surveyFarewellEnglish.breakpoints}>
        <Field {...fieldsEnglish.surveyFarewellEnglish} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Typography
          className={classes.questionSectionTitle}
          variant="subtitle2"
        >
          <FormattedMessage {...messages.questionSectionTitle} />
        </Typography>
      </Grid>
    </Fragment>
  );

  return isEnglish ? htmlSetupHeaderEnglish : htmlSetupHeader;
};
