const formikMapper = {
  name: 'name',
  phone: 'phone',
  open_at: 'open_at',
  until_at: 'until_at',
  direction: 'direction',
  url_maps: 'url_maps',
  latitude: 'latitude',
  longitude: 'longitude',
  text: 'audio_text[0].text',
  descriptionSpanish: 'description[0].text',
  image: 'image',
  textEnglish: 'audio_text[1].text',
  descriptionEnglish: 'description[1].text'
};

export { formikMapper };
