import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ScreenLoaderContext } from './ScreenLoaderContext';

const ScreenLoaderProvider = (props) => {
  const { children } = props;
  const [show, setShow] = useState(false);

  const contextValue = useMemo(
    () => ({
      show,
      handleShowScreen: setShow
    }),
    [show]
  );

  return (
    <ScreenLoaderContext.Provider value={contextValue}>
      {children}
    </ScreenLoaderContext.Provider>
  );
};

ScreenLoaderProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export { ScreenLoaderProvider };
