import { BaseLayout, FullScreenLayout } from 'layouts';
import { Error404 } from 'views/Error404';
import { Alerts } from 'views/Robot/Alerts';
import { Directory as directoryRobot } from 'views/Robot/Directory';
import { Catalog as viewCatalog } from 'views/Robot/Catalog';
import { Gestures } from 'views/Robot/Gestures';
import { Time } from 'views/Robot/Time';
// import { Branding } from 'views/Robot/Branding';
import { Robot } from 'views/Robot';
import { Interactions } from 'views/Robot/Interactions';
import { Resolutions } from 'views/Advertising/Resolutions';
import { Scenes, MainSceneDesign } from 'views/Advertising/Scenes';
import { Events } from 'views/Advertising/Events';
import { MediaLibrary } from 'views/Advertising/MediaLibrary';
import Metrics from 'views/Metrics/Metrics';
import AudioFlow from 'views/Robot/AudioFlow/AudioFlow';
import CreateAudioFlow from 'views/Robot/AudioFlow/CreateAudioFlow/CreateAudioFlow';
import Surveys from 'views/Surveys/Surveys';
import { Screens } from 'views/Advertising/Screens';
import { Products } from 'views/Products/Products';
import { ProductForm } from 'views/Products/ProductForm';
import { Categories as viewCategories } from 'views/Categories/Categories';
import { Subcategories } from 'views/Categories/Subcategories';
import { Directory as viewDirectory } from 'views/Directory/Directory';
import { DirectoryForm } from 'views/Directory/DirectoryForm';
import Branches from 'views/Branches/Branches';
import { HistoryTurn, TakeTurn } from 'views/Turns';
import { Users as viewUsers } from 'views/Users';
import Permissions from 'views/Permissions/Permissions';
import RelatePlatforms from 'views/Robot/RelatePlatforms/RelatePlatforms';
import CreateSurvey from 'views/Surveys/CreateSurveys/CreateSurvey';
import Campaigns from 'views/Advertising/Campaigns/Campaigns';
import CreateBranch from 'views/Branches/CreateBranch/CreateBranch';
import CreateUpdate from 'views/Advertising/Campaigns/CreateUpdate/CreateUpdate';
import { ClientsList, ClientForm } from 'views/Clients';
import UpdateGesture from 'views/Robot/Gestures/UpdateGesture/UpdateGesture';
import { Branches as viewBranches } from 'views/Robot/Branches';
import { MyAccount } from 'views/MyAccount';
import { WelcomeScreen } from 'views/WelcomeScreen';
import { GraphicSurvey } from 'views/Surveys/GraphicSurvey';
import { PersonalInformation } from 'views/Robot/PersonalInformation';
import { InformationTable } from 'views/Information';
import { InformationForm } from 'views/Information/InformationForm';

export const Common = [
  {
    path: '/',
    layout: BaseLayout,
    component: WelcomeScreen,
    exact: true,
    titleMessage: 'welcome',
    private: true
  },
  {
    path: '/my-account',
    layout: BaseLayout,
    component: MyAccount,
    exact: true,
    titleMessage: 'myAccount',
    private: true
  },
  {
    layout: FullScreenLayout,
    component: Error404,
    titleMessage: '404'
  }
];

export const Categories = [
  {
    path: '/categories',
    layout: BaseLayout,
    component: viewCategories,
    exact: true,
    titleMessage: 'categories',
    private: true
  },
  {
    path: '/categories/subcategories',
    layout: BaseLayout,
    component: Subcategories,
    exact: true,
    titleMessage: 'subcategories',
    private: true
  }
];

export const Directory = [
  {
    path: '/directory',
    layout: BaseLayout,
    component: viewDirectory,
    exact: true,
    titleMessage: 'directory',
    private: true
  },
  {
    path: '/directory/form',
    layout: BaseLayout,
    component: DirectoryForm,
    exact: true,
    titleMessage: 'directoryForm',
    private: true
  }
];

export const Advertising = [
  {
    path: '/advertising/scenes',
    layout: BaseLayout,
    component: Scenes,
    exact: true,
    titleMessage: 'advertisingScenes',
    private: true
  },
  {
    path: '/advertising/scenes/design',
    layout: BaseLayout,
    component: MainSceneDesign,
    exact: true,
    titleMessage: 'advertisingScenes',
    private: true
  },
  {
    path: '/advertising/campaigns',
    layout: BaseLayout,
    component: Campaigns,
    exact: true,
    titleMessage: 'advertisingCampaigns',
    private: true
  },
  {
    path: '/advertising/campaigns/create',
    layout: BaseLayout,
    component: CreateUpdate,
    exact: true,
    titleMessage: 'advertisingCampaigns',
    private: true
  },
  {
    path: '/advertising/resolutions',
    layout: BaseLayout,
    component: Resolutions,
    exact: true,
    titleMessage: 'advertisingResolutions',
    private: true
  },

  {
    path: '/advertising/events',
    layout: BaseLayout,
    component: Events,
    exact: true,
    titleMessage: 'advertisingEvents'
  },
  {
    path: '/advertising/screens',
    layout: BaseLayout,
    component: Screens,
    exact: true,
    titleMessage: 'advertisingScreens',
    private: true
  },
  {
    path: '/advertising/media-library',
    layout: BaseLayout,
    component: MediaLibrary,
    exact: true,
    titleMessage: 'advertisingMediaLibrary',
    private: true
  }
];

export const Catalog = [
  {
    path: '/products',
    layout: BaseLayout,
    component: Products,
    exact: true,
    titleMessage: 'products',
    private: true
  },
  {
    path: '/product/form',
    layout: BaseLayout,
    component: ProductForm,
    exact: true,
    titleMessage: 'productsAdd',
    private: true
  }
];

export const Users = [
  {
    path: '/users',
    layout: BaseLayout,
    component: viewUsers,
    exact: true,
    titleMessage: 'users',
    private: true
  }
];

export const Clients = [
  {
    path: '/clients',
    layout: BaseLayout,
    component: ClientsList,
    exact: true,
    titleMessage: 'clients',
    private: true
  },
  {
    path: '/client/form/:id',
    layout: BaseLayout,
    component: ClientForm,
    exact: true,
    titleMessage: 'clients',
    private: true
  }
];

export const Survey = [
  {
    path: '/surveys',
    layout: BaseLayout,
    component: Surveys,
    exact: true,
    titleMessage: 'surveys',
    private: true
  },
  {
    path: '/surveys/create',
    layout: BaseLayout,
    component: CreateSurvey,
    exact: true,
    titleMessage: 'surveysCreate',
    private: true
  },
  {
    path: '/surveys/edit',
    layout: BaseLayout,
    component: CreateSurvey,
    exact: true,
    titleMessage: 'surveysEdit',
    private: true
  },
  {
    path: '/surveys/metrics',
    layout: BaseLayout,
    component: GraphicSurvey,
    exact: true,
    titleMessage: 'surveys',
    private: true
  }
];

export const Turns = [
  {
    path: '/turns/history',
    layout: BaseLayout,
    component: HistoryTurn,
    exact: true,
    titleMessage: 'historyTurns',
    private: true
  },
  {
    path: '/turns/take',
    layout: BaseLayout,
    component: TakeTurn,
    exact: true,
    titleMessage: 'takeTurns',
    private: true
  }
];

export const Permission = [
  {
    path: '/permissions',
    layout: BaseLayout,
    component: Permissions,
    exact: true,
    titleMessage: 'permissions',
    private: true
  }
];

export const Branch = [
  {
    path: '/branch',
    layout: BaseLayout,
    component: Branches,
    exact: true,
    titleMessage: 'branch',
    private: true
  },
  {
    path: '/branch/form/',
    layout: BaseLayout,
    component: CreateBranch,
    exact: true,
    titleMessage: 'branchCreate',
    private: true
  }
];

export const MetricsAnalyctics = [
  {
    path: '/metrics',
    layout: BaseLayout,
    component: Metrics,
    exact: true,
    titleMessage: 'metrics',
    private: true
  }
];

export const RobotAdmin = [
  {
    path: '/robot/times',
    layout: BaseLayout,
    component: Time,
    exact: true,
    titleMessage: 'robotTimes',
    private: false
  },
  {
    path: '/robot/relate-platforms',
    layout: BaseLayout,
    component: RelatePlatforms,
    exact: true,
    titleMessage: 'robot_relate_platforms',
    private: true
  },
  {
    path: '/robot/audios',
    layout: BaseLayout,
    component: AudioFlow,
    exact: true,
    titleMessage: 'robot_audios',
    private: true
  },
  {
    path: '/robot/audios-flow/update',
    layout: BaseLayout,
    component: CreateAudioFlow,
    exact: true,
    titleMessage: 'robot_audios_create',
    private: true
  }
];

export const Robots = [
  {
    path: '/robots',
    layout: BaseLayout,
    component: Robot,
    exact: true,
    titleMessage: 'robot',
    private: true
  },
  {
    path: '/robot/alerts',
    layout: BaseLayout,
    component: Alerts,
    exact: true,
    titleMessage: 'robotAlerts',
    private: true
  },
  {
    path: '/robot/catalog',
    layout: BaseLayout,
    component: viewCatalog,
    exact: true,
    titleMessage: 'robotCatalog',
    private: true
  },
  {
    path: '/robot/branches',
    layout: BaseLayout,
    component: viewBranches,
    exact: true,
    titleMessage: 'robotBranches',
    private: true
  },
  {
    path: '/robot/interactions',
    layout: BaseLayout,
    component: Interactions,
    exact: true,
    titleMessage: 'robotInteractions',
    private: true
  },
  {
    path: '/robot/directory',
    layout: BaseLayout,
    component: directoryRobot,
    exact: true,
    titleMessage: 'robotDirectory',
    private: true
  },
  {
    path: '/robot/gestures',
    layout: BaseLayout,
    component: Gestures,
    exact: true,
    titleMessage: 'robotGestures',
    private: false
  },
  {
    path: '/robot/gestures/edit',
    layout: BaseLayout,
    component: UpdateGesture,
    exact: true,
    titleMessage: 'robotGesturesEdit',
    private: false
  },
  {
    path: '/robot/events',
    layout: BaseLayout,
    component: Events,
    exact: true,
    titleMessage: 'advertisingEvents'
  },
  {
    path: '/robot/personal-information',
    layout: BaseLayout,
    component: PersonalInformation,
    exact: true,
    titleMessage: 'personalInformation'
  }
];

export const Information = [
  {
    path: '/information',
    layout: BaseLayout,
    component: InformationTable,
    exact: true,
    titleMessage: 'information',
    private: true
  },
  {
    path: '/information/form',
    layout: BaseLayout,
    component: InformationForm,
    exact: true,
    titleMessage: 'informationAdd',
    private: true
  }
];
