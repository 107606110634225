const tableHead = [
  { key: 'id', label: 'ID' },
  { key: 'date', label: 'FECHA' },
  { key: 'interaction_start_time', label: 'HORA DE INICIO' },
  { key: 'gender', label: 'GÉNERO' },
  { key: 'age', label: 'EDAD' },
  { key: 'poll_name', label: 'ENCUESTA APLICADA' },
  { key: 'poll_id', label: 'ID ENCUESTA' },
  { key: 'interaction_end_time', label: 'HORA DE TÉRMINO' },
  { key: 'total_interaction_time', label: 'TIEMPO TOTAL INTERACCIÓN' }
];

const filters = [{ key: 'date', value: 'FECHA' }];

const mapInteractions = (values) => {
  const mappedBranches = values.map((interaction) => ({
    'Id ': interaction.id,
    'Fecha ': interaction.date,
    'Hora de inicio': interaction.interaction_start_time,
    'Género ': interaction.gender,
    'Edad ': interaction.age,
    'Nombre Encuesta': interaction.poll_name,
    'Id Encuesta': interaction.poll_id,
    'Hora de término': interaction.interaction_end_time,
    'Tiempo total de interacción': interaction.total_interaction_time
  }));

  return mappedBranches;
};

export { tableHead, filters, mapInteractions };
