import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    [theme.breakpoints.down('xs')]: {
      margin: `0 ${theme.spacing(1)}px`,
      padding: theme.spacing(1),
      marginTop: theme.spacing(2)
    }
  },
  contentActions: {
    displya: 'flex',
    alignItems: 'center'
  },
  contentSubactions: {
    marginTop: theme.spacing(4),
    display: 'flex',
    alignItems: 'center'
  },
  contentTitles: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  catalogTitle: {
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  categoriesTitle: {
    fontSize: '20px',
    letterSpacing: '6px',
    color: theme.palette.primary.main,
    fontWeight: 800,
    textTransform: 'uppercase'
  },
  contentButons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-end',
      [theme.breakpoints.down('xs')]: {
        justifyContent: 'center',
        marginTop: theme.spacing(2)
      }
    }
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  arrowIcon: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  }
}));

export { useStyles };
