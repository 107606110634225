import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  surveysTitle: {
    id: 'components.Surveys.surveysTitle',
    defaultMessage: 'ENCUESTAS'
  },
  createQuiz: {
    id: 'components.Surveys.createQuiz',
    defaultMessage: 'CREAR ENCUESTA'
  },
  surveyMetrics: {
    id: 'components.Surveys.surveyMetrics',
    defaultMessage: 'Ver métricas'
  },
  downloadExcel: {
    id: 'components.Surveys.downloadExcel',
    defaultMessage: 'Descargar'
  }
});
