import React from 'react';
import { useIntl } from 'react-intl';
import {
  Paper,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  CardActions,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import {
  ImageOutlined,
  VideoLibrary,
  PlayArrow,
  Stop
} from '@material-ui/icons';
import { useStyles } from './SceneDesignStyles';
import { messages } from './SceneDesignMessages';
import { useHistory } from 'react-router-dom';
import { Carrousel } from 'components/Carrousel';
import { DragDropList } from 'components/DragDropList';
import { useSceneDesign } from './useSceneDesign';
import { TYPE_VIEW_IMAGE, TYPE_VIEW_VIDEO } from 'utils/TypeViewScene';

const SceneDesign = ({
  sceneName,
  media,
  handleChangeMedia,
  handleChangeView,
  handleSetMediaSelected,
  handleSubmitScene
}) => {
  const { formatMessage: f } = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const {
    onDragEnd,
    options,
    play,
    setPlay,
    isHorizontal,
    setIsHorizontal
  } = useSceneDesign(
    media,
    handleChangeMedia,
    handleChangeView,
    handleSetMediaSelected
  );

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12} md={isHorizontal ? 12 : 4}>
        <Card
          style={{
            backgroundColor: 'black',
            minHeight: 600,
            height: '100%',
            maxHeight: isHorizontal ? 600 : '100%'
          }}
        >
          <Carrousel play={play} stopMedia={setPlay} data={media} />
          <CardActions style={{ position: 'absolute', bottom: 10, left: 10 }}>
            <Button
              disabled={play}
              className={classes.buttonMedia}
              onClick={() => {
                if (media.length > 0) {
                  setPlay(true);
                }
              }}
            >
              <PlayArrow />
            </Button>
            <Button
              disabled={!play}
              className={classes.buttonMedia}
              onClick={() => {
                setPlay(false);
              }}
            >
              <Stop />
            </Button>
          </CardActions>
        </Card>
      </Grid>
      <Grid item xs={12} md={isHorizontal ? 12 : 8}>
        <Grid container direction="column" spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h1"
              className={classes.titleSceneDesign}
              display="inline"
            >
              {f(messages.titleDesignScene)}
            </Typography>
            <Typography
              variant="h1"
              className={classes.sceneName}
              display="inline"
            >
              {sceneName}
            </Typography>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography
                variant="h6"
                className={classes.textResolution}
                display="inline"
              >
                {f(messages.textResolution)}
              </Typography>
              <Typography
                variant="h6"
                className={classes.numberResolution}
                display="inline"
              >
                {isHorizontal ? '1080x1920' : '1920x1080'}
              </Typography>
            </Grid>
            <Grid item container direction="row-reverse" xs={6}>
              <Button
                className={classes.buttonSave}
                onClick={() => {
                  handleSubmitScene();
                }}
              >
                {f(messages.buttonSave)}
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid item xs={12}>
              <FormControlLabel
                className={classes.textResolution}
                control={
                  <Checkbox
                    checked={isHorizontal}
                    onChange={() => setIsHorizontal(!isHorizontal)}
                  />
                }
                label={f(messages.textOrientationHorizontal)}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              <CardContent>
                <Typography variant="h2" className={classes.tittleAddMedia}>
                  {f(messages.titleAddMedia)}
                </Typography>
                <Paper className={classes.buttonsContainer}>
                  <Button
                    className={classes.button}
                    startIcon={<ImageOutlined />}
                    onClick={() => {
                      handleChangeView(TYPE_VIEW_IMAGE);
                    }}
                  >
                    {f(messages.buttonImage)}
                  </Button>
                  <Button
                    className={classes.button}
                    startIcon={<VideoLibrary />}
                    onClick={() => {
                      handleChangeView(TYPE_VIEW_VIDEO);
                    }}
                  >
                    {f(messages.buttonVideo)}
                  </Button>
                </Paper>
                <DragDropList
                  items={media}
                  onDragEnd={(e) => onDragEnd(e, media, handleChangeMedia)}
                  options={options}
                />
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export { SceneDesign };
