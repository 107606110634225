import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  rootCard: {
    padding: theme.spacing(2),
    borderRadius: '16px',
    width: '100%',
    marginTop: theme.spacing(2)
  },
  contentPieChart: {
    width: '100%',
    height: '415px',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      heigh: 'autot'
    }
  }
}));

export { useStyles };
