import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Table } from '@octopy/react-table';
import { useLoader } from '@octopy/react-loader';
import { Typography, Grid, Button } from '@material-ui/core';
import { AddCircle, Delete } from '@material-ui/icons';
import { useDrawer } from 'components/Drawer';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { MediaLibraryForm } from './MediaLibraryForm';
import { useApi } from 'hooks';
import { get } from 'lodash';
import { messages } from './MediaLibraryMessages';
import { useStyles } from './MediaLibraryStyles';

const MediaLibrary = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { modalConfirmationDelete } = useModalConfirmation();
  const { handleShowLoader } = useLoader();
  const { handleOpenDrawer, handleCloseDrawer } = useDrawer();
  const [mediaLibraryList, setMediaLibraryList] = useState([]);
  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = get(storage, 'user.client.client_id', {});

  const [mediaFile] = useApi({
    endpoint: `/publicity/media_library/media_by_client/${clientId}/`,
    method: 'get'
  });

  const [deleteMediaFile] = useApi({
    endpoint: '/publicity/media_library',
    method: 'delete'
  });

  useEffect(() => {
    handleShowLoader(true);
    getMediaFile();
  }, []);

  const getMediaFile = async () => {
    try {
      const response = await mediaFile();
      const mediaFileResponse = get(response, 'payload', []);

      if (response.headerResponse.code === 200) {
        setMediaLibraryList(mediaFileResponse);
        handleShowLoader(false);
      }
    } catch (error) {}
  };

  const handleDeleteMediaFile = async (id) => {
    try {
      const response = await deleteMediaFile({
        urlParams: `${id}/`
      });
      const headerResponse = get(response, 'headerResponse', {});

      if (headerResponse.code === 200) getMediaFile();
    } catch (error) {}
  };

  const tableHead = [
    { key: 'media_id', label: 'ID' },
    { key: 'name', label: 'NOMBRE' },
    { key: 'file_type', label: 'TIPO' },
    { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false },
    { key: '', label: '' }
  ];

  const filters = [
    { key: 'name', value: 'NOMBRE' },
    { key: 'file_type', value: 'TIPO' },
    { key: '', value: '' }
  ];

  const configProps = {
    filters,
    selectedOptions: {
      checkboxHidden: true
    },
    actions: {
      customs: {
        text: intl.formatMessage(messages.delete),
        onClick: (item) => handleModal(item),
        icon: <Delete />
      }
    }
  };

  const handleModal = (data) => {
    modalConfirmationDelete(
      intl.formatMessage(messages.titleModal),
      intl.formatMessage(messages.infoTextModal),
      data.name,
      () => {
        handleDeleteMediaFile(data.media_id);
      }
    );
  };

  const handleDrawer = () => {
    handleOpenDrawer({
      closeButton: true,
      title: (
        <Typography className={classes.titleModal}>
          {intl.formatMessage(messages.addMedium)}
        </Typography>
      ),
      body: (
        <MediaLibraryForm
          getMediaFile={getMediaFile}
          handleCloseDrawer={() => handleCloseDrawer()}
        />
      ),
      configProps: {
        anchor: 'right'
      }
    });
  };

  return (
    <div className={classes.container}>
      <Grid container className={classes.contentActions}>
        <Grid item xs={12} md={9} className={classes.contentTitle}>
          <Typography className={classes.title}>
            {intl.formatMessage(messages.advertising)} /&nbsp;
          </Typography>
          <Typography className={classes.interactions}>
            {intl.formatMessage(messages.mediaLibrary)}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3} className={classes.contentButton}>
          <Button
            className={classes.button}
            endIcon={<AddCircle className={classes.addIcon} />}
            onClick={() => handleDrawer()}
          >
            {intl.formatMessage(messages.add)}
          </Button>
        </Grid>
      </Grid>
      <Grid className={classes.contentTable}>
        <Table
          columns={tableHead}
          data={mediaLibraryList}
          configProps={configProps}
          // onSelectItems={onSelectItems}
        />
      </Grid>
    </div>
  );
};

MediaLibrary.propTypes = {};

export { MediaLibrary };
