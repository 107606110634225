import React from 'react';
import { useIntl } from 'react-intl';
import { useModal } from '@octopy/react-modal';
import { Typography, Grid, Button, IconButton } from '@material-ui/core';
import { ReportProblemOutlined, CheckCircleOutline } from '@material-ui/icons';
import { Close } from '@material-ui/icons';
import { messages } from './useModalConfirmationMessages';
import { useStyles } from './useModalConfirmationStyles';

export const useModalConfirmation = () => {
  const { formatMessage: f } = useIntl();
  const classes = useStyles();
  const { handleOpenModal, handleCloseModal } = useModal();

  const modalConfirmation = (
    titleText,
    bodyText,
    // nameData,
    callbackConfirm = () => {},
    callbackCancel = () => {},
    showCancelButton = true
  ) => {
    handleOpenModal({
      title: (
        <Grid className={classes.contentModalDelete}>
          <Grid className={classes.contentIconClose}>
            <IconButton onClick={() => handleCloseModal()}>
              <Close className={classes.iconClose} />
            </IconButton>
          </Grid>
          <Typography className={classes.titleModal}>{titleText}</Typography>
        </Grid>
      ),
      body: (
        <Grid style={{ marginBottom: 20 }}>
          {/* {nameData ? (
            <Typography className={classes.infoTextModal}>
              {bodyText} &nbsp;
              <span className={classes.nameData}>{nameData}</span>?
            </Typography>
          ) : (
            <Typography className={classes.infoTextModal}>
              {bodyText}
            </Typography>
          )} */}
          <Typography className={classes.infoTextModal}>{bodyText}</Typography>
          <Grid className={classes.contentButtonsModal}>
            {showCancelButton && (
              <Button
                className={classes.buttonCanel}
                onClick={() => {
                  handleCloseModal();
                  callbackCancel();
                }}
              >
                {f(messages.cancel)}
              </Button>
            )}
            <Button
              className={classes.button}
              onClick={() => {
                handleCloseModal();
                callbackConfirm();
              }}
            >
              {f(messages.confirm)}
            </Button>
          </Grid>
        </Grid>
      ),
      configProps: {
        maxWidth: 'lg'
      }
    });
  };

  const modalConfirmationDelete = (
    titleText,
    bodyText,
    nameData,
    callbackConfirm = () => {},
    callbackCancel = () => {},
    showCancelButton = true
  ) => {
    handleOpenModal({
      title: (
        <Grid className={classes.contentModalDelete}>
          <Grid className={classes.contentIconClose}>
            <IconButton onClick={() => handleCloseModal()}>
              <Close className={classes.iconClose} />
            </IconButton>
          </Grid>
          <Typography className={classes.titleModal}>{titleText}</Typography>
        </Grid>
      ),
      body: (
        <Grid style={{ marginBottom: 20 }}>
          {nameData ? (
            <div className={classes.contentText}>
              <span className={classes.fontText}>
                {bodyText} &nbsp;
                <span className={classes.nameData}>{nameData}</span>?
              </span>
            </div>
          ) : (
            <Typography className={classes.infoTextModal}>
              {bodyText}
            </Typography>
          )}
          <Grid className={classes.contentButtonsModal}>
            {showCancelButton && (
              <Button
                className={classes.buttonCanel}
                onClick={() => {
                  handleCloseModal();
                  callbackCancel();
                }}
              >
                {f(messages.cancel)}
              </Button>
            )}
            <Button
              className={classes.button}
              onClick={() => {
                handleCloseModal();
                callbackConfirm();
              }}
            >
              {f(messages.confirm)}
            </Button>
          </Grid>
        </Grid>
      ),
      configProps: {
        maxWidth: 'lg'
      }
    });
  };

  const modalReportProblem = (
    titleText,
    bodyText,
    suggestion,
    callbackConfirm = () => {}
  ) => {
    handleOpenModal({
      title: (
        <Grid className={classes.contentModal}>
          <ReportProblemOutlined className={classes.icon} />
          <Typography className={classes.titleModal}>{titleText}</Typography>
        </Grid>
      ),
      body: (
        <Grid style={{ marginBottom: 20, textAlign: 'center' }}>
          <Typography className={classes.infoTextModal}>{bodyText}</Typography>
          {suggestion ? (
            <Grid>
              <Typography className={classes.infoTextModal}>
                {suggestion}
              </Typography>
            </Grid>
          ) : null}
          <Button
            className={classes.button}
            onClick={() => {
              handleCloseModal();
              callbackConfirm();
            }}
          >
            {f(messages.confirm)}
          </Button>
        </Grid>
      )
    });
  };

  const modalSuccess = (titleText, bodyText, callbackConfirm = () => {}) => {
    handleOpenModal({
      title: (
        <Grid className={classes.contentModal}>
          <CheckCircleOutline className={classes.iconSuccess} />
          <Typography className={classes.titleModal}>{titleText}</Typography>
        </Grid>
      ),
      body: (
        <Grid style={{ marginBottom: 20, textAlign: 'center' }}>
          <Typography className={classes.infoTextModal}>{bodyText}</Typography>
          <Button
            className={classes.button}
            onClick={() => {
              handleCloseModal();
              callbackConfirm();
            }}
          >
            {f(messages.confirm)}
          </Button>
        </Grid>
      )
    });
  };

  return {
    modalConfirmation,
    modalConfirmationDelete,
    modalReportProblem,
    modalSuccess
  };
};
