const tableHead = [
  { key: 'branchoffice_id', label: 'ID' },
  { key: 'name', label: 'NOMBRE', align: 'center' },
  { key: 'phone', label: 'TELÉFONO', align: 'center' },
  { key: 'direction', label: 'DIRECCIÓN', align: 'center' },
  { key: 'open_at', label: 'HORA APERTURA', align: 'center' },
  { key: 'until_at', label: 'HORA CIERRE', align: 'center' },
  { key: 'url_maps', label: 'GOOGLE MAPS', align: 'center' },
  { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false }
];

const filters = [{ key: 'name', value: 'Nombre' }];

export { filters, tableHead };
