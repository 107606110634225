import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  alertRobot: {
    id: 'components.Card.alertRobot',
    defaultMessage: 'Alertas:'
  },
  interactionRobot: {
    id: 'components.Card.interactionRobot',
    defaultMessage: 'Interacciones:'
  },
  Status: {
    id: 'components.Card.Status',
    defaultMessage: 'Estatus'
  },
  robotDelete: {
    id: 'components.Card.robotDelete',
    defaultMessage: 'Eliminar '
  },
  relateSurvey: {
    id: 'components.Card.relateSurvey',
    defaultMessage: 'Relacionar encuesta '
  },
  titleModal: {
    id: 'components.Card.titleModal',
    defaultMessage: 'ELIMINAR ROBOT'
  },
  descriptionModal: {
    id: 'components.Card.descriptionModal',
    defaultMessage: '¿Seguro que quiere eliminar este Robot?'
  },
  buttonModal: {
    id: 'components.Card.buttonModal',
    defaultMessage: 'CONTINUAR'
  },
  cancel: {
    id: 'components.Card.cancel',
    defaultMessage: 'CANCELAR'
  },
  clientRobots: {
    id: 'components.Card.clientRobots',
    defaultMessage: 'Robots: '
  },
  clientDate: {
    id: 'components.Card.clientDate',
    defaultMessage: 'Fecha de vencimiento: '
  },
  clientPermissions: {
    id: 'components.Card.clientPermissions',
    defaultMessage: 'No. Permisos: '
  },
  clientSpace: {
    id: 'components.Card.clientSpace',
    defaultMessage: 'Espacio utilizado: '
  },
  robotTitleDrawer: {
    id: 'components.Card.robotTitleDrawer',
    defaultMessage: 'ROBOT001'
  },
  relateSurveyDrawer: {
    id: 'components.Card.relateSurveyDrawer',
    defaultMessage: 'RELACIONAR ENCUESTA'
  }
});
