import { useApi } from 'hooks';
import { get } from 'lodash';
import { useState } from 'react';
import { mapClients } from '../helpers';
import { useHistory } from 'react-router-dom';
import { useDrawer } from 'components/Drawer';
import { useModal } from '@octopy/react-modal';

const useRobots = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = get(storage, 'user.client.client_id', {});
  const isSuperAdmin = get(storage, 'user.is_staff', {});

  const [newData, setNewData] = useState([]);
  const [data, setData] = useState('');
  const [robot, setRobot] = useState([]);
  const [clientsList, setClientsList] = useState([]);
  const { handleCloseDrawer } = useDrawer();
  const { handleCloseModal } = useModal();
  const history = useHistory();

  const [robotListClient] = useApi({
    endpoint: `/robots/robots/robots_by_client/${clientId}`,
    method: 'get'
  });

  const [superAdminRobotList] = useApi({
    endpoint: '/robots/robots/',
    method: 'get'
  });

  const [deleteRobot] = useApi({
    endpoint: '/robots/robots',
    method: 'delete'
  });

  const [getmanagementClients] = useApi({
    endpoint: 'management/clients',
    method: 'get'
  });

  const getRobotList = async () => {
    try {
      const response = await robotListClient();
      const robotResponse = get(response, 'payload', []);

      if (response.headerResponse.code === 200) {
        setNewData(robotResponse);
        setRobot(robotResponse);
      }
    } catch (error) {}
  };

  const getSuperAdminRobotList = async () => {
    try {
      const response = await superAdminRobotList();
      const headerResponse = get(response, 'headerResponse', '');
      const dataResponse = get(response, 'payload', []);

      if (headerResponse.code === 200) {
        setNewData(dataResponse);
        setRobot(dataResponse);
      }
    } catch (error) {}
  };

  const getClients = async () => {
    try {
      const {
        headerResponse: { code },
        payload
      } = await getmanagementClients();

      if (code === 200) {
        setClientsList(mapClients(payload));
      }
    } catch (error) {}
  };

  const handleDelete = async (robotId) => {
    try {
      const responseDeleteRobot = await deleteRobot({
        urlParams: `${robotId}/`
      });

      getRobotList();

      if (responseDeleteRobot.headerResponse.code === 200) {
        history.push('/robots');
        handleCloseDrawer();
        handleCloseModal();
      }
    } catch (error) {}
  };

  const handleOnchange = (event) => {
    const keyword = event.target.value;

    if (keyword !== '') {
      const result = robot.filter((item) =>
        item.name.toLowerCase().startsWith(keyword.toLowerCase())
      );

      setNewData(result);
    } else {
      setNewData(robot);
    }

    setData(keyword);
  };

  const [robotPowerOff] = useApi({
    endpoint: `/robots/power_options/robot_power_off/`,
    method: 'post'
  });

  const handleRobotPowerOff = async (idRobot) => {
    const {
      headerResponse: { code }
    } = await robotPowerOff({
      body: {
        robot_id: idRobot
      }
    });

    if (code === 200) {
      if (isSuperAdmin) {
        getSuperAdminRobotList();
      } else {
        getRobotList();
      }

      handleCloseModal();
    }
  };

  return {
    newData,
    data,
    clientsList,
    handleDelete,
    handleOnchange,
    getRobotList,
    getSuperAdminRobotList,
    getClients,
    handleRobotPowerOff
  };
};

export { useRobots };
