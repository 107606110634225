import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  nameLabel: {
    id: 'components.CategoriesRobot.nameLabel',
    defaultMessage: 'Nombre'
  },
  namePlaceholder: {
    id: 'components.CategoriesRobot.namePlaceholder',
    defaultMessage: 'Nombre'
  },
  nameEnglishLabel: {
    id: 'components.CategoriesRobot.nameEnglishLabel',
    defaultMessage: 'Name'
  },
  nameEnglishPlaceholder: {
    id: 'components.CategoriesRobot.nameEnglishPlaceholder',
    defaultMessage: 'Name'
  },
  saveButton: {
    id: 'components.CategoriesRobot.saveButton',
    defaultMessage: 'GUARDAR'
  },
  descriptionAdd: {
    id: 'components.CategoriesRobot.descriptionAdd',
    defaultMessage: 'Agrega un nombre a la nueva categoría'
  },
  titleEnglish: {
    id: 'components.CategoriesRobot.titleEnglish',
    defaultMessage: 'EN INGLÉS'
  }
});
