import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
      margin: `0 ${theme.spacing(1.5)}px`,
      padding: theme.spacing(0)
    }
  },
  pageTitle: {
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(20),
    fontWeight: 'bold',
    letterSpacing: theme.typography.pxToRem(8)
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  titleModal: {
    fontSize: '20px',
    fontWeight: 800,
    color: theme.palette.common.black,
    letterSpacing: '6px'
  },
  contentModal: {
    width: '600px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  },
  contentIconClose: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  iconClose: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    color: theme.palette.common.black,
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(2.5),
      height: theme.spacing(2.5)
    }
  },
  infoTextModal: {
    fontSize: '14px',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2.5),
    color: theme.palette.common.black
  },
  nameData: {
    fontSize: '14px',
    fontWeight: 800
  },
  contentButtonsModal: {
    display: 'flex',
    justifyContent: 'space-evenly',
    maxWidth: '100%'
  },
  buttonCanel: {
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.common.black}CE`,
      color: theme.palette.common.white
    }
  },
  contentButons: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'flex-end',
      marginTop: '15px'
    }
  },
  contentTable: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(7)
    }
  }
}));

export { useStyles };
