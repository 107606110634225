import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useLoader } from '@octopy/react-loader';
import { Table } from '@octopy/react-table';
import { Grid, Typography } from '@material-ui/core';
import { useApi } from 'hooks';
import { tableHead, filters } from './utils';
import { messages } from './AlertsMessages';
import { useStyles } from './AlertsStyles';
import { get } from 'lodash';
import { format } from 'date-fns/esm';
import { HeaderTitle } from 'components/HeaderTitle';

const Alerts = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { handleShowLoader } = useLoader();
  const [alerts, setAlerts] = useState([]);
  const idRobot = history.location.state.robot_id;

  const [getAlertList] = useApi({
    endpoint: `/notifications/notifications/notifications_by_robot/${idRobot}/`,
    method: 'get'
  });

  useEffect(() => {
    handleShowLoader(true);
    getAlerts();
  }, []);

  const getAlerts = async () => {
    try {
      const response = await getAlertList();
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', []);

      setTimeout(() => {
        handleShowLoader(false);
        if (headerResponse.code === 200) {
          const formatDataResponse = dataResponse.map((item) => {
            const id = item.notification_id;
            const createDate = item.origin_created_date
              ? format(new Date(item.origin_created_date), 'dd/MM/yyyy')
              : '';
            const hourCreate = item.origin_created_date
              ? format(new Date(item.origin_created_date), 'HH:mm:ss')
              : '';
            const alert = item.title;
            const typeAlert = handleType(item.type);
            const description = item.description;
            const alertPriority = item.priority
              ? handlePriority(item.priority)
              : '';

            return {
              notification_id: id,
              origin_created_date: createDate,
              origin_created_hour: hourCreate,
              alert: alert,
              type: typeAlert,
              description: description,
              priority: alertPriority
            };
          });

          setAlerts(formatDataResponse);
        }
      }, 1000);
    } catch (error) {
      handleShowLoader(false);
    }
  };

  const handleType = (type) => {
    switch (type) {
      case 'error':
        return (
          <Typography className={classes.typeRed}>
            {intl.formatMessage(messages.error)}
          </Typography>
        );

      case 'warning':
        return (
          <Typography className={classes.typeOrange}>
            {intl.formatMessage(messages.warning)}
          </Typography>
        );

      case 'success':
        return (
          <Typography className={classes.typeGreen}>
            {intl.formatMessage(messages.success)}
          </Typography>
        );

      default:
        break;
    }
  };

  const handlePriority = (priority) => {
    switch (priority) {
      case 'high':
        return (
          <Typography className={classes.typeRed}>
            {intl.formatMessage(messages.high)}
          </Typography>
        );

      case 'medium':
        return (
          <Typography className={classes.typeOrange}>
            {intl.formatMessage(messages.medium)}
          </Typography>
        );

      default:
        break;
    }
  };

  const configProps = {
    filters,
    selectedOptions: {
      checkboxHidden: true
    }
  };

  return (
    <div className={classes.container}>
      <HeaderTitle
        firstTitle={history.location.state.name}
        secondTitle={intl.formatMessage(messages.alertTitle)}
        redirection="robots"
      />
      <Grid className={classes.contentTable}>
        <div className={'tab-0'}>
          <Table columns={tableHead} data={alerts} configProps={configProps} />
        </div>
      </Grid>
    </div>
  );
};

Alerts.propTypes = {};

export { Alerts };
