import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TYPE_IMAGEN, TYPE_VIDEO } from 'utils/TypeSource';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    flexGrow: 1
  },
  img: {
    overflow: 'hidden',
    width: '100%',
    height: '100%',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%'
  },
  videoContainer: {
    width: '100%',
    height: '100%',
    display: 'flex'
  },
  video: {
    width: '100%',
    height: 'auto'
  }
}));

const Carrousel = ({ data, play, stopMedia, isPreview }) => {
  const classes = useStyles();
  const videoRef = useRef(null);
  const [activeStep, setActiveStep] = useState(0);
  const [media, setMedia] = useState({});
  const [timer, setTimer] = useState(null); //temporizador

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (play && data.length > 0) {
      let initMedia = data[0];

      setMedia(initMedia);
      const idTimer = setTimeout(() => {
        if (data.length > 1) {
          handleNext();
        } else {
          stopMedia(false);
        }
      }, initMedia.time * 1000);

      setTimer(idTimer);
    } else {
      clearTimeout(timer);
      setMedia({});
      setActiveStep(0);
    }
  }, [play]);

  useEffect(() => {
    if (play && data.length >= 1) {
      if (activeStep <= data.length) {
        const currentMedia = data[activeStep];

        setMedia(currentMedia);
        const idTimer = setTimeout(() => {
          const nextStep = activeStep + 1;

          if (nextStep < data.length) {
            handleNext();
          } else {
            stopMedia(false);
          }
        }, currentMedia.time * 1000);

        setTimer(idTimer);
      }
    }
  }, [activeStep]);

  useEffect(() => {
    if (play) {
      if (media.type === TYPE_VIDEO) {
        if (videoRef.current) {
          //videoRef.current.load();// trigger to notify new url
          videoRef.current.pause();
          videoRef.current.currentTime = 0;
          videoRef.current.play();
        }
      }
    }
  }, [media]);

  useEffect(() => {
    if (!isPreview) {
      stopMedia(false);
    }
  }, [data]);

  return (
    <div className={classes.root}>
      {media.type === TYPE_VIDEO ? (
        <div className={classes.videoContainer}>
          <video key={media.id} className={classes.video} ref={videoRef} loop>
            <source src={media.url} type="video/mp4" />
            <track kind="captions" />
            Your browser does not support the video tag.
          </video>
        </div>
      ) : null}
      {media.type === TYPE_IMAGEN ? (
        <img className={classes.img} src={media.url} alt={media.name} />
      ) : null}
    </div>
  );
};

export { Carrousel };
