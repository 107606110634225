import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  cancel: {
    id: 'components.useModalConfirmation.cancel',
    defaultMessage: 'CANCELAR'
  },
  confirm: {
    id: 'components.useModalConfirmation.confirm',
    defaultMessage: 'ACEPTAR'
  }
});
