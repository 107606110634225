/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import * as Yup from 'yup';
import { useIntl } from 'react-intl';
import { messages as formMessages } from '@octopy/react-form';
import { messages } from './ClientFormMessages';
import { get } from 'lodash';
import { useApi } from 'hooks';
import { uploadFilePromise } from 'utils/aws';
import { Alert, useModal } from '@octopy/react-modal';
import { useLoader } from '@octopy/react-loader';
import { useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { sources } from 'providers/AWS';
import {
  SESSION_ENGLISH,
  SESSION_SPANISH,
  SESSION_QUIMIC
} from 'utils/sessionLanguage';

export const useClientForm = (idClient) => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const bucketName = get(storage, 'user.client.bucket_name', {});
  const { images } = sources(bucketName);
  const { handleShowLoader } = useLoader();
  const { handleOpenModal } = useModal();
  const { formatMessage: f } = useIntl();
  const history = useHistory();

  //initial Formik Structure
  const requiredFieldError = f(formMessages.requiredFieldError);
  const colorHexRegex = '^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$';

  const getValidationRules = () =>
    Yup.object().shape({
      image: Yup.object()
        .shape({ url: Yup.string() })
        .nullable()
        .required(requiredFieldError),
      name: Yup.string().max(60).required(requiredFieldError),
      primary_color: Yup.string()
        .matches(colorHexRegex, {
          message: f(messages.colorHexValidation),
          excludeEmptyString: true
        })
        .required(requiredFieldError),
      secondary_color: Yup.string()
        .matches(colorHexRegex, {
          message: f(messages.colorHexValidation),
          excludeEmptyString: true
        })
        .required(requiredFieldError)
    });

  const initialValues = {
    image: null,
    name: '',
    primary_color: '',
    secondary_color: ''
  };

  const inputProps = {
    fullWidth: true
  };

  const fieldsForm = [
    Object.assign(
      {
        type: 'text',
        name: 'name',
        label: f(messages.nameLabelForm),
        breakpoints: { xs: 12 }
      },
      inputProps
    ),
    Object.assign({
      type: 'image',
      name: 'image',
      breakpoints: { xs: 12 },
      icon: PublishIcon,
      label: f(messages.imageLabelForm),
      multiple: false,
      color: 'primary.main'
    }),
    Object.assign(
      {
        type: 'text',
        name: 'primary_color',
        label: f(messages.colorLabelForm),
        breakpoints: { xs: 12 }
      },
      inputProps
    ),
    Object.assign(
      {
        type: 'text',
        name: 'secondary_color',
        label: f(messages.colorLabelForm),
        breakpoints: { xs: 12 }
      },
      inputProps
    )
  ];

  // Get modules
  const [modulesFieldsForm, setModulesFieldsForm] = useState([]);
  const [valuesForm, setValuesForm] = useState(initialValues);
  const [modules, setModules] = useState([]);

  const [getModulesList] = useApi({
    endpoint: `management/modules/`,
    method: 'get'
  });

  const getModules = async () => {
    const response = await getModulesList();

    const modulesResponse = get(response, 'payload', []);

    let fieldsFormSwitch = [];
    let modules = initialValues;

    modulesResponse.map((module) => {
      modules[`${module.name}`] = false;
      fieldsFormSwitch.push(
        Object.assign(
          {
            type: 'switch',
            name: module.name,
            label: module.name,
            breakpoints: { xs: 12, md: 12, lg: 6, xl: 6 }
          },
          inputProps
        )
      );
    });

    setModules(modulesResponse);
    setValuesForm(modules);
    setModulesFieldsForm(fieldsFormSwitch);
  };

  useEffect(() => {
    getModules();
  }, []);

  //Formik
  const formik = useFormik({
    initialValues: valuesForm,
    validationSchema: getValidationRules(),
    onSubmit: (values) => handleSubmit(values)
  });

  //Utils
  const getIdsModules = (values) => {
    let arrayIds = [];

    modules.map((module) => {
      let exits = values[module.name] != undefined;

      //Comprobar si se encuentra el modulo dentro de los campos del formik
      if (exits) {
        //Comprobar que si el modulo se encuentra activo
        if (values[module.name]) {
          arrayIds.push(module.module_id);
        }
      }
    });

    return arrayIds;
  };

  const setModulesFormik = (modules) => {
    modules.map((module) => {
      formik.setFieldValue(module.name, true);
    });
  };

  const erroUploadFile = () => {
    handleOpenModal({
      configProps: {
        maxWidth: 'sm'
      },
      body: <Alert message={f(messages.uploadFileError)} />
    });
    handleShowLoader(false);
  };

  //Events Create Edit Client
  const handleSubmit = async (values) => {
    handleShowLoader(true);
    let body = {
      image: values.image.file,
      name: values.name,
      primary_color: values.primary_color,
      secondary_color: values.secondary_color,
      modules: getIdsModules(values)
    };

    if (values.image && values.image.file) {
      const urlS3 = await uploadFilePromise(
        values.image.file,
        images,
        erroUploadFile
      );

      body.image = urlS3;

      if (idClient != 0) {
        handleEdit(body);
      } else {
        handleCreate(body);
      }
    } else {
      if (idClient !== 0) {
        handleEdit(body);
      }
    }
  };

  const [createClient] = useApi({
    endpoint: 'management/clients/',
    method: 'post'
  });

  const handleCreate = async (data) => {
    const response = await createClient({
      body: data
    });
    const headerResponse = get(response, 'headerResponse', {});
    const dataResponse = get(response, 'payload', {});

    if (headerResponse.code == 200) {
      await handleSessionClientCreate(dataResponse.client_id);
      history.push('/clients');
    } else {
      handleShowLoader(false);
    }
  };

  //Create session by client
  const [createSessionClient] = useApi({
    endpoint: 'session_client/session_client/',
    method: 'post'
  });

  const handleSessionClientCreate = async (client) => {
    let sessionsClient = [SESSION_SPANISH, SESSION_ENGLISH, SESSION_QUIMIC];

    sessionsClient.map(async (item) => {
      await createSessionClient({
        body: {
          session_name: item,
          client: client
        }
      });
    });
  };

  const [editClient] = useApi({
    endpoint: 'management/clients',
    method: 'put'
  });

  const handleEdit = async (data) => {
    const response = await editClient({
      urlParams: `${idClient}/`,
      body: data
    });
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code == 200) {
      history.push('/clients');
    } else {
      handleShowLoader(false);
    }
  };

  const [getClient] = useApi({
    endpoint: 'management/clients',
    method: 'get'
  });

  const getClientData = async () => {
    const response = await getClient({
      urlParams: idClient
    });

    const dataResponse = get(response, 'payload', {});
    const headerResponse = get(response, 'headerResponse', {});

    if (headerResponse.code === 404) {
      history.push('/clients');
    } else {
      formik.setValues({
        ...valuesForm,
        image:
          dataResponse.image != '' && dataResponse.image != null
            ? { url: dataResponse.image }
            : null,
        name: dataResponse.name,
        primary_color: dataResponse.primary_color,
        secondary_color: dataResponse.secondary_color
      });
      setModulesFormik(dataResponse.modules);
    }
  };

  useEffect(() => {
    if (idClient != 0) {
      getClientData();
    }
  }, []);

  return {
    formik,
    fieldsForm,
    modulesFieldsForm
  };
};
