import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(2)}px 0`
  },
  icon: {
    padding: 0,
    margin: '0 3px'
  },
  questionZone: {
    marginTop: theme.spacing(3)
  },
  maxCharactersHelperText: {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.grey[600]
  }
}));

export { useStyles };
