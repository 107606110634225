import {
  mainSection,
  catalogSection,
  managementSection,
  superAdminSection
} from './utils';
import { get } from 'lodash';

export const useLists = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const userProfile = storage.user ? storage.user?.profile : null;
  const modulesProfile = userProfile != null ? userProfile.modules : [];
  const isSuperAdmin = get(storage, 'user.is_staff', {});

  if (isSuperAdmin) {
    return superAdminSection;
  } else {
    //Filters display SideMenu
    let availableModules = [];

    //Filters Main Section
    let mainSectionFilter = mainSection.items.filter((item1) =>
      modulesProfile.some(
        (item2) => item1.name.toLowerCase() === item2.name.toLowerCase()
      )
    );

    let mainSectionItem = {
      items: mainSectionFilter
    };

    availableModules.push(mainSectionItem);

    //Filters Catalog Section
    let catalogSectionFilter = catalogSection.items.filter((item1) =>
      modulesProfile.some(
        (item2) => item1.name.toLowerCase() === item2.name.toLowerCase()
      )
    );

    let catalogSectionItem = {
      title: catalogSectionFilter.length > 0 ? catalogSection.title : '',
      items: catalogSectionFilter
    };

    availableModules.push(catalogSectionItem);

    //Filters Management Section
    let managementSectionFilter = managementSection.items.filter((item1) =>
      modulesProfile.some(
        (item2) => item1.name.toLowerCase() === item2.name.toLowerCase()
      )
    );

    let managementSectionItem = {
      title: managementSectionFilter.length > 0 ? managementSection.title : '',
      items: managementSectionFilter
    };

    availableModules.push(managementSectionItem);

    return availableModules;
  }
};
