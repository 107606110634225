import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Grid, IconButton, Card } from '@material-ui/core';
import { AddCircle, TextFields } from '@material-ui/icons';
import { messages } from './SetupActionsMessages';
import { useStyles } from './SetupActionsStyles';

const SetupActions = ({
  addQuestion,
  addSection,
  questions,
  setQuestions,
  indexLastQuestion,
  setIndexLastQuestion
}) => {
  const intl = useIntl();
  const classes = useStyles();

  return (
    <Card className={classes.floatingCardContainer}>
      <Grid container spacing={2} alignItems="center" direction="column">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {intl.formatMessage(messages.action)}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              addQuestion(
                questions,
                setQuestions,
                indexLastQuestion,
                setIndexLastQuestion
              );
            }}
          >
            <AddCircle />
          </IconButton>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              addSection(
                questions,
                setQuestions,
                indexLastQuestion,
                setIndexLastQuestion
              );
            }}
          >
            <TextFields />
          </IconButton>
        </Grid>
      </Grid>
    </Card>
  );
};

SetupActions.propTypes = {
  addQuestion: PropTypes.func,
  addSection: PropTypes.func,
  questions: PropTypes.any,
  setQuestions: PropTypes.any,
  indexLastQuestion: PropTypes.any,
  setIndexLastQuestio: PropTypes.any
};

export { SetupActions };
