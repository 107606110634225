import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  Box,
  ButtonBase,
  Collapse,
  Drawer,
  Hidden,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  useTheme
} from '@material-ui/core';
import ExpandIcon from '@material-ui/icons/ExpandMore';
import { Images } from 'assets';
import { ProfilePreview } from './ProfilePreview';
import { MiniMenuSwitch } from './MiniMenuSwitch';
import { useSideMenu } from '.';
import { useStyles } from './SideMenuStyles';
import { useLists } from './helpers';
import { useRouteMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { get } from 'lodash';

const SideMenu = () => {
  const route = useRouteMatch();
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const { visible, setVisible, mini, expanded, setExpanded } = useSideMenu();
  const classes = useStyles({ expanded });
  const [subsubitemVisible, setSubsubitemVisible] = useState(false);

  const storage = JSON.parse(localStorage.getItem('session'));
  const isSuperAdmin = get(storage, 'user.is_staff', {});

  const lists = useLists();

  const subitemsVisibilityStates = {
    publicidad: useState(false),
    turnos: useState(false),
    users: useState(false)
  };

  useEffect(() => setVisible(false), [route]);

  const getSubsubitems = (subsubitems) => (
    <Collapse in={subsubitemVisible} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {subsubitems.map((item, index) => (
          <ListItem
            key={index}
            button
            component={item.path ? Link : null}
            to={item.path}
            className={`${classes.listItem} ${
              route.path === item.path ? 'active' : ''
            }`}
          >
            <ListItemIcon className={classes.listItemIcon}>
              <item.icon className={classes.subsubIcon} />
            </ListItemIcon>
            <ListItemText className={classes.listItemText}>
              {formatMessage(item.message)}
            </ListItemText>
          </ListItem>
        ))}
      </List>
    </Collapse>
  );

  const getSubitemsList = (subitems, visible) => (
    <Collapse in={visible} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {subitems.map((item, index) => {
          if (item.items) {
            return (
              <div key={index}>
                <ListItem
                  button
                  to={item.path}
                  className={classes.listItem}
                  onClick={() => {
                    setSubsubitemVisible(
                      (subsubitemVisible) => !subsubitemVisible
                    );
                  }}
                >
                  <ListItemIcon className={classes.listItemIcon}>
                    {item.icon && <item.icon className={classes.subIcon} />}
                  </ListItemIcon>
                  <ListItemText
                    className={classes.listItemText}
                    primaryTypographyProps={
                      item.path === route.path
                        ? {
                            className: classes.boldText,
                            variant: 'body2',
                            color: 'textSecondary'
                          }
                        : undefined
                    }
                  >
                    {formatMessage(item.message)}
                  </ListItemText>
                  <ExpandIcon
                    className={`${classes.expandIcon} ${
                      subsubitemVisible ? 'inverted' : ''
                    }`}
                  />
                </ListItem>
                {item.items ? getSubsubitems(item.items) : null}
              </div>
            );
          } else {
            return (
              <ListItem
                button
                onClick={mini ? () => setExpanded(false) : null}
                component={item.path ? Link : null}
                to={item.path}
                className={`${classes.listItem} ${
                  route.path === item.path ? 'active' : ''
                }`}
              >
                <ListItemIcon className={classes.listItemIcon}>
                  {item.icon && <item.icon className={classes.subIcon} />}
                </ListItemIcon>
                <ListItemText className={classes.listItemText}>
                  {formatMessage(item.message)}
                </ListItemText>
              </ListItem>
            );
          }
        })}
      </List>
    </Collapse>
  );

  const getList = (list) => (
    <List
      subheader={
        list.title ? (
          <ListSubheader
            disableSticky
            className={classes.listSubHeader}
            component="div"
          >
            {formatMessage(list.title)}
          </ListSubheader>
        ) : null
      }
    >
      {list.items.map((item, index) => {
        const [subitemsVisible, setSubitemsVisible] = subitemsVisibilityStates[
          item.name.toLowerCase()
        ] || [false, () => null];

        return (
          <>
            <ListItem
              className={`${classes.listItem} ${
                route.path === item.path ? 'active' : ''
              }`}
              button
              component={!item.items && item.path ? Link : null}
              to={item.path}
              key={item.message + index}
              onClick={() => {
                if (!item.items && mini) setExpanded(false);
                setSubitemsVisible((visible) => !visible);
                item.action && item.action();
              }}
            >
              <ListItemIcon className={classes.listItemIcon}>
                <item.icon />
              </ListItemIcon>
              <ListItemText
                className={classes.listItemText}
                primaryTypographyProps={
                  item.to === route.path
                    ? {
                        className: classes.boldText,
                        variant: 'body2',
                        color: 'textSecondary'
                      }
                    : undefined
                }
              >
                {formatMessage(item.message)}
              </ListItemText>
              {!!item.items && (
                <ExpandIcon
                  className={`${classes.expandIcon} ${
                    subitemsVisible ? 'inverted' : ''
                  }`}
                />
              )}
            </ListItem>
            {!!item.items && getSubitemsList(item.items, subitemsVisible)}
          </>
        );
      })}
    </List>
  );

  const drawer = (
    <Box pr={0.5}>
      <MiniMenuSwitch />
      <ButtonBase
        disableRipple
        component={Link}
        to={isSuperAdmin ? '/robots' : '/'}
        className={classes.logoContainer}
      >
        <img className={classes.iconLogo} src={Images.iconLogo} alt="logo" />
        <div className={classes.nameLogo} />
      </ButtonBase>
      <ProfilePreview onClick={() => setExpanded(false)} />
      {lists.map((list) => getList(list))}
    </Box>
  );

  return (
    <>
      <Hidden mdUp implementation="css">
        <Drawer
          transitionDuration={theme.transitions.duration.standard}
          variant="temporary"
          anchor="left"
          open={visible}
          onClose={() => setVisible(false)}
          className={classes.drawer}
        >
          {drawer}
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          className={classes.drawer}
          variant="permanent"
          open
          onMouseOver={mini ? () => setExpanded(true) : null}
          onMouseLeave={mini ? () => setExpanded(false) : null}
        >
          {drawer}
        </Drawer>
      </Hidden>
    </>
  );
};

export { SideMenu };
