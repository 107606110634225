import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { FormControlLabel, Grid, Checkbox } from '@material-ui/core';
import { TimePicker as MaterialTimePicker } from '@material-ui/pickers';
import TimeIcon from '@material-ui/icons/AccessTime';
import { messages } from './TimePickerMessages';
import { useStyles } from './TimePickerStyles';

const TimePicker = ({ item, onChangeCheckBox, onChangeTime }) => {
  const intl = useIntl();

  return (
    <Grid container item xs={6} sm={3} md={2} lg={2} xl={1}>
      <Grid item xs={12}>
        <FormControlLabel
          control={
            <Checkbox
              checked={item.isActive}
              onChange={(e) => {
                onChangeCheckBox(item.id, e.target.checked);
              }}
              name="checkedB"
              color="primary"
            />
          }
          label={item.day[0].title}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialTimePicker
          ampm={false}
          variant="inline"
          margin="normal"
          mask="__:__ _M"
          autoOk
          label={intl.formatMessage(messages.from)}
          value={item.from}
          onChange={(e) => {
            onChangeTime(item.id, 'from', e);
          }}
          // error={formik.touched.name && Boolean(formik.errors.name)}
          // helperText={formik.touched.name && formik.errors.name}
        />
      </Grid>
      <Grid item xs={12}>
        <MaterialTimePicker
          ampm={false}
          margin="normal"
          mask="__:__ _M"
          variant="inline"
          autoOk
          label={intl.formatMessage(messages.to)}
          value={item.to}
          onChange={(e) => {
            onChangeTime(item.id, 'to', e);
          }}
          // error={formik.touched.name && Boolean(formik.errors.name)}
          // helperText={formik.touched.name && formik.errors.name}
        />
      </Grid>
    </Grid>
  );
};

TimePicker.propTypes = {
  labelCheckBox: PropTypes.string.isRequired
};

export { TimePicker };
