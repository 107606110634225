import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    paddingTop: 0,
    width: '100%',
    maxWidth: '100vw'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center'
  },
  contentClose: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  title: {
    fontSize: '13px',
    fontWeight: 800,
    marginBottom: 5
  },
  icon: {
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.primary.main
    }
  },
  infoText: {
    fontSize: '13px',
    marginTop: theme.spacing(1)
  },
  alertText: {
    fontSize: '13px'
  },
  cardNotification: {
    backgroundColor:
      theme.palette.mode === 'light' ? theme.palette.grey[200] : '#001E3C',
    padding: theme.spacing(1),
    borderRadius: '5px',
    width: '365px',
    [theme.breakpoints.down('xs')]: {
      width: '285px'
    }
  },
  iconClose: {
    boxShadow: '0px 3px 6px #00000029',
    backgroundColor: theme.palette.common.white
  },
  containerDate: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1)
  },
  dateAlert: {
    fontSize: '12px',
    color: theme.palette.mode === 'light' ? theme.palette.grey[700] : '#ffff'
  },
  alertNumber: {
    fontSize: '14px',
    marginBottom: theme.spacing(1)
  },
  error: { color: theme.palette.error.main },
  warning: { color: theme.palette.warning.main },
  success: { coor: theme.palette.success.main }
}));

export { useStyles };
