import { useEffect, useState } from 'react';

import { useFormik } from 'formik';

import { useDrawer } from 'components/Drawer';

import { useIntl } from 'react-intl';
import { messages as formMessages } from '@octopy/react-form';
import { messages as messagesAddTakeTurnDrawer } from '../AddTakeTurnDrawerMessages';

import * as yup from 'yup';

import { mapBoxes } from './helper';

import { useApi } from 'hooks';

import { useBranches } from 'views/Branches/useBranches';

const initialValues = { selectBranch: '', boxNumber: '', boxDelete: '' };

export const useForm = () => {
  const { handleCloseDrawer } = useDrawer();
  const { formatMessage: f } = useIntl();

  const { branchList } = useBranches();

  const requiredError = formMessages.requiredFieldError;

  const [listBox, setListBox] = useState([]);

  const [turnsAtentionCreate] = useApi({
    endpoint: 'turns/attention_box/',
    method: 'post'
  });
  const [listBoxGeneral] = useApi({
    endpoint: 'turns/attention_box/',
    method: 'get'
  });
  const [deleteBox] = useApi({
    endpoint: 'turns/attention_box',
    method: 'delete'
  });

  const handleCreateTurnsAtention = async (boxNumber, selectBranch) => {
    try {
      const body = { box_number: boxNumber, branch_office: selectBranch };
      const { headerResponse } = await turnsAtentionCreate({ body });

      if (headerResponse.code === 200) {
        handleCloseDrawer();
      }
    } catch (error) {}
  };

  const handleGetBoxList = async () => {
    try {
      const { headerResponse, payload } = await listBoxGeneral();

      if (headerResponse.code === 200 && payload.length !== 0) {
        const mappedBoxes = mapBoxes(payload);

        setListBox(mappedBoxes);
      }
    } catch (error) {}
  };

  const handleDeleteBox = async (boxDeleteId) => {
    try {
      const { headerResponse } = await deleteBox({
        urlParams: boxDeleteId
      });

      if (headerResponse.code === 200) {
        handleCloseDrawer();
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleGetBoxList();
  }, []);

  const getValidationRulesCreate = () =>
    yup.object({
      selectBranch: yup.string().required(f(requiredError)),
      boxNumber: yup.string().required(f(requiredError))
    });
  const formikCreate = useFormik({
    initialValues: {
      selectBranch: initialValues.selectBranch,
      boxNumber: initialValues.boxNumber
    },
    validationSchema: getValidationRulesCreate(),
    onSubmit: (values) =>
      handleCreateTurnsAtention(values.boxNumber, values.selectBranch)
  });

  const getValidationRulesDelete = () =>
    yup.object({
      boxDelete: yup.string().required(f(requiredError))
    });
  const formikDelete = useFormik({
    initialValues: {
      boxDelete: initialValues.boxDelete
    },
    validationSchema: getValidationRulesDelete(),
    onSubmit: (values) => handleDeleteBox(values.boxDelete)
  });

  const fieldCreateBox = {
    selectBranch: {
      type: 'select',
      name: 'selectBranch',
      label: f(messagesAddTakeTurnDrawer.selectBranch),
      placeholder: f(messagesAddTakeTurnDrawer.selectBranch),
      options: branchList,
      getOptionLabel: (option) => option.name,
      getOptionSelected: (option, id) => option === id,
      getOptionValue: (option) => option.id,
      breakpoints: { xs: 12, md: 4, lg: 3 }
    },
    boxNumber: {
      id: 'boxNumber',
      type: 'number',
      name: 'boxNumber',
      label: f(messagesAddTakeTurnDrawer.boxNumber),
      placeholder: f(messagesAddTakeTurnDrawer.boxNumber)
    }
  };

  const fieldsDelete = {
    boxDelete: {
      type: 'select',
      name: 'boxDelete',
      label: f(messagesAddTakeTurnDrawer.boxSelect),
      placeholder: f(messagesAddTakeTurnDrawer.boxSelect),
      options: listBox,
      getOptionLabel: (option) => option.name,
      getOptionSelected: (option, id) => option === id,
      getOptionValue: (option) => option.id
    }
  };

  return { fieldCreateBox, formikCreate, fieldsDelete, formikDelete };
};
