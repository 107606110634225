import { Delete, AddCircleOutline } from '@material-ui/icons';

export const useTable = (handleClickAdd, handleClickDelete, filters) => {
  const configPropsRight = {
    filters,
    selectedOptions: {
      checkboxHidden: true
    },
    actions: {
      customs: {
        text: 'Agregar',
        onClick: (item) => {
          handleClickAdd(item);
        },
        icon: <AddCircleOutline />
      }
    }
  };

  const configPropsLeft = {
    filters,
    selectedOptions: {
      checkboxHidden: true
    },
    actions: {
      customs: {
        text: 'Eliminar',
        onClick: (item) => {
          handleClickDelete(item);
        },
        icon: <Delete />
      }
    }
  };

  return {
    configPropsRight,
    configPropsLeft
  };
};
