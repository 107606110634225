const tableHead = [
  { key: 'item_id', label: 'ID' },
  { key: 'name', label: 'NOMBRE' },
  { key: 'category', label: 'CATEGORÍA' },
  { key: 'sub_category', label: 'SUBCATEGORÍA' },

  { key: '', label: '' }
];

const filters = [
  { key: 'name', value: 'NOMBRE' },
  { key: 'category', value: 'CATEGORÍA/SUBCATEGORÍA' }
];

const ProductsListMapper = {
  item_id: 'item_id',
  name: 'name[0].text',
  category: 'category.name[0].text',
  sub_category: 'sub_category.name[0].text',
  audio_text: 'audio_text'
};

export { tableHead, filters, ProductsListMapper };
