import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import {
  renderTextField,
  renderTimePicker,
  renderCheckbox,
  renderImagePicker
} from '@octopy/react-form';
import { useLoader } from '@octopy/react-loader';
import { Button, Grid, Typography } from '@material-ui/core';
import { TextBotSelect } from 'components/TextBotSelect';
import { useHelper } from './helpers';
import { messages } from './CreateBranchMessages';
import { useStyles } from './CreateBranchStyles';
import { HeaderTitle } from 'components/HeaderTitle';

const CreateBranch = () => {
  const { handleShowLoader } = useLoader();
  const classes = useStyles();
  const intl = useIntl();
  const {
    idBranch,
    formik,
    fieldsMainFormik,
    fieldsHourForm,
    fieldsDirectionsFomr,
    fieldAddEnglishFormik,
    fieldsFormImage,
    fieldDescriptionSpanishFormik,
    fieldDescriptionEnglishFormik,
    handleChangeText,
    handleChangeRobot,
    handleChangeTextEnglish,
    handleChangeRobotEnglish
  } = useHelper();

  const init = async () => {
    await setTimeout(() => {
      handleShowLoader(false);
    }, 1000);
  };

  useEffect(() => {
    handleShowLoader(true);
    init();
  }, []);

  const fieldsMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'title' && field.text}
      {field.type === 'text' && renderTextField({ index, formik, field })}
    </Grid>
  );

  const fieldsMapperHour = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'title' && field.text}
      {field.type === 'timePicker' &&
        renderTimePicker({
          index,
          formik,
          field
        })}
    </Grid>
  );

  const fieldsMapperDirections = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'text' && renderTextField({ index, formik, field })}
    </Grid>
  );

  const fieldCheckboxMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'checkbox' && renderCheckbox({ index, formik, field })}
    </Grid>
  );

  const fieldsMapperImage = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'title' && field.text}
      {field.type === 'image' && renderImagePicker({ index, formik, field })}
    </Grid>
  );

  const fieldDescriptionSpanishMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'text' && renderTextField({ index, formik, field })}
    </Grid>
  );

  const fieldDescriptionEnglishMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'text' && renderTextField({ index, formik, field })}
    </Grid>
  );

  return (
    <div className={classes.root}>
      <HeaderTitle
        firstTitle={
          idBranch === 0
            ? intl.formatMessage(messages.titleCreate)
            : intl.formatMessage(messages.titleEdit)
        }
      />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography className={classes.subTitle}>
                {intl.formatMessage(messages.textAddContact)}
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              {fieldsMainFormik.map(fieldsMapper)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Typography className={classes.subTitle}>
                {intl.formatMessage(messages.attentionSchedule)}
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              {fieldsHourForm.map(fieldsMapperHour)}
            </Grid>
          </Grid>
        </Grid>

        <Grid item container spacing={2}>
          {fieldsDirectionsFomr.map(fieldsMapperDirections)}
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{}}>
        {fieldsFormImage.map(fieldsMapperImage)}
      </Grid>
      <Grid container>
        <Grid item>
          <Typography className={classes.title}>
            {intl.formatMessage(messages.textRobot)}
          </Typography>
          <Typography className={classes.infoSubtitle}>
            {intl.formatMessage(messages.textRobotInfo)}
          </Typography>
        </Grid>

        <Grid item container spacing={2}>
          {fieldDescriptionSpanishFormik.map(fieldDescriptionSpanishMapper)}
          <TextBotSelect
            textBot={formik.values.text}
            textBotMessage={formik.touched.text && formik.errors.text}
            idBot={formik.values.robot}
            idBotMessage={formik.touched.robot && formik.errors.robot}
            onHandleChangeText={handleChangeText}
            onHandleChangeBot={handleChangeRobot}
            isEnglish={false}
          />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 20, marginBottom: 15 }}>
        <Grid item>{fieldAddEnglishFormik.map(fieldCheckboxMapper)}</Grid>
      </Grid>
      <Grid container spacing={2}>
        {formik.values.addEnglish ? (
          <Grid item container>
            <Grid item xs={12}>
              <Typography className={classes.pageTitle}>
                {intl.formatMessage(messages.englishTitle)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography className={classes.title}>
                {intl.formatMessage(messages.textRobot)}
              </Typography>
              <Typography className={classes.infoSubtitle}>
                {intl.formatMessage(messages.textRobotInfo)}
              </Typography>
            </Grid>
            <Grid item container spacing={2}>
              {fieldDescriptionEnglishFormik.map(fieldDescriptionEnglishMapper)}
              <TextBotSelect
                textBot={formik.values.textEnglish}
                textBotMessage={
                  formik.touched.textEnglish && formik.errors.textEnglish
                }
                idBot={formik.values.robotEnglish}
                idBotMessage={
                  formik.touched.robotEnglish && formik.errors.robotEnglish
                }
                onHandleChangeText={handleChangeTextEnglish}
                onHandleChangeBot={handleChangeRobotEnglish}
                isEnglish={true}
              />
            </Grid>
          </Grid>
        ) : null}
      </Grid>

      <Grid item xs={12} className={classes.contentButton}>
        <Button className={classes.button} onClick={() => formik.submitForm()}>
          {idBranch === 0
            ? intl.formatMessage(messages.create)
            : intl.formatMessage(messages.save)}
        </Button>
      </Grid>
    </div>
  );
};

CreateBranch.propTypes = {};

export default CreateBranch;
