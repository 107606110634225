/* eslint-disable array-callback-return */
import { useState } from 'react';
import { get } from 'lodash';
import { useApi } from 'hooks';
import { useLoader } from '@octopy/react-loader';
import { format } from 'date-fns';

export const UseMetrics = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = get(storage, 'user.client.client_id', {});
  const { handleShowLoader } = useLoader();

  //states
  const [peopleServerd, setPeopleServerd] = useState([]);
  const [totalinteractions, setTotalinteractions] = useState([]);
  const [answeredPolls, setAnsweredPolls] = useState([]);
  const [timeInteractions, setTimeInteractions] = useState([]);
  const [timeInteractionsTotal, setTimeInteractionsTotal] = useState([]);

  const [interactionGender, setInteractionGender] = useState([]);
  const [interactionGenderTotal, setInteractionGenderTotal] = useState([]);

  const [pollsConducted, setPollsConducted] = useState([]);
  const [totalpollsConducted, setTotalPollsConducted] = useState('');
  const [topProducts, setTopProducts] = useState([]);
  const [topCategories, setTopCategories] = useState([]);
  const [topIntentions, setTopIntentions] = useState([]);

  //state detector person_crossed
  const [dataCrossed, setDataCrossed] = useState([]);

  //People Serverd
  const [getPeopleServerd] = useApi({
    endpoint: '/analytics/analytics/people_served/',
    method: 'post'
  });

  const handlePeopleServerd = async (bodyData) => {
    try {
      const response = await getPeopleServerd({
        body: bodyData
      });
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', []);

      if (headerResponse.code === 200) {
        var keys = Object.keys(dataResponse);
        let arrayPeopleServerd = [];

        keys.map((item) => {
          arrayPeopleServerd.push({
            label: item,
            value: dataResponse[item]
          });
        });
        setPeopleServerd(arrayPeopleServerd);
      }
    } catch (error) {}
  };

  //Total Interactions
  const [getTotalInteractions] = useApi({
    endpoint: '/analytics/analytics/interaction_total/',
    method: 'post'
  });

  const handleTotalInteractions = async (bodyData) => {
    try {
      const response = await getTotalInteractions({
        body: bodyData
      });
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', []);

      if (headerResponse.code === 200) {
        var keys = Object.keys(dataResponse);
        let arrayTotalInteractions = [];

        keys.map((item) => {
          arrayTotalInteractions.push({
            label: item,
            value: dataResponse[item]
          });
        });

        setTotalinteractions(arrayTotalInteractions);
      }
    } catch (error) {}
  };

  //Answered Polls
  const [getAnsweredPolls] = useApi({
    endpoint: '/analytics/analytics/answered_polls/',
    method: 'post'
  });

  const handleAnsweredPolls = async (bodyData) => {
    try {
      const response = await getAnsweredPolls({
        body: bodyData
      });
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', []);

      if (headerResponse.code === 200) {
        var keys = Object.keys(dataResponse);
        let arrayAnsweredPolls = [];

        keys.map((item) => {
          arrayAnsweredPolls.push({
            label: item,
            value: dataResponse[item]
          });
        });
        setAnsweredPolls(arrayAnsweredPolls);
      }
    } catch (error) {}
  };

  //Time Interactions
  const [getTimeInteractions] = useApi({
    endpoint: '/analytics/analytics/time_interaction/',
    method: 'post'
  });

  const handleTimeInteractions = async (bodyData) => {
    try {
      const response = await getTimeInteractions({
        body: bodyData
      });
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', []);

      if (headerResponse.code === 200) {
        var keys = Object.keys(dataResponse);
        let arrayTimeInteractions = [];
        let timeTotalInteraccions = {};

        keys.map((item, index) => {
          if (index !== 0) {
            arrayTimeInteractions.push({
              label: item,
              value: dataResponse[item]
            });
          }

          if (index === 0) {
            timeTotalInteraccions = {
              label: item,
              value: dataResponse[item]
            };
          }
        });

        setTimeInteractions(arrayTimeInteractions);
        setTimeInteractionsTotal(timeTotalInteraccions);
      }
    } catch (error) {}
  };

  //Interaction Gender
  const [getInteractionGender] = useApi({
    endpoint: '/analytics/analytics/interaction_gender/',
    method: 'post'
  });

  const handleGetInteractionGender = async (bodyData) => {
    try {
      const response = await getInteractionGender({
        body: bodyData
      });
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', {});

      if (headerResponse.code === 200) {
        var keys = Object.keys(dataResponse);
        let arrayInteractionGender = [];
        let interactionGenderTotal = {};

        keys.map((item, index) => {
          if (index !== 0) {
            arrayInteractionGender.push({
              label: item,
              value: dataResponse[item]
            });
          }

          if (index === 0) {
            interactionGenderTotal = {
              label: item,
              value: dataResponse[item]
            };
          }
        });

        setInteractionGender(arrayInteractionGender);
        setInteractionGenderTotal(interactionGenderTotal);
      }
    } catch (error) {}
  };

  //Polls Conducted
  const [getPollsConducted] = useApi({
    endpoint: '/analytics/analytics/polls_conducted/',
    method: 'post'
  });

  const handleGetPollsConducted = async (bodyData) => {
    try {
      const response = await getPollsConducted({
        body: bodyData
      });
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', []);

      if (headerResponse.code === 200) {
        var keys = Object.keys(dataResponse);
        let arrayPollsConducted = [];

        keys.map((item, index) => {
          if (index == 0) {
            setTotalPollsConducted(`${dataResponse[item]}`);
          }

          if (index !== 0) {
            arrayPollsConducted.push({
              label: item,
              value: dataResponse[item]
            });
          }
        });

        setPollsConducted(arrayPollsConducted);
      }
    } catch (error) {}
  };

  //Top Products
  const [getTopProducts] = useApi({
    endpoint: '/analytics/analytics/top_products_category/',
    method: 'post'
  });

  const handleGetTopProducts = async (bodyData) => {
    try {
      const response = await getTopProducts({
        body: bodyData
      });
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', []);

      if (headerResponse.code === 200) {
        let categoriesList = [];
        let productsList = [];

        dataResponse.map((items) => {
          items.map((item) => {
            if (item['Product']) {
              productsList.push(item.Product);
            }

            if (item['category']) {
              categoriesList.push(item.category);
            }
          });
        });

        setTopCategories(categoriesList);
        setTopProducts(productsList);
      }
    } catch (error) {}
  };

  //Top intents
  const [getTopIntents] = useApi({
    endpoint: '/analytics/analytics/top_intent/',
    method: 'post'
  });

  const handleGetTopIntents = async (bodyData) => {
    try {
      const response = await getTopIntents({
        body: bodyData
      });
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', []);

      if (headerResponse.code === 200) {
        setTopIntentions(dataResponse);
      }
    } catch (error) {}
  };

  //  Data detector person_crossed

  const [getDetectorCrossed] = useApi({
    endpoint: '/detector/report',
    method: 'get'
  });

  const handleDataDetectorCrossed = async (bodyData) => {
    try {
      const responseDataDetectorCrossed = await getDetectorCrossed({
        urlParams: `?from_dt=${bodyData.from_dt}%2000%3A00%3A00&to_dt=${bodyData.to_dt}%2023%3A59%3A59`,
        bodyDetector: bodyData
      });

      const headerResponse = get(
        responseDataDetectorCrossed,
        'headerResponse',
        {}
      );
      const dataResponse = get(responseDataDetectorCrossed, 'payload', []);

      if (headerResponse.code === 200) {
        var keys = Object.keys(dataResponse.person_by_month_count);
        let arrayDetectorCrossed = [];

        keys.map((item) => {
          arrayDetectorCrossed.push({
            label: item,
            value: dataResponse.person_by_month_count[item]
          });
        });

        setDataCrossed(arrayDetectorCrossed);
      }
    } catch (error) {}
  };

  const sumbitFilters = async (values) => {
    handleShowLoader(true);
    setPeopleServerd([]);
    setTotalinteractions([]);
    setAnsweredPolls([]);
    setTimeInteractions([]);
    setTimeInteractionsTotal([]);
    setInteractionGender([]);
    setInteractionGenderTotal([]);
    setPollsConducted([]);
    setTotalPollsConducted('');
    setTopProducts([]);
    setTopCategories([]);
    setTopIntentions([]);
    // set detector crossed
    setDataCrossed([]);

    let body = {
      client: clientId,
      robot: values.robot !== '' ? parseInt(values.robot) : 0,
      start_date:
        values.dateStart !== null
          ? format(values.dateStart, 'yyyy-MM-dd')
          : null,
      end_date:
        values.dateEnd !== null ? format(values.dateEnd, 'yyyy-MM-dd') : null
    };

    let bodyDetector = {
      from_dt:
        values.dateStart !== null
          ? format(values.dateStart, 'yyyy-MM-dd')
          : null,
      to_dt:
        values.dateEnd !== null ? format(values.dateEnd, 'yyyy-MM-dd') : null,
      event_type: 'person_crossed'
    };

    await handlePeopleServerd(body);
    await handleTotalInteractions(body);
    await handleAnsweredPolls(body);
    await handleTimeInteractions(body);

    await handleGetInteractionGender(body);
    await handleGetPollsConducted(body);
    await handleGetTopProducts(body);
    await handleGetTopIntents(body);

    // data detector crossed
    await handleDataDetectorCrossed(bodyDetector);

    handleShowLoader(false);
  };

  return {
    sumbitFilters,
    peopleServerd,
    totalinteractions,
    answeredPolls,
    timeInteractions,
    timeInteractionsTotal,
    interactionGender,
    interactionGenderTotal,
    pollsConducted,
    totalpollsConducted,
    topProducts,
    topCategories,
    topIntentions,
    dataCrossed
  };
};
