/* eslint-disable eqeqeq */
import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import { useIntl } from 'react-intl';
import { useClientForm } from './useClientForm';
import { useStyles } from './ClientFormStyles';
import { messages } from './ClientFormMessages';
import {
  renderTextField,
  renderImagePicker,
  renderSwitch
} from '@octopy/react-form';
import { HeaderTitle } from 'components/HeaderTitle';

const ClientForm = (props) => {
  const idClient = props.match.params.id;
  const { formatMessage: f } = useIntl();
  const classes = useStyles();
  const { formik, fieldsForm, modulesFieldsForm } = useClientForm(idClient);

  const renderLabels = (index) => {
    switch (index) {
      case 1:
        return (
          <Typography className={classes.textLabel}>
            {f(messages.uploadImageLabelForm)}
          </Typography>
        );
      case 2:
        return (
          <Typography className={classes.textLabel}>
            {f(messages.primaryLabelForm)}
          </Typography>
        );
      case 3:
        return (
          <Typography className={classes.textLabel}>
            {f(messages.secondaryLabelForm)}
          </Typography>
        );
      default:
        break;
    }
  };

  const fieldsMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {renderLabels(index)}
      {field.type === 'text' && renderTextField({ index, formik, field })}
      {field.type === 'image' && renderImagePicker({ index, formik, field })}
    </Grid>
  );

  const fieldsMapperSwitch = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'switch' && renderSwitch({ index, formik, field })}
    </Grid>
  );

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item xs={12} className={classes.gridHeader}>
        <HeaderTitle
          firstTitle={
            idClient == 0
              ? f(messages.titleCreateView)
              : f(messages.titleEditView)
          }
        />
      </Grid>
      <Grid item xs={12} container spacing={10}>
        <Grid item container spacing={2} xs={12} md={6} lg={4} xl={4}>
          <Grid item xs={12}>
            <Typography className={classes.textSection}>
              {f(messages.textNameLogoColor)}
            </Typography>
          </Grid>
          {fieldsForm.map(fieldsMapper)}
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={6}
          lg={8}
          xl={8}
          alignContent="flex-start"
        >
          <Grid item xs={12}>
            <Typography className={classes.textSection}>
              {f(messages.textPermissions)}
            </Typography>
          </Grid>
          {modulesFieldsForm.map(fieldsMapperSwitch)}
        </Grid>
      </Grid>
      <Grid item xs={12} className={classes.contentButton}>
        <Button
          className={classes.button}
          onClick={() => {
            formik.submitForm();
          }}
        >
          <Typography className={classes.textButton}>
            {f(messages.saveLabelButton)}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export { ClientForm };
