import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  catalogTitle: {
    id: 'components.Subcategories.catalogTitle',
    defaultMessage: 'CATÁLOGO'
  },
  categoryTitle: {
    id: 'components.Subcategories.categoryTitle',
    defaultMessage: 'CATEGORÍAS'
  },
  subcategoryTitle: {
    id: 'components.Subcategories.subcategoryName',
    defaultMessage: 'SUBCATEGORÍAS'
  },
  addSubcategories: {
    id: 'components.Subcategories.addSubcategories',
    defaultMessage: 'AGREGAR SUBCATEGORÍA'
  },
  editSubcategory: {
    id: 'components.Subcategories.editSubcategory',
    defaultMessage: 'EDITAR SUBCATEGORÍA'
  },
  addSubcategory: {
    id: 'components.Subcategories.addSubcategory',
    defaultMessage: 'AGREGAR SUBCATEGORÍA'
  },
  to: {
    id: 'components.Subcategories.to',
    defaultMessage: 'A:'
  },
  of: {
    id: 'components.Subcategories.from',
    defaultMessage: 'DE:'
  },
  titleModal: {
    id: 'components.Subcategories.titleModal',
    defaultMessage: 'ELIMINAR SUBCATEGORÍA'
  },
  descriptionModal: {
    id: 'components.Subcategories.descriptionModal',
    defaultMessage: '¿Seguro que quiere eliminar esta subcategoría'
  },
  buttonModal: {
    id: 'components.Subcategories.buttonModal',
    defaultMessage: 'CONTINUAR'
  },
  cancel: {
    id: 'components.Subcategories.cancel',
    defaultMessage: 'CANCELAR'
  },
  delete: {
    id: 'components.Subcategories.delete',
    defaultMessage: 'ELIMINAR'
  }
});
