import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: `${theme.spacing(2)}px 0`
  },
  options: {
    color: theme.palette.grey[700],
    display: 'flex',
    gap: theme.spacing(2)
  },
  icon: {
    padding: 0,
    margin: '0 3px'
  },
  questionZone: {
    marginTop: theme.spacing(3)
  },
  textFieldStylesQuestion: {
    display: 'flex'
  }
}));

export { useStyles };
