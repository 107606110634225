const tableHead = [
  { key: 'text', label: 'AUDIO' },
  { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false }
];

const tableHeadGesture = [
  { key: 'id_gesture', label: 'ID' },
  { key: 'name', label: 'NOMBRE' },
  // { key: 'duration', label: 'DURACIÓN', align: 'center' },
  { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false }
];

const tableHeadEscene = [
  { key: 'scene_id', label: 'ID' },
  { key: 'name', label: 'NOMBRE' },
  // { key: 'duration', label: 'DURACION', align: 'center' },
  { key: 'actions', label: 'ACCIONES', align: 'center', isSortable: false }
];

const filters = [
  { key: 'name', value: 'NOMBRE' },
  { key: '', value: '' }
];

const esceneListMapper = {
  scene_id: 'scene_id',
  name: 'name',
  duration: 'duration'
};

export {
  tableHead,
  tableHeadGesture,
  tableHeadEscene,
  esceneListMapper,
  filters
};
