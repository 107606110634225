import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'routes.UpdateGesture.title',
    defaultMessage: 'Agente'
  },
  subtitle: {
    id: 'routes.UpdateGesture.subtitle',
    defaultMessage: 'Editar intención'
  },
  addButton: {
    id: 'routes.UpdateGesture.addButton',
    defaultMessage: 'AGREGAR'
  },
  audioSubtitle: {
    id: 'routes.UpdateGesture.audioSubtitle',
    defaultMessage: 'Audio'
  },
  addedAudioText: {
    id: 'routes.UpdateGesture.addedAudioText',
    defaultMessage: 'Audio agregado'
  },
  nullAudioList: {
    id: 'routes.UpdateGesture.nullAudioList',
    defaultMessage: 'No se han agregado audios'
  },
  associateGesture: {
    id: 'routes.UpdateGesture.associateGesture',
    defaultMessage: 'Asociar un gesto a una intención'
  },
  associateVideo: {
    id: 'routes.UpdateGesture.associateVideo',
    defaultMessage: 'Asociar un video a una intención'
  },
  relateScene: {
    id: 'routes.UpdateGesture.relateScene',
    defaultMessage: 'Relacionar escena'
  },
  relateSceneDescription: {
    id: 'routes.UpdateGesture.relateSceneDescription',
    defaultMessage: 'Selecciona la escena que deseas mostrar con la intención.'
  },
  preview: {
    id: 'routes.UpdateGesture.preview',
    defaultMessage: 'PREVISUALIZAR'
  },
  generateAudioError: {
    id: 'routes.UpdateGesture.generateAudioError',
    defaultMessage: 'No se ha podido generar el audio. Intente de nuevo.'
  },
  name: { id: 'routes.UpdateGesture.name', defaultMessage: 'Nombre' },
  requiredField: {
    id: 'routes.UpdateGesture.requiredField',
    defaultMessage: 'Este campo es obligatorio.'
  },
  uploadFileError: {
    id: 'routes.UpdateGesture.uploadFileError',
    defaultMessage:
      'Ha ocurrido un error al cargar el archivo. Intentalo de nuevo más tarde.'
  },
  textRobot: {
    id: 'routes.UpdateGesture.textRobot',
    defaultMessage: 'Texto para el robot'
  },
  buttonAdd: {
    id: 'routes.UpdateGesture.buttonAdd',
    defaultMessage: 'AGREGAR'
  },
  saveButton: {
    id: 'routes.UpdateGesture.saveButton',
    defaultMessage: 'GUARDAR'
  },
  gestureLabel: {
    id: 'routes.UpdateGesture.gestureLabel',
    defaultMessage: 'Gesto'
  },
  gesturePlaceholder: {
    id: 'routes.UpdateGesture.gesturePlaceholder',
    defaultMessage: 'Seleccionar gesto'
  },
  videoLabel: {
    id: 'routes.UpdateGesture.videoLabel',
    defaultMessage: 'Contenido multimedia'
  },
  videoPlaceholder: {
    id: 'routes.UpdateGesture.videoPlaceholder',
    defaultMessage: 'Seleccionar contenido multimedia'
  },
  nameGoogle: {
    id: 'routes.UpdateGesture.nameGoogle',
    defaultMessage: '*Nombre heredado de google. No editable'
  },
  scenesTotal: {
    id: 'routes.UpdateGesture.scenesTotal',
    defaultMessage: 'Total de Escenas'
  },
  titleModal: {
    id: 'routes.CreateAudioFlow.titleModal',
    defaultMessage: 'DATOS GUARDADOS CON ÉXITO'
  },
  textInfoSucces: {
    id: 'routes.CreateAudioFlow.textInfoSucces',
    defaultMessage: 'Los audios se han agregado exitosamente.'
  },
  onlyNumbers: {
    id: 'routes.RegisterUser.onlyNumbers',
    defaultMessage: 'Escriba unicamente números'
  },
  positiveNumbers: {
    id: 'routes.RegisterUser.positiveNumbers',
    defaultMessage: 'Escriba unicamente números positivos'
  },
  decimalNumbers: {
    id: 'routes.RegisterUser.decimalNumbers',
    defaultMessage: 'Número no válido'
  },
  timeLabel: {
    id: 'routes.RegisterUser.timeLabel',
    defaultMessage: 'Duración del gesto'
  },
  timePlaceholder: {
    id: 'routes.RegisterUser.timePlaceholder',
    defaultMessage: 'Duración en segundos'
  }
});
