import { useIntl } from 'react-intl';

import * as yup from 'yup';

import { messages } from '../CampaignsMessages';

export const useValidationSchema = () => {
  const intl = useIntl();

  const requiredErrorMessage = intl.formatMessage(messages.requiredError);

  const validationSchema = yup.object({
    nameScence: yup.string().required(requiredErrorMessage)
  });

  return validationSchema;
};
