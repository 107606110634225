import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  personalInformationTitle: {
    id: 'components.PersonalInformation.personalInformationTitle',
    defaultMessage: 'INFORMACIÓN PARA EL PERSONAL DEL LABORATORIO'
  },
  personalInformationText: {
    id: 'views.Robot.PersonalInformation.personalInformationText',
    defaultMessage:
      'Selecciona los ítems que deseas agregar al catálogo que mostrará este robot:'
  },
  cancel: {
    id: 'components.PersonalInformation.cancel',
    defaultMessage: 'CANCELAR'
  },
  save: {
    id: 'components.PersonalInformation.save',
    defaultMessage: 'GUARDAR'
  },
  labelTotal: {
    id: 'views.Robot.PersonalInformation.labelTotal',
    defaultMessage: 'Items agregados:'
  },

  emptyAgentChemical: {
    id: 'routes.PersonalInformation.emptyAgentChemical',
    defaultMessage: 'NO EXISTE AGENTE QUÍMICO PARA ESTE ROBOT'
  },
  infoReportProblem: {
    id: 'routes.PersonalInformation.infoReportProblem',
    defaultMessage: 'Consulta a soporte técnico.'
  },
  infoReportProblemIsStaff: {
    id: 'routes.PersonalInformation.infoReportProblemIsStaff',
    defaultMessage:
      'Genere agente del tipo químico, para este robot en: "Relacionar Plataformas", posteriormente, vuelva a intentarlo.'
  },
  acept: {
    id: 'routes.PersonalInformation.acept',
    defaultMessage: 'ACEPTAR'
  },
  uploadFileError: {
    id: 'views.PersonalInformation.uploadFileError',
    defaultMessage:
      'Ha ocurrido un error al cargar el archivo. Intentalo de nuevo más tarde.'
  },
  titleSuccessModal: {
    id: 'routes.PersonalInformation.titleSuccessModal',
    defaultMessage: 'Datos guardados con éxito'
  },
  textInfoSucces: {
    id: 'routes.PersonalInformation.textInfoSucces',
    defaultMessage: 'La infromación se ha agregado exitosamente.'
  }
});
