import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contentTitle: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      flexDirection: 'column'
    }
  },
  fristTitle: {
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800,
    textTransform: 'upperCase',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px',
      letterSpacing: '1.6px'
    }
  },
  secondTitle: {
    color: theme.palette.primary.main,
    fontSize: '20px',
    fontWeight: 800,
    letterSpacing: '6px',
    [theme.breakpoints.down('xs')]: {
      fontSize: '20px'
    }
  },
  iconBack: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  contentIcon: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

export { useStyles };
