import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  Grid,
  TextField,
  InputAdornment
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { Field } from '../Field';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useStyles } from './CreateUpdateStyles';
import { messages } from './CreateUpdateMessages';
import { TableSelect } from 'components/TableSelect';
import { useHelper } from '../hooks/useHelper';
import { useFields } from '../hooks/useFields';
import { HeaderTitle } from 'components/HeaderTitle';
//import { data as campaignData } from './CreateUpdateDummy';

const CreateUpdate = () => {
  const classes = useStyles();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [scenceLeft, setScenceLeft] = useState(null);

  const edit = history.location.state.edit;
  const publicityCampaignId = history.location.state.edit
    ? history.location.state.publicityCampaignId
    : null;

  const {
    scene,
    formik,
    handleGetListScence,
    handleGetScencesByClient
  } = useHelper(edit, publicityCampaignId, scenceLeft, setScenceLeft);

  const { fields } = useFields(formik);

  useEffect(() => {
    handleGetListScence();
  }, []);

  useEffect(() => {
    if (edit) {
      handleGetScencesByClient();
    }
  }, []);

  const onSearch = (event) => {
    setSearchValue(event.target.value);
  };

  const changeData = (scenceLeftChange) => {
    setScenceLeft(scenceLeftChange);
  };

  const tableHead = [
    { key: 'id', label: 'ID' },
    { key: 'name', label: 'NOMBRE' },
    { key: '', label: '' }
  ];

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HeaderTitle
            firstTitle={
              edit
                ? formatMessage(messages.titleEdit)
                : formatMessage(messages.title)
            }
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 20 }}>
          <Card className={classes.cardContainer}>
            <Grid container spacing={2}>
              <Grid item {...fields.nameScence.breakpoints}>
                <Field
                  {...fields.nameScence}
                  label={formatMessage(messages.name)}
                />
              </Grid>

              <Grid
                item
                container
                spacing={2}
                justify="flex-end"
                alignItems="center"
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <Button
                  className={[classes.buttonCanel, classes.cancelButton]}
                  onClick={() => history.push('/advertising/campaigns')}
                >
                  {formatMessage(messages.buttonCancel)}
                </Button>
                <Button
                  className={[classes.button, classes.buttons]}
                  onClick={formik.submitForm}
                >
                  {formatMessage(messages.buttonSave)}
                </Button>
              </Grid>
              <Grid
                item
                container
                alignItems="flex-end"
                xs={12}
                sm={12}
                md={6}
                lg={6}
              >
                <span className={classes.description}>
                  {formatMessage(messages.description)}
                </span>
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <TextField
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Search />
                      </InputAdornment>
                    )
                  }}
                  fullWidth
                  label={formatMessage(messages.textFieldSearch)}
                  placeholder={formatMessage(messages.textFieldSearch)}
                  value={searchValue}
                  separatedLabel={false}
                  onChange={onSearch}
                />
              </Grid>
              <Grid xs={12} sm={12} md={12} lg={12}>
                <TableSelect
                  textSearch={searchValue}
                  data={scene}
                  scenceLeft={scenceLeft}
                  changeData={changeData}
                  edit={edit}
                  tableHead={tableHead}
                  keyID={'id'}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </div>
  );
};

CreateUpdate.propTypes = {};

export default CreateUpdate;
