import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 0,
    width: 400,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    }
  },
  resolutionSection: {
    fontSize: '14px',
    color: theme.palette.common.black,
    opacity: 1
  },
  infoTextModal: {
    marginTop: theme.spacing(2),
    fontSize: '15px'
  },
  infoTextDrawer: {
    fontSize: '14px'
  },
  button: {
    marginTop: theme.spacing(3),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  margin: {
    marginTop: '20px'
  },
  text: {
    flexWrap: 'wrap',
    marginTop: '20px'
  }
}));

export { useStyles };
