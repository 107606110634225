import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  productsTitle: {
    id: 'components.Products.productsTitle',
    defaultMessage: 'CATÁLOGO'
  },
  itemsTitle: {
    id: 'components.Products.itemsTitle',
    defaultMessage: 'ÍTEMS / PRODUCTOS'
  },
  exportButton: {
    id: 'components.Products.exportButton',
    defaultMessage: 'EXPORTAR'
  },
  addItems: {
    id: 'components.Products.addItems',
    defaultMessage: 'AGREGAR ÍTEM'
  },
  titleModal: {
    id: 'components.Products.titleModal',
    defaultMessage: 'ELIMINAR PRODUCTO'
  },
  infoTextModal: {
    id: 'components.Products.infoTextModal',
    defaultMessage: '¿Seguro que quiere eliminar este producto'
  },
  cancel: {
    id: 'components.Products.cancel',
    defaultMessage: 'CANCELAR'
  },
  delete: {
    id: 'components.Products.delete',
    defaultMessage: 'ELIMINAR'
  },
  products: {
    id: 'components.Products.products',
    defaultMessage: 'PRODUCTOS'
  }
});
