import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  writeQuestions: {
    id: 'routes.DraggableList.writeQuestions',
    defaultMessage: 'Escribe aquí la pregunta'
  },
  option: {
    id: 'routes.DraggableList.option',
    defaultMessage: 'Opción'
  },
  typeOfQuestion: {
    id: 'routes.DraggableList.typeOfQuestion',
    defaultMessage: 'Tipo de pregunta'
  },
  sliderType: {
    id: 'routes.DraggableList.sliderType',
    defaultMessage: 'Tipo de deslizador'
  },
  addOption: {
    id: 'routes.DraggableList.addOption',
    defaultMessage: 'Agregar opción'
  },
  writeQuestionEnglish: {
    id: 'routes.DraggableList.writeQuestionEnglish',
    defaultMessage: 'Write the question'
  },
  optionEnglish: {
    id: 'routes.DraggableList.optionEnglish',
    defaultMessage: 'Option'
  },
  typeOfQuestionEnglish: {
    id: 'routes.DraggableList.typeOfQuestionEnglish',
    defaultMessage: 'type of question'
  },
  sliderTypeEnglish: {
    id: 'routes.DraggableList.sliderTypeEnglish',
    defaultMessage: 'Slider type'
  },
  addOptionEnglish: {
    id: 'routes.DraggableList.addOptionEnglish',
    defaultMessage: 'Add option'
  },
  stars: {
    id: 'routes.DraggableList.stars',
    defaultMessage: 'Estrellas'
  },
  smiley: {
    id: 'routes.DraggableList.smiley',
    defaultMessage: 'Emoticón'
  },
  numerical: {
    id: 'routes.DraggableList.numerical',
    defaultMessage: 'Numerico'
  },
  starsEnglish: {
    id: 'routes.DraggableList.starsEnglish',
    defaultMessage: 'Stars'
  },
  smileyEnglish: {
    id: 'routes.DraggableList.smileyEnglish',
    defaultMessage: 'Smiley'
  },
  numericalEnglish: {
    id: 'routes.DraggableList.numericalEnglish',
    defaultMessage: 'Numerical'
  },
  sectionTitle: {
    id: 'routes.DraggableList.sectionTitle',
    defaultMessage: 'Titulo de la sección'
  },
  sectionTitleEnglish: {
    id: 'routes.DraggableList.sectionTitle',
    defaultMessage: 'Section title'
  },
  description: {
    id: 'routes.DraggableList.description',
    defaultMessage: 'Desripción'
  },
  descriptionEnglish: {
    id: 'routes.DraggableList.descriptionEnglish',
    defaultMessage: 'Description'
  },
  requiredError: {
    id: 'components.EventSearch.requiredError',
    defaultMessage: 'Este campo es requerido'
  },
  requiredErrorEnglish: {
    id: 'components.EventSearch.requiredErrorEnglish',
    defaultMessage: 'This field is required'
  }
});
