const robots = [
  {
    numberRobot: '001',
    alerts: '15',
    interactions: '300',
    status: 'ON LINE',
    serialNumber: '123456789',
    url: 'http://localhost:3000/robots',
    image: 'http://landscape3design.com/wp-content/uploads/2015/04/demo.png'
  },
  {
    numberRobot: '002',
    alerts: '25',
    interactions: '3000',
    status: 'OFF LINE',
    serialNumber: '123456789',
    url: 'http://localhost:3000/robots'
  },
  {
    numberRobot: '003',
    alerts: '1500',
    interactions: '254000',
    status: 'ON LINE',
    serialNumber: '123456789',
    url: 'http://localhost:3000/robots'
  },
  {
    numberRobot: '004',
    alerts: '25400000',
    interactions: '30',
    status: 'ON LINE',
    serialNumber: '123456789',
    url: 'http://localhost:3000/robots'
  },
  {
    numberRobot: '005',
    alerts: '15',
    interactions: '300',
    status: 'OFF LINE',
    serialNumber: '123456789',
    url: 'http://localhost:3000/robots'
  }
];
const inputProps = {
  variant: 'outlined',
  fullWidth: true,
  size: 'medium'
};

export { robots, inputProps };
