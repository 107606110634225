import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: 0,
    width: 400,
    [theme.breakpoints.down('xs')]: {
      width: 'auto'
    }
  },
  subtitle: {
    fontWeight: 800,
    fontSize: '15px'
  },
  textInfo: {
    fontSize: '14px',
    marginBottom: theme.spacing(2)
  },
  button: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  }
}));

export { useStyles };
