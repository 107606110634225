import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  nameLabel: {
    id: 'components.FormScenes.nameLabel',
    defaultMessage: 'Nombre'
  },
  resolutionLabel: {
    id: 'components.FormScenes.resolutionbel',
    defaultMessage: 'Resolución'
  },
  backgroundLabel: {
    id: 'components.FormScenes.backgroundLabel',
    defaultMessage: 'Color de fondo'
  },
  backgroundPlaceholder: {
    id: 'components.FormScenes.backgroundPlaceholder',
    defaultMessage: '#000000'
  },
  subtitle: {
    id: 'components.FormScenes.subtitle',
    defaultMessage: 'Datos de la presentación visual.'
  },
  infoText: {
    id: 'components.FormScenes.infoText',
    defaultMessage:
      'Selecciona una imagen de fondo, esta debe ser del mismo tamaño que la resolución seleccionada'
  },
  save: {
    id: 'components.FormScenes.save',
    defaultMessage: 'GUARDAR'
  },
  uploadImage: {
    id: 'components.FormScenes.uploadImage',
    defaultMessage: 'Subir Foto'
  },
  saveAndDesign: {
    id: 'components.FormScenes.saveAndDesign',
    defaultMessage: 'GUARDAR Y DISEÑAR'
  }
});
