import { useEffect, useState } from 'react';
import { useLoader } from '@octopy/react-loader';
import { get } from 'lodash';
import { useApi } from 'hooks';
import { responseMapper } from 'utils/responseMapper';

const brancheListMapper = {
  id: 'id',
  name: 'name',
  audio_text: 'audio_text'
};

export const useBranches = () => {
  const { handleShowLoader } = useLoader();
  const [branchList, setBranchList] = useState([]);
  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = storage.user.client.client_id;

  const [branchOffices] = useApi({
    endpoint: `/branch_offices/branch_offices/branchoffice_by_client_id/${clientId}/`,
    method: 'get'
  });

  const getBranchOffices = async () => {
    const response = await branchOffices();

    const branchResponse = get(response, 'payload', []);

    const mappedBranches = responseMapper({
      template: brancheListMapper,
      data: branchResponse
    });

    if (response.headerResponse.code === 200) {
      setBranchList(mappedBranches);
      handleShowLoader(false);
    }
  };

  useEffect(() => {
    handleShowLoader(true);
    getBranchOffices();
  }, []);

  return {
    branchList,
    getBranchOffices
  };
};
