import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { useModal } from '@octopy/react-modal';
import { useDrawer } from 'components/Drawer';
import { useApi } from 'hooks';
import {
  Typography,
  Card as CardMaterial,
  Grid,
  Divider,
  IconButton,
  Fade,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Avatar,
  Button,
  CardHeader
} from '@material-ui/core';
import {
  MoreVert,
  Notifications,
  Group,
  PowerSettingsNew,
  Edit,
  Delete,
  Assignment,
  Close
} from '@material-ui/icons';
import { Images } from 'assets';
import { RelateSurveyForm } from 'views/Robot/RelateSurveyForm';
import { messages } from './CardMessages';
import { useStyles } from './CardStyles';
import { get } from 'lodash';
import { getItem } from 'utils/persistentStorage';

const Card = ({
  id,
  name,
  status,
  onClick,
  robot,
  image,
  onClickAcept,
  isClient,
  numRobots,
  dueDate,
  permissions,
  space,
  onEdit,
  onDelete,
  data,
  list,
  onClickUnlink,
  getRobotList,
  isSuperAdmin
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { handleOpenDrawer, handleCloseDrawer } = useDrawer();
  const [menu, setMenu] = useState(null);
  const [alerts, setAlerts] = useState(null);
  const [interactions, setInteractions] = useState(null);
  const open = Boolean(menu);
  const session = getItem('session');

  const [editRobot] = useApi({
    endpoint: '/robots/robots',
    method: 'put'
  });

  const [getAlerst] = useApi({
    endpoint: `/notifications/notifications/notifications_by_robot/${id}/`,
    method: 'get'
  });

  const [getInteractions] = useApi({
    endpoint: `/interaction/interaction/interaction_by_robot/${id}/`,
    method: 'get'
  });

  useEffect(() => {
    if (!isClient) {
      getAlertsByRobot();
      getInteractionsByRobot();
    }
  }, []);

  const getAlertsByRobot = async () => {
    try {
      const response = await getAlerst();
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', []);

      if (headerResponse.code === 200) setAlerts(dataResponse.length);
    } catch (error) {}
  };

  const getInteractionsByRobot = async () => {
    try {
      const response = await getInteractions();
      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', []);

      if (headerResponse.code === 200) setInteractions(dataResponse.length);
    } catch (error) {}
  };

  const handleDeleteRobot = () => {
    handleOpenModal({
      title: (
        <Grid className={classes.contentModal}>
          <Grid className={classes.closeButton}>
            <IconButton onClick={() => handleCloseModal()}>
              <Close className={classes.iconClose} />
            </IconButton>
          </Grid>
          <Typography className={classes.titleModal}>
            {intl.formatMessage(messages.titleModal)}
          </Typography>
        </Grid>
      ),
      body: (
        <Grid style={{ marginBottom: 30 }}>
          <Typography className={classes.descriptionModal}>
            {intl.formatMessage(messages.descriptionModal)}
          </Typography>
          <Grid container spacing={2} className={classes.gridButtonModal}>
            <Grid item xs={12} sm={6} lg={6} className={classes.gridButtons}>
              <Button
                className={classes.buttonCanel}
                onClick={() => handleCloseModal()}
              >
                {intl.formatMessage(messages.cancel)}
              </Button>
            </Grid>
            <Grid item xs={12} sm={6} lg={6} className={classes.gridButtons}>
              <Button className={classes.buttonModal} onClick={onClickAcept}>
                {intl.formatMessage(messages.buttonModal)}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ),
      configProps: {
        maxWidth: 'lg'
      }
    });
  };

  const updateSurveysRobot = async (id, values) => {
    try {
      const {
        headerResponse: { code }
      } = await editRobot({
        urlParams: `${id}/`,
        body: {
          polls: [parseInt(values.polls)]
        }
      });

      if (code === 200) {
        handleCloseDrawer();
        getRobotList();
      }
    } catch (error) {
      throw new Error(error);
    }
  };

  const handleRelateSurveys = (values) => {
    if (values.applyToAllRobots) {
      //TODO: apply to all robots the self surveys
    } else {
      updateSurveysRobot(id, values);
    }
  };

  const handleDrawer = () => {
    handleOpenDrawer({
      closeButton: true,
      title: (
        <>
          <Typography className={classes.robotTitleDrawer}>{name}</Typography>
          <Typography className={classes.relateSurveyDrawer}>
            {intl.formatMessage(messages.relateSurveyDrawer)}
          </Typography>
        </>
      ),
      body: (
        <>
          <RelateSurveyForm
            handleSubmit={handleRelateSurveys}
            initialValues={{ polls: get(data, ['polls', [0]], '').toString() }}
          />
        </>
      ),
      configProps: {
        anchor: 'right'
      }
    });
  };

  const listUsers = () => {
    switch (isSuperAdmin) {
      case false:
        return (
          <>
            <MenuItem
              onClick={() => {
                handleDrawer();
                setMenu(null);
              }}
            >
              <ListItemIcon>
                <Assignment fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography className={classes.textItemMenu}>
                  {intl.formatMessage(messages.relateSurvey)}
                </Typography>
              </ListItemText>
            </MenuItem>
          </>
        );
      case true:
        return (
          <>
            <MenuItem
              onClick={() => {
                handleDeleteRobot();
                setMenu(null);
              }}
            >
              <ListItemIcon>
                <Delete fontSize="small" />
              </ListItemIcon>
              <ListItemText>
                <Typography className={classes.textItemMenu}>
                  {intl.formatMessage(messages.robotDelete)}
                </Typography>
              </ListItemText>
            </MenuItem>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <CardMaterial className={classes.rootCard}>
      <CardHeader
        avatar={
          image ? (
            <Avatar src={image} alt="imageLogo" />
          ) : (
            <Avatar src={Images.robotIcon} alt="icono" />
          )
        }
        action={
          robot && (
            <IconButton onClick={(e) => setMenu(e.currentTarget)}>
              <MoreVert className={classes.icons} />
            </IconButton>
          )
        }
        title={<Typography className={classes.nameRobot}>{name}</Typography>}
      />
      <Menu
        id="long-menu"
        anchorEl={menu}
        keepMounted
        open={open}
        onClose={() => setMenu(null)}
        TransitionComponent={Fade}
      >
        {list &&
          list.map((item) => (
            <MenuItem
              onClick={() => history.push(item.path, data)}
              disabled={
                item.path === '/robot/personal-information' &&
                get(session, ['user', 'client', 'bucket_name'], undefined) ===
                  undefined
              }
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>
                <Typography className={classes.textItemMenu}>
                  {item.message}
                </Typography>
              </ListItemText>
            </MenuItem>
          ))}
        <div> {listUsers()} </div>
      </Menu>
      {isClient ? (
        <>
          <Grid className={classes.containerLabel}>
            <Grid>
              <Typography className={classes.labelWithoutIcon}>
                {intl.formatMessage(messages.clientRobots)}
                {numRobots}
              </Typography>
            </Grid>
            <Grid>
              <Typography className={classes.labelWithoutIcon}>
                {intl.formatMessage(messages.clientDate)}
                {dueDate}
              </Typography>
            </Grid>
            <Grid>
              <Typography className={classes.labelWithoutIcon}>
                {intl.formatMessage(messages.clientPermissions)}
                {permissions}
              </Typography>
            </Grid>
            <Grid>
              <Typography className={classes.labelWithoutIcon}>
                {intl.formatMessage(messages.clientSpace)}
                {space}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={classes.gridDivider}>
            <Divider className={classes.Divider} />
          </Grid>
        </>
      ) : (
        <Grid container style={{ padding: 10, marginTop: '-2rem' }}>
          <Grid item xs={12} className={classes.Alert}>
            <Notifications />
            <Typography className={classes.alertRobot}>
              {intl.formatMessage(messages.alertRobot)} &nbsp;
              {alerts !== null ? alerts : 0}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.Interaction}>
            <Group />
            <Typography className={classes.alertRobot}>
              {intl.formatMessage(messages.interactionRobot)} &nbsp;{' '}
              {interactions ? interactions : 0}
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.gridDivider}>
            <Divider className={classes.Divider} />
          </Grid>
        </Grid>
      )}
      <Grid container className={classes.powerEdit} style={{ padding: 10 }}>
        <Grid className={classes.ButonPower}>
          {robot && (
            <IconButton onClick={() => onClickUnlink(id)} disabled={!status}>
              <PowerSettingsNew
                className={status ? classes.powerOn : classes.icons}
              />
            </IconButton>
          )}
          <Grid className={classes.statusText}>
            <Typography className={classes.Status}>
              {intl.formatMessage(messages.Status)}
            </Typography>
            <Typography className={classes.statusOff}>
              {robot
                ? status
                  ? 'ON LINE'
                  : 'OFF LINE'
                : status
                ? 'Activo'
                : 'Inactivo'}
            </Typography>
          </Grid>
        </Grid>
        <Grid>
          {!robot ? (
            <>
              <IconButton onClick={() => onEdit(id)}>
                <Edit className={classes.icons} />
              </IconButton>
              <IconButton onClick={() => onDelete(id)}>
                <Delete className={classes.icons} />
              </IconButton>
            </>
          ) : (
            isSuperAdmin && (
              <IconButton onClick={(item) => onClick(item)}>
                <Edit className={classes.icons} />
              </IconButton>
            )
          )}
        </Grid>
      </Grid>
    </CardMaterial>
  );
};

Card.propTypes = {
  name: PropTypes.string,
  status: PropTypes.bool,
  onClick: PropTypes.func,
  robot: PropTypes.bool,
  image: PropTypes.node,
  isClient: PropTypes.bool,
  numRobots: PropTypes.string,
  dueDate: PropTypes.string,
  permissions: PropTypes.string,
  space: PropTypes.string,
  onClickUnlink: PropTypes.func
};

export { Card };
