import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  checkoutAssigned: {
    id: 'views.Turns.HistoryTurns.checkoutAssigned',
    defaultMessage: 'CAJA ASIGNADA'
  },
  turnAssigned: {
    id: 'views.Turns.HistoryTurns.turnAssigned',
    defaultMessage: 'TURNO ASIGNADO'
  },
  buttonAttended: {
    id: 'views.Turns.HistoryTurns.buttonAttended',
    defaultMessage: 'ATENDIDO'
  },
  buttonNoWait: {
    id: 'views.Turns.HistoryTurns.buttonNoWait',
    defaultMessage: 'EL CLIENTE NO ESPERO'
  },
  buttonNextTurn: {
    id: 'views.Turns.HistoryTurns.buttonNextTurn',
    defaultMessage: 'ATENDER EL SIGUIENTE TURNO'
  },
  assign: {
    id: 'views.Turns.HistoryTurns.assign',
    defaultMessage: 'Asignada'
  },
  notAssign: {
    id: 'views.Turns.HistoryTurns.notAssign',
    defaultMessage: 'No asiganada'
  }
});
