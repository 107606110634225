import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.5),
      margin: `0 ${theme.spacing(1)}px`,
      marginTop: theme.spacing(3)
    }
  },
  description: {
    fontSize: '14px',
    fontWeight: 500
  },
  descriptionSubtitle: {
    fontSize: '14px'
  },
  descriptionError: {
    fontSize: '12px',
    color: theme.palette.error.main,
    marginLeft: '14px'
  },
  button: {
    marginTop: theme.spacing(6),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  quill: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    '& .ql-container': {
      height: 'auto'
    },
    '& .ql-editor': {
      height: theme.spacing(35),
      overflowY: 'scroll'
    }
  },
  contentButton: {
    marginBottom: theme.spacing(8)
  }
}));

export { useStyles };
