import { messages } from './SideMenuMessages';
import {
  Android,
  Apartment,
  Assessment,
  Assignment,
  ListAlt,
  LiveTv,
  AssignmentInd,
  PermContactCalendar,
  Receipt,
  Security,
  Widgets,
  Image,
  PermMedia,
  Settings,
  PeopleAlt,
  FormatListNumbered,
  InsertDriveFile
} from '@material-ui/icons';
import {
  MODULE_ROBOT,
  MODULE_METRICS,
  MODULE_SURVEYS,
  MODULE_ADVERTISING,
  MODULE_CATEGORIES,
  MODULE_CATALOG,
  MODULE_BRANCHES,
  MODULE_DIRECTORY,
  MODULE_TURNS,
  MODULE_USERS,
  MODULE_PERMISSIONS,
  MODULE_CLIENTS,
  MODULE_INFORMATION
} from 'utils/modules';

export const mainSection = {
  items: [
    {
      id: 11,
      name: MODULE_METRICS,
      path: '/metrics',
      message: messages.metrics,
      icon: Assessment
    },
    {
      id: 9,
      name: MODULE_ROBOT,
      path: '/robots',
      message: messages.robot,
      icon: Android
    },
    {
      id: 7,
      name: MODULE_SURVEYS,
      path: '/surveys',
      message: messages.surveys,
      icon: Assignment
    },
    {
      id: 14,
      name: MODULE_ADVERTISING,
      path: '/advertising',
      message: messages.advertising,
      icon: LiveTv,
      items: [
        {
          name: 'scenes',
          path: '/advertising/scenes',
          message: messages.advertisingEscenes,
          icon: Image
        },
        {
          name: 'campaigns',
          path: '/advertising/campaigns',
          message: messages.advertisingCampaigns,
          icon: Settings
        },
        {
          name: 'mediaLibrary',
          path: '/advertising/media-library',
          message: messages.advertisingMediaLibrary,
          icon: PermMedia
        }
      ]
    }
  ]
};

export const catalogSection = {
  title: messages.catalogue,
  items: [
    {
      id: 12,
      name: MODULE_CATALOG,
      message: messages.products,
      icon: Widgets,
      path: '/products'
    },
    {
      id: 18,
      name: MODULE_CATEGORIES,
      message: messages.categories,
      icon: ListAlt,
      path: '/categories'
    },
    {
      id: 13,
      name: MODULE_BRANCHES,
      message: messages.branch,
      icon: Apartment,
      path: '/branch'
    },
    {
      id: 16,
      name: MODULE_DIRECTORY,
      message: messages.directory,
      icon: PermContactCalendar,
      path: '/directory'
    },
    {
      id: 17,
      name: MODULE_TURNS,
      message: messages.turn,
      icon: Receipt,
      path: '/turns',
      items: [
        {
          name: 'turnHistory',
          path: '/turns/history',
          message: messages.turnHistory,
          icon: FormatListNumbered
        },
        {
          name: 'turnTake',
          path: '/turns/take',
          message: messages.turnTake,
          icon: Receipt
        }
      ]
    },
    {
      id: 19,
      name: MODULE_INFORMATION,
      message: messages.information,
      icon: InsertDriveFile,
      path: '/information'
    }
  ]
};

export const managementSection = {
  title: messages.management,
  items: [
    {
      id: 10,
      name: MODULE_USERS,
      message: messages.users,
      icon: AssignmentInd,
      path: '/users'
    },
    {
      id: 15,
      name: MODULE_PERMISSIONS,
      message: messages.permissions,
      icon: Security,
      path: '/permissions'
    },
    {
      id: 8,
      name: MODULE_CLIENTS,
      message: messages.clients,
      icon: PeopleAlt,
      path: '/clients'
    }
  ]
};

export const superAdminSection = [
  {
    items: [
      {
        name: MODULE_ROBOT,
        path: '/robots',
        message: messages.robot,
        icon: Android
      }
    ]
  },
  {
    title: messages.management,
    items: [
      {
        name: MODULE_USERS,
        message: messages.users,
        icon: AssignmentInd,
        path: '/users'
      },
      {
        name: MODULE_PERMISSIONS,
        message: messages.permissions,
        icon: Security,
        path: '/permissions'
      },
      {
        name: MODULE_CLIENTS,
        message: messages.clients,
        icon: PeopleAlt,
        path: '/clients'
      }
    ]
  }
];
