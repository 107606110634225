import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  Robot: {
    id: 'components.Time.Robot',
    defaultMessage: 'ROBOT  001'
  },
  timeTitle: {
    id: 'components.Time.timeTitle',
    defaultMessage: 'TIEMPOS DE INTERACCIÓN'
  },
  actionTitle: {
    id: 'components.Time.actionTitle',
    defaultMessage: '1.'
  },
  actionDescription: {
    id: 'components.Time.actionDescription',
    defaultMessage: 'Tiempo máximo de esperar acción del usuario:'
  },
  inactivityTitle: {
    id: 'components.Time.inactivityTitle',
    defaultMessage: '2.'
  },
  inactivityDescription: {
    id: 'components.Time.inactivityDescription',
    defaultMessage: 'Tiempo de inactividad permitido durante la encuesta:'
  },
  microTitle: {
    id: 'components.Time.microTitle',
    defaultMessage: '3.'
  },
  microDescription: {
    id: 'components.Time.microDescription',
    defaultMessage: 'Tiempo de escucha del micrófono:'
  },
  reminderTitle: {
    id: 'components.Time.reminderTitle',
    defaultMessage: '4.'
  },
  reminderDescription: {
    id: 'components.Time.reminderDescription',
    defaultMessage: 'Tiempo de recordar al cliente de hacer otra pregunta:'
  },
  buttonSave: {
    id: 'components.Time.buttonSave',
    defaultMessage: 'GUARDAR'
  },
  minTime: {
    id: 'components.Time.minTime',
    defaultMessage: 'Mínimo 20 segundos'
  },
  maxTime: {
    id: 'components.Time.maxTime',
    defaultMessage: 'Maximo 60 segundos'
  },
  minTimeSix: {
    id: 'components.Time.minTimeSix',
    defaultMessage: 'Minimo 6 segundos'
  },
  minTimeTen: {
    id: 'components.Time.minTimeTen',
    defaultMessage: 'Minimo 10 segundos'
  },
  maxTimeTen: {
    id: 'components.Time.maxTimeTen',
    defaultMessage: 'Maximo 10 segundos'
  },
  titleSuccessModal: {
    id: 'routes.Time.titleSuccessModal',
    defaultMessage: 'Datos guardados con éxito'
  },
  textInfoSucces: {
    id: 'routes.Time.textInfoSucces',
    defaultMessage: 'Los tiempos de interacción se han guardado exitosamente.'
  }
});
