import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(4)}px`,
    paddingBottom: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      margin: `0 ${theme.spacing(1)}px`,
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(8)
    }
  },
  contentHeader: {
    marginBottom: theme.spacing(2)
  },
  contentTitle: {
    display: 'flex',
    alignItems: 'center'
  },
  titleRobot: {
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800,
    alignItems: 'center'
  },
  gridContainerActions: {
    alignItems: 'center',
    justifyContent: 'flex-end',
    dysplay: 'flex'
  },
  gridItemSearch: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      marginTop: '10px',
      marginBottom: '10px',
      display: 'flex',
      justifyContent: 'center'
    }
  },
  gridItemButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  contentButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  },
  contentSearch: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  Button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  contentModal: {
    width: '600px',
    textAlign: 'center',
    marginBottom: theme.spacing(3)
  },
  closeButton: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  titleModal: {
    textAlign: 'center',
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800
  },
  descriptionModal: {
    textAlign: 'center',
    fontSize: '14px',
    marginTop: '20px',
    marginBottom: '20px'
  },
  gridButtonModal: {
    display: 'flex',
    justifyContent: 'center'
  },
  buttonModal: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  contentCards: {
    [theme.breakpoints.down('xs')]: {
      display: 'flex',
      justifyContent: 'center'
    }
  }
}));

export { useStyles };
