import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Paper } from '@material-ui/core';
import { get } from 'lodash';
import { messages } from './WelcomeScreenMessages';
import { useStyles } from './WelcomeScreenStyles';

const WelcomeScreen = () => {
  const intl = useIntl();
  const classes = useStyles();

  const storage = JSON.parse(localStorage.getItem('session'));
  const userName = get(storage, 'user.name', 'user.last_name', '');
  const userLastName = get(storage, 'user.last_name', '');
  const profile = get(storage, 'user.profile.name', '');

  return (
    <div className={classes.container}>
      <Paper>
        <div className={classes.paralaxEffect}>
          <div className={classes.paralaxTextCotainer}>
            <span className={classes.welcomeHeader}>
              {intl.formatMessage(messages.welcomeHeader)}
            </span>
            <span className={classes.username}>
              {userName} {userLastName}
            </span>
            <span className={classes.welcomeRole}>
              {intl.formatMessage(messages.welcomeRole)} {profile}
            </span>
          </div>
        </div>
      </Paper>
    </div>
  );
};

WelcomeScreen.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { WelcomeScreen };
