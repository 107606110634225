import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { Button, Card, Grid, Typography } from '@material-ui/core';
import { HeaderTitle } from 'components/HeaderTitle';
import { TableTransferList } from 'components/TableTransferList';
import { tableHead, filters } from './utils';
import { usePersonalInformation } from './hooks/usePersonalInformation';
import { messages } from './PersonalInformationMessages';
import { useStyles } from './PersonalInformationStyles';

const PersonalInformation = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const [leftAux, setLeftAux] = useState(null);
  const {
    dataTable,
    handleInformation,
    handleRobotId,
    handleOnSubmit
  } = usePersonalInformation({ setLeftAux, leftAux });

  useEffect(() => {
    handleRobotId();
    handleInformation();
  }, []);

  const handleOnChange = (arrayAux) => {
    setLeftAux(arrayAux);
  };

  return (
    <div className={classes.container}>
      <HeaderTitle
        firstTitle={history.location.state.name}
        secondTitle={intl.formatMessage(messages.personalInformationTitle)}
        redirection="robots"
      />
      <Card className={classes.rootCard}>
        <Grid container className={classes.contentActions}>
          <Grid>
            <Typography className={classes.infoText}>
              {intl.formatMessage(messages.personalInformationText)}
            </Typography>
          </Grid>
          {/* <Grid>
            <FormControlLabel
              value="end"
              control={<Checkbox color="primary" />}
              label={
                <Typography className={classes.infoText}>
                  {intl.formatMessage(messages.labelCheckbox)}
                </Typography>
              }
              labelPlacement="end"
            />
          </Grid> */}
          <Grid className={classes.gridButtons}>
            <Grid style={{ marginRight: 20 }}>
              <Button
                className={classes.cancelButton}
                onClick={() => history.push('/robots')}
              >
                {intl.formatMessage(messages.cancel)}
              </Button>
            </Grid>
            <Button className={classes.button} onClick={() => handleOnSubmit()}>
              {intl.formatMessage(messages.save)}
            </Button>
          </Grid>
        </Grid>
        <Grid style={{ marginBottom: 20 }}>
          <TableTransferList
            data={dataTable}
            tableHead={tableHead}
            filters={filters}
            changeData={handleOnChange}
            leftAux={leftAux}
            labelTotal={intl.formatMessage(messages.labelTotal)}
            id="information_item_id"
          />
        </Grid>
      </Card>
    </div>
  );
};

PersonalInformation.propTypes = {};

export { PersonalInformation };
