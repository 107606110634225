import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleItem: {
    id: 'components.ProductForm.titleItem',
    defaultMessage: 'AGREGAR ÍTEM'
  },
  titleEditItem: {
    id: 'components.ProductForm.titleEditItem',
    defaultMessage: 'EDITAR ÍTEM'
  },
  descriptionItem: {
    id: 'components.ProductForm.descriptionItem',
    defaultMessage: 'Agrega información del producto'
  },
  titleImage: {
    id: 'components.ProductForm.titleImage',
    defaultMessage: 'Agrega imágenes:'
  },
  descriptionImage: {
    id: 'components.ProductForm.descriptionImage',
    defaultMessage:
      'La primera imagen agregada será la principal, hasta máximo 5 imágenes.'
  },
  uploadImage: {
    id: 'components.ProductForm.uploadImage',
    defaultMessage: 'Subir Foto'
  },
  titleTextBot: {
    id: 'components.ProductForm.titleTextBot',
    defaultMessage: 'Agrega un texto para el bot:'
  },
  descriptionTextBot: {
    id: 'components.ProductForm.descriptionTextBot',
    defaultMessage:
      'Este texto se reproducirá cuando el usuario vea este producto.'
  },
  englishPart: {
    id: 'components.ProductForm.englishPart',
    defaultMessage: 'Agregar información en inglés'
  },
  nameLabelSpanish: {
    id: 'components.ProductForm.nameLabelSpanish',
    defaultMessage: 'Nombre'
  },
  namePlaceholderSpanish: {
    id: 'components.ProductForm.namePlaceholderSpanish',
    defaultMessage: 'Nombre'
  },
  categoryLabelSpanish: {
    id: 'components.ProductForm.categoryLabelSpanish',
    defaultMessage: 'Categoría'
  },
  categoryPlaceholderSpanish: {
    id: 'components.ProductForm.categoryPlaceholderSpanish',
    defaultMessage: 'Seleccione categoría'
  },
  subcategoryLabelSpanish: {
    id: 'components.ProductForm.subcategoryLabelSpanish',
    defaultMessage: 'Subcategoria'
  },
  subcategoryPlaceholderSpanish: {
    id: 'components.ProductForm.subcategoryPlaceholderSpanish',
    defaultMessage: 'Seleccione subcategoria'
  },
  priceLabelSpanish: {
    id: 'components.ProductForm.priceLabelSpanish',
    defaultMessage: 'Precio'
  },
  currencyLabelSpanish: {
    id: 'components.ProductForm.currencyLabelSpanish',
    defaultMessage: 'Moneda'
  },
  currencyPlaceholderSpanish: {
    id: 'components.ProductForm.currencyPlaceholderSpanish',
    defaultMessage: 'Seleccione moneda'
  },
  descriptionLabelSpanish: {
    id: 'components.ProductForm.descriptionLabelSpanish',
    defaultMessage: 'Descripción'
  },
  englishInfo: {
    id: 'components.ProductForm.englishInfo',
    defaultMessage: 'INFORMACIÓN QUE SE MOSTRARÁ EN INGLÉS'
  },
  nameLabelEnglish: {
    id: 'components.ProductForm.nameLabelEnglish',
    defaultMessage: 'Name'
  },
  namePlaceholderEnglish: {
    id: 'components.ProductForm.namePlaceholderEnglish',
    defaultMessage: 'Name'
  },
  categoryLabelEnglish: {
    id: 'components.ProductForm.categoryLabelEnglish',
    defaultMessage: 'Category'
  },
  categoryPlaceholderEnglish: {
    id: 'components.ProductForm.categoryPlaceholderEnglish',
    defaultMessage: 'Select category'
  },
  subcategoryLabelEnglish: {
    id: 'components.ProductForm.subcategoryLabelEnglish',
    defaultMessage: 'Subcategory'
  },
  subcategoryPlaceholderEnglish: {
    id: 'components.ProductForm.subcategoryPlaceholderEnglish',
    defaultMessage: 'Select subcategory'
  },
  priceLabelEnglish: {
    id: 'components.ProductForm.priceLabelEnglish',
    defaultMessage: 'Price'
  },
  currencyLabelEnglish: {
    id: 'components.ProductForm.currencyLabelEnglish',
    defaultMessage: 'Currency'
  },
  currencyPlaceholderEnglish: {
    id: 'components.ProductForm.currencyPlaceholderEnglish',
    defaultMessage: 'Select currency'
  },
  descriptionLabelEnglish: {
    id: 'components.ProductForm.descriptionLabelEnglish',
    defaultMessage: 'Desciption'
  },
  uploadFileError: {
    id: 'views.ProductForm.uploadFileError',
    defaultMessage:
      'Ha ocurrido un error al cargar el archivo. Intentalo de nuevo más tarde.'
  },
  errorRobot: {
    id: 'views.ProductForm.errorRobot',
    defaultMessage: 'ERROR'
  },
  infoTextSelect: {
    id: 'views.ProductForm.infoTextSelect',
    defaultMessage:
      'La selección de los agentes por robot no coinciden. Vuelva a intentarlo.'
  },
  add: {
    id: 'components.ProductForm.add',
    defaultMessage: 'CREAR'
  },
  save: {
    id: 'components.ProductForm.save',
    defaultMessage: 'GUARDAR'
  },
  errorModalTitle: {
    id: 'components.ProductForm.errorModalTitle',
    defaultMessage: 'ERROR AL SOLICITAR PETICIÓN.'
  },
  errorModalDescription: {
    id: 'components.ProductForm.errorModalDescription',
    defaultMessage:
      'Para continuar es necesario guardar la información del producto en ingles.'
  }
});
