import React from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton, Grid } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useStyles } from './HeaderTitleStyles';
import { useHistory } from 'react-router-dom';

const HeaderTitle = ({ firstTitle, secondTitle, redirection }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={10} className={classes.contentTitle}>
        <Typography className={classes.fristTitle}>
          {firstTitle}
          {firstTitle && secondTitle ? <>&nbsp;/&nbsp;</> : null}
        </Typography>
        {secondTitle && (
          <Typography className={classes.secondTitle}>{secondTitle}</Typography>
        )}
      </Grid>
      <Grid item xs={2} className={classes.contentIcon}>
        <IconButton
          onClick={() => {
            redirection
              ? history.push(`/${redirection}`)
              : window.history.back();
          }}
        >
          <ArrowBack className={classes.iconBack} />
        </IconButton>
      </Grid>
    </Grid>
  );
};

HeaderTitle.propTypes = {
  headerTitle: PropTypes.string,
  secondTitle: PropTypes.string,
  redirection: PropTypes.string
};

export { HeaderTitle };
