import React from 'react';
import PropTypes from 'prop-types';

import { useIntl, FormattedMessage } from 'react-intl';

import { Typography, Button, TextField } from '@material-ui/core';

import { messages } from './AddTakeTurnDrawerMessages';

import { useStyles } from './AddTakeTurnDrawerStyles';

import { renderAutocomplete, renderTextField } from '@octopy/react-form';

import { useForm } from './hooks/useForm';

const AddTakeTurnDrawer = () => {
  const classes = useStyles();
  const { formatMessage: f } = useIntl();

  const {
    fieldCreateBox,
    formikCreate,
    fieldsDelete,
    formikDelete
  } = useForm();

  return (
    <div className={classes.container}>
      <Typography className={classes.approverSubtitle} variant="subtitle1">
        <FormattedMessage {...messages.boxCreate} />
      </Typography>

      <div className={classes.spaceBet}>
        {renderAutocomplete({
          formik: formikCreate,
          field: fieldCreateBox.selectBranch,
          inputProps: {}
        })}
      </div>

      <div className={classes.spaceBet}>
        {/* {renderTextField({
          formik: formikCreate,
          field: fieldCreateBox.boxNumber,
          inputProps: {
            min: 1,
            pattern: '^[0-9]+'
          },
          onKeyPress:{(event) => {
            if (event?.key === '-' || event?.key === '+') {
              event.preventDefault();
            }
          }}
        })} */}
        <TextField
          fullWidth
          variant="outlined"
          label={fieldCreateBox.boxNumber.label}
          placeholder={fieldCreateBox.boxNumber.placeholder}
          type={fieldCreateBox.boxNumber.type}
          name={fieldCreateBox.boxNumber.name}
          id={fieldCreateBox.boxNumber.id}
          value={formikCreate.values.boxNumber}
          InputProps={{
            inputProps: { min: 1, pattern: '^[0-9]+' }
          }}
          onKeyPress={(event) => {
            if (event?.key === '-' || event?.key === '+') {
              event.preventDefault();
            }
          }}
          onChange={formikCreate.handleChange}
          error={
            formikCreate.touched.boxNumber &&
            Boolean(formikCreate.errors.boxNumber)
          }
          helperText={
            formikCreate.touched.boxNumber && formikCreate.errors.boxNumber
          }
        />
      </div>

      <Button
        size="small"
        className={classes.buttonS}
        color="primary"
        onClick={formikCreate.submitForm}
      >
        <Typography className={classes.textButton}>
          {f(messages.boxCreateS)}
        </Typography>
      </Button>

      <Typography className={classes.approverSubtitle} variant="subtitle1">
        <FormattedMessage {...messages.boxDelete} />
      </Typography>

      <div className={classes.spaceBet}>
        {renderAutocomplete({
          formik: formikDelete,
          field: fieldsDelete.boxDelete,
          inputProps: {}
        })}
      </div>

      <Button
        size="small"
        className={classes.buttonS}
        color="primary"
        onClick={formikDelete.submitForm}
      >
        <Typography className={classes.textButton}>
          {f(messages.boxDeleteS)}
        </Typography>
      </Button>
    </div>
  );
};

AddTakeTurnDrawer.propTypes = {};

export { AddTakeTurnDrawer };
