import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import { Alert, useModal } from '@octopy/react-modal';
import { useLoader } from '@octopy/react-loader';
import {
  Grid,
  Typography,
  Card,
  // FormControlLabel,
  // Checkbox,
  Button
} from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import { TableTransferList } from 'components/TableTransferList';
import { useScreenLoader } from 'components/ScreenLoader';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { useApiProducts } from 'views/Products/hooks/useApiProducts';
import { useApi } from 'hooks';
import {
  extractAgentData,
  extractElementData,
  mergeAgentAndAudioText,
  createDataUpdateCatalog,
  createDataUpdate
} from 'utils/robot';
import { uploadFilePromise } from 'utils/aws';
import { sources } from 'providers/AWS';
import { getFileFromUrl } from 'utils/fileAudioUrl';
import { responseMapper } from 'utils/responseMapper';
import { tableHead, filters, ProductsListMapper } from './utils';
import { useStyles } from './CatalogStyles';
import { messages } from './CatalogMessages';
import { HeaderTitle } from 'components/HeaderTitle';

const Catalog = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const bucketName = get(storage, 'user.client.bucket_name', {});
  const isStaff = get(storage, 'user.is_staff', '');

  const intl = useIntl();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const { productsList } = useApiProducts();
  const { modalSuccess } = useModalConfirmation();
  const { handleOpenModal, handleCloseModal } = useModal();
  const { handleShowScreen } = useScreenLoader();
  const { handleShowLoader } = useLoader();
  const { audios } = sources(bucketName);
  const idRobot = location.state.robot_id;
  const [leftAux, setLeftAux] = useState([]);
  const [updateAudio, setupdateAudio] = useState([]);
  const [articlesRobot, setarticlesRobot] = useState([]);

  // cambiar este endpoint

  const [getRobotId] = useApi({
    endpoint: `/robots/robots/${idRobot}`,
    method: 'get'
  });

  const [robotUpdate] = useApi({
    endpoint: `/robots/robots/${idRobot}/`,
    method: 'put'
  });

  const [getAgents] = useApi({
    endpoint: 'agents/agents_by_robot',
    method: 'get'
  });

  const [createSpeech] = useApi({
    endpoint: '/speech/',
    method: 'post'
  });
  const [createSpeechList] = useApi({
    endpoint: '/speech/list/',
    method: 'post'
  });
  const [getCataglogArticle] = useApi({
    endpoint: `/catalogue/article`,
    method: 'get'
  });

  // catalogo articulos por robot
  const [getArticleByRobot] = useApi({
    endpoint: `/catalogue/article/articles_by_robot_id/${idRobot}/`,
    method: 'get'
  });

  useEffect(() => {
    handleDataRobotId();
    handleDataArticle();
  }, []);

  const errorUploadFile = () => {
    handleOpenModal({
      configProps: {
        maxWidth: 'sm'
      },
      body: <Alert message={intl.formatMessage(messages.uploadFileError)} />
    });
    handleShowLoader(false);
  };

  const createCategory = async (dataItems) => {
    let mappedDirectoryAux = [];

    for (const item of dataItems) {
      const response = await getCataglogArticle({
        urlParams: item.item_id
      });

      const dataResponse = get(response, 'payload', []);

      const mappedDirectory = responseMapper({
        template: ProductsListMapper,
        data: dataResponse
      });

      mappedDirectoryAux.push({
        item_id: item.item_id,
        ...mappedDirectory
      });
    }

    return mappedDirectoryAux;
  };

  const handleDataArticle = async () => {
    try {
      handleShowLoader(true);
      const response = await getArticleByRobot();

      const headerResponse = get(response, 'headerResponse', {});
      const dataresponse = get(response, 'payload', []);

      if (headerResponse.code === 200) {
        setarticlesRobot([...dataresponse]);
        const result = await createCategory(dataresponse);

        setLeftAux(result ? result : null);
      }

      handleShowLoader(false);
    } catch (error) {}
  };

  const handleDataRobotId = async () => {
    try {
      const response = await getRobotId();

      const headerResponse = get(response, 'headerResponse', {});
      const dataResponse = get(response, 'payload', []);
      const dataAgents = get(dataResponse, 'agents', []);

      if (headerResponse.code === 200) {
        if (dataAgents.length === 0) {
          handleOpenModal({
            title: (
              <Grid className={classes.contentModal}>
                <ReportProblemOutlined className={classes.icon} />
                <Typography className={classes.titleModal}>
                  {intl.formatMessage(messages.emptyAgents)}
                </Typography>
              </Grid>
            ),
            body: (
              <Grid style={{ marginBottom: 20, textAlign: 'center' }}>
                <Typography className={classes.infoTextModal}>
                  {!isStaff
                    ? intl.formatMessage(messages.infoReportProblem)
                    : intl.formatMessage(messages.infoReportProblemIsStaff)}
                </Typography>
                <Button
                  className={classes.button}
                  onClick={() => {
                    history.push('/robots');
                    handleCloseModal();
                  }}
                >
                  {intl.formatMessage(messages.acept)}
                </Button>
              </Grid>
            )
          });
        }
      }
    } catch (error) {}
  };

  const createArrayAudio = async (arrayCreate) => {
    const response = await createSpeechList({
      body: {
        robot_id: idRobot,
        texts: arrayCreate
      }
    });

    const formatResponse = [];

    get(response, ['payload', 'texts'], []).map((text) => {
      const objectFormat = {
        language_id: text.language_id,
        audio_url: text.url
      };

      if (formatResponse.length === 0) {
        formatResponse.push({
          item_id: text.object_id,
          audio_url: [objectFormat]
        });
      } else {
        formatResponse.map((responseFromated, index) => {
          if (responseFromated.item_id === text.object_id) {
            formatResponse[index].audio_url.push(objectFormat);
          } else {
            if (
              formatResponse.findIndex((x) => x.item_id === text.object_id) ===
              -1
            ) {
              formatResponse.push({
                item_id: text.object_id,
                audio_url: [objectFormat]
              });
            }
          }
        });
      }
    });
    if (get(response, ['headerResponse', 'code'], 204) === 200) {
      handleUpdate(formatResponse);
    }

    handleShowScreen(false);
  };

  const handleOnSubmit = async () => {
    try {
      handleShowScreen(true);

      const responseItemsData = extractElementData(updateAudio, 'item_id');

      const response = await getAgents({
        urlParams: idRobot
      });
      const dataResponse = get(response, 'payload', []);

      const responseAgentData = extractAgentData(dataResponse);
      const audioTextData = mergeAgentAndAudioText(
        responseItemsData,
        responseAgentData
      );

      createDataUpdateCatalog({
        items: audioTextData,
        idReference: 'item_id',
        runFetches: (values) => createArrayAudio(values)
      });
    } catch (error) {
      handleShowScreen(false);
    }
  };

  const handleUpdate = async (data) => {
    try {
      const dataExist = [];

      leftAux.length !== 0 &&
        leftAux.map((itemFins) => {
          const positionFinded = articlesRobot.findIndex(
            (item) => item.item_id === itemFins.item_id
          );

          if (positionFinded !== -1)
            dataExist.push(articlesRobot[positionFinded]);
        });

      const dataSend =
        dataExist.length !== 0
          ? dataExist.map((item) => ({
              item_id: item.item_id,
              audio_url: get(item, ['audio_url', [0], 'language'], []).map(
                (itemLenguaje) => ({
                  audio_url: itemLenguaje.audio_url,
                  language_id: itemLenguaje.language_id.language_id
                })
              )
            }))
          : [];

      const response = await robotUpdate({
        body: {
          items: [...data, ...dataSend]
        }
      });
      const headerResponse = get(response, 'headerResponse', {});

      if (headerResponse.code === 200) {
        modalSuccess(
          intl.formatMessage(messages.titleSuccessModal),
          intl.formatMessage(messages.textInfoSucces),
          () => {
            history.push('/robots');
          }
        );
      }
    } catch (error) {
      console.log('error->', error);
    } finally {
      handleShowScreen(false);
    }
  };

  const handleOnChange = (arrayAux) => {
    let objetosFaltantes = [];

    let nombresArreglo1 = arrayAux.map((objeto) => objeto.item_id);

    let nombresArreglo2 = leftAux.map((objeto) => objeto.item_id);

    if (arrayAux.length > leftAux.length) {
      arrayAux.forEach((objeto) => {
        if (!nombresArreglo2.includes(objeto.item_id)) {
          objetosFaltantes.push(objeto);
        }
      });

      leftAux.forEach((objeto) => {
        if (!nombresArreglo1.includes(objeto.item_id)) {
          objetosFaltantes.push(objeto);
        }
      });
    }

    setupdateAudio([...objetosFaltantes, ...updateAudio]);

    setLeftAux(arrayAux);
  };

  return (
    <div className={classes.container}>
      <HeaderTitle
        firstTitle={history.location.state.name}
        secondTitle={intl.formatMessage(messages.titleSecondView)}
        redirection="robots"
      />
      <Card className={classes.rootCard}>
        <Grid container className={classes.contentActions}>
          <Grid>
            <Typography className={classes.infoText}>
              {intl.formatMessage(messages.catalogText)}
            </Typography>
          </Grid>
          {/* <Grid>
            <FormControlLabel
              value="end"
              control={<Checkbox color="primary" />}
              label={
                <Typography className={classes.infoText}>
                  {intl.formatMessage(messages.labelCheckbox)}
                </Typography>
              }
              labelPlacement="end"
            />
          </Grid> */}

          <Grid className={classes.gridButtons}>
            <Grid style={{ marginRight: 20 }}>
              <Button
                className={classes.cancelButton}
                onClick={() => history.push('/robots')}
              >
                {intl.formatMessage(messages.cancel)}
              </Button>
            </Grid>
            <Button className={classes.button} onClick={() => handleOnSubmit()}>
              {intl.formatMessage(messages.save)}
            </Button>
          </Grid>
        </Grid>
        <Grid style={{ marginBottom: 20 }}>
          <TableTransferList
            data={productsList}
            tableHead={tableHead}
            filters={filters}
            changeData={handleOnChange}
            leftAux={leftAux}
            labelTotal={intl.formatMessage(messages.labelTotal)}
            id="item_id"
          />
        </Grid>
      </Card>
    </div>
  );
};

export { Catalog };
