import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    margin: `0 ${theme.spacing(5)}px`,
    [theme.breakpoints.down('sm')]: {
      margin: `0 ${theme.spacing(1)}px`,
      padding: theme.spacing(1),
      marginTop: theme.spacing(2),
      height: 'auto'
    }
  },
  cardContainer: {
    padding: theme.spacing(4)
  },
  buttons: {
    margin: theme.spacing(2),
    marginRight: 0
  },
  button: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  buttonCanel: {
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.common.black}CE`,
      color: theme.palette.common.white
    }
  },
  description: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 'bold'
  }
}));

export { useStyles };
