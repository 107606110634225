import { useFormik } from 'formik';

import { useIntl } from 'react-intl';

import * as Yup from 'yup';

import { messages } from '../ButtonSimpleMultimediaMessages';
import {
  AUDIOFORMAT,
  VIDEOFORMAT,
  IMAGEFORMAT,
  SIZE100MB,
  SIZE30MB
} from './formats';

import { messages as formMessages } from '@octopy/react-form';

export const useForm = () => {
  const intl = useIntl();

  const requiredErr = intl.formatMessage(formMessages.requiredFieldError);
  const fileLarge = intl.formatMessage(messages.fileLarge);
  const fileType = intl.formatMessage(messages.fileType);

  const initialValues = {
    audioVideo: null
  };

  const validationSchema = Yup.object().shape({
    audioVideo: Yup.mixed()
      .nullable()
      .required(requiredErr)
      .test('fileFormat', fileType, (value) => {
        let newValidacion = false;

        if (value) {
          let validateType = value?.type.split('/');

          switch (validateType[0]) {
            case 'video':
              newValidacion =
                value === null || (value && VIDEOFORMAT.includes(value.type));
              break;
            case 'audio':
              newValidacion =
                value === null || (value && AUDIOFORMAT.includes(value.type));
              break;
            case 'image':
              newValidacion =
                value === null || (value && IMAGEFORMAT.includes(value.type));
              break;
            default:
              break;
          }
        }

        return newValidacion;
      })
      .test('fileSize', fileLarge, (value) => {
        let newValidacion = false;
        let validateType = value?.type.split('/');

        if (value) {
          switch (validateType[0]) {
            case 'video':
              newValidacion =
                value === null || (value && value.size <= SIZE30MB);
              break;
            case 'audio':
              newValidacion =
                value === null || (value && value.size <= SIZE100MB);
              break;
            case 'image':
              newValidacion =
                value === null || (value && value.size <= SIZE30MB);

              break;

            default:
              break;
          }
        }

        return newValidacion;
      })
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => console.log('values >>>', values)
  });

  const field = {
    audioVideo: {
      type: 'file',
      name: 'audioVideo',
      label: intl.formatMessage(messages.upMediaFile),
      value: formik.values.audioVideo,
      error: formik.touched.audioVideo?.file && formik.errors.audioVideo?.file
    }
  };

  return { formik, field };
};
