import React from 'react';
import PropTypes from 'prop-types';
// import { useIntl } from 'react-intl';
import { Typography, Grid, Divider } from '@material-ui/core';
// import { messages } from './TopMetricsMessages';
import { useStyles } from './TopMetricsStyles';

const TopMetrics = ({ name, index }) => {
  // const intl = useIntl();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Grid className={classes.grid}>
        <Typography className={classes.number}>{index}. &nbsp;</Typography>
        <Typography className={classes.name}>{name}</Typography>
      </Grid>
      <Divider className={classes.divider} />
    </div>
  );
};

TopMetrics.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number
};

export { TopMetrics };
