import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  catalogTitle: {
    id: 'components.Categories.catalogTitle',
    defaultMessage: 'CATÁLOGO'
  },
  categoriesTitle: {
    id: 'components.Categories.categoriesTitle',
    defaultMessage: 'CATEGORÍAS'
  },
  exportButton: {
    id: 'components.Categories.exportButton',
    defaultMessage: 'EXPORTAR'
  },
  addCategories: {
    id: 'components.Categories.addCategories',
    defaultMessage: 'AGREGAR'
  },
  addCategorie: {
    id: 'components.CategoriesRobot.addCategorie',
    defaultMessage: 'AGREGAR CATEGORÍA'
  },
  editCategorie: {
    id: 'components.CategoriesRobot.editCategorie',
    defaultMessage: 'EDITAR CATEGORÍA'
  },
  titleModal: {
    id: 'components.CategoriesRobot.titleModal',
    defaultMessage: 'ELIMINAR CATEGORÍA'
  },
  descriptionModal: {
    id: 'components.CategoriesRobot.descriptionModal',
    defaultMessage: '¿Seguro que quiere eliminar esta categoría'
  },
  cancel: {
    id: 'components.CategoriesRobot.cancel',
    defaultMessage: 'CANCELAR'
  },
  delete: {
    id: 'components.CategoriesRobot.delete',
    defaultMessage: 'ELIMINAR'
  }
});
