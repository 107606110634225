export const mapBoxes = (boxes) => {
  const mappedBoxes = boxes.map((box) => ({
    id: box.attention_box_id,
    name: `${box.box_number}`,
    assigned: box.assigned,
    branch_office: box.branch_office,
    user: box.user
  }));

  return mappedBoxes;
};
