import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete as MUIAutocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';

import { useStyles } from './AutocompleteStyles';

const Autocomplete = (props) => {
  const {
    formik,
    name,
    options,
    optionLabel,
    label = '',
    placeholder = '',
    hasSeparateLabel = true,
    renderOption = (option) => option[optionLabel],
    // getOptionLabel = (option) => option[optionLabel].toString(),
    renderTags,
    defaultValue,
    value,
    onChange
  } = props;
  const classes = useStyles();

  if (options && options.length > 0) {
    const labelKey = options[0][optionLabel]
      ? optionLabel
      : Object.keys(options[0])[0];

    if (hasSeparateLabel) {
      return (
        <>
          <label className={classes.label} htmlFor={name}>
            {label}
          </label>
          <MUIAutocomplete
            onChange={onChange}
            renderOption={renderOption}
            fullWidth
            name={name}
            options={options}
            getOptionLabel={(option) => option[labelKey].toString()}
            renderTags={renderTags}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder={placeholder}
                variant="outlined"
              />
            )}
          />
        </>
      );
    } else {
      return (
        <MUIAutocomplete
          onChange={onChange}
          defaultValue={defaultValue}
          value={value}
          renderOption={renderOption}
          fullWidth
          name={name}
          options={options}
          getOptionLabel={(option) => option[labelKey].toString()}
          renderTags={renderTags}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={placeholder}
              variant="outlined"
            />
          )}
        />
      );
    }
  }

  return null;
};

Autocomplete.propTypes = {
  formik: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  optionLabel: PropTypes.any.isRequired
};

export default Autocomplete;
