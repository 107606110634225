export const MODULE_ROBOT = 'Robot';
export const MODULE_METRICS = 'Métricas';
export const MODULE_SURVEYS = 'Encuestas';
export const MODULE_ADVERTISING = 'Publicidad';
export const MODULE_CATEGORIES = 'Categorías';
export const MODULE_CATALOG = 'Catálogo';
export const MODULE_BRANCHES = 'Sucursales';
export const MODULE_DIRECTORY = 'Directorio';
export const MODULE_TURNS = 'Turnos';
export const MODULE_USERS = 'Usuarios';
export const MODULE_PERMISSIONS = 'Permisos';
export const MODULE_CLIENTS = 'Gestor clientes';
export const MODULE_INFORMATION = 'Información';
