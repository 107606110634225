import React, { useRef, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { useHistory } from 'react-router-dom';
import { Form, messages as formMessages } from '@octopy/react-form';
import { useModal, Alert } from '@octopy/react-modal';
import { useLoader } from '@octopy/react-loader';
import { setToken, decodeToken } from '@octopy/react-auth';
import { Typography, Button, Box } from '@material-ui/core';
import * as Yup from 'yup';
import * as formConfig from '../../../utils/form-config';
import { Images } from 'assets';
import { useApi, useQueryString } from 'hooks';
import { countdownInterval } from './helpers';
import { messages } from './PasswordRecoveryMessages';
import { useStyles } from './PasswordRecoveryStyles';

const defaultMinutesToExpire = 10;

const PasswordRecovery = ({ timeMinutesToExpire }) => {
  const intl = useIntl();
  const classes = useStyles();
  const formikRef = useRef();
  const history = useHistory();
  const queryString = useQueryString();
  const { handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();

  const token = get(queryString, 'token', '').replaceAll('$', '.');
  const uidb64 = get(queryString, 'uidb64', '');

  const [resetPasswordService] = useApi({
    endpoint: 'auth/password-reset-complete',
    method: 'put',
    token
  });

  useEffect(() => {
    const processId = countdownInterval(
      timeMinutesToExpire || defaultMinutesToExpire,
      () => {
        handleShowExpiredTimeAlert();
        history.push(`/login`);
      }
    );

    return () => {
      clearInterval(processId);
    };
  }, []);

  useEffect(() => {
    setToken(token);
  }, []);

  const handleShowExpiredTimeAlert = () => {
    handleOpenModal({
      body: <Alert message={intl.formatMessage(messages.expiredTimeMessage)} />,
      configProps: {
        maxWidth: 'xs'
      }
    });
  };

  const getInitialValues = () => ({
    password: '',
    confirmPassword: ''
  });

  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );

  const passwordNoMatchesError = intl.formatMessage(
    formMessages.passwordNoMatchesError
  );

  // const passwordRegexError = intl.formatMessage(messages.passwordRegexError);
  const getValidationRules = () =>
    Yup.object({
      password: Yup.string()
        .min(8, intl.formatMessage(messages.passwordCharacters))
        .required(requiredFieldError),
      confirmPassword: Yup.string()
        .min(8, intl.formatMessage(messages.passwordCharacters))
        .required(requiredFieldError)
        .oneOf([Yup.ref('password'), null], passwordNoMatchesError)
    });

  const getFields = () => [
    {
      type: 'password',
      name: 'password',
      label: intl.formatMessage(formMessages.passwordLabel),
      placeholder: intl.formatMessage(formMessages.passwordPlaceholder)
    },
    {
      type: 'password',
      name: 'confirmPassword',
      label: intl.formatMessage(formMessages.passwordConfirmLabel),
      placeholder: intl.formatMessage(formMessages.passwordConfirmPlaceholder)
    }
  ];

  const submitNewPassword = async (formData) => {
    if (!token) return;

    handleShowLoader(true);
    // const { email } = decodeToken(token);

    const { headerResponse = {} } = await resetPasswordService({
      body: {
        password: formData.password,
        token,
        uidb64
      }
    });

    if (headerResponse.code === 200) {
      handleOpenModal({
        body: (
          <Alert
            message={intl.formatMessage(messages.successRecoveryMessage)}
          />
        ),
        configProps: {
          maxWidth: 'xs'
        }
      });

      handleShowLoader(false);
      history.push(`/login`);
    }
  };

  return (
    <div className={classes.container}>
      <div className={classes.formContainer}>
        <Box className={classes.contentTitles}>
          <img
            alt="Octopy"
            src={Images.octopy}
            className={classes.octopyLogo}
          />
        </Box>

        <Typography className={classes.infoText}>
          {intl.formatMessage(messages.infoText)}
        </Typography>

        <Form
          formikRef={formikRef}
          initialValues={getInitialValues()}
          validationRules={getValidationRules()}
          fields={getFields()}
          handleSubmit={submitNewPassword}
          inputProps={formConfig.inputProps}
          showSubmitButton={false}
        />
        <Button
          className={classes.submitButton}
          size="large"
          variant="contained"
          onClick={() => formikRef.current.submitForm()}
        >
          {intl.formatMessage(messages.recoveryPassword)}
        </Button>
      </div>
    </div>
  );
};

PasswordRecovery.propTypes = {};

export { PasswordRecovery };
