import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  title: {
    id: 'components.MyAccount.title',
    defaultMessage: 'MI CUENTA'
  },
  userData: {
    id: 'components.MyAccount.userData',
    defaultMessage: 'DATOS DEL USUARIO'
  },
  storageData: {
    id: 'components.MyAccount.storageData',
    defaultMessage: 'DATOS DEL ESPACIO DE LA CUENTA'
  },
  rolePermission: {
    id: 'components.MyAccount.rolePermission',
    defaultMessage: 'ROL Y PERMISOS'
  },
  nameLabel: {
    id: 'components.MyAccount.nameLabel',
    defaultMessage: 'Nombre'
  },
  lastNameLabel: {
    id: 'components.MyAccount.lastNameLabel',
    defaultMessage: 'Apellidos'
  },
  phoneLabel: {
    id: 'components.MyAccount.phoneLabel',
    defaultMessage: 'Teléfono'
  },
  onlyNumbers: {
    id: 'routes.MyAccount.onlyNumbers',
    defaultMessage: 'Escriba unicamente números'
  },
  positiveNumbers: {
    id: 'routes.MyAccount.positiveNumbers',
    defaultMessage: 'Escriba unicamente números positivos'
  },
  decimalNumbers: {
    id: 'routes.MyAccount.decimalNumbers',
    defaultMessage: 'Número no válido'
  },
  emailLabel: {
    id: 'components.MyAccount.emailLabel',
    defaultMessage: 'Email'
  },
  emailPlaceholder: {
    id: 'components.MyAccount.emailPlaceholder',
    defaultMessage: 'email@dominio.com'
  },
  uploadImageLabel: {
    id: 'components.MyAccount.uploadImageLabel',
    defaultMessage: 'Subir imagen'
  },
  imageTextInfo: {
    id: 'components.MyAccount.imageTextInfo',
    defaultMessage:
      'Selecciona una foto desde tu ordenador, de lo contrario se mostrará una imagen preestablecida:'
  },
  assignedRole: {
    id: 'components.MyAccount.assignedRole',
    defaultMessage: 'Rol asignado:'
  },
  assignedPermissions: {
    id: 'components.MyAccount.assignedPermissions',
    defaultMessage: 'Permisos asignados:'
  },
  permissionsText: {
    id: 'components.MyAccount.permissionsText',
    defaultMessage: 'Módulos permitidos y que puede visualizar el usuario'
  },
  used: {
    id: 'components.MyAccount.used',
    defaultMessage: 'Usado: '
  },
  details: {
    id: 'components.MyAccount.details',
    defaultMessage: 'Detalles'
  },
  advertising: {
    id: 'components.MyAccount.advertising',
    defaultMessage: 'Publicidad (videos e imágenes):'
  },
  catalogue: {
    id: 'components.MyAccount.catalogue',
    defaultMessage: 'Catálogo (videos e imágenes):'
  },
  voicebot: {
    id: 'components.MyAccount.voicebot',
    defaultMessage: 'Voicebot (audios):'
  },
  surveys: {
    id: 'components.MyAccount.survey',
    defaultMessage: 'Encuestas:'
  },
  callData: {
    id: 'components.MyAccount.callData',
    defaultMessage: 'Datos de llamadas:'
  },
  interactionLog: {
    id: 'components.MyAccount.interactionLog',
    defaultMessage: 'Registro de interacciones:'
  },
  update: {
    id: 'components.MyAccount.update',
    defaultMessage: 'ACTUALIZAR'
  },
  uploadFileError: {
    id: 'views.MyAccount.uploadFileError',
    defaultMessage:
      'Ha ocurrido un error al cargar el archivo. Intentalo de nuevo más tarde.'
  }
});
