import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  hello: {
    id: 'components.Interactions.hello',
    defaultMessage: 'Hello {name}!!'
  },
  interactions: {
    id: 'components.Interactions.interactions',
    defaultMessage: 'INTERACCIONES'
  },
  date: {
    id: 'components.Interactions.date',
    defaultMessage: 'Fecha'
  },
  datePlaceHolder: {
    id: 'components.Interactions.datePlaceholder',
    defaultMessage: 'dd/mm/aaaa'
  },
  search: {
    id: 'components.Interactions.search',
    defaultMessage: 'BUSCAR'
  },
  exportCsv: {
    id: 'components.Interactions.exportCsv',
    defaultMessage: 'EXPORTAR'
  },
  woman: {
    id: 'components.Interactions.woman',
    defaultMessage: 'MUJER'
  },
  men: {
    id: 'components.Interactions.men',
    defaultMessage: 'HOMBRE'
  },
  title: {
    id: 'components.Interactions.title',
    defaultMessage: 'Interacciónes'
  }
});
