import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  advertisingTitle: {
    id: 'components.Screens.advertisingTitle',
    defaultMessage: 'PUBLICIDAD'
  },
  screensTitle: {
    id: 'components.Screens.screensTitle',
    defaultMessage: 'PANTALLAS'
  },
  downloadControler: {
    id: 'components.Screens.downloadControler',
    defaultMessage: 'DESCARGAR CONTROLADOR'
  },
  Update: {
    id: 'components.Screens.Update',
    defaultMessage: 'ACTUALIZAR'
  }
});
