import React from 'react';
import { useHistory } from 'react-router-dom';
import { useMainSceneDesign } from './useMainSceneDesign';
import { SceneDesign } from '../SceneDesign';
import { SceneImage } from '../SceneImage';
import { SceneVideo } from '../SceneVideo';
import {
  TYPE_VIEW_DESIGN,
  TYPE_VIEW_IMAGE,
  TYPE_VIEW_VIDEO
} from 'utils/TypeViewScene';

const MainSceneDesign = () => {
  const history = useHistory();

  const idScene = history.location.state ? history.location.state.idScene : 0;
  const nameScene = history.location.state
    ? history.location.state.nameScene
    : '';

  const {
    scene,
    media,
    setMedia,
    mediaSelected,
    setMediaSelected,
    typeView,
    setTypeView,
    submitScene
  } = useMainSceneDesign(idScene, nameScene);

  const viewScene = () => {
    switch (typeView) {
      case TYPE_VIEW_DESIGN:
        return (
          <SceneDesign
            sceneName={scene.name}
            media={media}
            handleChangeMedia={setMedia}
            handleChangeView={setTypeView}
            handleSetMediaSelected={setMediaSelected}
            handleSubmitScene={submitScene}
          />
        );
      case TYPE_VIEW_IMAGE:
        return (
          <SceneImage
            mediaSelected={mediaSelected}
            media={media}
            handleChangeMedia={setMedia}
            handleChangeView={setTypeView}
            handleSetMediaSelected={setMediaSelected}
          />
        );
      case TYPE_VIEW_VIDEO:
        return (
          <SceneVideo
            mediaSelected={mediaSelected}
            media={media}
            handleChangeMedia={setMedia}
            handleChangeView={setTypeView}
            handleSetMediaSelected={setMediaSelected}
          />
        );
    }
  };

  return <>{viewScene()}</>;
};

export { MainSceneDesign };
