import React from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer
} from '@material-ui/core';
import { DragDropListItem } from './DragDropListItem';
import { messages } from './DragDropListMessages';
import { useStyles } from './DragDropListStyles';
import { useIntl } from 'react-intl';

const DragDropList = ({ items, onDragEnd, options }) => {
  const { formatMessage: f } = useIntl();
  const classes = useStyles();

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <TableContainer className={classes.containerTable}>
            <Table>
              <TableHead className={classes.headContainer}>
                <TableRow>
                  <TableCell>{f(messages.positionTitle)}</TableCell>
                  <TableCell>{f(messages.nameTitle)}</TableCell>
                  <TableCell>{f(messages.typeTitle)}</TableCell>
                  <TableCell>{f(messages.timeTitle)}</TableCell>
                  <TableCell>{f(messages.actionTitle)}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody
                ref={provided.innerRef}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...provided.droppableProps}
              >
                {items.map((item, index) => (
                  <DragDropListItem
                    item={item}
                    index={index}
                    key={`${item.id}_${index}`}
                    options={options}
                  />
                ))}
                {provided.placeholder}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export { DragDropList };
