import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2)
  },
  textSelectSurvey: {
    fontSize: '14px',
    marginTop: '20px',
    marginBottom: '20px'
  },
  saveButton: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(1),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  contentButton: {
    display: 'flex',
    marginTop: theme.spacing(1)
  },
  contentCheckBox: {
    marginTop: '20px',
    marginBottom: '20px'
  }
}));

export { useStyles };
