import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleCreateView: {
    id: 'views.Clients.ClientForm.titleCreateView',
    defaultMessage: 'NUEVO CLIENTE'
  },
  titleEditView: {
    id: 'views.Clients.ClientForm.titleEditView',
    defaultMessage: 'EDITAR CLIENTE'
  },
  textNameLogoColor: {
    id: 'views.Clients.ClientForm.textNameLogoColor',
    defaultMessage: 'Nombre, logotipo y colores del cliente'
  },
  textPermissions: {
    id: 'views.Clients.ClientForm.textPermissions',
    defaultMessage: 'Permisos'
  },
  nameLabelForm: {
    id: 'views.Clients.ClientForm.nameLabelForm',
    defaultMessage: 'Nombre'
  },
  uploadImageLabelForm: {
    id: 'views.Clients.ClientForm.uploadImageLabelForm',
    defaultMessage: 'Agregar una imagen:'
  },
  imageLabelForm: {
    id: 'views.Clients.ClientForm.imageLabelForm',
    defaultMessage: 'Subir foto'
  },
  primaryLabelForm: {
    id: 'views.Clients.ClientForm.primaryLabelForm',
    defaultMessage: 'Color primario'
  },
  secondaryLabelForm: {
    id: 'views.Clients.ClientForm.secondaryLabelForm',
    defaultMessage: 'Color secundario'
  },
  colorLabelForm: {
    id: 'views.Clients.ClientForm.colorLabelForm',
    defaultMessage: 'Color de fondo (HEX)'
  },
  saveLabelButton: {
    id: 'views.Clients.ClientForm.saveLabelButton',
    defaultMessage: 'GUARDAR'
  },
  colorHexValidation: {
    id: 'views.Clients.ClientForm.colorHexValidation',
    defaultMessage: 'Formato color HEX invalido.'
  },
  uploadFileError: {
    id: 'views.Clients.ClientForm.uploadFileError',
    defaultMessage:
      'Ha ocurrido un error al cargar el archivo. Intentalo de nuevo más tarde.'
  }
});
