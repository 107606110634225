import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const SoundPlayer = ({ src }) => (
  <div>
    <audio src={src} controls>
      <track kind="captions" />
    </audio>
  </div>
);

SoundPlayer.propTypes = {};

export { SoundPlayer };
