import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { useLoader } from '@octopy/react-loader';
import { useModal, Alert } from '@octopy/react-modal';
import { Typography, Button, Grid, TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useApi } from 'hooks';
import { ButtonSimpleMultimedia } from 'components/ButtonSimpleMultimedia';
import { messages } from './MediaLibraryFormMessages';
import { useStyles } from './MediaLibraryFormStyles';
import { uploadFilePromise } from 'utils/aws';
import { sources } from 'providers/AWS';
import { TYPE_IMAGEN, TYPE_VIDEO } from 'utils/TypeSource';
import { get } from 'lodash';

const MediaLibraryForm = ({ handleCloseDrawer, getMediaFile }) => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleOpenModal } = useModal();
  const { handleShowLoader } = useLoader();
  const storage = JSON.parse(localStorage.getItem('session'));
  const idClient = storage.user.client.client_id;
  const bucketName = get(storage, 'user.client.bucket_name', {});
  const { videos, images } = sources(bucketName);
  const formikButtonRef = useRef();

  const [createMediaFile] = useApi({
    endpoint: 'publicity/media_library/',
    method: 'post'
  });

  const requiredFieldError = intl.formatMessage(messages.requiredFieldError);

  const errorUploadFile = () => {
    handleOpenModal({
      configProps: {
        maxWidth: 'sm'
      },
      body: <Alert message={intl.formatMessage(messages.uploadFileError)} />
    });
    handleShowLoader(false);
  };

  const validationSchema = () =>
    Yup.object({
      name: Yup.string().required(requiredFieldError)
    });

  const formik = useFormik({
    initialValues: {
      name: '',
      source: null
    },
    validationSchema,
    onSubmit: async (values) => {
      let fileList = [];

      if (values.source && values.source.file) {
        handleShowLoader(true);
        const fileSource = values.source.file;
        const urlS3 = await uploadFilePromise(
          fileSource,
          fileSource.type.split('/')[0] === 'video' ? videos : images,
          errorUploadFile
        );

        fileList.push({
          name: values.name,
          url_file: urlS3,
          extention: fileSource.type.split('/')[1],
          file_type:
            fileSource.type.split('/')[0] === 'video'
              ? TYPE_VIDEO
              : TYPE_IMAGEN,

          duration: 2,
          resolution: '1920x1080'
        });

        let body = {
          client: idClient,
          files: fileList
        };

        handleCreateMedia(body);
      }
    }
  });

  const handleCreateMedia = async (body) => {
    const responseCreateMediaFile = await createMediaFile({
      body
    });

    if (responseCreateMediaFile.headerResponse.code === 200) {
      handleShowLoader(false);
      handleCloseDrawer();
      getMediaFile();
    }
  };

  return (
    <div className={classes.container}>
      <Grid container>
        <Grid item xs={12} sm={10} lg={10}>
          <Typography className={classes.infoTextDrawer}>
            {intl.formatMessage(messages.infoTextDrawer)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={10} lg={10} className={classes.text}>
          <TextField
            fullWidth
            variant="outlined"
            label={intl.formatMessage(messages.nameLabel)}
            placeholder={intl.formatMessage(messages.nameLabel)}
            type="text"
            id="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name}
            helperText={formik.touched.name || formik.errors.name}
          />
        </Grid>
      </Grid>
      <Grid className={classes.margin}>
        <ButtonSimpleMultimedia
          audio={false}
          videoImage={true}
          formikRef={formikButtonRef}
        />
      </Grid>
      <Grid xs={9} className={classes.margin}>
        <Typography className={classes.infoTextDrawer}>
          {intl.formatMessage(messages.resolution)}
        </Typography>
      </Grid>
      <Button
        className={classes.button}
        onClick={async () => {
          let validForm = await formik.validateForm(); //{}
          let validSource = await formikButtonRef.current.validateForm(); // {audioVideo:'Campo Requerido'}
          let validateImageErrors = Object.entries(validSource).length === 0;
          let validateFormErrors = Object.entries(validForm).length === 0;

          if (validateImageErrors && validateFormErrors) {
            let file = formikButtonRef.current.values.audioVideo;

            formik.setFieldValue('source', {
              url: URL.createObjectURL(file),
              file
            });
            formik.submitForm();
          }
        }}
      >
        {intl.formatMessage(messages.save)}
      </Button>
    </div>
  );
};

MediaLibraryForm.propTypes = {
  getMediaFile: PropTypes.func,
  handleCloseDrawer: PropTypes.func
};

MediaLibraryForm.defaultProps = {
  getMediaFile: () => {},
  handleCloseDrawer: () => {}
};

export { MediaLibraryForm };
