import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import {
  renderTextField,
  renderImagePicker,
  usePasswordInputs,
  renderAutocomplete
} from '@octopy/react-form';
import { Typography, Grid, Button, IconButton, Card } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useFormRegister } from './useFormRegister';
import { ChangePassword } from './ChangePassword';
import { messages } from './FormRegisterMessages';
import { useStyles } from './FormRegisterStyles';

const FormRegister = ({ onClick, dataEdit = {} }) => {
  const intl = useIntl();
  const classes = useStyles();
  const {
    formik,
    fieldsFormDataUser,
    fieldsFormImage,
    fieldsFormPassword: fields,
    fieldsFormProfile,
    fieldClientList
  } = useFormRegister({ onClick, dataEdit });

  const passwordInputs = usePasswordInputs({ fields, formik });

  const fieldsMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'text' && renderTextField({ index, formik, field })}
    </Grid>
  );

  const fieldsMapperPassword = (field, index) => (
    <Grid key={`password-section-field-${index}`} item {...field.breakpoints}>
      {field.type === 'password' &&
        renderTextField({ index, formik, field, passwordInputs })}
    </Grid>
  );

  const fieldsMapperImage = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'image' && renderImagePicker({ index, formik, field })}
    </Grid>
  );

  const fieldsMapperProfile = (field, index) => (
    <Grid key={`profile-section-field-${index}`} item {...field.breakpoints}>
      {field.type === 'select' &&
        renderAutocomplete({
          index,
          formik,
          field,
          inputProps: { variant: 'outlined', fullWidth: true }
        })}
    </Grid>
  );

  const fieldsMapperClient = (field, index) => (
    <Grid key={`client-section-field-${index}`} item {...field.breakpoints}>
      {field.type === 'select' &&
        renderAutocomplete({
          index,
          formik,
          field,
          inputProps: { variant: 'outlined', fullWidth: true }
        })}
    </Grid>
  );

  return (
    <div className={classes.container}>
      <Grid className={classes.contentHeader}>
        <Typography className={classes.title}>
          {dataEdit
            ? intl.formatMessage(messages.userEdit)
            : intl.formatMessage(messages.titleUser)}
        </Typography>
        <Grid>
          <IconButton onClick={() => onClick()}>
            <ArrowBack className={classes.arrowBack} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} lg={6}>
          <Grid container direction="column" spacing={1}>
            <Grid item lg={12}>
              <Card className={classes.rootCard}>
                <Typography className={classes.titleCard}>
                  {intl.formatMessage(messages.userData)}
                </Typography>
                <Grid container alignContent="flex-start">
                  <Grid item container spacing={2} lg={12}>
                    {fieldsFormDataUser.map(fieldsMapper)}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item lg={12}>
              <Card className={classes.rootCard}>
                <Typography className={classes.titleCard}>
                  {intl.formatMessage(messages.imageProfile)}
                </Typography>
                <Typography className={classes.infoText}>
                  {intl.formatMessage(messages.infoText)}
                </Typography>
                {fieldsFormImage.map(fieldsMapperImage)}
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Grid container direction="column" spacing={1}>
            <Card className={classes.rootCard}>
              <Typography className={classes.titleCard}>
                {intl.formatMessage(messages.clientTitle)}
              </Typography>
              <Typography className={classes.profile}>
                {intl.formatMessage(messages.assignClient)}
              </Typography>
              {fieldClientList.map(fieldsMapperClient)}
            </Card>

            <Card className={classes.rootCard}>
              <Typography className={classes.titleCard}>
                {intl.formatMessage(messages.profilePermissions)}
              </Typography>
              <Typography className={classes.profile}>
                {intl.formatMessage(messages.profile)}
              </Typography>
              {fieldsFormProfile.map(fieldsMapperProfile)}
            </Card>

            <Grid item lg={12}>
              {dataEdit ? (
                <ChangePassword idUser={dataEdit.user_id} />
              ) : (
                <Card className={classes.rootCard}>
                  <Typography className={classes.titleCard}>
                    {intl.formatMessage(messages.password)}
                  </Typography>
                  <Grid container alignContent="flex-start">
                    <Grid item container spacing={2} lg={12}>
                      {fields.map(fieldsMapperPassword)}
                    </Grid>
                  </Grid>
                </Card>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.contentButton}>
          <Button
            onClick={() => formik.submitForm()}
            className={classes.button}
          >
            {dataEdit
              ? intl.formatMessage(messages.save)
              : intl.formatMessage(messages.titleUser)}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

FormRegister.propTypes = {
  onClick: PropTypes.func,
  dataEdit: PropTypes.object
};

export { FormRegister };
