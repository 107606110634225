import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { useIntl } from 'react-intl';
import { Form, messages as formMessages } from '@octopy/react-form';
import { useLoader } from '@octopy/react-loader';
import { Button } from '@material-ui/core';
import * as Yup from 'yup';
import { useApi } from 'hooks';
import { inputProps } from '../utils';
import { messages } from './FormRobotMessages';
import { useStyles } from './FormRobotStyles';
import { getItem } from 'utils/persistentStorage';

const FormRobot = ({
  item = null,
  handleCloseDrawer,
  clientsList,
  getRobotList,
  getSuperAdminRobotList
}) => {
  const storage = getItem('session');

  const isSuperAdmin = get(storage, 'user.is_staff', {});

  const intl = useIntl();
  const classes = useStyles();
  const { handleShowLoader } = useLoader();
  const formikRef = useRef();

  const [createRobot] = useApi({
    endpoint: '/robots/robots/',
    method: 'post'
  });

  const [editRobot] = useApi({
    endpoint: '/robots/robots',
    method: 'put'
  });

  const getInitialValues = (initialValues) => {
    let mapClient;

    if (item) {
      mapClient = item;
    }

    return {
      robot_id: item ? mapClient.robot_id : '',
      name: item ? mapClient.name : '',
      serial_number: item ? item.serial_number : '',
      url: item ? mapClient.url : '',
      client: item ? mapClient.client : '',
      screen_resolution: item ? mapClient.screen_resolution : '',
      ...initialValues
    };
  };

  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );

  const getValidationRules = () =>
    Yup.object({
      name: Yup.string().required(requiredFieldError),
      serial_number: Yup.string().required(requiredFieldError),
      url: Yup.string().required(requiredFieldError),
      client: Yup.string().required(requiredFieldError),
      screen_resolution: Yup.string().required(requiredFieldError)
    });

  const getFields = () => [
    {
      type: 'text',
      name: 'name',
      label: intl.formatMessage(messages.nameLabel),
      placeholder: intl.formatMessage(messages.namePlaceholder),
      breakpoints: { xs: 12, lg: 10 }
    },
    {
      type: 'text',
      name: 'serial_number',
      label: intl.formatMessage(messages.serialLabel),
      placeholder: intl.formatMessage(messages.serialPlaceholder),
      breakpoints: { xs: 12, lg: 10 }
    },
    {
      type: 'text',
      name: 'url',
      label: intl.formatMessage(messages.urlLabel),
      placeholder: intl.formatMessage(messages.urlPlaceholder),
      breakpoints: { xs: 12, lg: 10 }
    },
    {
      type: 'select',
      name: 'client',
      label: intl.formatMessage(messages.clientLabel),
      placeholder: intl.formatMessage(messages.clientPlaceholder),
      breakpoints: { xs: 12, lg: 10 },
      options: clientsList,
      getOptionLabel: (option) => option.label,
      getOptionValue: (option) => option.name,
      getOptionSelected: (option, value) => option === value
    },
    {
      type: 'text',
      name: 'screen_resolution',
      label: intl.formatMessage(messages.resolutionLabel),
      placeholder: intl.formatMessage(messages.resolutionPlaceholder),
      breakpoints: { xs: 12, lg: 10 }
    }
  ];

  const handleSubmit = async (formData) => {
    const responseCreateRobot = await createRobot({
      body: formData
    });

    handleShowLoader(true);
    if (isSuperAdmin) getSuperAdminRobotList();

    if (responseCreateRobot.headerResponse.code === 200) {
      setTimeout(() => {
        handleShowLoader(false);
        handleCloseDrawer();
      }, 1000);
    } else handleShowLoader(false);
  };

  const handleEdit = async (formData) => {
    try {
      const responseEditRobot = await editRobot({
        urlParams: `${formData.robot_id}/`,
        body: formData
      });

      handleShowLoader(true);
      if (isSuperAdmin) getSuperAdminRobotList();
      else getRobotList();

      if (responseEditRobot.headerResponse.code === 200) {
        handleCloseDrawer();
      } else handleShowLoader(false);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classes.container}>
      <Form
        formikRef={formikRef}
        initialValues={getInitialValues()}
        validationRules={getValidationRules()}
        fields={getFields()}
        handleSubmit={item ? handleEdit : handleSubmit}
        showSubmitButton={false}
        inputProps={inputProps}
        withInputsBorder
      />
      <Button
        className={classes.buttonSave}
        onClick={() => formikRef.current.submitForm()}
      >
        {intl.formatMessage(messages.saveButton)}
      </Button>
    </div>
  );
};

FormRobot.propTypes = {
  getRobotList: PropTypes.func,
  getSuperAdminRobotList: PropTypes.func,
  handleCloseDrawer: PropTypes.func,
  clientsList: PropTypes.array
};

export { FormRobot };
