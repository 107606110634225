import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  directoryTitle: {
    id: 'components.Directory.directoryTitle',
    defaultMessage: 'DIRECTORIO'
  },
  exportButton: {
    id: 'components.Directory.exportButton',
    defaultMessage: 'EXPORTAR'
  },
  addItems: {
    id: 'components.Directory.addItems',
    defaultMessage: 'AGREGAR'
  }
});
