import { useIntl } from 'react-intl';

import { messages } from '../CreateUpdate/CreateUpdateMessages';

export const useFields = (formik) => {
  const intl = useIntl();

  const commonBreakpoints = {
    xs: 12,
    sm: 12,
    md: 6,
    lg: 6
  };

  const fields = {
    nameScence: {
      type: 'text',
      name: 'nameScence',
      value: formik.values.nameScence,
      label: intl.formatMessage(messages.textFieldName),
      onChange: formik.handleChange,
      error: formik.touched.nameScence && !!formik.errors.nameScence,
      helperText: formik.touched.nameScence && formik.errors.nameScence,
      breakpoints: commonBreakpoints
    }
  };

  return { fields };
};
