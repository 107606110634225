import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { Table } from '@octopy/react-table';
import { useDrawer } from 'components/Drawer';
import { useModalConfirmation } from 'components/ModalConfirmation';
import { useHistory } from 'react-router-dom';
import { Typography, Grid, Button, IconButton } from '@material-ui/core';
import { AddCircle, Delete, ArrowBack } from '@material-ui/icons';
import { SubcategoriesForm } from './SubcategoriesForm';
import { useApi } from 'hooks';
import { tableHead } from './utils';
import { useStyles } from './SubcategoriesStyles';
import { messages } from './SubcategoriesMessages';

const Subcategories = () => {
  const intl = useIntl();
  const classes = useStyles();
  const history = useHistory();
  const { handleOpenDrawer } = useDrawer();
  const { modalConfirmationDelete } = useModalConfirmation();
  const [subcategoryList, setSubcategoryList] = useState([]);
  const nameCategory = history.location.state.name;
  const categoryId = history.location.state.category_id;

  const [getSubcategoryList] = useApi({
    endpoint: '/catalogue/subcategory/subcategories_by_category',
    method: 'get'
  });

  const [deleteSubcategory] = useApi({
    endpoint: 'catalogue/subcategory',
    method: 'delete'
  });

  const handleGetSubcategoryList = async () => {
    try {
      const response = await getSubcategoryList({
        urlParams: categoryId
      });

      const dataResponse = get(response, 'payload', []);

      if (response.headerResponse.code === 200) {
        const formatData = dataResponse.map((item) => {
          const nameFormat = item.name[0].text;
          const subcategoryId = item.subcategory_id;

          return {
            name: nameFormat,
            subcategory_id: subcategoryId
          };
        });

        setSubcategoryList(formatData);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleGetSubcategoryList();
  }, []);

  const configProps = {
    actions: {
      edit: {
        onClick: (item) => handleDrawer(item.subcategory_id),
        disabled: false,
        hidden: false
      },
      customs: {
        text: 'Eliminar',
        onClick: (item) => handleDelete(item),
        icon: <Delete />
      }
    },
    selectedOptions: {
      checkboxHidden: true
    }
  };

  const handleDrawer = (subcategoryId) => {
    handleOpenDrawer({
      closeButton: true,
      title: (
        <Grid style={{ marginTop: 20 }}>
          {subcategoryId ? (
            <Grid>
              <Typography className={classes.catalogTitle}>
                {intl.formatMessage(messages.editSubcategory)}
              </Typography>
              <Typography className={classes.catalogTitle}>
                <Typography className={classes.catalogTitle}>
                  {intl.formatMessage(messages.of)}
                  <span className={classes.categoriesTitle}>
                    {nameCategory}
                  </span>
                </Typography>
              </Typography>
            </Grid>
          ) : (
            <>
              <Typography className={classes.catalogTitle}>
                {intl.formatMessage(messages.addSubcategory)}
              </Typography>
              <Typography className={classes.catalogTitle}>
                {intl.formatMessage(messages.to)}
                <span className={classes.categoriesTitle}>{nameCategory}</span>
              </Typography>
            </>
          )}
        </Grid>
      ),
      body: (
        <SubcategoriesForm
          subcategoryId={subcategoryId}
          categoryId={categoryId}
          handleGetSubcategoryList={() => handleGetSubcategoryList()}
        />
      ),
      configProps: {
        anchor: 'right'
      }
    });
  };

  const handleDelete = (data) => {
    modalConfirmationDelete(
      intl.formatMessage(messages.titleModal),
      intl.formatMessage(messages.descriptionModal),
      data.name,
      () => {
        handleSubcategoryDelete(data.subcategory_id);
      }
    );
  };

  const handleSubcategoryDelete = async (subcategoryId) => {
    try {
      const response = await deleteSubcategory({
        urlParams: subcategoryId
      });

      if (response.headerResponse.code === 200) {
        handleGetSubcategoryList();
      }
    } catch (error) {}
  };

  return (
    <div className={classes.container}>
      <Grid container spacing={2} className={classes.contentActions}>
        <Grid item xs={12} lg={10} className={classes.contentTitles}>
          <Typography className={classes.catalogTitle}>
            {intl.formatMessage(messages.catalogTitle)}
            &nbsp;-&nbsp;
          </Typography>
          <Typography className={classes.categoriesTitle}>
            {intl.formatMessage(messages.categoryTitle)}
            &nbsp;-&nbsp;
          </Typography>
          <Typography className={classes.catalogTitle}>
            {nameCategory}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={2}
          style={{
            display: 'flex',
            justifyContent: 'flex-end'
          }}
        >
          <IconButton onClick={() => window.history.back()}>
            <ArrowBack className={classes.arrowIcon} />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container className={classes.contentSubactions}>
        <Grid item xs={12} sm={6}>
          <Typography className={classes.catalogTitle}>
            {intl.formatMessage(messages.subcategoryTitle)}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.contentButons}>
          <Button
            className={classes.button}
            onClick={() => handleDrawer()}
            endIcon={<AddCircle />}
          >
            {intl.formatMessage(messages.addSubcategories)}
          </Button>
        </Grid>
      </Grid>
      <Table
        // mainKey="subcategory_id"
        columns={tableHead}
        data={subcategoryList}
        configProps={configProps}
        // onSelectItems={onSelectItems}
      />
    </div>
  );
};

Subcategories.propTypes = {};

export { Subcategories };
