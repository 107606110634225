import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  containerTable: {
    marginTop: theme.spacing(1),
    overflow: 'hidden',
    transition:
      theme.palette.mode === 'light'
        ? 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        : 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: 16,
    boxShadow:
      theme.palette.mode === 'light'
        ? '0 0 2px 0 rgb(170 180 190 / 30%), 0 16px 32px -4px rgb(170 180 190 / 30%)'
        : '0 0 2px 0 rgb(0 0 0 / 25%), 0 16px 32px -4px rgb(0 0 0 / 25%)',
    height: '100%'
  },
  headContainer: {
    height: theme.typography.pxToRem(50)
  }
}));

export { useStyles };
