import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  menuEditLabel: {
    id: 'routes.Metrics.menuEditLabel',
    defaultMessage: 'Bienvenido'
  },
  title: {
    id: 'routes.Metrics.title',
    defaultMessage: 'AUDIOS PARA FLUJOS DE PANTALLA'
  },
  add: {
    id: 'routes.Metrics.add',
    defaultMessage: 'AGREGAR'
  },
  edit: {
    id: 'routes.Metrics.edit',
    defaultMessage: 'Editar'
  }
});
