import { useEffect, useState } from 'react';
import { useLoader } from '@octopy/react-loader';
import { useHistory } from 'react-router-dom';
import { get } from 'lodash';
import { useApi } from 'hooks';
import { useModal } from '@octopy/react-modal';
import { responseMapper } from 'utils/responseMapper';

const DirectoryListMapper = {
  directory_id: 'directory_id',
  name: 'name',
  specialty: 'specialty.name[0].text'
};

export const useDirectory = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const clientId = get(storage, 'user.client.client_id', {});

  const history = useHistory();
  const { handleShowLoader } = useLoader();
  const { handleCloseModal } = useModal();
  const [directory, setDirectory] = useState([]);

  const [getDirectoryList] = useApi({
    endpoint: `/directories/directory/directory_by_client_id/${clientId}/`,
    method: 'get'
  });

  const getDirectoryData = async () => {
    try {
      const response = await getDirectoryList();
      const directoryResponse = get(response, 'payload', []);

      const mappedDirectory = responseMapper({
        template: DirectoryListMapper,
        data: directoryResponse
      });

      setDirectory(mappedDirectory);
      handleShowLoader(false);
    } catch (error) {}
  };

  useEffect(() => {
    handleShowLoader(true);
    getDirectoryData();
  }, []);

  const [deleteItem] = useApi({
    endpoint: `directories/directory`,
    method: 'delete'
  });

  const handleDeleteItem = async (item) => {
    try {
      handleShowLoader(true);
      const response = await deleteItem({ urlParams: item });

      if (response.headerResponse.code === 200) {
        handleCloseModal();
        await getDirectoryData();
      }
    } catch (error) {}
  };

  const handleEditItem = async (item) => {
    history.push('/directory/form', {
      idMedical: item
    });
  };

  return {
    directory,
    handleEditItem,
    handleDeleteItem
  };
};
