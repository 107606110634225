import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import {
  Typography,
  Grid,
  Box,
  Button,
  TextField,
  MenuItem
} from '@material-ui/core';
import { Table } from '@octopy/react-table';
import { useStyles } from './TakeTurnStyles';
import { messages } from './TakeTurnMessages';
import { useTakeTurn } from './useTakeTurn';
import { useField } from './useFied';
import { useTable } from './useTable';
import { useFormikTakeTurns } from './useFormik';
import { AddTakeTurnDrawer } from '../AddTakeTurnDrawer';
import { CardTakeTurn } from '../CardTakeTurn';
import { useDrawer } from 'components/Drawer';
import { useEffect } from 'react';
import { useApi } from 'hooks';
import { get } from 'lodash';

const TakeTurn = () => {
  const { formatMessage: f } = useIntl();
  const classes = useStyles();
  const { handleOpenDrawer } = useDrawer();

  const [boxes, setBoxes] = useState([]);
  const [hasBox, setHasBox] = useState(false);
  const [idHasBox, setIdHasBox] = useState(0);
  const [numberBox, setNumberBox] = useState(0);
  const [numberTurn, setNumberTurn] = useState(0);
  const [isAdminBox, setIsAdminBox] = useState(false);

  const onSubmit = (releaseBox, link) => handleUpdateUserBox(releaseBox, link);

  const { formik } = useFormikTakeTurns({ onSubmit });
  const { turns, tableHead, configProps } = useTable(
    formik.values.selectBranch
  );
  const { fieldsTakeTurn } = useField({ boxes });

  const {
    handleUpdateUserBox,
    handleUpdateTakeTurn,
    handleCloseTurn
  } = useTakeTurn({
    formik,
    idHasBox,
    setBoxes,
    setHasBox,
    setIdHasBox,
    setNumberBox,
    setNumberTurn,
    numberTurn
  });

  const storage = JSON.parse(localStorage.getItem('session'));
  const dataUser = get(storage, 'user', {});
  const userId = get(dataUser, 'user_id', '');

  const [getUserData] = useApi({
    endpoint: `/management/users/${userId}/`,
    method: 'get'
  });

  useEffect(() => {
    if (userId) getUser();
  }, [userId]);

  const getUser = async () => {
    try {
      const response = await getUserData();
      const dataResponse = get(response, 'payload', {});
      const headerResponse = get(response, 'headerResponse', {});

      if (headerResponse.code === 200) {
        const modulesMapper = dataResponse.profile.modules.map((item) => item);
        let example = modulesMapper.find((item) => item.name === 'Admin cajas');

        if (example.name === 'Admin cajas') setIsAdminBox(true);
        else setIsAdminBox(false);
      }
    } catch (error) {}
  };

  const handleOpenAddDrawerBox = () => {
    formik.setValues({ selectBranch: '', releaseBox: '' });
    handleOpenDrawer({
      configProps: {
        anchor: 'right'
      },
      closeButton: true,
      body: <AddTakeTurnDrawer />
    });
  };

  const htmlAssignBox = () => (
    <>
      <Grid item xs={12}>
        <Typography className={classes.tittleView}>
          {f(messages.releaseBox)}
        </Typography>
      </Grid>

      <Grid item {...fieldsTakeTurn.selectBranch.breakpoints}>
        <TextField
          name={fieldsTakeTurn.selectBranch.name}
          id={fieldsTakeTurn.selectBranch.name}
          variant="outlined"
          label={<Typography>{fieldsTakeTurn.selectBranch.label}</Typography>}
          fullWidth
          select
          onChange={formik.handleChange}
          value={formik.values.selectBranch}
          className={classes.rootSelect}
          error={
            formik.touched.selectBranch && Boolean(formik.errors.selectBranch)
          }
          helperText={formik.touched.selectBranch && formik.errors.selectBranch}
        >
          {fieldsTakeTurn.selectBranch.options?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item {...fieldsTakeTurn.releaseBox.breakpoints}>
        <TextField
          name={fieldsTakeTurn.releaseBox.name}
          id={fieldsTakeTurn.releaseBox.name}
          variant="outlined"
          label={<Typography>{fieldsTakeTurn.releaseBox.label}</Typography>}
          fullWidth
          select
          onChange={formik.handleChange}
          value={formik.values.releaseBox}
          className={classes.rootSelect}
          error={formik.touched.releaseBox && Boolean(formik.errors.releaseBox)}
          helperText={formik.touched.releaseBox && formik.errors.releaseBox}
        >
          {fieldsTakeTurn.releaseBox.options?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </TextField>
      </Grid>

      <Grid item xs={12} md={3}>
        <Button
          size="Large"
          className={classes.button}
          color="primary"
          onClick={formik.submitForm}
        >
          <Typography className={classes.textButton}>
            {f(messages.assignBox)}
          </Typography>
        </Button>
      </Grid>

      {isAdminBox && (
        <Grid item xs={12} md={3} className={classes.buttonAdminBox}>
          <Button
            size="Large"
            color="primary"
            className={classes.button}
            onClick={handleOpenAddDrawerBox}
          >
            <Typography className={classes.textButton}>
              {f(messages.adminBox)}
            </Typography>
          </Button>
        </Grid>
      )}
    </>
  );

  return (
    <Box className={classes.container}>
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12} className={classes.contentTitles}>
          <Typography className={classes.tittleView}>
            {f(messages.titleView)}
            &nbsp;-&nbsp;
          </Typography>
          <Typography className={classes.tittleSecondView}>
            {f(messages.titleSecondView)}
          </Typography>
        </Grid>

        {!hasBox && htmlAssignBox()}

        {hasBox && (
          <Grid item xs={12} md={3}>
            <Button
              size="Large"
              className={classes.button}
              color="primary"
              onClick={onSubmit}
              disabled={numberTurn !== 0 ? true : false}
            >
              <Typography className={classes.textButton}>
                {f(messages.releaseBox)}
              </Typography>
            </Button>
          </Grid>
        )}

        <CardTakeTurn
          idHasBox={idHasBox}
          numberBox={numberBox}
          handleUpdateTakeTurn={handleUpdateTakeTurn}
          numberTurn={numberTurn}
          handleCloseTurn={handleCloseTurn}
        />
        <Grid item xs={12}>
          <Typography className={classes.tittleView}>
            {f(messages.titleTable)}
          </Typography>
        </Grid>
      </Grid>
      <Grid className={classes.contentTable}>
        <div className={'tab-0'}>
          <Table columns={tableHead} data={turns} configProps={configProps} />
        </div>
      </Grid>
    </Box>
  );
};

export { TakeTurn };
