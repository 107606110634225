import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { Form, messages as formMessages } from '@octopy/react-form';
import { useModal } from '@octopy/react-modal';
import { useLoader } from '@octopy/react-loader';
import { Box, Button, Typography } from '@material-ui/core';
import * as Yup from 'yup';
import * as formConfig from './form-config';
import { useApi } from 'hooks';
import { Images } from 'assets';
import { messages } from './EmailFormMessages';
import { useStyles } from './EmailFormStyles';

const EmailForm = () => {
  const intl = useIntl();
  const classes = useStyles();
  const [success, setSuccess] = useState(false);
  const { handleCloseModal } = useModal();
  const { handleShowLoader } = useLoader();
  const formikRef = useRef();

  const [recoveryPassword] = useApi({
    endpoint: 'auth/request-reset-email/',
    method: 'post'
  });

  const getInitialValues = () => ({
    email: ''
  });

  const requiredFieldError = intl.formatMessage(
    formMessages.requiredFieldError
  );

  const invalidEmailError = intl.formatMessage(formMessages.invalidEmailError);

  const getValidationRules = () =>
    Yup.object({
      email: Yup.string().email(invalidEmailError).required(requiredFieldError)
    });

  const getFields = () => [
    {
      type: 'text',
      name: 'email',
      label: intl.formatMessage(formMessages.emailLabel),
      placeholder: intl.formatMessage(formMessages.emailPlaceholder)
    }
  ];

  const handleSubmit = async (data) => {
    const responseRecoveryPass = await recoveryPassword({
      body: {
        email: data.email,
        redirect_domain: process.env.REACT_APP_RESET_PASSWORD
      }
    });

    console.log('REDIRECT DOMAIN', responseRecoveryPass);

    handleShowLoader(true);

    if (responseRecoveryPass.headerResponse.code === 200) {
      setTimeout(() => {
        handleShowLoader(false);
        setSuccess(true);
      }, 1000);
    } else handleShowLoader(false);
  };

  return (
    <Box className={classes.container}>
      {success ? (
        <>
          <Typography className={classes.recoveryPasswordTexts}>
            {intl.formatMessage(messages.recoveryText)} <br />
            {intl.formatMessage(messages.passwordText)}
          </Typography>
          <Box mt={1.5} mb={2}>
            <Typography>{intl.formatMessage(messages.emailText)}</Typography>
          </Box>
          <Button
            onClick={() => {
              handleCloseModal();
            }}
            className={classes.buttonSend}
          >
            {intl.formatMessage(messages.acept)}
          </Button>
        </>
      ) : (
        <>
          <Box className={classes.containerImage}>
            <img src={Images.recoveryPasswordLogo} alt="avatar" />
          </Box>
          <Typography className={classes.recoveryPasswordTexts}>
            {intl.formatMessage(messages.recoveryText)} <br />
            {intl.formatMessage(messages.passwordText)}
          </Typography>
          <Typography className={classes.informationText}>
            {intl.formatMessage(messages.informationText)}
          </Typography>
          <Box className={classes.contentForm}>
            <Form
              formikRef={formikRef}
              initialValues={getInitialValues()}
              validationRules={getValidationRules()}
              fields={getFields()}
              handleSubmit={handleSubmit}
              showSubmitButton={false}
              inputProps={formConfig.inputProps}
              withInputsBorder
            />
            <Box className={classes.contentButtons}>
              <Button
                className={classes.buttonSend}
                onClick={() => formikRef.current.submitForm()}
              >
                {intl.formatMessage(messages.send)}
              </Button>
              <Button
                className={classes.buttonCancel}
                onClick={() => handleCloseModal()}
              >
                {intl.formatMessage(messages.cancel)}
              </Button>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

EmailForm.propTypes = {
  onSubmit: PropTypes.node
};

export { EmailForm };
