import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: '15px'
    }
  },
  tittleView: {
    fontSize: theme.typography.pxToRem(20),
    letterSpacing: theme.typography.pxToRem(6),
    color:
      theme.palette.mode === 'light'
        ? theme.palette.common.black
        : theme.palette.common.white,
    fontWeight: 800
  },
  contentTitles: {
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column'
    }
  },
  tittleSecondView: {
    fontSize: theme.typography.pxToRem(20),
    letterSpacing: theme.typography.pxToRem(6),
    color: theme.palette.primary.main,
    fontWeight: 800
  },
  contentButtonCheckout: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonAdminBox: {
    display: 'flex',
    justifyContent: 'end'
  },
  button: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}CE`,
      color: theme.palette.common.white
    }
  },
  textButton: {
    fontSize: theme.typography.pxToRem(16)
  },
  contentTable: {
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(7)
    }
  }
}));

export { useStyles };
