import { makeStyles } from '@material-ui/core/styles';
import { Images } from 'assets';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    backgroundImage: `url(${Images.robotHand})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center 30%',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.common.black,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(1),
      height: 'auto'
    }
  },
  formContainer: {
    maxWidth: theme.typography.pxToRem(340),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  contentTitles: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  octopyLogo: {
    width: '100%',
    height: 'auto',
    marginBottom: theme.spacing(4)
  },
  submitButton: {
    marginTop: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    fontSize: '15px',
    letterSpacing: '1px',
    '&:active': {
      background: theme.palette.info.dark
    },
    '&:hover': {
      background: theme.palette.info.dark
    }
  },
  passwordRecoveryLink: {
    marginTop: theme.spacing(10),
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    fontSize: '16px',
    cursor: 'pointer'
  }
}));

export { useStyles };
