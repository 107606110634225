import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  titleView: {
    id: 'views.Robot.Catalog.titleView',
    defaultMessage: 'ROBOT 01'
  },
  titleSecondView: {
    id: 'views.Robot.Catalog.titleSecondView',
    defaultMessage: 'CATÁLOGO'
  },
  catalogText: {
    id: 'views.Robot.Catalog.catalogText',
    defaultMessage:
      'Selecciona los ítems que deseas agregar al catálogo que mostrará este robot:'
  },
  labelCheckbox: {
    id: 'views.Robot.Catalog.labelCheckbox',
    defaultMessage: 'Aplicar a todos los robots'
  },
  cancel: {
    id: 'views.Robot.Catalog.cancel',
    defaultMessage: 'CANCELAR'
  },
  save: {
    id: 'views.Robot.Catalog.save',
    defaultMessage: 'GUARDAR'
  },
  labelTotal: {
    id: 'views.Robot.Catalog.labelTotal',
    defaultMessage: 'Items agregados:'
  },
  titleSuccessModal: {
    id: 'routes.Catalog.titleSuccessModal',
    defaultMessage: 'Datos guardados con éxito'
  },
  textInfoSucces: {
    id: 'routes.Catalog.textInfoSucces',
    defaultMessage: 'Los productos se han agregado exitosamente.'
  },
  uploadFileError: {
    id: 'views.Catalog.uploadFileError',
    defaultMessage:
      'Ha ocurrido un error al cargar el archivo. Intentalo de nuevo más tarde.'
  },
  emptyAgents: {
    id: 'routes.Catalog.emptyAgents',
    defaultMessage: 'NO EXISTEN AGENTES PARA ESTE ROBOT'
  },
  infoReportProblem: {
    id: 'routes.Catalog.infoReportProblem',
    defaultMessage: 'Consulta a soporte técnico.'
  },
  infoReportProblemIsStaff: {
    id: 'routes.Catalog.infoReportProblemIsStaff',
    defaultMessage:
      'Genere agentes para este robot en: "Relacionar Plataformas", posteriormente, vuelva a intentarlo.'
  },
  acept: {
    id: 'routes.Catalog.acept',
    defaultMessage: 'ACEPTAR'
  }
});
