import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  renderTextField,
  renderImagePicker,
  renderSwitch
} from '@octopy/react-form';
import { useLoader } from '@octopy/react-loader';
import { useIntl } from 'react-intl';
import { Button, Card, Grid, Typography } from '@material-ui/core';
import { useMyAccount } from './useMyAccount';
import { messages } from './MyAccountMessages';
import { useStyles } from './MyAccountStyles';

const MyAccount = () => {
  const intl = useIntl();
  const classes = useStyles();
  const { handleShowLoader } = useLoader();
  const {
    formik,
    fieldsFormDataUser,
    fieldsFormImage,
    modulesFieldsForm
  } = useMyAccount();

  useEffect(() => {
    handleShowLoader(true);
    setTimeout(() => {
      handleShowLoader(false);
    }, 1000);
  }, []);

  const fieldsMapper = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'text' && renderTextField({ index, formik, field })}
    </Grid>
  );

  const fieldsMapperImage = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'image' && renderImagePicker({ index, formik, field })}
    </Grid>
  );

  const fieldsMapperPermissions = (field, index) => (
    <Grid item {...field.breakpoints}>
      {field.type === 'switch' &&
        renderSwitch({
          index,
          formik,
          field
        })}
    </Grid>
  );

  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        {intl.formatMessage(messages.title)}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
          <Grid container spacing={2} direction="column">
            <Grid item>
              <Card className={classes.rootCard}>
                <Typography className={classes.titleCard}>
                  {intl.formatMessage(messages.userData)}
                </Typography>
                <Grid container alignContent="flex-start">
                  <Grid item container spacing={2} lg={12}>
                    {fieldsFormDataUser.map(fieldsMapper)}
                  </Grid>
                </Grid>
              </Card>
            </Grid>
            <Grid item>
              <Card className={classes.rootCard}>
                <Typography className={classes.titleCard}>
                  {intl.formatMessage(messages.userData)}
                </Typography>
                <Typography className={classes.imageTextInfo}>
                  {intl.formatMessage(messages.imageTextInfo)}
                </Typography>
                {fieldsFormImage.map(fieldsMapperImage)}
              </Card>
            </Grid>
            <Grid item>
              <Card className={classes.rootCard}>
                <Typography className={classes.titleCard}>
                  {intl.formatMessage(messages.storageData)}
                </Typography>
                <Grid className={classes.displaFlex}>
                  <Typography className={classes.formatText}>
                    {intl.formatMessage(messages.used)} &nbsp;
                  </Typography>
                  <Typography className={classes.storageText}>
                    0.53 GB de 5 GB
                  </Typography>
                </Grid>
                <Grid style={{ marginTop: 20 }}>
                  <Typography className={classes.formatText}>
                    {intl.formatMessage(messages.details)}
                  </Typography>
                  <Grid className={classes.displaFlex}>
                    <Typography className={classes.formatText}>
                      {intl.formatMessage(messages.advertising)} &nbsp;
                    </Typography>
                    <Typography className={classes.storageText}>
                      0.2 GB
                    </Typography>
                  </Grid>
                  <Grid className={classes.displaFlex}>
                    <Typography className={classes.formatText}>
                      {intl.formatMessage(messages.catalogue)} &nbsp;
                    </Typography>
                    <Typography className={classes.storageText}>
                      0.2 GB
                    </Typography>
                  </Grid>
                  <Grid className={classes.displaFlex}>
                    <Typography className={classes.formatText}>
                      {intl.formatMessage(messages.voicebot)} &nbsp;
                    </Typography>
                    <Typography className={classes.storageText}>
                      0.2 GB
                    </Typography>
                  </Grid>
                  <Grid className={classes.displaFlex}>
                    <Typography className={classes.formatText}>
                      {intl.formatMessage(messages.surveys)} &nbsp;
                    </Typography>
                    <Typography className={classes.storageText}>
                      0.01 GB
                    </Typography>
                  </Grid>
                  <Grid className={classes.displaFlex}>
                    <Typography className={classes.formatText}>
                      {intl.formatMessage(messages.callData)} &nbsp;
                    </Typography>
                    <Typography className={classes.storageText}>
                      0.01 GB
                    </Typography>
                  </Grid>
                  <Grid style={{ display: 'flex', marginBottom: 10 }}>
                    <Typography className={classes.formatText}>
                      {intl.formatMessage(messages.interactionLog)} &nbsp;
                    </Typography>
                    <Typography className={classes.storageText}>
                      0.01 GB
                    </Typography>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Card className={classes.rootCard}>
            <Typography className={classes.titleCard}>
              {intl.formatMessage(messages.rolePermission)}
            </Typography>
            <Grid>
              <Typography className={classes.formatText}>
                {intl.formatMessage(messages.assignedRole)}
              </Typography>
              <Typography className={classes.storageText}>
                Usuario Operador
              </Typography>
            </Grid>
            <Grid style={{ marginTop: 10 }}>
              <Typography className={classes.formatText}>
                {intl.formatMessage(messages.assignedPermissions)}
              </Typography>
              <Typography className={classes.storageText}>
                {intl.formatMessage(messages.permissionsText)}
              </Typography>
              <Grid container item xs={12} style={{ marginTop: 15 }}>
                {modulesFieldsForm.map(fieldsMapperPermissions)}
              </Grid>
            </Grid>
          </Card>
          <Grid className={classes.contentButton}>
            <Button
              className={classes.button}
              onClick={() => formik.submitForm()}
            >
              {intl.formatMessage(messages.update)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

MyAccount.propTypes = {
  exampleProp: PropTypes.string.isRequired
};

export { MyAccount };
