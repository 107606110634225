import { useEffect, useRef, useState } from 'react';
import { useApi } from 'hooks';
import { useHistory } from 'react-router-dom';
import { useLoader } from '@octopy/react-loader';
import { get } from 'lodash';
import { responseMapper } from 'utils/responseMapper';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { messages } from '../UpdateGestureMessages';
import * as Yup from 'yup';
import { messages as formMessages } from '@octopy/react-form';
import { getFileFromUrl } from 'utils/fileAudioUrl';
import { uploadFilePromise } from 'utils/aws';
import { sources } from 'providers/AWS';
import { useModal, Alert } from '@octopy/react-modal';

const keyMapper = {
  key: 'agent_key',
  language: 'language.language_id',
  pitch: 'pitch',
  speed: 'speed',
  tone: 'voice_tone'
};

const mapperIntents = {
  agentId: 'agent.agent_id',
  gestures: 'gestures',
  audios: 'audios',
  name: 'name',
  id: 'id'
};

export const useUpdateGesture = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const bucketName = get(storage, 'user.client.bucket_name', {});

  const { formatMessage } = useIntl();
  const history = useHistory();
  const { handleShowLoader } = useLoader();
  const { handleOpenModal } = useModal();
  const { audios } = sources(bucketName);
  const [audioAgent, setAudioAgent] = useState({});
  const [showSoundPlayer, setShowSoundPlayer] = useState(false);
  const [prevewSpeech, setPrevewSpeech] = useState(null);
  const [dataAudios, setDataAudios] = useState([]);
  const [gestureL, setGestureL] = useState([]);
  // Hook de estado para data de videos
  const [videoL, setVideoL] = useState([]);
  // Hook de estado para almacenar el media_id
  const [selectedMediaId, setSelectedMediaId] = useState(null);
  const [tabGesture, setTabGesture] = useState([]);
  const [inputName, setInputName] = useState('');
  const formikRef = useRef();

  const idIntents = history.location.state
    ? history.location.state.idIntents
    : history.push('/robots');

  // consulta del id de cliente
  const idClient = storage.user.client.client_id;

  // console.log('ID CLIENT', idClient);

  const [intents] = useApi({
    endpoint: `/intents/${idIntents}/`,
    method: 'get'
  });

  const [createSpeech] = useApi({
    endpoint: `/speech/`,
    method: 'post'
  });

  const [gestures] = useApi({
    endpoint: '/gestures/',
    method: 'get'
  });

  const [agents] = useApi({
    endpoint: `/agents`,
    method: 'get'
  });

  //consulta de api biblioteca de medios

  const [publicity] = useApi({
    endpoint: `/publicity/media_library/media_by_client/${idClient}/`,
    method: 'get'
  });

  const getDataAgent = async (idAgent) => {
    try {
      const responseAgents = await agents({
        urlParams: `${idAgent}/`
      });

      if (responseAgents.headerResponse.code === 200) {
        const dataResponseAgents = get(responseAgents, 'payload', []);

        const agentMapper = responseMapper({
          template: keyMapper,
          data: dataResponseAgents
        });

        setAudioAgent(agentMapper);
      }
    } catch (error) {}
  };

  const getIntents = async () => {
    handleShowLoader(true);
    try {
      const responseIntents = await intents();

      if (responseIntents.headerResponse.code === 200) {
        const dataResponseIntents = get(responseIntents, 'payload', []);

        // console.log('INTENTS', dataResponseIntents);

        const intentsMapper = responseMapper({
          template: mapperIntents,
          data: dataResponseIntents
        });

        // console.log('INTENTSMAPPER', intentsMapper);

        if (intentsMapper.audios && intentsMapper.gestures !== null) {
          setDataAudios(intentsMapper.audios);
          setTabGesture(intentsMapper.gestures);
        }

        setInputName(intentsMapper.name);
        getDataAgent(intentsMapper.agentId);
        handleShowLoader(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getIntents();
    getGestureList();
    getPublicityList();
  }, []);

  const handleCreateSpeech = async () => {
    try {
      let bodySpeech = {
        key: audioAgent.key,
        language: audioAgent.language,
        pitch: audioAgent.pitch,
        speed: audioAgent.speed,
        text: formik.values.text,
        tone: audioAgent.tone
      };

      const responseCreateSpeech = await createSpeech({
        body: bodySpeech
      });

      if (responseCreateSpeech.headerResponse.code === 200) {
        const dataResponseCreateSpeech = get(
          responseCreateSpeech,
          'payload',
          ''
        );

        setPrevewSpeech(dataResponseCreateSpeech);
      }
    } catch (error) {}
  };

  const getGestureList = async () => {
    try {
      const responseGestureList = await gestures();

      if (responseGestureList.headerResponse.code === 200) {
        const dataResponseGestureList = get(responseGestureList, 'payload', []);

        setGestureL(dataResponseGestureList);
      }
    } catch (error) {}
  };

  // despliege de la data de biblioteca de medios

  const getPublicityList = async () => {
    try {
      const responsePublicityList = await publicity();

      if (responsePublicityList.headerResponse.code === 200) {
        const dataResponsePublicityList = get(
          responsePublicityList,
          'payload',
          []
        );

        setVideoL(dataResponsePublicityList);

        // console.log('LISTA VIDEOS', dataResponsePublicityList);
      }
    } catch (error) {}
  };

  const validationSchema = Yup.object({
    text: Yup.string().required(formatMessage(messages.requiredField))
  });

  const formik = useFormik({
    initialValues: {
      text: ''
    },
    validationSchema,
    onSubmit: async (values) => {
      let bodySpeech = {
        key: audioAgent.key,
        language: audioAgent.language,
        pitch: audioAgent.pitch,
        speed: audioAgent.speed,
        text: formik.values.text,
        tone: audioAgent.tone
      };

      const responseCreateSpeech = await createSpeech({
        body: bodySpeech
      });

      if (responseCreateSpeech.headerResponse.code === 200) {
        const dataResponseCreateSpeech = get(
          responseCreateSpeech,
          'payload',
          ''
        );

        const fileUrl = await getFileFromUrl(dataResponseCreateSpeech, 'audio');
        const urlS3 = await uploadFilePromise(fileUrl, audios, errorUploadFile);

        let newBody = {
          text: bodySpeech.text,
          s3_url: urlS3
        };

        let aux = dataAudios.slice();

        aux.push({ ...newBody });

        setDataAudios(aux);
        formik.resetForm();
        setShowSoundPlayer(false);
      }
    }
  });

  const errorUploadFile = () => {
    handleOpenModal({
      configProps: {
        maxWidth: 'sm'
      },
      body: <Alert message={formatMessage(messages.uploadFileError)} />
    });
    handleShowLoader(false);
  };

  const getInitialValues = () => ({
    gesture: '',
    time: 1,
    // agregar formulario de video
    video: ''
  });

  const requiredFieldError = formatMessage(formMessages.requiredFieldError);

  const onlyNumbers = formatMessage(messages.onlyNumbers);
  const positiveNumbers = formatMessage(messages.positiveNumbers);
  const decimalNumbers = formatMessage(messages.decimalNumbers);

  const getValidationRules = () =>
    Yup.object({
      gesture: Yup.string().required(requiredFieldError),
      // time: Yup.number()
      //   .typeError(onlyNumbers)
      //   .positive(positiveNumbers)
      //   .integer(decimalNumbers)
      //   .required(requiredFieldError),
      // agregar validacion de video
      video: Yup.string().required(requiredFieldError)
    });

  const getFields = () => [
    {
      type: 'select',
      name: 'gesture',
      label: formatMessage(messages.gestureLabel),
      placeholder: formatMessage(messages.gesturePlaceholder),
      options: gestureL,
      getOptionLabel: (option) => `${option.name || ''}`,
      getOptionValue: (option) => `${option.id_gesture || ''}`,
      getOptionSelected: (option, value) => option === value,
      breakpoints: { xs: 12, lg: 6 }
    },
    // {
    //   type: 'text',
    //   name: 'time',
    //   label: formatMessage(messages.timeLabel),
    //   placeholder: formatMessage(messages.timePlaceholder),
    //   breakpoints: { xs: 12, lg: 6 }
    // },
    // field video
    {
      type: 'select',
      name: 'video',
      label: formatMessage(messages.videoLabel),
      placeholder: formatMessage(messages.videoPlaceholder),
      options: videoL,
      getOptionLabel: (optionvideo) => `${optionvideo.name || ''}`,
      getOptionValue: (optionvideo) => `${optionvideo.media_id || ''}`,
      getOptionSelected: (optionvideo, value) => optionvideo === value,
      breakpoints: { xs: 12, lg: 6 }
    }
  ];

  const handleSubmit = (values) => {
    const idGesture = parseInt(values.gesture);
    const IdVideo = parseInt(values.video);
    let dataGesture = gestureL.find((item) => item.id_gesture === idGesture);
    //agregar video seleccionado
    let selectedVideo = videoL.find(
      (itemVideo) => itemVideo.media_id === IdVideo
    );

    // console.log('VALUES', values);
    // console.log('dataGesture', dataGesture);
    // console.log('Video', selectedVideo);

    handleShowLoader(true);
    setTimeout(() => {
      let aux = tabGesture.slice();

      aux.push({
        id_gesture: dataGesture.id_gesture,
        name: dataGesture.name,
        duration: values.time,
        media_id: selectedVideo.media_id
      });

      // console.log('AUX', aux);

      setTabGesture(aux);

      setSelectedMediaId(selectedVideo.media_id);

      formikRef.current.resetForm();
      handleShowLoader(false);
    }, 1000);
  };

  return {
    formik,
    formikRef,
    showSoundPlayer,
    prevewSpeech,
    dataAudios,
    tabGesture,
    selectedMediaId,
    inputName,
    setDataAudios,
    handleCreateSpeech,
    setShowSoundPlayer,
    setTabGesture,
    getValidationRules,
    getInitialValues,
    getFields,
    handleSubmit
  };
};
