import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2)
  },
  subtitle: {
    fontSize: '14px',
    color: theme.palette.common.black,
    marginTop: theme.spacing(2)
  },
  switchText: {
    fontSize: '16px',
    color: theme.palette.common.black,
    opacity: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1.5)
  },
  buttonSave: {
    background: theme.palette.info.light,
    color: theme.palette.common.white,
    padding: theme.spacing(1.3),
    marginTop: theme.spacing(4),
    fontSize: '15px'
  }
}));

export { useStyles };
