/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import { get } from 'lodash';
import { Table } from '@octopy/react-table';
import { useLoader } from '@octopy/react-loader';
import { Typography, Grid, Tab, Tabs, Box } from '@material-ui/core';
import {
  Edit as EditIcon,
  Notifications,
  RecordVoiceOver
} from '@material-ui/icons';
import { useApi } from 'hooks';
import { messages } from './GesturesMessages';
import { useStyles } from './GesturesStyles';
import { useHistory, useLocation } from 'react-router-dom';
import { HeaderTitle } from 'components/HeaderTitle';

const Gestures = () => {
  const intl = useIntl();
  const classes = useStyles();
  const location = useLocation();
  const { handleShowLoader } = useLoader();
  const [dataTable, setDataTable] = useState([]);
  const [session, setSession] = useState([]);
  const idClient = get(location, ['state', 'client'], '');

  const history = useHistory();

  const [gestures] = useApi({
    endpoint: '/gestures/',
    method: 'get'
  });

  const getGestures = async () => {
    handleShowLoader(true);
    const responseGestures = await gestures();

    const gesturesResponse = get(responseGestures, 'payload', []);

    setDataTable(gesturesResponse);
    handleShowLoader(false);
  };

  const [value, setValue] = useState(0);

  const getAgentes = async (value) => {
    let intents = [];

    const responseGestures = await agentList({
      urlParams: value
    });

    if (responseGestures.headerResponse.code === 200) {
      const gesturesResponse = get(responseGestures, 'payload', []);

      intents = intents.concat(gesturesResponse);

      const array = [];

      intents.map((items) => {
        array.push({
          ...items,
          audios: items.audios ? items.audios.length : 0,
          gestures: items.gestures ? items.gestures.length : 0
        });
      });

      setDataTable(array);
    }
  };

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    if (newValue !== 0) {
      const getno = await sessionRobot({
        urlParams: `${newValue}/${get(location, ['state', 'robot_id'], '')}`
      });

      if (get(getno, ['headerResponse', 'code'], 400) === 200) {
        getAgentes(get(getno, ['payload', [0], 'agent_id'], ''));
      }
    }
  };

  const [agentList] = useApi({
    endpoint: '/intents/intents_by_agent_id',
    method: 'get'
  });

  const [sessionRobot] = useApi({
    endpoint: '/agents/agents_by_session_type_client',
    method: 'get'
  });

  /* const getAgentsGesture = async () => {
    console.log('?, value', value);
    console.log('agentRobot >>>', agentRobot );
    const agentsBySession = agentRobot.filter(
      (x) => x.session_type.session_id === value
    );

    console.log('agentsBySession >>>', agentsBySession );

    let intents = [];

    for (let i = 0; i < agentsBySession.length; i++) {
      const responseGestures = await agentList({
        urlParams: agentsBySession[i].agent_id
      });

      console.log('responseGestures >>>', responseGestures );

      if (responseGestures.headerResponse.code === 200) {
        const gesturesResponse = get(responseGestures, 'payload', []);

        intents = intents.concat(gesturesResponse);
      } 
    }

     const array = [];

    intents.map((items) => {
      array.push({
        ...items,
        audios: items.audios ? items.audios.length : 0,
        gestures: items.gestures ? items.gestures.length : 0
      });
    });

    setDataTable(array);
  }; */

  const [sessionByClient] = useApi({
    endpoint: `/session_client/session_client/sessions_by_client/${idClient}/`,
    method: 'get'
  });

  const info = [
    {
      tabId: 0,
      tabName: (
        <Box className={classes.boxTitle}>
          <Notifications fontSize="small" />
          <Typography className={classes.tabTitle}>
            {intl.formatMessage(messages.Gestures)}
          </Typography>
        </Box>
      ),
      tableHead: [
        { key: 'id_gesture', label: 'ID' },
        { key: 'name', label: 'GESTO' }
      ]
    }
  ];

  const getSessionByClient = async () => {
    try {
      const responseSessionByClient = await sessionByClient();

      if (responseSessionByClient.headerResponse.code === 200) {
        const dataResponseSessionByClient = get(
          responseSessionByClient,
          'payload',
          []
        );

        dataResponseSessionByClient.sort((a, b) =>
          a.session_name.localeCompare(b.session_name)
        );
        let sessionTab = info;

        dataResponseSessionByClient.map((itemSeession) => {
          let tab = {
            tabId: itemSeession.session_id,
            tabName: (
              <Box className={classes.boxTitle}>
                <RecordVoiceOver fontSize="small" />
                <Typography className={classes.tabTitle}>
                  {itemSeession.session_name}
                </Typography>
              </Box>
            ),
            tableHead: [
              { key: 'id', label: 'ID' },
              { key: 'name', label: 'INTENCIÓN' },
              { key: 'audios', label: 'AUDIOS' },
              { key: 'gestures', label: 'GESTOS' }
            ]
          };

          sessionTab.push(tab);
        });

        setSession(sessionTab);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSessionByClient();
  }, []);

  useEffect(async () => {
    setDataTable([]);
    if (value === 0) {
      await getGestures();
    }
  }, [value]);

  const actionFunctions = {
    selectedOptions: {
      checkboxHidden: true
    },
    actions: {
      edit: {
        text: 'Editar',
        icon: <EditIcon />,
        onClick: (item) => {
          history.push('/robot/gestures/edit', {
            idIntents: item.id
          });
        }
      }
    }
  };

  const tabProps = (index) => ({
    id: `simple-tabpanel-${index}`,
    'aria-constrol': `simple-tabpanel-${index}`
  });

  return (
    <div className={classes.container}>
      <HeaderTitle
        firstTitle={history.location.state.name}
        secondTitle={intl.formatMessage(messages.gesturesTitle)}
        redirection="robots"
      />
      <Grid item xs={12} sm={12} md={12} lg={12} style={{ marginTop: 40 }}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
        >
          {session.map((item, index) => (
            <Tab
              label={item.tabName}
              {...tabProps(item.tabId)}
              value={item.tabId}
            />
          ))}
        </Tabs>
        {session.map((item, index) => (
          <div
            role="tabpanel"
            hidden={value !== item.tabId}
            id={`simple-tabpanel-${item.tabId}`}
            aria-labelledby={`simple-tabpanel-${item.tabId}`}
          >
            {value === item.tabId && (
              <Grid className={classes.contentTable}>
                <div className={`tab-${item.tabId}`}>
                  <Table
                    columns={item.tableHead}
                    data={dataTable}
                    configProps={actionFunctions}
                  />
                </div>
              </Grid>
            )}
          </div>
        ))}
      </Grid>
    </div>
  );
};

Gestures.propTypes = {};

export { Gestures };
