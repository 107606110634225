import { useEffect, useState } from 'react';
import { useLoader } from '@octopy/react-loader';
import { useIntl } from 'react-intl';
import { messages } from './HistoryTurnMessages';
import { get } from 'lodash';
import { useApi } from 'hooks';
import { format } from 'date-fns';

export const mappedTurns = (turns) => {
  const turnsMapped = turns.map((item) => ({
    id: item.id,
    turn: item.turn,
    created_at: format(
      new Date(item.created_at),
      `${'dd/MM/yyyy'} - ${'HH:mm:ss'}`
    ),
    attention_date: format(
      new Date(item.attention_date),
      `${'dd/MM/yyyy'} - ${'HH:mm:ss'}`
    ),
    waiting_time: item.waiting_time,
    end_date: format(
      new Date(item.end_date),
      `${'dd/MM/yyyy'} - ${'HH:mm:ss'}`
    ),
    service_duration: item.service_duration,
    attended_by: item.attended_by
      ? `${item.attended_by.name} ${item.attended_by.last_name}`
      : '-'
  }));

  return turnsMapped;
};

export const useHistoryTurn = () => {
  const { formatMessage: f } = useIntl();
  const { handleShowLoader } = useLoader();
  const [turns, setTurns] = useState();

  const [getTurnsList] = useApi({
    endpoint: `/turns/turn/`,
    method: 'get'
  });

  const getTurnsData = async () => {
    try {
      const response = await getTurnsList();
      const turnsResponse = get(response, 'payload', []);
      const headerResponse = get(response, 'headerResponse', '{');

      if (headerResponse.code === 200) {
        const formatData = mappedTurns(turnsResponse);

        setTurns(formatData);
        handleShowLoader(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleShowLoader(true);
    getTurnsData();
  }, []);

  const tableHead = [
    { key: 'id', label: f(messages.id) },
    { key: 'turn', label: f(messages.turn) },
    { key: 'created_at', label: f(messages.dateRequest) },
    { key: 'attention_date', label: f(messages.dateAssign) },
    { key: 'waiting_time', label: f(messages.standbyTime) },
    { key: 'end_date', label: f(messages.dateAttention) },
    { key: 'service_duration', label: f(messages.attentionTime) },
    { key: 'attended_by', label: f(messages.attendedBy) }
  ];

  const filters = [
    { key: 'turn', value: f(messages.turn) },
    { key: 'attendedBy', value: f(messages.attendedBy) }
  ];

  const configProps = {
    filters,
    selectedOptions: {
      checkboxHidden: true,
      disabled: true
    },
    tableOptions: {
      Option: {
        disabled: true
      }
    }
  };

  return {
    turns,
    tableHead,
    configProps
  };
};
