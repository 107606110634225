import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  headerBackground: {
    background: theme.palette.common.black,
    color: theme.palette.common.white,
    height: theme.typography.pxToRem(65)
  },
  title: {
    flexGrow: 1,
    color: theme.palette.common.white
  },
  titleContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)'
  },
  titleDecoration: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(40),
    gridColumn: '1 / 2',
    gridRow: '1 / 3',
    justifySelf: 'center'
  },
  textTitleContainer: {
    fontSize: theme.typography.pxToRem(12),
    gridColumn: '2 / 5',
    gridRow: '1 / 3',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    fontWeight: 'bold'
  },
  subtitle: {
    fontSize: theme.typography.pxToRem(10),
    marginTop: theme.spacing(-2),
    whiteSpace: 'nowrap',
    fontWeight: 'lighter'
  },
  link: {
    display: 'flex',
    color: 'inherit',
    textDecoration: 'none'
  },
  badge: {
    margin: `0 ${theme.spacing(2)}px`,
    color: theme.palette.common.black,
    '& .MuiBadge-anchorOriginTopRightRectangle': {
      backgroundColor: theme.palette.success.main
    }
  },
  notificationIcon: {
    color: theme.palette.common.white
  },
  drawerTitle: {
    fontSize: '20px',
    letterSpacing: '6px',
    fontWeight: 800,
    alignItems: 'center'
  },
  alertNumber: {
    fontSize: '14px',
    marginBottom: theme.spacing(1)
  }
}));

export { useStyles };
