import {
  Notifications,
  SupervisorAccount,
  Face,
  Timelapse,
  Audiotrack,
  Cached,
  Event,
  PermContactCalendar,
  MenuBook,
  Apartment,
  LibraryBooks
} from '@material-ui/icons';
import { useIntl } from 'react-intl';
import { messages } from './RobotMessages';
import { get } from 'lodash';

export const useMenuRobot = () => {
  const storage = JSON.parse(localStorage.getItem('session'));
  const isSuperAdmin = get(storage, 'user.is_staff', {});
  const intl = useIntl();

  const menuSuperAdmin = [
    {
      path: '/robot/alerts',
      message: intl.formatMessage(messages.robotAlerts),
      icon: <Notifications fontSize="small" />
    },
    {
      path: '/robot/audios',
      message: intl.formatMessage(messages.robotAudios),
      icon: <Audiotrack fontSize="small" />
    },
    {
      path: '/robot/catalog',
      message: intl.formatMessage(messages.robotCatalog),
      icon: <MenuBook fontSize="small" />
    },
    {
      path: '/robot/directory',
      message: intl.formatMessage(messages.robotDitectory),
      icon: <PermContactCalendar fontSize="small" />
    },
    {
      path: '/robot/events',
      message: intl.formatMessage(messages.robotEvent),
      icon: <Event fontSize="small" />
    },
    {
      path: '/robot/gestures',
      message: intl.formatMessage(messages.robotGestures),
      icon: <Face fontSize="small" />
    },
    {
      path: '/robot/interactions',
      message: intl.formatMessage(messages.robotInteractions),
      icon: <SupervisorAccount fontSize="small" />
    },
    {
      path: '/robot/branches',
      message: intl.formatMessage(messages.robotBranches),
      icon: <Apartment fontSize="small" />
    },
    {
      path: '/robot/relate-platforms',
      message: intl.formatMessage(messages.robotRelatePlataforms),
      icon: <Cached fontSize="small" />
    },
    {
      path: '/robot/times',
      message: intl.formatMessage(messages.robotTimes),
      icon: <Timelapse fontSize="small" />
    },
    {
      path: '/robot/personal-information',
      message: intl.formatMessage(messages.personalInformationTitle),
      icon: <LibraryBooks fontSize="small" />
    }
  ];

  const menuUser = [
    {
      path: '/robot/alerts',
      message: intl.formatMessage(messages.robotAlerts),
      icon: <Notifications fontSize="small" />
    },
    {
      path: '/robot/catalog',
      message: intl.formatMessage(messages.robotCatalog),
      icon: <MenuBook fontSize="small" />
    },
    // {
    //   path: '/robot/directory',
    //   message: intl.formatMessage(messages.robotDitectory),
    //   icon: <PermContactCalendar fontSize="small" />
    // },
    {
      path: '/robot/events',
      message: intl.formatMessage(messages.robotEvent),
      icon: <Event fontSize="small" />
    },
    {
      path: '/robot/gestures',
      message: intl.formatMessage(messages.robotGestures),
      icon: <Face fontSize="small" />
    },
    {
      path: '/robot/interactions',
      message: intl.formatMessage(messages.robotInteractions),
      icon: <SupervisorAccount fontSize="small" />
    }
    // {
    //   path: '/robot/branches',
    //   message: intl.formatMessage(messages.robotBranches),
    //   icon: <Apartment fontSize="small" />
    // },
    // {
    //   path: '/robot/personal-information',
    //   message: intl.formatMessage(messages.personalInformationTitle),
    //   icon: <LibraryBooks fontSize="small" />
    // }
    // {
    //   path: '/robot/relate-platforms',
    //   message: intl.formatMessage(messages.robotRelatePlataforms),
    //   icon: <Cached fontSize="small" />
    // }
  ];
  const menuRobot = ({ menuElements }) =>
    isSuperAdmin ? menuSuperAdmin : menuUser;

  return { menuRobot };
};
