import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  nameLabel: {
    id: 'components.FormRobot.nameLabel',
    defaultMessage: 'Nombre'
  },
  namePlaceholder: {
    id: 'components.FormRobot.namePlaceholder',
    defaultMessage: 'Nombre'
  },
  serialLabel: {
    id: 'components.FormRobot.serialLabel',
    defaultMessage: 'Número de serie'
  },
  serialPlaceholder: {
    id: 'components.FormRobot.serialPlaceholder',
    defaultMessage: 'Número de serie'
  },
  urlLabel: {
    id: 'components.FormRobot.urlLabel',
    defaultMessage: 'URL'
  },
  urlPlaceholder: {
    id: 'components.FormRobot.urlLabel',
    defaultMessage: 'URL'
  },
  clientLabel: {
    id: 'components.FormRobot.clientLabel',
    defaultMessage: 'Cliente'
  },
  clientPlaceholder: {
    id: 'components.FormRobot.clientPlaceholder',
    defaultMessage: 'Cliente'
  },
  resolutionLabel: {
    id: 'components.FormRobot.resolutionhLabel',
    defaultMessage: 'Resolucion'
  },
  resolutionPlaceholder: {
    id: 'components.FormRobot.resolutionPlaceholder',
    defaultMessage: 'Resolucion'
  },
  // clientLabel: {
  //   id: 'components.FormRobot.clientLabel',
  //   defaultMessage: 'Cliente'
  // },
  // clientPlaceholder: {
  //   id: 'components.FormRobot.clientPlaceholder',
  //   defaultMessage: 'Cliente'
  // },
  titleResolution: {
    id: 'components.FormRobot.titleResolution',
    defaultMessage: 'Agrega la resolución de la pantalla del robot'
  },
  saveButton: {
    id: 'components.FormRobot.saveButton',
    defaultMessage: 'GUARDAR'
  }
});
